export const AllIcons = [
	/*
    { name: 'stepforward', type: 'AntDesign' },
    { name: 'stepbackward', type: 'AntDesign' },
    { name: 'forward', type: 'AntDesign' },
    { name: 'banckward', type: 'AntDesign' },
    { name: 'caretright', type: 'AntDesign' },
    { name: 'caretleft', type: 'AntDesign' },
    { name: 'caretdown', type: 'AntDesign' },
    { name: 'caretup', type: 'AntDesign' },
    { name: 'rightcircle', type: 'AntDesign' },
    { name: 'leftcircle', type: 'AntDesign' },
    { name: 'upcircle', type: 'AntDesign' },
    { name: 'downcircle', type: 'AntDesign' },
    { name: 'rightcircleo', type: 'AntDesign' },
    { name: 'leftcircleo', type: 'AntDesign' },
    { name: 'upcircleo', type: 'AntDesign' },
    { name: 'downcircleo', type: 'AntDesign' },
    { name: 'verticleleft', type: 'AntDesign' },
    { name: 'verticleright', type: 'AntDesign' },
    { name: 'back', type: 'AntDesign' },
    { name: 'retweet', type: 'AntDesign' },
    { name: 'shrink', type: 'AntDesign' },
    { name: 'arrowsalt', type: 'AntDesign' },
    { name: 'doubleright', type: 'AntDesign' },
    { name: 'doubleleft', type: 'AntDesign' },
    { name: 'arrowdown', type: 'AntDesign' },
    { name: 'arrowup', type: 'AntDesign' },
    { name: 'arrowright', type: 'AntDesign' },
    { name: 'arrowleft', type: 'AntDesign' },
    { name: 'down', type: 'AntDesign' },
    { name: 'up', type: 'AntDesign' },
    { name: 'right', type: 'AntDesign' },
    { name: 'left', type: 'AntDesign' },
    { name: 'minussquareo', type: 'AntDesign' },
    { name: 'minuscircle', type: 'AntDesign' },
    { name: 'minuscircleo', type: 'AntDesign' },
    { name: 'minus', type: 'AntDesign' },
    { name: 'pluscircleo', type: 'AntDesign' },
    { name: 'pluscircle', type: 'AntDesign' },
    { name: 'plus', type: 'AntDesign' },
    { name: 'infocirlce', type: 'AntDesign' },
    { name: 'infocirlceo', type: 'AntDesign' },
    { name: 'info', type: 'AntDesign' },
    { name: 'exclamation', type: 'AntDesign' },
    { name: 'exclamationcircle', type: 'AntDesign' },
    { name: 'exclamationcircleo', type: 'AntDesign' },
    { name: 'closecircle', type: 'AntDesign' },
    { name: 'closecircleo', type: 'AntDesign' },
    { name: 'checkcircle', type: 'AntDesign' },
    { name: 'checkcircleo', type: 'AntDesign' },
    { name: 'check', type: 'AntDesign' },
    { name: 'close', type: 'AntDesign' },
    { name: 'customerservice', type: 'AntDesign' },
    { name: 'creditcard', type: 'AntDesign' },
    { name: 'codesquareo', type: 'AntDesign' },
    { name: 'book', type: 'AntDesign' },
    { name: 'barschart', type: 'AntDesign' },
    { name: 'bars', type: 'AntDesign' },
    { name: 'question', type: 'AntDesign' },
    { name: 'questioncircle', type: 'AntDesign' },
    { name: 'questioncircleo', type: 'AntDesign' },
    { name: 'pause', type: 'AntDesign' },
    { name: 'pausecircle', type: 'AntDesign' },
    { name: 'pausecircleo', type: 'AntDesign' },
    { name: 'clockcircle', type: 'AntDesign' },
    { name: 'clockcircleo', type: 'AntDesign' },
    { name: 'swap', type: 'AntDesign' },
    { name: 'swapleft', type: 'AntDesign' },
    { name: 'swapright', type: 'AntDesign' },
    { name: 'plussquareo', type: 'AntDesign' },
    { name: 'frown', type: 'AntDesign' },
    { name: 'menufold', type: 'AntDesign' },
    { name: 'mail', type: 'AntDesign' },
    { name: 'link', type: 'AntDesign' },
    { name: 'areachart', type: 'AntDesign' },
    { name: 'linechart', type: 'AntDesign' },
    { name: 'home', type: 'AntDesign' },
    { name: 'laptop', type: 'AntDesign' },
    { name: 'star', type: 'AntDesign' },
    { name: 'staro', type: 'AntDesign' },
    { name: 'filter', type: 'AntDesign' },
    { name: 'meho', type: 'AntDesign' },
    { name: 'meh', type: 'AntDesign' },
    { name: 'shoppingcart', type: 'AntDesign' },
    { name: 'save', type: 'AntDesign' },
    { name: 'user', type: 'AntDesign' },
    { name: 'videocamera', type: 'AntDesign' },
    { name: 'totop', type: 'AntDesign' },
    { name: 'team', type: 'AntDesign' },
    { name: 'sharealt', type: 'AntDesign' },
    { name: 'setting', type: 'AntDesign' },
    { name: 'picture', type: 'AntDesign' },
    { name: 'phone', type: 'AntDesign' },
    { name: 'paperclip', type: 'AntDesign' },
    { name: 'notification', type: 'AntDesign' },
    { name: 'menuunfold', type: 'AntDesign' },
    { name: 'inbox', type: 'AntDesign' },
    { name: 'lock', type: 'AntDesign' },
    { name: 'qrcode', type: 'AntDesign' },
    { name: 'tags', type: 'AntDesign' },
    { name: 'tagso', type: 'AntDesign' },
    { name: 'cloudo', type: 'AntDesign' },
    { name: 'cloud', type: 'AntDesign' },
    { name: 'cloudupload', type: 'AntDesign' },
    { name: 'clouddownload', type: 'AntDesign' },
    { name: 'clouddownloado', type: 'AntDesign' },
    { name: 'clouduploado', type: 'AntDesign' },
    { name: 'enviroment', type: 'AntDesign' },
    { name: 'enviromento', type: 'AntDesign' },
    { name: 'eye', type: 'AntDesign' },
    { name: 'eyeo', type: 'AntDesign' },
    { name: 'camera', type: 'AntDesign' },
    { name: 'camerao', type: 'AntDesign' },
    { name: 'windows', type: 'AntDesign' },
    { name: 'export2', type: 'AntDesign' },
    { name: 'export', type: 'AntDesign' },
    { name: 'circledowno', type: 'AntDesign' },
    { name: 'circledown', type: 'AntDesign' },
    { name: 'hdd', type: 'AntDesign' },
    { name: 'ie', type: 'AntDesign' },
    { name: 'delete', type: 'AntDesign' },
    { name: 'enter', type: 'AntDesign' },
    { name: 'pushpino', type: 'AntDesign' },
    { name: 'pushpin', type: 'AntDesign' },
    { name: 'heart', type: 'AntDesign' },
    { name: 'hearto', type: 'AntDesign' },
    { name: 'smile-circle', type: 'AntDesign' },
    { name: 'smileo', type: 'AntDesign' },
    { name: 'frowno', type: 'AntDesign' },
    { name: 'calculator', type: 'AntDesign' },
    { name: 'chrome', type: 'AntDesign' },
    { name: 'github', type: 'AntDesign' },
    { name: 'iconfontdesktop', type: 'AntDesign' },
    { name: 'caretcircleoup', type: 'AntDesign' },
    { name: 'upload', type: 'AntDesign' },
    { name: 'download', type: 'AntDesign' },
    { name: 'piechart', type: 'AntDesign' },
    { name: 'lock1', type: 'AntDesign' },
    { name: 'unlock', type: 'AntDesign' },
    { name: 'windowso', type: 'AntDesign' },
    { name: 'dotchart', type: 'AntDesign' },
    { name: 'barchart', type: 'AntDesign' },
    { name: 'codesquare', type: 'AntDesign' },
    { name: 'plussquare', type: 'AntDesign' },
    { name: 'minussquare', type: 'AntDesign' },
    { name: 'closesquare', type: 'AntDesign' },
    { name: 'closesquareo', type: 'AntDesign' },
    { name: 'checksquare', type: 'AntDesign' },
    { name: 'checksquareo', type: 'AntDesign' },
    { name: 'fastbackward', type: 'AntDesign' },
    { name: 'fastforward', type: 'AntDesign' },
    { name: 'upsquare', type: 'AntDesign' },
    { name: 'downsquare', type: 'AntDesign' },
    { name: 'leftsquare', type: 'AntDesign' },
    { name: 'rightsquare', type: 'AntDesign' },
    { name: 'rightsquareo', type: 'AntDesign' },
    { name: 'leftsquareo', type: 'AntDesign' },
    { name: 'down-square-o', type: 'AntDesign' },
    { name: 'up-square-o', type: 'AntDesign' },
    { name: 'play', type: 'AntDesign' },
    { name: 'playcircleo', type: 'AntDesign' },
    { name: 'tag', type: 'AntDesign' },
    { name: 'tago', type: 'AntDesign' },
    { name: 'addfile', type: 'AntDesign' },
    { name: 'folder1', type: 'AntDesign' },
    { name: 'file1', type: 'AntDesign' },
    { name: 'switcher', type: 'AntDesign' },
    { name: 'addfolder', type: 'AntDesign' },
    { name: 'folderopen', type: 'AntDesign' },
    { name: 'search1', type: 'AntDesign' },
    { name: 'ellipsis1', type: 'AntDesign' },
    { name: 'calendar', type: 'AntDesign' },
    { name: 'filetext1', type: 'AntDesign' },
    { name: 'copy1', type: 'AntDesign' },
    { name: 'jpgfile1', type: 'AntDesign' },
    { name: 'pdffile1', type: 'AntDesign' },
    { name: 'exclefile1', type: 'AntDesign' },
    { name: 'pptfile1', type: 'AntDesign' },
    { name: 'unknowfile1', type: 'AntDesign' },
    { name: 'wordfile1', type: 'AntDesign' },
    { name: 'dingding', type: 'AntDesign' },
    { name: 'dingding-o', type: 'AntDesign' },
    { name: 'mobile1', type: 'AntDesign' },
    { name: 'tablet1', type: 'AntDesign' },
    { name: 'bells', type: 'AntDesign' },
    { name: 'disconnect', type: 'AntDesign' },
    { name: 'database', type: 'AntDesign' },
    { name: 'barcode', type: 'AntDesign' },
    { name: 'hourglass', type: 'AntDesign' },
    { name: 'key', type: 'AntDesign' },
    { name: 'flag', type: 'AntDesign' },
    { name: 'layout', type: 'AntDesign' },
    { name: 'printer', type: 'AntDesign' },
    { name: 'USB', type: 'AntDesign' },
    { name: 'skin', type: 'AntDesign' },
    { name: 'tool', type: 'AntDesign' },
    { name: 'car', type: 'AntDesign' },
    { name: 'addusergroup', type: 'AntDesign' },
    { name: 'carryout', type: 'AntDesign' },
    { name: 'deleteuser', type: 'AntDesign' },
    { name: 'deleteusergroup', type: 'AntDesign' },
    { name: 'man', type: 'AntDesign' },
    { name: 'isv', type: 'AntDesign' },
    { name: 'gift', type: 'AntDesign' },
    { name: 'idcard', type: 'AntDesign' },
    { name: 'medicinebox', type: 'AntDesign' },
    { name: 'redenvelopes', type: 'AntDesign' },
    { name: 'rest', type: 'AntDesign' },
    { name: 'Safety', type: 'AntDesign' },
    { name: 'wallet', type: 'AntDesign' },
    { name: 'woman', type: 'AntDesign' },
    { name: 'adduser', type: 'AntDesign' },
    { name: 'bank', type: 'AntDesign' },
    { name: 'Trophy', type: 'AntDesign' },
    { name: 'loading1', type: 'AntDesign' },
    { name: 'loading2', type: 'AntDesign' },
    { name: 'like2', type: 'AntDesign' },
    { name: 'dislike2', type: 'AntDesign' },
    { name: 'like1', type: 'AntDesign' },
    { name: 'dislike1', type: 'AntDesign' },
    { name: 'bulb1', type: 'AntDesign' },
    { name: 'rocket1', type: 'AntDesign' },
    { name: 'select1', type: 'AntDesign' },
    { name: 'apple1', type: 'AntDesign' },
    { name: 'apple-o', type: 'AntDesign' },
    { name: 'android1', type: 'AntDesign' },
    { name: 'android', type: 'AntDesign' },
    { name: 'aliwangwang-o1', type: 'AntDesign' },
    { name: 'aliwangwang', type: 'AntDesign' },
    { name: 'pay-circle1', type: 'AntDesign' },
    { name: 'pay-circle-o1', type: 'AntDesign' },
    { name: 'poweroff', type: 'AntDesign' },
    { name: 'trademark', type: 'AntDesign' },
    { name: 'find', type: 'AntDesign' },
    { name: 'copyright', type: 'AntDesign' },
    { name: 'sound', type: 'AntDesign' },
    { name: 'earth', type: 'AntDesign' },
    { name: 'wifi', type: 'AntDesign' },
    { name: 'sync', type: 'AntDesign' },
    { name: 'login', type: 'AntDesign' },
    { name: 'logout', type: 'AntDesign' },
    { name: 'reload1', type: 'AntDesign' },
    { name: 'message1', type: 'AntDesign' },
    { name: 'shake', type: 'AntDesign' },
    { name: 'API', type: 'AntDesign' },
    { name: 'appstore-o', type: 'AntDesign' },
    { name: 'appstore1', type: 'AntDesign' },
    { name: 'scan1', type: 'AntDesign' },
    { name: 'exception1', type: 'AntDesign' },
    { name: 'contacts', type: 'AntDesign' },
    { name: 'solution1', type: 'AntDesign' },
    { name: 'fork', type: 'AntDesign' },
    { name: 'edit', type: 'AntDesign' },
    { name: 'form', type: 'AntDesign' },
    { name: 'warning', type: 'AntDesign' },
    { name: 'table', type: 'AntDesign' },
    { name: 'profile', type: 'AntDesign' },
    { name: 'dashboard', type: 'AntDesign' },
    { name: 'indent-left', type: 'AntDesign' },
    { name: 'indent-right', type: 'AntDesign' },
    { name: 'menu-unfold', type: 'AntDesign' },
    { name: 'menu-fold', type: 'AntDesign' },
    { name: 'antdesign', type: 'AntDesign' },
    { name: 'alipay-square', type: 'AntDesign' },
    { name: 'codepen-circle', type: 'AntDesign' },
    { name: 'google', type: 'AntDesign' },
    { name: 'amazon', type: 'AntDesign' },
    { name: 'codepen', type: 'AntDesign' },
    { name: 'facebook-square', type: 'AntDesign' },
    { name: 'dropbox', type: 'AntDesign' },
    { name: 'googleplus', type: 'AntDesign' },
    { name: 'linkedin-square', type: 'AntDesign' },
    { name: 'medium-monogram', type: 'AntDesign' },
    { name: 'gitlab', type: 'AntDesign' },
    { name: 'medium-wordmark', type: 'AntDesign' },
    { name: 'QQ', type: 'AntDesign' },
    { name: 'skype', type: 'AntDesign' },
    { name: 'taobao-square', type: 'AntDesign' },
    { name: 'alipay-circle', type: 'AntDesign' },
    { name: 'youtube', type: 'AntDesign' },
    { name: 'wechat', type: 'AntDesign' },
    { name: 'twitter', type: 'AntDesign' },
    { name: 'weibo', type: 'AntDesign' },
    { name: 'HTML', type: 'AntDesign' },
    { name: 'taobao-circle', type: 'AntDesign' },
    { name: 'weibo-circle', type: 'AntDesign' },
    { name: 'weibo-square', type: 'AntDesign' },
    { name: 'CodeSandbox', type: 'AntDesign' },
    { name: 'aliyun', type: 'AntDesign' },
    { name: 'zhihu', type: 'AntDesign' },
    { name: 'behance', type: 'AntDesign' },
    { name: 'dribbble', type: 'AntDesign' },
    { name: 'dribbble-square', type: 'AntDesign' },
    { name: 'behance-square', type: 'AntDesign' },
    { name: 'file-markdown', type: 'AntDesign' },
    { name: 'instagram', type: 'AntDesign' },
    { name: 'yuque', type: 'AntDesign' },
    { name: 'slack', type: 'AntDesign' },
    { name: 'slack-square', type: 'AntDesign' },

    { name: '500px', type: 'Entypo' },
	{ name: '500px-with-circle', type: 'Entypo' },
	{ name: 'add-to-list', type: 'Entypo' },
	{ name: 'add-user', type: 'Entypo' },
	{ name: 'address', type: 'Entypo' },
	{ name: 'adjust', type: 'Entypo' },
	{ name: 'air', type: 'Entypo' },
	{ name: 'aircraft', type: 'Entypo' },
	{ name: 'aircraft-landing', type: 'Entypo' },
	{ name: 'aircraft-take-off', type: 'Entypo' },
	{ name: 'align-bottom', type: 'Entypo' },
	{ name: 'align-horizontal-middle', type: 'Entypo' },
	{ name: 'align-left', type: 'Entypo' },
	{ name: 'align-right', type: 'Entypo' },
	{ name: 'align-top', type: 'Entypo' },
	{ name: 'align-vertical-middle', type: 'Entypo' },
	{ name: 'app-store', type: 'Entypo' },
	{ name: 'archive', type: 'Entypo' },
	{ name: 'area-graph', type: 'Entypo' },
	{ name: 'arrow-bold-down', type: 'Entypo' },
	{ name: 'arrow-bold-left', type: 'Entypo' },
	{ name: 'arrow-bold-right', type: 'Entypo' },
	{ name: 'arrow-bold-up', type: 'Entypo' },
	{ name: 'arrow-down', type: 'Entypo' },
	{ name: 'arrow-left', type: 'Entypo' },
	{ name: 'arrow-long-down', type: 'Entypo' },
	{ name: 'arrow-long-left', type: 'Entypo' },
	{ name: 'arrow-long-right', type: 'Entypo' },
	{ name: 'arrow-long-up', type: 'Entypo' },
	{ name: 'arrow-right', type: 'Entypo' },
	{ name: 'arrow-up', type: 'Entypo' },
	{ name: 'arrow-with-circle-down', type: 'Entypo' },
	{ name: 'arrow-with-circle-left', type: 'Entypo' },
	{ name: 'arrow-with-circle-right', type: 'Entypo' },
	{ name: 'arrow-with-circle-up', type: 'Entypo' },
	{ name: 'attachment', type: 'Entypo' },
	{ name: 'awareness-ribbon', type: 'Entypo' },
	{ name: 'back', type: 'Entypo' },
	{ name: 'back-in-time', type: 'Entypo' },
	{ name: 'baidu', type: 'Entypo' },
	{ name: 'bar-graph', type: 'Entypo' },
	{ name: 'basecamp', type: 'Entypo' },
	{ name: 'battery', type: 'Entypo' },
	{ name: 'beamed-note', type: 'Entypo' },
	{ name: 'behance', type: 'Entypo' },
	{ name: 'bell', type: 'Entypo' },
	{ name: 'blackboard', type: 'Entypo' },
	{ name: 'block', type: 'Entypo' },
	{ name: 'book', type: 'Entypo' },
	{ name: 'bookmark', type: 'Entypo' },
	{ name: 'bookmarks', type: 'Entypo' },
	{ name: 'bowl', type: 'Entypo' },
	{ name: 'box', type: 'Entypo' },
	{ name: 'briefcase', type: 'Entypo' },
	{ name: 'browser', type: 'Entypo' },
	{ name: 'brush', type: 'Entypo' },
	{ name: 'bucket', type: 'Entypo' },
	{ name: 'bug', type: 'Entypo' },
	{ name: 'cake', type: 'Entypo' },
	{ name: 'calculator', type: 'Entypo' },
	{ name: 'calendar', type: 'Entypo' },
	{ name: 'camera', type: 'Entypo' },
	{ name: 'ccw', type: 'Entypo' },
	{ name: 'chat', type: 'Entypo' },
	{ name: 'check', type: 'Entypo' },
	{ name: 'chevron-down', type: 'Entypo' },
	{ name: 'chevron-left', type: 'Entypo' },
	{ name: 'chevron-right', type: 'Entypo' },
	{ name: 'chevron-small-down', type: 'Entypo' },
	{ name: 'chevron-small-left', type: 'Entypo' },
	{ name: 'chevron-small-right', type: 'Entypo' },
	{ name: 'chevron-small-up', type: 'Entypo' },
	{ name: 'chevron-thin-down', type: 'Entypo' },
	{ name: 'chevron-thin-left', type: 'Entypo' },
	{ name: 'chevron-thin-right', type: 'Entypo' },
	{ name: 'chevron-thin-up', type: 'Entypo' },
	{ name: 'chevron-up', type: 'Entypo' },
	{ name: 'chevron-with-circle-down', type: 'Entypo' },
	{ name: 'chevron-with-circle-left', type: 'Entypo' },
	{ name: 'chevron-with-circle-right', type: 'Entypo' },
	{ name: 'chevron-with-circle-up', type: 'Entypo' },
	{ name: 'circle', type: 'Entypo' },
	{ name: 'circle-with-cross', type: 'Entypo' },
	{ name: 'circle-with-minus', type: 'Entypo' },
	{ name: 'circle-with-plus', type: 'Entypo' },
	{ name: 'circular-graph', type: 'Entypo' },
	{ name: 'clapperboard', type: 'Entypo' },
	{ name: 'classic-computer', type: 'Entypo' },
	{ name: 'clipboard', type: 'Entypo' },
	{ name: 'clock', type: 'Entypo' },
	{ name: 'cloud', type: 'Entypo' },
	{ name: 'code', type: 'Entypo' },
	{ name: 'cog', type: 'Entypo' },
	{ name: 'colours', type: 'Entypo' },
	{ name: 'compass', type: 'Entypo' },
	{ name: 'controller-fast-backward', type: 'Entypo' },
	{ name: 'controller-fast-forward', type: 'Entypo' },
	{ name: 'controller-jump-to-start', type: 'Entypo' },
	{ name: 'controller-next', type: 'Entypo' },
	{ name: 'controller-paus', type: 'Entypo' },
	{ name: 'controller-play', type: 'Entypo' },
	{ name: 'controller-record', type: 'Entypo' },
	{ name: 'controller-stop', type: 'Entypo' },
	{ name: 'controller-volume', type: 'Entypo' },
	{ name: 'copy', type: 'Entypo' },
	{ name: 'creative-cloud', type: 'Entypo' },
	{ name: 'creative-commons', type: 'Entypo' },
	{ name: 'creative-commons-attribution', type: 'Entypo' },
	{ name: 'creative-commons-noderivs', type: 'Entypo' },
	{ name: 'creative-commons-noncommercial-eu', type: 'Entypo' },
	{ name: 'creative-commons-noncommercial-us', type: 'Entypo' },
	{ name: 'creative-commons-public-domain', type: 'Entypo' },
	{ name: 'creative-commons-remix', type: 'Entypo' },
	{ name: 'creative-commons-share', type: 'Entypo' },
	{ name: 'creative-commons-sharealike', type: 'Entypo' },
	{ name: 'credit', type: 'Entypo' },
	{ name: 'credit-card', type: 'Entypo' },
	{ name: 'crop', type: 'Entypo' },
	{ name: 'cross', type: 'Entypo' },
	{ name: 'cup', type: 'Entypo' },
	{ name: 'cw', type: 'Entypo' },
	{ name: 'cycle', type: 'Entypo' },
	{ name: 'database', type: 'Entypo' },
	{ name: 'dial-pad', type: 'Entypo' },
	{ name: 'direction', type: 'Entypo' },
	{ name: 'document', type: 'Entypo' },
	{ name: 'document-landscape', type: 'Entypo' },
	{ name: 'documents', type: 'Entypo' },
	{ name: 'dot-single', type: 'Entypo' },
	{ name: 'dots-three-horizontal', type: 'Entypo' },
	{ name: 'dots-three-vertical', type: 'Entypo' },
	{ name: 'dots-two-horizontal', type: 'Entypo' },
	{ name: 'dots-two-vertical', type: 'Entypo' },
	{ name: 'download', type: 'Entypo' },
	{ name: 'dribbble', type: 'Entypo' },
	{ name: 'dribbble-with-circle', type: 'Entypo' },
	{ name: 'drink', type: 'Entypo' },
	{ name: 'drive', type: 'Entypo' },
	{ name: 'drop', type: 'Entypo' },
	{ name: 'dropbox', type: 'Entypo' },
	{ name: 'edit', type: 'Entypo' },
	{ name: 'email', type: 'Entypo' },
	{ name: 'emoji-flirt', type: 'Entypo' },
	{ name: 'emoji-happy', type: 'Entypo' },
	{ name: 'emoji-neutral', type: 'Entypo' },
	{ name: 'emoji-sad', type: 'Entypo' },
	{ name: 'erase', type: 'Entypo' },
	{ name: 'eraser', type: 'Entypo' },
	{ name: 'evernote', type: 'Entypo' },
	{ name: 'export', type: 'Entypo' },
	{ name: 'eye', type: 'Entypo' },
	{ name: 'eye-with-line', type: 'Entypo' },
	{ name: 'facebook', type: 'Entypo' },
	{ name: 'facebook-with-circle', type: 'Entypo' },
	{ name: 'feather', type: 'Entypo' },
	{ name: 'fingerprint', type: 'Entypo' },
	{ name: 'flag', type: 'Entypo' },
	{ name: 'flash', type: 'Entypo' },
	{ name: 'flashlight', type: 'Entypo' },
	{ name: 'flat-brush', type: 'Entypo' },
	{ name: 'flattr', type: 'Entypo' },
	{ name: 'flickr', type: 'Entypo' },
	{ name: 'flickr-with-circle', type: 'Entypo' },
	{ name: 'flow-branch', type: 'Entypo' },
	{ name: 'flow-cascade', type: 'Entypo' },
	{ name: 'flow-line', type: 'Entypo' },
	{ name: 'flow-parallel', type: 'Entypo' },
	{ name: 'flow-tree', type: 'Entypo' },
	{ name: 'flower', type: 'Entypo' },
	{ name: 'folder', type: 'Entypo' },
	{ name: 'folder-images', type: 'Entypo' },
	{ name: 'folder-music', type: 'Entypo' },
	{ name: 'folder-video', type: 'Entypo' },
	{ name: 'forward', type: 'Entypo' },
	{ name: 'foursquare', type: 'Entypo' },
	{ name: 'funnel', type: 'Entypo' },
	{ name: 'game-controller', type: 'Entypo' },
	{ name: 'gauge', type: 'Entypo' },
	{ name: 'github', type: 'Entypo' },
	{ name: 'github-with-circle', type: 'Entypo' },
	{ name: 'globe', type: 'Entypo' },
	{ name: 'google-', type: 'Entypo' },
	{ name: 'google--with-circle', type: 'Entypo' },
	{ name: 'google-drive', type: 'Entypo' },
	{ name: 'google-hangouts', type: 'Entypo' },
	{ name: 'google-play', type: 'Entypo' },
	{ name: 'graduation-cap', type: 'Entypo' },
	{ name: 'grid', type: 'Entypo' },
	{ name: 'grooveshark', type: 'Entypo' },
	{ name: 'hair-cross', type: 'Entypo' },
	{ name: 'hand', type: 'Entypo' },
	{ name: 'heart', type: 'Entypo' },
	{ name: 'heart-outlined', type: 'Entypo' },
	{ name: 'help', type: 'Entypo' },
	{ name: 'help-with-circle', type: 'Entypo' },
	{ name: 'home', type: 'Entypo' },
	{ name: 'hour-glass', type: 'Entypo' },
	{ name: 'houzz', type: 'Entypo' },
	{ name: 'icloud', type: 'Entypo' },
	{ name: 'image', type: 'Entypo' },
	{ name: 'image-inverted', type: 'Entypo' },
	{ name: 'images', type: 'Entypo' },
	{ name: 'inbox', type: 'Entypo' },
	{ name: 'infinity', type: 'Entypo' },
	{ name: 'info', type: 'Entypo' },
	{ name: 'info-with-circle', type: 'Entypo' },
	{ name: 'instagram', type: 'Entypo' },
	{ name: 'instagram-with-circle', type: 'Entypo' },
	{ name: 'install', type: 'Entypo' },
	{ name: 'key', type: 'Entypo' },
	{ name: 'keyboard', type: 'Entypo' },
	{ name: 'lab-flask', type: 'Entypo' },
	{ name: 'landline', type: 'Entypo' },
	{ name: 'language', type: 'Entypo' },
	{ name: 'laptop', type: 'Entypo' },
	{ name: 'lastfm', type: 'Entypo' },
	{ name: 'lastfm-with-circle', type: 'Entypo' },
	{ name: 'layers', type: 'Entypo' },
	{ name: 'leaf', type: 'Entypo' },
	{ name: 'level-down', type: 'Entypo' },
	{ name: 'level-up', type: 'Entypo' },
	{ name: 'lifebuoy', type: 'Entypo' },
	{ name: 'light-bulb', type: 'Entypo' },
	{ name: 'light-down', type: 'Entypo' },
	{ name: 'light-up', type: 'Entypo' },
	{ name: 'line-graph', type: 'Entypo' },
	{ name: 'link', type: 'Entypo' },
	{ name: 'linkedin', type: 'Entypo' },
	{ name: 'linkedin-with-circle', type: 'Entypo' },
	{ name: 'list', type: 'Entypo' },
	{ name: 'location', type: 'Entypo' },
	{ name: 'location-pin', type: 'Entypo' },
	{ name: 'lock', type: 'Entypo' },
	{ name: 'lock-open', type: 'Entypo' },
	{ name: 'log-out', type: 'Entypo' },
	{ name: 'login', type: 'Entypo' },
	{ name: 'loop', type: 'Entypo' },
	{ name: 'magnet', type: 'Entypo' },
	{ name: 'magnifying-glass', type: 'Entypo' },
	{ name: 'mail', type: 'Entypo' },
	{ name: 'mail-with-circle', type: 'Entypo' },
	{ name: 'man', type: 'Entypo' },
	{ name: 'map', type: 'Entypo' },
	{ name: 'mask', type: 'Entypo' },
	{ name: 'medal', type: 'Entypo' },
	{ name: 'medium', type: 'Entypo' },
	{ name: 'medium-with-circle', type: 'Entypo' },
	{ name: 'megaphone', type: 'Entypo' },
	{ name: 'menu', type: 'Entypo' },
	{ name: 'merge', type: 'Entypo' },
	{ name: 'message', type: 'Entypo' },
	{ name: 'mic', type: 'Entypo' },
	{ name: 'minus', type: 'Entypo' },
	{ name: 'mixi', type: 'Entypo' },
	{ name: 'mobile', type: 'Entypo' },
	{ name: 'modern-mic', type: 'Entypo' },
	{ name: 'moon', type: 'Entypo' },
	{ name: 'mouse', type: 'Entypo' },
	{ name: 'mouse-pointer', type: 'Entypo' },
	{ name: 'music', type: 'Entypo' },
	{ name: 'network', type: 'Entypo' },
	{ name: 'new', type: 'Entypo' },
	{ name: 'new-message', type: 'Entypo' },
	{ name: 'news', type: 'Entypo' },
	{ name: 'newsletter', type: 'Entypo' },
	{ name: 'note', type: 'Entypo' },
	{ name: 'notification', type: 'Entypo' },
	{ name: 'notifications-off', type: 'Entypo' },
	{ name: 'old-mobile', type: 'Entypo' },
	{ name: 'old-phone', type: 'Entypo' },
	{ name: 'onedrive', type: 'Entypo' },
	{ name: 'open-book', type: 'Entypo' },
	{ name: 'palette', type: 'Entypo' },
	{ name: 'paper-plane', type: 'Entypo' },
	{ name: 'paypal', type: 'Entypo' },
	{ name: 'pencil', type: 'Entypo' },
	{ name: 'phone', type: 'Entypo' },
	{ name: 'picasa', type: 'Entypo' },
	{ name: 'pie-chart', type: 'Entypo' },
	{ name: 'pin', type: 'Entypo' },
	{ name: 'pinterest', type: 'Entypo' },
	{ name: 'pinterest-with-circle', type: 'Entypo' },
	{ name: 'plus', type: 'Entypo' },
	{ name: 'popup', type: 'Entypo' },
	{ name: 'power-plug', type: 'Entypo' },
	{ name: 'price-ribbon', type: 'Entypo' },
	{ name: 'price-tag', type: 'Entypo' },
	{ name: 'print', type: 'Entypo' },
	{ name: 'progress-empty', type: 'Entypo' },
	{ name: 'progress-full', type: 'Entypo' },
	{ name: 'progress-one', type: 'Entypo' },
	{ name: 'progress-two', type: 'Entypo' },
	{ name: 'publish', type: 'Entypo' },
	{ name: 'qq', type: 'Entypo' },
	{ name: 'qq-with-circle', type: 'Entypo' },
	{ name: 'quote', type: 'Entypo' },
	{ name: 'radio', type: 'Entypo' },
	{ name: 'raft', type: 'Entypo' },
	{ name: 'raft-with-circle', type: 'Entypo' },
	{ name: 'rainbow', type: 'Entypo' },
	{ name: 'rdio', type: 'Entypo' },
	{ name: 'rdio-with-circle', type: 'Entypo' },
	{ name: 'remove-user', type: 'Entypo' },
	{ name: 'renren', type: 'Entypo' },
	{ name: 'reply', type: 'Entypo' },
	{ name: 'reply-all', type: 'Entypo' },
	{ name: 'resize-100-', type: 'Entypo' },
	{ name: 'resize-full-screen', type: 'Entypo' },
	{ name: 'retweet', type: 'Entypo' },
	{ name: 'rocket', type: 'Entypo' },
	{ name: 'round-brush', type: 'Entypo' },
	{ name: 'rss', type: 'Entypo' },
	{ name: 'ruler', type: 'Entypo' },
	{ name: 'save', type: 'Entypo' },
	{ name: 'scissors', type: 'Entypo' },
	{ name: 'scribd', type: 'Entypo' },
	{ name: 'select-arrows', type: 'Entypo' },
	{ name: 'share', type: 'Entypo' },
	{ name: 'share-alternative', type: 'Entypo' },
	{ name: 'shareable', type: 'Entypo' },
	{ name: 'shield', type: 'Entypo' },
	{ name: 'shop', type: 'Entypo' },
	{ name: 'shopping-bag', type: 'Entypo' },
	{ name: 'shopping-basket', type: 'Entypo' },
	{ name: 'shopping-cart', type: 'Entypo' },
	{ name: 'shuffle', type: 'Entypo' },
	{ name: 'signal', type: 'Entypo' },
	{ name: 'sina-weibo', type: 'Entypo' },
	{ name: 'skype', type: 'Entypo' },
	{ name: 'skype-with-circle', type: 'Entypo' },
	{ name: 'slideshare', type: 'Entypo' },
	{ name: 'smashing', type: 'Entypo' },
	{ name: 'sound', type: 'Entypo' },
	{ name: 'sound-mix', type: 'Entypo' },
	{ name: 'sound-mute', type: 'Entypo' },
	{ name: 'soundcloud', type: 'Entypo' },
	{ name: 'sports-club', type: 'Entypo' },
	{ name: 'spotify', type: 'Entypo' },
	{ name: 'spotify-with-circle', type: 'Entypo' },
	{ name: 'spreadsheet', type: 'Entypo' },
	{ name: 'squared-cross', type: 'Entypo' },
	{ name: 'squared-minus', type: 'Entypo' },
	{ name: 'squared-plus', type: 'Entypo' },
	{ name: 'star', type: 'Entypo' },
	{ name: 'star-outlined', type: 'Entypo' },
	{ name: 'stopwatch', type: 'Entypo' },
	{ name: 'stumbleupon', type: 'Entypo' },
	{ name: 'stumbleupon-with-circle', type: 'Entypo' },
	{ name: 'suitcase', type: 'Entypo' },
	{ name: 'swap', type: 'Entypo' },
	{ name: 'swarm', type: 'Entypo' },
	{ name: 'sweden', type: 'Entypo' },
	{ name: 'switch', type: 'Entypo' },
	{ name: 'tablet', type: 'Entypo' },
	{ name: 'tablet-mobile-combo', type: 'Entypo' },
	{ name: 'tag', type: 'Entypo' },
	{ name: 'text', type: 'Entypo' },
	{ name: 'text-document', type: 'Entypo' },
	{ name: 'text-document-inverted', type: 'Entypo' },
	{ name: 'thermometer', type: 'Entypo' },
	{ name: 'thumbs-down', type: 'Entypo' },
	{ name: 'thumbs-up', type: 'Entypo' },
	{ name: 'thunder-cloud', type: 'Entypo' },
	{ name: 'ticket', type: 'Entypo' },
	{ name: 'time-slot', type: 'Entypo' },
	{ name: 'tools', type: 'Entypo' },
	{ name: 'traffic-cone', type: 'Entypo' },
	{ name: 'trash', type: 'Entypo' },
	{ name: 'tree', type: 'Entypo' },
	{ name: 'triangle-down', type: 'Entypo' },
	{ name: 'triangle-left', type: 'Entypo' },
	{ name: 'triangle-right', type: 'Entypo' },
	{ name: 'triangle-up', type: 'Entypo' },
	{ name: 'tripadvisor', type: 'Entypo' },
	{ name: 'trophy', type: 'Entypo' },
	{ name: 'tumblr', type: 'Entypo' },
	{ name: 'tumblr-with-circle', type: 'Entypo' },
	{ name: 'tv', type: 'Entypo' },
	{ name: 'twitter', type: 'Entypo' },
	{ name: 'twitter-with-circle', type: 'Entypo' },
	{ name: 'typing', type: 'Entypo' },
	{ name: 'uninstall', type: 'Entypo' },
	{ name: 'unread', type: 'Entypo' },
	{ name: 'untag', type: 'Entypo' },
	{ name: 'upload', type: 'Entypo' },
	{ name: 'upload-to-cloud', type: 'Entypo' },
	{ name: 'user', type: 'Entypo' },
	{ name: 'users', type: 'Entypo' },
	{ name: 'v-card', type: 'Entypo' },
	{ name: 'video', type: 'Entypo' },
	{ name: 'video-camera', type: 'Entypo' },
	{ name: 'vimeo', type: 'Entypo' },
	{ name: 'vimeo-with-circle', type: 'Entypo' },
	{ name: 'vine', type: 'Entypo' },
	{ name: 'vine-with-circle', type: 'Entypo' },
	{ name: 'vinyl', type: 'Entypo' },
	{ name: 'vk', type: 'Entypo' },
	{ name: 'vk-alternitive', type: 'Entypo' },
	{ name: 'vk-with-circle', type: 'Entypo' },
	{ name: 'voicemail', type: 'Entypo' },
	{ name: 'wallet', type: 'Entypo' },
	{ name: 'warning', type: 'Entypo' },
	{ name: 'water', type: 'Entypo' },
	{ name: 'windows-store', type: 'Entypo' },
	{ name: 'xing', type: 'Entypo' },
	{ name: 'xing-with-circle', type: 'Entypo' },
	{ name: 'yelp', type: 'Entypo' },
	{ name: 'youko', type: 'Entypo' },
	{ name: 'youko-with-circle', type: 'Entypo' },
	{ name: 'youtube', type: 'Entypo' },
	{ name: 'youtube-with-circle', type: 'Entypo' },
*/

    { name: 'archive', type: 'EvilIcons' },
    { name: 'arrow-down', type: 'EvilIcons' },
    { name: 'arrow-left', type: 'EvilIcons' },
    { name: 'arrow-right', type: 'EvilIcons' },
    { name: 'arrow-up', type: 'EvilIcons' },
    { name: 'bell', type: 'EvilIcons' },
    { name: 'calendar', type: 'EvilIcons' },
    { name: 'camera', type: 'EvilIcons' },
    { name: 'cart', type: 'EvilIcons' },
    { name: 'chart', type: 'EvilIcons' },
    { name: 'check', type: 'EvilIcons' },
    { name: 'chevron-down', type: 'EvilIcons' },
    { name: 'chevron-left', type: 'EvilIcons' },
    { name: 'chevron-right', type: 'EvilIcons' },
    { name: 'chevron-up', type: 'EvilIcons' },
    { name: 'clock', type: 'EvilIcons' },
    { name: 'close', type: 'EvilIcons' },
    { name: 'close-o', type: 'EvilIcons' },
    { name: 'comment', type: 'EvilIcons' },
    { name: 'credit-card', type: 'EvilIcons' },
    { name: 'envelope', type: 'EvilIcons' },
    { name: 'exclamation', type: 'EvilIcons' },
    { name: 'external-link', type: 'EvilIcons' },
    { name: 'eye', type: 'EvilIcons' },
    { name: 'gear', type: 'EvilIcons' },
    { name: 'heart', type: 'EvilIcons' },
    { name: 'image', type: 'EvilIcons' },
    { name: 'like', type: 'EvilIcons' },
    { name: 'link', type: 'EvilIcons' },
    { name: 'location', type: 'EvilIcons' },
    { name: 'lock', type: 'EvilIcons' },
    { name: 'minus', type: 'EvilIcons' },
    { name: 'navicon', type: 'EvilIcons' },
    { name: 'paperclip', type: 'EvilIcons' },
    { name: 'pencil', type: 'EvilIcons' },
    { name: 'play', type: 'EvilIcons' },
    { name: 'plus', type: 'EvilIcons' },
    { name: 'pointer', type: 'EvilIcons' },
    { name: 'question', type: 'EvilIcons' },
    { name: 'redo', type: 'EvilIcons' },
    { name: 'refresh', type: 'EvilIcons' },
    { name: 'retweet', type: 'EvilIcons' },
    { name: 'sc-facebook', type: 'EvilIcons' },
    { name: 'sc-github', type: 'EvilIcons' },
    { name: 'sc-google-plus', type: 'EvilIcons' },
    { name: 'sc-instagram', type: 'EvilIcons' },
    { name: 'sc-linkedin', type: 'EvilIcons' },
    { name: 'sc-odnoklassniki', type: 'EvilIcons' },
    { name: 'sc-pinterest', type: 'EvilIcons' },
    { name: 'sc-skype', type: 'EvilIcons' },
    { name: 'sc-soundcloud', type: 'EvilIcons' },
    { name: 'sc-telegram', type: 'EvilIcons' },
    { name: 'sc-tumblr', type: 'EvilIcons' },
    { name: 'sc-twitter', type: 'EvilIcons' },
    { name: 'sc-vimeo', type: 'EvilIcons' },
    { name: 'sc-vk', type: 'EvilIcons' },
    { name: 'sc-youtube', type: 'EvilIcons' },
    { name: 'search', type: 'EvilIcons' },
    { name: 'share-apple', type: 'EvilIcons' },
    { name: 'share-google', type: 'EvilIcons' },
    { name: 'spinner', type: 'EvilIcons' },
    { name: 'spinner-2', type: 'EvilIcons' },
    { name: 'spinner-3', type: 'EvilIcons' },
    { name: 'star', type: 'EvilIcons' },
    { name: 'tag', type: 'EvilIcons' },
    { name: 'trash', type: 'EvilIcons' },
    { name: 'trophy', type: 'EvilIcons' },
    { name: 'undo', type: 'EvilIcons' },
    { name: 'unlock', type: 'EvilIcons' },
    { name: 'user', type: 'EvilIcons' },
    

    { name: 'activity', type: 'Feather' },
    { name: 'airplay', type: 'Feather' },
    { name: 'alert-circle', type: 'Feather' },
    { name: 'alert-octagon', type: 'Feather' },
    { name: 'alert-triangle', type: 'Feather' },
    { name: 'align-center', type: 'Feather' },
    { name: 'align-justify', type: 'Feather' },
    { name: 'align-left', type: 'Feather' },
    { name: 'align-right', type: 'Feather' },
    { name: 'anchor', type: 'Feather' },
    { name: 'aperture', type: 'Feather' },
    { name: 'archive', type: 'Feather' },
    { name: 'arrow-down', type: 'Feather' },
    { name: 'arrow-down-circle', type: 'Feather' },
    { name: 'arrow-down-left', type: 'Feather' },
    { name: 'arrow-down-right', type: 'Feather' },
    { name: 'arrow-left', type: 'Feather' },
    { name: 'arrow-left-circle', type: 'Feather' },
    { name: 'arrow-right', type: 'Feather' },
    { name: 'arrow-right-circle', type: 'Feather' },
    { name: 'arrow-up', type: 'Feather' },
    { name: 'arrow-up-circle', type: 'Feather' },
    { name: 'arrow-up-left', type: 'Feather' },
    { name: 'arrow-up-right', type: 'Feather' },
    { name: 'at-sign', type: 'Feather' },
    { name: 'award', type: 'Feather' },
    { name: 'bar-chart', type: 'Feather' },
    { name: 'bar-chart-2', type: 'Feather' },
    { name: 'battery', type: 'Feather' },
    { name: 'battery-charging', type: 'Feather' },
    { name: 'bell', type: 'Feather' },
    { name: 'bell-off', type: 'Feather' },
    { name: 'bluetooth', type: 'Feather' },
    { name: 'bold', type: 'Feather' },
    { name: 'book', type: 'Feather' },
    { name: 'book-open', type: 'Feather' },
    { name: 'bookmark', type: 'Feather' },
    { name: 'box', type: 'Feather' },
    { name: 'briefcase', type: 'Feather' },
    { name: 'calendar', type: 'Feather' },
    { name: 'camera', type: 'Feather' },
    { name: 'camera-off', type: 'Feather' },
    { name: 'cast', type: 'Feather' },
    { name: 'check', type: 'Feather' },
    { name: 'check-circle', type: 'Feather' },
    { name: 'check-square', type: 'Feather' },
    { name: 'chevron-down', type: 'Feather' },
    { name: 'chevron-left', type: 'Feather' },
    { name: 'chevron-right', type: 'Feather' },
    { name: 'chevron-up', type: 'Feather' },
    { name: 'chevrons-down', type: 'Feather' },
    { name: 'chevrons-left', type: 'Feather' },
    { name: 'chevrons-right', type: 'Feather' },
    { name: 'chevrons-up', type: 'Feather' },
    { name: 'chrome', type: 'Feather' },
    { name: 'circle', type: 'Feather' },
    { name: 'clipboard', type: 'Feather' },
    { name: 'clock', type: 'Feather' },
    { name: 'cloud', type: 'Feather' },
    { name: 'cloud-drizzle', type: 'Feather' },
    { name: 'cloud-lightning', type: 'Feather' },
    { name: 'cloud-off', type: 'Feather' },
    { name: 'cloud-rain', type: 'Feather' },
    { name: 'cloud-snow', type: 'Feather' },
    { name: 'code', type: 'Feather' },
    { name: 'codepen', type: 'Feather' },
    { name: 'command', type: 'Feather' },
    { name: 'compass', type: 'Feather' },
    { name: 'copy', type: 'Feather' },
    { name: 'corner-down-left', type: 'Feather' },
    { name: 'corner-down-right', type: 'Feather' },
    { name: 'corner-left-down', type: 'Feather' },
    { name: 'corner-left-up', type: 'Feather' },
    { name: 'corner-right-down', type: 'Feather' },
    { name: 'corner-right-up', type: 'Feather' },
    { name: 'corner-up-left', type: 'Feather' },
    { name: 'corner-up-right', type: 'Feather' },
    { name: 'cpu', type: 'Feather' },
    { name: 'credit-card', type: 'Feather' },
    { name: 'crop', type: 'Feather' },
    { name: 'crosshair', type: 'Feather' },
    { name: 'database', type: 'Feather' },
    { name: 'delete', type: 'Feather' },
    { name: 'disc', type: 'Feather' },
    { name: 'dollar-sign', type: 'Feather' },
    { name: 'download', type: 'Feather' },
    { name: 'download-cloud', type: 'Feather' },
    { name: 'droplet', type: 'Feather' },
    { name: 'edit', type: 'Feather' },
    { name: 'edit-2', type: 'Feather' },
    { name: 'edit-3', type: 'Feather' },
    { name: 'external-link', type: 'Feather' },
    { name: 'eye', type: 'Feather' },
    { name: 'eye-off', type: 'Feather' },
    { name: 'facebook', type: 'Feather' },
    { name: 'fast-forward', type: 'Feather' },
    { name: 'feather', type: 'Feather' },
    { name: 'file', type: 'Feather' },
    { name: 'file-minus', type: 'Feather' },
    { name: 'file-plus', type: 'Feather' },
    { name: 'file-text', type: 'Feather' },
    { name: 'film', type: 'Feather' },
    { name: 'filter', type: 'Feather' },
    { name: 'flag', type: 'Feather' },
    { name: 'folder', type: 'Feather' },
    { name: 'folder-minus', type: 'Feather' },
    { name: 'folder-plus', type: 'Feather' },
    { name: 'gift', type: 'Feather' },
    { name: 'git-branch', type: 'Feather' },
    { name: 'git-commit', type: 'Feather' },
    { name: 'git-merge', type: 'Feather' },
    { name: 'git-pull-request', type: 'Feather' },
    { name: 'github', type: 'Feather' },
    { name: 'gitlab', type: 'Feather' },
    { name: 'globe', type: 'Feather' },
    { name: 'grid', type: 'Feather' },
    { name: 'hard-drive', type: 'Feather' },
    { name: 'hash', type: 'Feather' },
    { name: 'headphones', type: 'Feather' },
    { name: 'heart', type: 'Feather' },
    { name: 'help-circle', type: 'Feather' },
    { name: 'home', type: 'Feather' },
    { name: 'image', type: 'Feather' },
    { name: 'inbox', type: 'Feather' },
    { name: 'info', type: 'Feather' },
    { name: 'instagram', type: 'Feather' },
    { name: 'italic', type: 'Feather' },
    { name: 'layers', type: 'Feather' },
    { name: 'layout', type: 'Feather' },
    { name: 'life-buoy', type: 'Feather' },
    { name: 'link', type: 'Feather' },
    { name: 'link-2', type: 'Feather' },
    { name: 'linkedin', type: 'Feather' },
    { name: 'list', type: 'Feather' },
    { name: 'loader', type: 'Feather' },
    { name: 'lock', type: 'Feather' },
    { name: 'log-in', type: 'Feather' },
    { name: 'log-out', type: 'Feather' },
    { name: 'mail', type: 'Feather' },
    { name: 'map', type: 'Feather' },
    { name: 'map-pin', type: 'Feather' },
    { name: 'maximize', type: 'Feather' },
    { name: 'maximize-2', type: 'Feather' },
    { name: 'menu', type: 'Feather' },
    { name: 'message-circle', type: 'Feather' },
    { name: 'message-square', type: 'Feather' },
    { name: 'mic', type: 'Feather' },
    { name: 'mic-off', type: 'Feather' },
    { name: 'minimize', type: 'Feather' },
    { name: 'minimize-2', type: 'Feather' },
    { name: 'minus', type: 'Feather' },
    { name: 'minus-circle', type: 'Feather' },
    { name: 'minus-square', type: 'Feather' },
    { name: 'monitor', type: 'Feather' },
    { name: 'moon', type: 'Feather' },
    { name: 'more-horizontal', type: 'Feather' },
    { name: 'more-vertical', type: 'Feather' },
    { name: 'move', type: 'Feather' },
    { name: 'music', type: 'Feather' },
    { name: 'navigation', type: 'Feather' },
    { name: 'navigation-2', type: 'Feather' },
    { name: 'octagon', type: 'Feather' },
    { name: 'package', type: 'Feather' },
    { name: 'paperclip', type: 'Feather' },
    { name: 'pause', type: 'Feather' },
    { name: 'pause-circle', type: 'Feather' },
    { name: 'percent', type: 'Feather' },
    { name: 'phone', type: 'Feather' },
    { name: 'phone-call', type: 'Feather' },
    { name: 'phone-forwarded', type: 'Feather' },
    { name: 'phone-incoming', type: 'Feather' },
    { name: 'phone-missed', type: 'Feather' },
    { name: 'phone-off', type: 'Feather' },
    { name: 'phone-outgoing', type: 'Feather' },
    { name: 'pie-chart', type: 'Feather' },
    { name: 'play', type: 'Feather' },
    { name: 'play-circle', type: 'Feather' },
    { name: 'plus', type: 'Feather' },
    { name: 'plus-circle', type: 'Feather' },
    { name: 'plus-square', type: 'Feather' },
    { name: 'pocket', type: 'Feather' },
    { name: 'power', type: 'Feather' },
    { name: 'printer', type: 'Feather' },
    { name: 'radio', type: 'Feather' },
    { name: 'refresh-ccw', type: 'Feather' },
    { name: 'refresh-cw', type: 'Feather' },
    { name: 'repeat', type: 'Feather' },
    { name: 'rewind', type: 'Feather' },
    { name: 'rotate-ccw', type: 'Feather' },
    { name: 'rotate-cw', type: 'Feather' },
    { name: 'rss', type: 'Feather' },
    { name: 'save', type: 'Feather' },
    { name: 'scissors', type: 'Feather' },
    { name: 'search', type: 'Feather' },
    { name: 'send', type: 'Feather' },
    { name: 'server', type: 'Feather' },
    { name: 'settings', type: 'Feather' },
    { name: 'share', type: 'Feather' },
    { name: 'share-2', type: 'Feather' },
    { name: 'shield', type: 'Feather' },
    { name: 'shield-off', type: 'Feather' },
    { name: 'shopping-bag', type: 'Feather' },
    { name: 'shopping-cart', type: 'Feather' },
    { name: 'shuffle', type: 'Feather' },
    { name: 'sidebar', type: 'Feather' },
    { name: 'skip-back', type: 'Feather' },
    { name: 'skip-forward', type: 'Feather' },
    { name: 'slack', type: 'Feather' },
    { name: 'slash', type: 'Feather' },
    { name: 'sliders', type: 'Feather' },
    { name: 'smartphone', type: 'Feather' },
    { name: 'speaker', type: 'Feather' },
    { name: 'square', type: 'Feather' },
    { name: 'star', type: 'Feather' },
    { name: 'stop-circle', type: 'Feather' },
    { name: 'sun', type: 'Feather' },
    { name: 'sunrise', type: 'Feather' },
    { name: 'sunset', type: 'Feather' },
    { name: 'tablet', type: 'Feather' },
    { name: 'tag', type: 'Feather' },
    { name: 'target', type: 'Feather' },
    { name: 'terminal', type: 'Feather' },
    { name: 'thermometer', type: 'Feather' },
    { name: 'thumbs-down', type: 'Feather' },
    { name: 'thumbs-up', type: 'Feather' },
    { name: 'toggle-left', type: 'Feather' },
    { name: 'toggle-right', type: 'Feather' },
    { name: 'trash', type: 'Feather' },
    { name: 'trash-2', type: 'Feather' },
    { name: 'trending-down', type: 'Feather' },
    { name: 'trending-up', type: 'Feather' },
    { name: 'triangle', type: 'Feather' },
    { name: 'truck', type: 'Feather' },
    { name: 'tv', type: 'Feather' },
    { name: 'twitter', type: 'Feather' },
    { name: 'type', type: 'Feather' },
    { name: 'umbrella', type: 'Feather' },
    { name: 'underline', type: 'Feather' },
    { name: 'unlock', type: 'Feather' },
    { name: 'upload', type: 'Feather' },
    { name: 'upload-cloud', type: 'Feather' },
    { name: 'user', type: 'Feather' },
    { name: 'user-check', type: 'Feather' },
    { name: 'user-minus', type: 'Feather' },
    { name: 'user-plus', type: 'Feather' },
    { name: 'user-x', type: 'Feather' },
    { name: 'users', type: 'Feather' },
    { name: 'video', type: 'Feather' },
    { name: 'video-off', type: 'Feather' },
    { name: 'voicemail', type: 'Feather' },
    { name: 'volume', type: 'Feather' },
    { name: 'volume-1', type: 'Feather' },
    { name: 'volume-2', type: 'Feather' },
    { name: 'volume-x', type: 'Feather' },
    { name: 'watch', type: 'Feather' },
    { name: 'wifi', type: 'Feather' },
    { name: 'wifi-off', type: 'Feather' },
    { name: 'wind', type: 'Feather' },
    { name: 'x', type: 'Feather' },
    { name: 'x-circle', type: 'Feather' },
    { name: 'x-square', type: 'Feather' },
    { name: 'youtube', type: 'Feather' },
    { name: 'zap', type: 'Feather' },
    { name: 'zap-off', type: 'Feather' },
    { name: 'zoom-in', type: 'Feather' },
    { name: 'zoom-out', type: 'Feather' },

/*
	{ name: 'glass', type: 'FontAwesome' },
	{ name: 'music', type: 'FontAwesome' },
	{ name: 'search', type: 'FontAwesome' },
	{ name: 'envelope-o', type: 'FontAwesome' },
	{ name: 'heart', type: 'FontAwesome' },
	{ name: 'star', type: 'FontAwesome' },
	{ name: 'star-o', type: 'FontAwesome' },
	{ name: 'user', type: 'FontAwesome' },
	{ name: 'film', type: 'FontAwesome' },
	{ name: 'th-large', type: 'FontAwesome' },
	{ name: 'th', type: 'FontAwesome' },
	{ name: 'th-list', type: 'FontAwesome' },
	{ name: 'check', type: 'FontAwesome' },
	{ name: 'remove', type: 'FontAwesome' },
	{ name: 'close', type: 'FontAwesome' },
	{ name: 'times', type: 'FontAwesome' },
	{ name: 'search-plus', type: 'FontAwesome' },
	{ name: 'search-minus', type: 'FontAwesome' },
	{ name: 'power-off', type: 'FontAwesome' },
	{ name: 'signal', type: 'FontAwesome' },
	{ name: 'gear', type: 'FontAwesome' },
	{ name: 'cog', type: 'FontAwesome' },
	{ name: 'trash-o', type: 'FontAwesome' },
	{ name: 'home', type: 'FontAwesome' },
	{ name: 'file-o', type: 'FontAwesome' },
	{ name: 'clock-o', type: 'FontAwesome' },
	{ name: 'road', type: 'FontAwesome' },
	{ name: 'download', type: 'FontAwesome' },
	{ name: 'arrow-circle-o-down', type: 'FontAwesome' },
	{ name: 'arrow-circle-o-up', type: 'FontAwesome' },
	{ name: 'inbox', type: 'FontAwesome' },
	{ name: 'play-circle-o', type: 'FontAwesome' },
	{ name: 'rotate-right', type: 'FontAwesome' },
	{ name: 'repeat', type: 'FontAwesome' },
	{ name: 'refresh', type: 'FontAwesome' },
	{ name: 'list-alt', type: 'FontAwesome' },
	{ name: 'lock', type: 'FontAwesome' },
	{ name: 'flag', type: 'FontAwesome' },
	{ name: 'headphones', type: 'FontAwesome' },
	{ name: 'volume-off', type: 'FontAwesome' },
	{ name: 'volume-down', type: 'FontAwesome' },
	{ name: 'volume-up', type: 'FontAwesome' },
	{ name: 'qrcode', type: 'FontAwesome' },
	{ name: 'barcode', type: 'FontAwesome' },
	{ name: 'tag', type: 'FontAwesome' },
	{ name: 'tags', type: 'FontAwesome' },
	{ name: 'book', type: 'FontAwesome' },
	{ name: 'bookmark', type: 'FontAwesome' },
	{ name: 'print', type: 'FontAwesome' },
	{ name: 'camera', type: 'FontAwesome' },
	{ name: 'font', type: 'FontAwesome' },
	{ name: 'bold', type: 'FontAwesome' },
	{ name: 'italic', type: 'FontAwesome' },
	{ name: 'text-height', type: 'FontAwesome' },
	{ name: 'text-width', type: 'FontAwesome' },
	{ name: 'align-left', type: 'FontAwesome' },
	{ name: 'align-center', type: 'FontAwesome' },
	{ name: 'align-right', type: 'FontAwesome' },
	{ name: 'align-justify', type: 'FontAwesome' },
	{ name: 'list', type: 'FontAwesome' },
	{ name: 'dedent', type: 'FontAwesome' },
	{ name: 'outdent', type: 'FontAwesome' },
	{ name: 'indent', type: 'FontAwesome' },
	{ name: 'video-camera', type: 'FontAwesome' },
	{ name: 'photo', type: 'FontAwesome' },
	{ name: 'image', type: 'FontAwesome' },
	{ name: 'picture-o', type: 'FontAwesome' },
	{ name: 'pencil', type: 'FontAwesome' },
	{ name: 'map-marker', type: 'FontAwesome' },
	{ name: 'adjust', type: 'FontAwesome' },
	{ name: 'tint', type: 'FontAwesome' },
	{ name: 'edit', type: 'FontAwesome' },
	{ name: 'pencil-square-o', type: 'FontAwesome' },
	{ name: 'share-square-o', type: 'FontAwesome' },
	{ name: 'check-square-o', type: 'FontAwesome' },
	{ name: 'arrows', type: 'FontAwesome' },
	{ name: 'step-backward', type: 'FontAwesome' },
	{ name: 'fast-backward', type: 'FontAwesome' },
	{ name: 'backward', type: 'FontAwesome' },
	{ name: 'play', type: 'FontAwesome' },
	{ name: 'pause', type: 'FontAwesome' },
	{ name: 'stop', type: 'FontAwesome' },
	{ name: 'forward', type: 'FontAwesome' },
	{ name: 'fast-forward', type: 'FontAwesome' },
	{ name: 'step-forward', type: 'FontAwesome' },
	{ name: 'eject', type: 'FontAwesome' },
	{ name: 'chevron-left', type: 'FontAwesome' },
	{ name: 'chevron-right', type: 'FontAwesome' },
	{ name: 'plus-circle', type: 'FontAwesome' },
	{ name: 'minus-circle', type: 'FontAwesome' },
	{ name: 'times-circle', type: 'FontAwesome' },
	{ name: 'check-circle', type: 'FontAwesome' },
	{ name: 'question-circle', type: 'FontAwesome' },
	{ name: 'info-circle', type: 'FontAwesome' },
	{ name: 'crosshairs', type: 'FontAwesome' },
	{ name: 'times-circle-o', type: 'FontAwesome' },
	{ name: 'check-circle-o', type: 'FontAwesome' },
	{ name: 'ban', type: 'FontAwesome' },
	{ name: 'arrow-left', type: 'FontAwesome' },
	{ name: 'arrow-right', type: 'FontAwesome' },
	{ name: 'arrow-up', type: 'FontAwesome' },
	{ name: 'arrow-down', type: 'FontAwesome' },
	{ name: 'mail-forward', type: 'FontAwesome' },
	{ name: 'share', type: 'FontAwesome' },
	{ name: 'expand', type: 'FontAwesome' },
	{ name: 'compress', type: 'FontAwesome' },
	{ name: 'plus', type: 'FontAwesome' },
	{ name: 'minus', type: 'FontAwesome' },
	{ name: 'asterisk', type: 'FontAwesome' },
	{ name: 'exclamation-circle', type: 'FontAwesome' },
	{ name: 'gift', type: 'FontAwesome' },
	{ name: 'leaf', type: 'FontAwesome' },
	{ name: 'fire', type: 'FontAwesome' },
	{ name: 'eye', type: 'FontAwesome' },
	{ name: 'eye-slash', type: 'FontAwesome' },
	{ name: 'warning', type: 'FontAwesome' },
	{ name: 'exclamation-triangle', type: 'FontAwesome' },
	{ name: 'plane', type: 'FontAwesome' },
	{ name: 'calendar', type: 'FontAwesome' },
	{ name: 'random', type: 'FontAwesome' },
	{ name: 'comment', type: 'FontAwesome' },
	{ name: 'magnet', type: 'FontAwesome' },
	{ name: 'chevron-up', type: 'FontAwesome' },
	{ name: 'chevron-down', type: 'FontAwesome' },
	{ name: 'retweet', type: 'FontAwesome' },
	{ name: 'shopping-cart', type: 'FontAwesome' },
	{ name: 'folder', type: 'FontAwesome' },
	{ name: 'folder-open', type: 'FontAwesome' },
	{ name: 'arrows-v', type: 'FontAwesome' },
	{ name: 'arrows-h', type: 'FontAwesome' },
	{ name: 'bar-chart-o', type: 'FontAwesome' },
	{ name: 'bar-chart', type: 'FontAwesome' },
	{ name: 'twitter-square', type: 'FontAwesome' },
	{ name: 'facebook-square', type: 'FontAwesome' },
	{ name: 'camera-retro', type: 'FontAwesome' },
	{ name: 'key', type: 'FontAwesome' },
	{ name: 'gears', type: 'FontAwesome' },
	{ name: 'cogs', type: 'FontAwesome' },
	{ name: 'comments', type: 'FontAwesome' },
	{ name: 'thumbs-o-up', type: 'FontAwesome' },
	{ name: 'thumbs-o-down', type: 'FontAwesome' },
	{ name: 'star-half', type: 'FontAwesome' },
	{ name: 'heart-o', type: 'FontAwesome' },
	{ name: 'sign-out', type: 'FontAwesome' },
	{ name: 'linkedin-square', type: 'FontAwesome' },
	{ name: 'thumb-tack', type: 'FontAwesome' },
	{ name: 'external-link', type: 'FontAwesome' },
	{ name: 'sign-in', type: 'FontAwesome' },
	{ name: 'trophy', type: 'FontAwesome' },
	{ name: 'github-square', type: 'FontAwesome' },
	{ name: 'upload', type: 'FontAwesome' },
	{ name: 'lemon-o', type: 'FontAwesome' },
	{ name: 'phone', type: 'FontAwesome' },
	{ name: 'square-o', type: 'FontAwesome' },
	{ name: 'bookmark-o', type: 'FontAwesome' },
	{ name: 'phone-square', type: 'FontAwesome' },
	{ name: 'twitter', type: 'FontAwesome' },
	{ name: 'facebook-f', type: 'FontAwesome' },
	{ name: 'facebook', type: 'FontAwesome' },
	{ name: 'github', type: 'FontAwesome' },
	{ name: 'unlock', type: 'FontAwesome' },
	{ name: 'credit-card', type: 'FontAwesome' },
	{ name: 'feed', type: 'FontAwesome' },
	{ name: 'rss', type: 'FontAwesome' },
	{ name: 'hdd-o', type: 'FontAwesome' },
	{ name: 'bullhorn', type: 'FontAwesome' },
	{ name: 'bell', type: 'FontAwesome' },
	{ name: 'certificate', type: 'FontAwesome' },
	{ name: 'hand-o-right', type: 'FontAwesome' },
	{ name: 'hand-o-left', type: 'FontAwesome' },
	{ name: 'hand-o-up', type: 'FontAwesome' },
	{ name: 'hand-o-down', type: 'FontAwesome' },
	{ name: 'arrow-circle-left', type: 'FontAwesome' },
	{ name: 'arrow-circle-right', type: 'FontAwesome' },
	{ name: 'arrow-circle-up', type: 'FontAwesome' },
	{ name: 'arrow-circle-down', type: 'FontAwesome' },
	{ name: 'globe', type: 'FontAwesome' },
	{ name: 'wrench', type: 'FontAwesome' },
	{ name: 'tasks', type: 'FontAwesome' },
	{ name: 'filter', type: 'FontAwesome' },
	{ name: 'briefcase', type: 'FontAwesome' },
	{ name: 'arrows-alt', type: 'FontAwesome' },
	{ name: 'group', type: 'FontAwesome' },
	{ name: 'users', type: 'FontAwesome' },
	{ name: 'chain', type: 'FontAwesome' },
	{ name: 'link', type: 'FontAwesome' },
	{ name: 'cloud', type: 'FontAwesome' },
	{ name: 'flask', type: 'FontAwesome' },
	{ name: 'cut', type: 'FontAwesome' },
	{ name: 'scissors', type: 'FontAwesome' },
	{ name: 'copy', type: 'FontAwesome' },
	{ name: 'files-o', type: 'FontAwesome' },
	{ name: 'paperclip', type: 'FontAwesome' },
	{ name: 'save', type: 'FontAwesome' },
	{ name: 'floppy-o', type: 'FontAwesome' },
	{ name: 'square', type: 'FontAwesome' },
	{ name: 'navicon', type: 'FontAwesome' },
	{ name: 'reorder', type: 'FontAwesome' },
	{ name: 'bars', type: 'FontAwesome' },
	{ name: 'list-ul', type: 'FontAwesome' },
	{ name: 'list-ol', type: 'FontAwesome' },
	{ name: 'strikethrough', type: 'FontAwesome' },
	{ name: 'underline', type: 'FontAwesome' },
	{ name: 'table', type: 'FontAwesome' },
	{ name: 'magic', type: 'FontAwesome' },
	{ name: 'truck', type: 'FontAwesome' },
	{ name: 'pinterest', type: 'FontAwesome' },
	{ name: 'pinterest-square', type: 'FontAwesome' },
	{ name: 'google-plus-square', type: 'FontAwesome' },
	{ name: 'google-plus', type: 'FontAwesome' },
	{ name: 'money', type: 'FontAwesome' },
	{ name: 'caret-down', type: 'FontAwesome' },
	{ name: 'caret-up', type: 'FontAwesome' },
	{ name: 'caret-left', type: 'FontAwesome' },
	{ name: 'caret-right', type: 'FontAwesome' },
	{ name: 'columns', type: 'FontAwesome' },
	{ name: 'unsorted', type: 'FontAwesome' },
	{ name: 'sort', type: 'FontAwesome' },
	{ name: 'sort-down', type: 'FontAwesome' },
	{ name: 'sort-desc', type: 'FontAwesome' },
	{ name: 'sort-up', type: 'FontAwesome' },
	{ name: 'sort-asc', type: 'FontAwesome' },
	{ name: 'envelope', type: 'FontAwesome' },
	{ name: 'linkedin', type: 'FontAwesome' },
	{ name: 'rotate-left', type: 'FontAwesome' },
	{ name: 'undo', type: 'FontAwesome' },
	{ name: 'legal', type: 'FontAwesome' },
	{ name: 'gavel', type: 'FontAwesome' },
	{ name: 'dashboard', type: 'FontAwesome' },
	{ name: 'tachometer', type: 'FontAwesome' },
	{ name: 'comment-o', type: 'FontAwesome' },
	{ name: 'comments-o', type: 'FontAwesome' },
	{ name: 'flash', type: 'FontAwesome' },
	{ name: 'bolt', type: 'FontAwesome' },
	{ name: 'sitemap', type: 'FontAwesome' },
	{ name: 'umbrella', type: 'FontAwesome' },
	{ name: 'paste', type: 'FontAwesome' },
	{ name: 'clipboard', type: 'FontAwesome' },
	{ name: 'lightbulb-o', type: 'FontAwesome' },
	{ name: 'exchange', type: 'FontAwesome' },
	{ name: 'cloud-download', type: 'FontAwesome' },
	{ name: 'cloud-upload', type: 'FontAwesome' },
	{ name: 'user-md', type: 'FontAwesome' },
	{ name: 'stethoscope', type: 'FontAwesome' },
	{ name: 'suitcase', type: 'FontAwesome' },
	{ name: 'bell-o', type: 'FontAwesome' },
	{ name: 'coffee', type: 'FontAwesome' },
	{ name: 'cutlery', type: 'FontAwesome' },
	{ name: 'file-text-o', type: 'FontAwesome' },
	{ name: 'building-o', type: 'FontAwesome' },
	{ name: 'hospital-o', type: 'FontAwesome' },
	{ name: 'ambulance', type: 'FontAwesome' },
	{ name: 'medkit', type: 'FontAwesome' },
	{ name: 'fighter-jet', type: 'FontAwesome' },
	{ name: 'beer', type: 'FontAwesome' },
	{ name: 'h-square', type: 'FontAwesome' },
	{ name: 'plus-square', type: 'FontAwesome' },
	{ name: 'angle-double-left', type: 'FontAwesome' },
	{ name: 'angle-double-right', type: 'FontAwesome' },
	{ name: 'angle-double-up', type: 'FontAwesome' },
	{ name: 'angle-double-down', type: 'FontAwesome' },
	{ name: 'angle-left', type: 'FontAwesome' },
	{ name: 'angle-right', type: 'FontAwesome' },
	{ name: 'angle-up', type: 'FontAwesome' },
	{ name: 'angle-down', type: 'FontAwesome' },
	{ name: 'desktop', type: 'FontAwesome' },
	{ name: 'laptop', type: 'FontAwesome' },
	{ name: 'tablet', type: 'FontAwesome' },
	{ name: 'mobile-phone', type: 'FontAwesome' },
	{ name: 'mobile', type: 'FontAwesome' },
	{ name: 'circle-o', type: 'FontAwesome' },
	{ name: 'quote-left', type: 'FontAwesome' },
	{ name: 'quote-right', type: 'FontAwesome' },
	{ name: 'spinner', type: 'FontAwesome' },
	{ name: 'circle', type: 'FontAwesome' },
	{ name: 'mail-reply', type: 'FontAwesome' },
	{ name: 'reply', type: 'FontAwesome' },
	{ name: 'github-alt', type: 'FontAwesome' },
	{ name: 'folder-o', type: 'FontAwesome' },
	{ name: 'folder-open-o', type: 'FontAwesome' },
	{ name: 'smile-o', type: 'FontAwesome' },
	{ name: 'frown-o', type: 'FontAwesome' },
	{ name: 'meh-o', type: 'FontAwesome' },
	{ name: 'gamepad', type: 'FontAwesome' },
	{ name: 'keyboard-o', type: 'FontAwesome' },
	{ name: 'flag-o', type: 'FontAwesome' },
	{ name: 'flag-checkered', type: 'FontAwesome' },
	{ name: 'terminal', type: 'FontAwesome' },
	{ name: 'code', type: 'FontAwesome' },
	{ name: 'mail-reply-all', type: 'FontAwesome' },
	{ name: 'reply-all', type: 'FontAwesome' },
	{ name: 'star-half-empty', type: 'FontAwesome' },
	{ name: 'star-half-full', type: 'FontAwesome' },
	{ name: 'star-half-o', type: 'FontAwesome' },
	{ name: 'location-arrow', type: 'FontAwesome' },
	{ name: 'crop', type: 'FontAwesome' },
	{ name: 'code-fork', type: 'FontAwesome' },
	{ name: 'unlink', type: 'FontAwesome' },
	{ name: 'chain-broken', type: 'FontAwesome' },
	{ name: 'question', type: 'FontAwesome' },
	{ name: 'info', type: 'FontAwesome' },
	{ name: 'exclamation', type: 'FontAwesome' },
	{ name: 'superscript', type: 'FontAwesome' },
	{ name: 'subscript', type: 'FontAwesome' },
	{ name: 'eraser', type: 'FontAwesome' },
	{ name: 'puzzle-piece', type: 'FontAwesome' },
	{ name: 'microphone', type: 'FontAwesome' },
	{ name: 'microphone-slash', type: 'FontAwesome' },
	{ name: 'shield', type: 'FontAwesome' },
	{ name: 'calendar-o', type: 'FontAwesome' },
	{ name: 'fire-extinguisher', type: 'FontAwesome' },
	{ name: 'rocket', type: 'FontAwesome' },
	{ name: 'maxcdn', type: 'FontAwesome' },
	{ name: 'chevron-circle-left', type: 'FontAwesome' },
	{ name: 'chevron-circle-right', type: 'FontAwesome' },
	{ name: 'chevron-circle-up', type: 'FontAwesome' },
	{ name: 'chevron-circle-down', type: 'FontAwesome' },
	{ name: 'html5', type: 'FontAwesome' },
	{ name: 'css3', type: 'FontAwesome' },
	{ name: 'anchor', type: 'FontAwesome' },
	{ name: 'unlock-alt', type: 'FontAwesome' },
	{ name: 'bullseye', type: 'FontAwesome' },
	{ name: 'ellipsis-h', type: 'FontAwesome' },
	{ name: 'ellipsis-v', type: 'FontAwesome' },
	{ name: 'rss-square', type: 'FontAwesome' },
	{ name: 'play-circle', type: 'FontAwesome' },
	{ name: 'ticket', type: 'FontAwesome' },
	{ name: 'minus-square', type: 'FontAwesome' },
	{ name: 'minus-square-o', type: 'FontAwesome' },
	{ name: 'level-up', type: 'FontAwesome' },
	{ name: 'level-down', type: 'FontAwesome' },
	{ name: 'check-square', type: 'FontAwesome' },
	{ name: 'pencil-square', type: 'FontAwesome' },
	{ name: 'external-link-square', type: 'FontAwesome' },
	{ name: 'share-square', type: 'FontAwesome' },
	{ name: 'compass', type: 'FontAwesome' },
	{ name: 'toggle-down', type: 'FontAwesome' },
	{ name: 'caret-square-o-down', type: 'FontAwesome' },
	{ name: 'toggle-up', type: 'FontAwesome' },
	{ name: 'caret-square-o-up', type: 'FontAwesome' },
	{ name: 'toggle-right', type: 'FontAwesome' },
	{ name: 'caret-square-o-right', type: 'FontAwesome' },
	{ name: 'euro', type: 'FontAwesome' },
	{ name: 'eur', type: 'FontAwesome' },
	{ name: 'gbp', type: 'FontAwesome' },
	{ name: 'dollar', type: 'FontAwesome' },
	{ name: 'usd', type: 'FontAwesome' },
	{ name: 'rupee', type: 'FontAwesome' },
	{ name: 'inr', type: 'FontAwesome' },
	{ name: 'cny', type: 'FontAwesome' },
	{ name: 'rmb', type: 'FontAwesome' },
	{ name: 'yen', type: 'FontAwesome' },
	{ name: 'jpy', type: 'FontAwesome' },
	{ name: 'ruble', type: 'FontAwesome' },
	{ name: 'rouble', type: 'FontAwesome' },
	{ name: 'rub', type: 'FontAwesome' },
	{ name: 'won', type: 'FontAwesome' },
	{ name: 'krw', type: 'FontAwesome' },
	{ name: 'bitcoin', type: 'FontAwesome' },
	{ name: 'btc', type: 'FontAwesome' },
	{ name: 'file', type: 'FontAwesome' },
	{ name: 'file-text', type: 'FontAwesome' },
	{ name: 'sort-alpha-asc', type: 'FontAwesome' },
	{ name: 'sort-alpha-desc', type: 'FontAwesome' },
	{ name: 'sort-amount-asc', type: 'FontAwesome' },
	{ name: 'sort-amount-desc', type: 'FontAwesome' },
	{ name: 'sort-numeric-asc', type: 'FontAwesome' },
	{ name: 'sort-numeric-desc', type: 'FontAwesome' },
	{ name: 'thumbs-up', type: 'FontAwesome' },
	{ name: 'thumbs-down', type: 'FontAwesome' },
	{ name: 'youtube-square', type: 'FontAwesome' },
	{ name: 'youtube', type: 'FontAwesome' },
	{ name: 'xing', type: 'FontAwesome' },
	{ name: 'xing-square', type: 'FontAwesome' },
	{ name: 'youtube-play', type: 'FontAwesome' },
	{ name: 'dropbox', type: 'FontAwesome' },
	{ name: 'stack-overflow', type: 'FontAwesome' },
	{ name: 'instagram', type: 'FontAwesome' },
	{ name: 'flickr', type: 'FontAwesome' },
	{ name: 'adn', type: 'FontAwesome' },
	{ name: 'bitbucket', type: 'FontAwesome' },
	{ name: 'bitbucket-square', type: 'FontAwesome' },
	{ name: 'tumblr', type: 'FontAwesome' },
	{ name: 'tumblr-square', type: 'FontAwesome' },
	{ name: 'long-arrow-down', type: 'FontAwesome' },
	{ name: 'long-arrow-up', type: 'FontAwesome' },
	{ name: 'long-arrow-left', type: 'FontAwesome' },
	{ name: 'long-arrow-right', type: 'FontAwesome' },
	{ name: 'apple', type: 'FontAwesome' },
	{ name: 'windows', type: 'FontAwesome' },
	{ name: 'android', type: 'FontAwesome' },
	{ name: 'linux', type: 'FontAwesome' },
	{ name: 'dribbble', type: 'FontAwesome' },
	{ name: 'skype', type: 'FontAwesome' },
	{ name: 'foursquare', type: 'FontAwesome' },
	{ name: 'trello', type: 'FontAwesome' },
	{ name: 'female', type: 'FontAwesome' },
	{ name: 'male', type: 'FontAwesome' },
	{ name: 'gittip', type: 'FontAwesome' },
	{ name: 'gratipay', type: 'FontAwesome' },
	{ name: 'sun-o', type: 'FontAwesome' },
	{ name: 'moon-o', type: 'FontAwesome' },
	{ name: 'archive', type: 'FontAwesome' },
	{ name: 'bug', type: 'FontAwesome' },
	{ name: 'vk', type: 'FontAwesome' },
	{ name: 'weibo', type: 'FontAwesome' },
	{ name: 'renren', type: 'FontAwesome' },
	{ name: 'pagelines', type: 'FontAwesome' },
	{ name: 'stack-exchange', type: 'FontAwesome' },
	{ name: 'arrow-circle-o-right', type: 'FontAwesome' },
	{ name: 'arrow-circle-o-left', type: 'FontAwesome' },
	{ name: 'toggle-left', type: 'FontAwesome' },
	{ name: 'caret-square-o-left', type: 'FontAwesome' },
	{ name: 'dot-circle-o', type: 'FontAwesome' },
	{ name: 'wheelchair', type: 'FontAwesome' },
	{ name: 'vimeo-square', type: 'FontAwesome' },
	{ name: 'turkish-lira', type: 'FontAwesome' },
	{ name: 'try', type: 'FontAwesome' },
	{ name: 'plus-square-o', type: 'FontAwesome' },
	{ name: 'space-shuttle', type: 'FontAwesome' },
	{ name: 'slack', type: 'FontAwesome' },
	{ name: 'envelope-square', type: 'FontAwesome' },
	{ name: 'wordpress', type: 'FontAwesome' },
	{ name: 'openid', type: 'FontAwesome' },
	{ name: 'institution', type: 'FontAwesome' },
	{ name: 'bank', type: 'FontAwesome' },
	{ name: 'university', type: 'FontAwesome' },
	{ name: 'mortar-board', type: 'FontAwesome' },
	{ name: 'graduation-cap', type: 'FontAwesome' },
	{ name: 'yahoo', type: 'FontAwesome' },
	{ name: 'google', type: 'FontAwesome' },
	{ name: 'reddit', type: 'FontAwesome' },
	{ name: 'reddit-square', type: 'FontAwesome' },
	{ name: 'stumbleupon-circle', type: 'FontAwesome' },
	{ name: 'stumbleupon', type: 'FontAwesome' },
	{ name: 'delicious', type: 'FontAwesome' },
	{ name: 'digg', type: 'FontAwesome' },
	{ name: 'pied-piper-pp', type: 'FontAwesome' },
	{ name: 'pied-piper-alt', type: 'FontAwesome' },
	{ name: 'drupal', type: 'FontAwesome' },
	{ name: 'joomla', type: 'FontAwesome' },
	{ name: 'language', type: 'FontAwesome' },
	{ name: 'fax', type: 'FontAwesome' },
	{ name: 'building', type: 'FontAwesome' },
	{ name: 'child', type: 'FontAwesome' },
	{ name: 'paw', type: 'FontAwesome' },
	{ name: 'spoon', type: 'FontAwesome' },
	{ name: 'cube', type: 'FontAwesome' },
	{ name: 'cubes', type: 'FontAwesome' },
	{ name: 'behance', type: 'FontAwesome' },
	{ name: 'behance-square', type: 'FontAwesome' },
	{ name: 'steam', type: 'FontAwesome' },
	{ name: 'steam-square', type: 'FontAwesome' },
	{ name: 'recycle', type: 'FontAwesome' },
	{ name: 'automobile', type: 'FontAwesome' },
	{ name: 'car', type: 'FontAwesome' },
	{ name: 'cab', type: 'FontAwesome' },
	{ name: 'taxi', type: 'FontAwesome' },
	{ name: 'tree', type: 'FontAwesome' },
	{ name: 'spotify', type: 'FontAwesome' },
	{ name: 'deviantart', type: 'FontAwesome' },
	{ name: 'soundcloud', type: 'FontAwesome' },
	{ name: 'database', type: 'FontAwesome' },
	{ name: 'file-pdf-o', type: 'FontAwesome' },
	{ name: 'file-word-o', type: 'FontAwesome' },
	{ name: 'file-excel-o', type: 'FontAwesome' },
	{ name: 'file-powerpoint-o', type: 'FontAwesome' },
	{ name: 'file-photo-o', type: 'FontAwesome' },
	{ name: 'file-picture-o', type: 'FontAwesome' },
	{ name: 'file-image-o', type: 'FontAwesome' },
	{ name: 'file-zip-o', type: 'FontAwesome' },
	{ name: 'file-archive-o', type: 'FontAwesome' },
	{ name: 'file-sound-o', type: 'FontAwesome' },
	{ name: 'file-audio-o', type: 'FontAwesome' },
	{ name: 'file-movie-o', type: 'FontAwesome' },
	{ name: 'file-video-o', type: 'FontAwesome' },
	{ name: 'file-code-o', type: 'FontAwesome' },
	{ name: 'vine', type: 'FontAwesome' },
	{ name: 'codepen', type: 'FontAwesome' },
	{ name: 'jsfiddle', type: 'FontAwesome' },
	{ name: 'life-bouy', type: 'FontAwesome' },
	{ name: 'life-buoy', type: 'FontAwesome' },
	{ name: 'life-saver', type: 'FontAwesome' },
	{ name: 'support', type: 'FontAwesome' },
	{ name: 'life-ring', type: 'FontAwesome' },
	{ name: 'circle-o-notch', type: 'FontAwesome' },
	{ name: 'ra', type: 'FontAwesome' },
	{ name: 'resistance', type: 'FontAwesome' },
	{ name: 'rebel', type: 'FontAwesome' },
	{ name: 'ge', type: 'FontAwesome' },
	{ name: 'empire', type: 'FontAwesome' },
	{ name: 'git-square', type: 'FontAwesome' },
	{ name: 'git', type: 'FontAwesome' },
	{ name: 'y-combinator-square', type: 'FontAwesome' },
	{ name: 'yc-square', type: 'FontAwesome' },
	{ name: 'hacker-news', type: 'FontAwesome' },
	{ name: 'tencent-weibo', type: 'FontAwesome' },
	{ name: 'qq', type: 'FontAwesome' },
	{ name: 'wechat', type: 'FontAwesome' },
	{ name: 'weixin', type: 'FontAwesome' },
	{ name: 'send', type: 'FontAwesome' },
	{ name: 'paper-plane', type: 'FontAwesome' },
	{ name: 'send-o', type: 'FontAwesome' },
	{ name: 'paper-plane-o', type: 'FontAwesome' },
	{ name: 'history', type: 'FontAwesome' },
	{ name: 'circle-thin', type: 'FontAwesome' },
	{ name: 'header', type: 'FontAwesome' },
	{ name: 'paragraph', type: 'FontAwesome' },
	{ name: 'sliders', type: 'FontAwesome' },
	{ name: 'share-alt', type: 'FontAwesome' },
	{ name: 'share-alt-square', type: 'FontAwesome' },
	{ name: 'bomb', type: 'FontAwesome' },
	{ name: 'soccer-ball-o', type: 'FontAwesome' },
	{ name: 'futbol-o', type: 'FontAwesome' },
	{ name: 'tty', type: 'FontAwesome' },
	{ name: 'binoculars', type: 'FontAwesome' },
	{ name: 'plug', type: 'FontAwesome' },
	{ name: 'slideshare', type: 'FontAwesome' },
	{ name: 'twitch', type: 'FontAwesome' },
	{ name: 'yelp', type: 'FontAwesome' },
	{ name: 'newspaper-o', type: 'FontAwesome' },
	{ name: 'wifi', type: 'FontAwesome' },
	{ name: 'calculator', type: 'FontAwesome' },
	{ name: 'paypal', type: 'FontAwesome' },
	{ name: 'google-wallet', type: 'FontAwesome' },
	{ name: 'cc-visa', type: 'FontAwesome' },
	{ name: 'cc-mastercard', type: 'FontAwesome' },
	{ name: 'cc-discover', type: 'FontAwesome' },
	{ name: 'cc-amex', type: 'FontAwesome' },
	{ name: 'cc-paypal', type: 'FontAwesome' },
	{ name: 'cc-stripe', type: 'FontAwesome' },
	{ name: 'bell-slash', type: 'FontAwesome' },
	{ name: 'bell-slash-o', type: 'FontAwesome' },
	{ name: 'trash', type: 'FontAwesome' },
	{ name: 'copyright', type: 'FontAwesome' },
	{ name: 'at', type: 'FontAwesome' },
	{ name: 'eyedropper', type: 'FontAwesome' },
	{ name: 'paint-brush', type: 'FontAwesome' },
	{ name: 'birthday-cake', type: 'FontAwesome' },
	{ name: 'area-chart', type: 'FontAwesome' },
	{ name: 'pie-chart', type: 'FontAwesome' },
	{ name: 'line-chart', type: 'FontAwesome' },
	{ name: 'lastfm', type: 'FontAwesome' },
	{ name: 'lastfm-square', type: 'FontAwesome' },
	{ name: 'toggle-off', type: 'FontAwesome' },
	{ name: 'toggle-on', type: 'FontAwesome' },
	{ name: 'bicycle', type: 'FontAwesome' },
	{ name: 'bus', type: 'FontAwesome' },
	{ name: 'ioxhost', type: 'FontAwesome' },
	{ name: 'angellist', type: 'FontAwesome' },
	{ name: 'cc', type: 'FontAwesome' },
	{ name: 'shekel', type: 'FontAwesome' },
	{ name: 'sheqel', type: 'FontAwesome' },
	{ name: 'ils', type: 'FontAwesome' },
	{ name: 'meanpath', type: 'FontAwesome' },
	{ name: 'buysellads', type: 'FontAwesome' },
	{ name: 'connectdevelop', type: 'FontAwesome' },
	{ name: 'dashcube', type: 'FontAwesome' },
	{ name: 'forumbee', type: 'FontAwesome' },
	{ name: 'leanpub', type: 'FontAwesome' },
	{ name: 'sellsy', type: 'FontAwesome' },
	{ name: 'shirtsinbulk', type: 'FontAwesome' },
	{ name: 'simplybuilt', type: 'FontAwesome' },
	{ name: 'skyatlas', type: 'FontAwesome' },
	{ name: 'cart-plus', type: 'FontAwesome' },
	{ name: 'cart-arrow-down', type: 'FontAwesome' },
	{ name: 'diamond', type: 'FontAwesome' },
	{ name: 'ship', type: 'FontAwesome' },
	{ name: 'user-secret', type: 'FontAwesome' },
	{ name: 'motorcycle', type: 'FontAwesome' },
	{ name: 'street-view', type: 'FontAwesome' },
	{ name: 'heartbeat', type: 'FontAwesome' },
	{ name: 'venus', type: 'FontAwesome' },
	{ name: 'mars', type: 'FontAwesome' },
	{ name: 'mercury', type: 'FontAwesome' },
	{ name: 'intersex', type: 'FontAwesome' },
	{ name: 'transgender', type: 'FontAwesome' },
	{ name: 'transgender-alt', type: 'FontAwesome' },
	{ name: 'venus-double', type: 'FontAwesome' },
	{ name: 'mars-double', type: 'FontAwesome' },
	{ name: 'venus-mars', type: 'FontAwesome' },
	{ name: 'mars-stroke', type: 'FontAwesome' },
	{ name: 'mars-stroke-v', type: 'FontAwesome' },
	{ name: 'mars-stroke-h', type: 'FontAwesome' },
	{ name: 'neuter', type: 'FontAwesome' },
	{ name: 'genderless', type: 'FontAwesome' },
	{ name: 'facebook-official', type: 'FontAwesome' },
	{ name: 'pinterest-p', type: 'FontAwesome' },
	{ name: 'whatsapp', type: 'FontAwesome' },
	{ name: 'server', type: 'FontAwesome' },
	{ name: 'user-plus', type: 'FontAwesome' },
	{ name: 'user-times', type: 'FontAwesome' },
	{ name: 'hotel', type: 'FontAwesome' },
	{ name: 'bed', type: 'FontAwesome' },
	{ name: 'viacoin', type: 'FontAwesome' },
	{ name: 'train', type: 'FontAwesome' },
	{ name: 'subway', type: 'FontAwesome' },
	{ name: 'medium', type: 'FontAwesome' },
	{ name: 'yc', type: 'FontAwesome' },
	{ name: 'y-combinator', type: 'FontAwesome' },
	{ name: 'optin-monster', type: 'FontAwesome' },
	{ name: 'opencart', type: 'FontAwesome' },
	{ name: 'expeditedssl', type: 'FontAwesome' },
	{ name: 'battery-4', type: 'FontAwesome' },
	{ name: 'battery', type: 'FontAwesome' },
	{ name: 'battery-full', type: 'FontAwesome' },
	{ name: 'battery-3', type: 'FontAwesome' },
	{ name: 'battery-three-quarters', type: 'FontAwesome' },
	{ name: 'battery-2', type: 'FontAwesome' },
	{ name: 'battery-half', type: 'FontAwesome' },
	{ name: 'battery-1', type: 'FontAwesome' },
	{ name: 'battery-quarter', type: 'FontAwesome' },
	{ name: 'battery-0', type: 'FontAwesome' },
	{ name: 'battery-empty', type: 'FontAwesome' },
	{ name: 'mouse-pointer', type: 'FontAwesome' },
	{ name: 'i-cursor', type: 'FontAwesome' },
	{ name: 'object-group', type: 'FontAwesome' },
	{ name: 'object-ungroup', type: 'FontAwesome' },
	{ name: 'sticky-note', type: 'FontAwesome' },
	{ name: 'sticky-note-o', type: 'FontAwesome' },
	{ name: 'cc-jcb', type: 'FontAwesome' },
	{ name: 'cc-diners-club', type: 'FontAwesome' },
	{ name: 'clone', type: 'FontAwesome' },
	{ name: 'balance-scale', type: 'FontAwesome' },
	{ name: 'hourglass-o', type: 'FontAwesome' },
	{ name: 'hourglass-1', type: 'FontAwesome' },
	{ name: 'hourglass-start', type: 'FontAwesome' },
	{ name: 'hourglass-2', type: 'FontAwesome' },
	{ name: 'hourglass-half', type: 'FontAwesome' },
	{ name: 'hourglass-3', type: 'FontAwesome' },
	{ name: 'hourglass-end', type: 'FontAwesome' },
	{ name: 'hourglass', type: 'FontAwesome' },
	{ name: 'hand-grab-o', type: 'FontAwesome' },
	{ name: 'hand-rock-o', type: 'FontAwesome' },
	{ name: 'hand-stop-o', type: 'FontAwesome' },
	{ name: 'hand-paper-o', type: 'FontAwesome' },
	{ name: 'hand-scissors-o', type: 'FontAwesome' },
	{ name: 'hand-lizard-o', type: 'FontAwesome' },
	{ name: 'hand-spock-o', type: 'FontAwesome' },
	{ name: 'hand-pointer-o', type: 'FontAwesome' },
	{ name: 'hand-peace-o', type: 'FontAwesome' },
	{ name: 'trademark', type: 'FontAwesome' },
	{ name: 'registered', type: 'FontAwesome' },
	{ name: 'creative-commons', type: 'FontAwesome' },
	{ name: 'gg', type: 'FontAwesome' },
	{ name: 'gg-circle', type: 'FontAwesome' },
	{ name: 'tripadvisor', type: 'FontAwesome' },
	{ name: 'odnoklassniki', type: 'FontAwesome' },
	{ name: 'odnoklassniki-square', type: 'FontAwesome' },
	{ name: 'get-pocket', type: 'FontAwesome' },
	{ name: 'wikipedia-w', type: 'FontAwesome' },
	{ name: 'safari', type: 'FontAwesome' },
	{ name: 'chrome', type: 'FontAwesome' },
	{ name: 'firefox', type: 'FontAwesome' },
	{ name: 'opera', type: 'FontAwesome' },
	{ name: 'internet-explorer', type: 'FontAwesome' },
	{ name: 'tv', type: 'FontAwesome' },
	{ name: 'television', type: 'FontAwesome' },
	{ name: 'contao', type: 'FontAwesome' },
	{ name: '500px', type: 'FontAwesome' },
	{ name: 'amazon', type: 'FontAwesome' },
	{ name: 'calendar-plus-o', type: 'FontAwesome' },
	{ name: 'calendar-minus-o', type: 'FontAwesome' },
	{ name: 'calendar-times-o', type: 'FontAwesome' },
	{ name: 'calendar-check-o', type: 'FontAwesome' },
	{ name: 'industry', type: 'FontAwesome' },
	{ name: 'map-pin', type: 'FontAwesome' },
	{ name: 'map-signs', type: 'FontAwesome' },
	{ name: 'map-o', type: 'FontAwesome' },
	{ name: 'map', type: 'FontAwesome' },
	{ name: 'commenting', type: 'FontAwesome' },
	{ name: 'commenting-o', type: 'FontAwesome' },
	{ name: 'houzz', type: 'FontAwesome' },
	{ name: 'vimeo', type: 'FontAwesome' },
	{ name: 'black-tie', type: 'FontAwesome' },
	{ name: 'fonticons', type: 'FontAwesome' },
	{ name: 'reddit-alien', type: 'FontAwesome' },
	{ name: 'edge', type: 'FontAwesome' },
	{ name: 'credit-card-alt', type: 'FontAwesome' },
	{ name: 'codiepie', type: 'FontAwesome' },
	{ name: 'modx', type: 'FontAwesome' },
	{ name: 'fort-awesome', type: 'FontAwesome' },
	{ name: 'usb', type: 'FontAwesome' },
	{ name: 'product-hunt', type: 'FontAwesome' },
	{ name: 'mixcloud', type: 'FontAwesome' },
	{ name: 'scribd', type: 'FontAwesome' },
	{ name: 'pause-circle', type: 'FontAwesome' },
	{ name: 'pause-circle-o', type: 'FontAwesome' },
	{ name: 'stop-circle', type: 'FontAwesome' },
	{ name: 'stop-circle-o', type: 'FontAwesome' },
	{ name: 'shopping-bag', type: 'FontAwesome' },
	{ name: 'shopping-basket', type: 'FontAwesome' },
	{ name: 'hashtag', type: 'FontAwesome' },
	{ name: 'bluetooth', type: 'FontAwesome' },
	{ name: 'bluetooth-b', type: 'FontAwesome' },
	{ name: 'percent', type: 'FontAwesome' },
	{ name: 'gitlab', type: 'FontAwesome' },
	{ name: 'wpbeginner', type: 'FontAwesome' },
	{ name: 'wpforms', type: 'FontAwesome' },
	{ name: 'envira', type: 'FontAwesome' },
	{ name: 'universal-access', type: 'FontAwesome' },
	{ name: 'wheelchair-alt', type: 'FontAwesome' },
	{ name: 'question-circle-o', type: 'FontAwesome' },
	{ name: 'blind', type: 'FontAwesome' },
	{ name: 'audio-description', type: 'FontAwesome' },
	{ name: 'volume-control-phone', type: 'FontAwesome' },
	{ name: 'braille', type: 'FontAwesome' },
	{ name: 'assistive-listening-systems', type: 'FontAwesome' },
	{ name: 'asl-interpreting', type: 'FontAwesome' },
	{ name: 'american-sign-language-interpreting', type: 'FontAwesome' },
	{ name: 'deafness', type: 'FontAwesome' },
	{ name: 'hard-of-hearing', type: 'FontAwesome' },
	{ name: 'deaf', type: 'FontAwesome' },
	{ name: 'glide', type: 'FontAwesome' },
	{ name: 'glide-g', type: 'FontAwesome' },
	{ name: 'signing', type: 'FontAwesome' },
	{ name: 'sign-language', type: 'FontAwesome' },
	{ name: 'low-vision', type: 'FontAwesome' },
	{ name: 'viadeo', type: 'FontAwesome' },
	{ name: 'viadeo-square', type: 'FontAwesome' },
	{ name: 'snapchat', type: 'FontAwesome' },
	{ name: 'snapchat-ghost', type: 'FontAwesome' },
	{ name: 'snapchat-square', type: 'FontAwesome' },
	{ name: 'pied-piper', type: 'FontAwesome' },
	{ name: 'first-order', type: 'FontAwesome' },
	{ name: 'yoast', type: 'FontAwesome' },
	{ name: 'themeisle', type: 'FontAwesome' },
	{ name: 'google-plus-circle', type: 'FontAwesome' },
	{ name: 'google-plus-official', type: 'FontAwesome' },
	{ name: 'fa', type: 'FontAwesome' },
	{ name: 'font-awesome', type: 'FontAwesome' },
	{ name: 'handshake-o', type: 'FontAwesome' },
	{ name: 'envelope-open', type: 'FontAwesome' },
	{ name: 'envelope-open-o', type: 'FontAwesome' },
	{ name: 'linode', type: 'FontAwesome' },
	{ name: 'address-book', type: 'FontAwesome' },
	{ name: 'address-book-o', type: 'FontAwesome' },
	{ name: 'vcard', type: 'FontAwesome' },
	{ name: 'address-card', type: 'FontAwesome' },
	{ name: 'vcard-o', type: 'FontAwesome' },
	{ name: 'address-card-o', type: 'FontAwesome' },
	{ name: 'user-circle', type: 'FontAwesome' },
	{ name: 'user-circle-o', type: 'FontAwesome' },
	{ name: 'user-o', type: 'FontAwesome' },
	{ name: 'id-badge', type: 'FontAwesome' },
	{ name: 'drivers-license', type: 'FontAwesome' },
	{ name: 'id-card', type: 'FontAwesome' },
	{ name: 'drivers-license-o', type: 'FontAwesome' },
	{ name: 'id-card-o', type: 'FontAwesome' },
	{ name: 'quora', type: 'FontAwesome' },
	{ name: 'free-code-camp', type: 'FontAwesome' },
	{ name: 'telegram', type: 'FontAwesome' },
	{ name: 'thermometer-4', type: 'FontAwesome' },
	{ name: 'thermometer', type: 'FontAwesome' },
	{ name: 'thermometer-full', type: 'FontAwesome' },
	{ name: 'thermometer-3', type: 'FontAwesome' },
	{ name: 'thermometer-three-quarters', type: 'FontAwesome' },
	{ name: 'thermometer-2', type: 'FontAwesome' },
	{ name: 'thermometer-half', type: 'FontAwesome' },
	{ name: 'thermometer-1', type: 'FontAwesome' },
	{ name: 'thermometer-quarter', type: 'FontAwesome' },
	{ name: 'thermometer-0', type: 'FontAwesome' },
	{ name: 'thermometer-empty', type: 'FontAwesome' },
	{ name: 'shower', type: 'FontAwesome' },
	{ name: 'bathtub', type: 'FontAwesome' },
	{ name: 's15', type: 'FontAwesome' },
	{ name: 'bath', type: 'FontAwesome' },
	{ name: 'podcast', type: 'FontAwesome' },
	{ name: 'window-maximize', type: 'FontAwesome' },
	{ name: 'window-minimize', type: 'FontAwesome' },
	{ name: 'window-restore', type: 'FontAwesome' },
	{ name: 'times-rectangle', type: 'FontAwesome' },
	{ name: 'window-close', type: 'FontAwesome' },
	{ name: 'times-rectangle-o', type: 'FontAwesome' },
	{ name: 'window-close-o', type: 'FontAwesome' },
	{ name: 'bandcamp', type: 'FontAwesome' },
	{ name: 'grav', type: 'FontAwesome' },
	{ name: 'etsy', type: 'FontAwesome' },
	{ name: 'imdb', type: 'FontAwesome' },
	{ name: 'ravelry', type: 'FontAwesome' },
	{ name: 'eercast', type: 'FontAwesome' },
	{ name: 'microchip', type: 'FontAwesome' },
	{ name: 'snowflake-o', type: 'FontAwesome' },
	{ name: 'superpowers', type: 'FontAwesome' },
	{ name: 'wpexplorer', type: 'FontAwesome' },
	{ name: 'meetup', type: 'FontAwesome' },

*/
    { name: 'address-book', type: 'Foundation' },
    { name: 'alert', type: 'Foundation' },
    { name: 'align-center', type: 'Foundation' },
    { name: 'align-justify', type: 'Foundation' },
    { name: 'align-left', type: 'Foundation' },
    { name: 'align-right', type: 'Foundation' },
    { name: 'anchor', type: 'Foundation' },
    { name: 'annotate', type: 'Foundation' },
    { name: 'archive', type: 'Foundation' },
    { name: 'arrow-down', type: 'Foundation' },
    { name: 'arrow-left', type: 'Foundation' },
    { name: 'arrow-right', type: 'Foundation' },
    { name: 'arrow-up', type: 'Foundation' },
    { name: 'arrows-compress', type: 'Foundation' },
    { name: 'arrows-expand', type: 'Foundation' },
    { name: 'arrows-in', type: 'Foundation' },
    { name: 'arrows-out', type: 'Foundation' },
    { name: 'asl', type: 'Foundation' },
    { name: 'asterisk', type: 'Foundation' },
    { name: 'at-sign', type: 'Foundation' },
    { name: 'background-color', type: 'Foundation' },
    { name: 'battery-empty', type: 'Foundation' },
    { name: 'battery-full', type: 'Foundation' },
    { name: 'battery-half', type: 'Foundation' },
    { name: 'bitcoin-circle', type: 'Foundation' },
    { name: 'bitcoin', type: 'Foundation' },
    { name: 'blind', type: 'Foundation' },
    { name: 'bluetooth', type: 'Foundation' },
    { name: 'bold', type: 'Foundation' },
    { name: 'book-bookmark', type: 'Foundation' },
    { name: 'book', type: 'Foundation' },
    { name: 'bookmark', type: 'Foundation' },
    { name: 'braille', type: 'Foundation' },
    { name: 'burst-new', type: 'Foundation' },
    { name: 'burst-sale', type: 'Foundation' },
    { name: 'burst', type: 'Foundation' },
    { name: 'calendar', type: 'Foundation' },
    { name: 'camera', type: 'Foundation' },
    { name: 'check', type: 'Foundation' },
    { name: 'checkbox', type: 'Foundation' },
    { name: 'clipboard-notes', type: 'Foundation' },
    { name: 'clipboard-pencil', type: 'Foundation' },
    { name: 'clipboard', type: 'Foundation' },
    { name: 'clock', type: 'Foundation' },
    { name: 'closed-caption', type: 'Foundation' },
    { name: 'cloud', type: 'Foundation' },
    { name: 'comment-minus', type: 'Foundation' },
    { name: 'comment-quotes', type: 'Foundation' },
    { name: 'comment-video', type: 'Foundation' },
    { name: 'comment', type: 'Foundation' },
    { name: 'comments', type: 'Foundation' },
    { name: 'compass', type: 'Foundation' },
    { name: 'contrast', type: 'Foundation' },
    { name: 'credit-card', type: 'Foundation' },
    { name: 'crop', type: 'Foundation' },
    { name: 'crown', type: 'Foundation' },
    { name: 'css3', type: 'Foundation' },
    { name: 'database', type: 'Foundation' },
    { name: 'die-five', type: 'Foundation' },
    { name: 'die-four', type: 'Foundation' },
    { name: 'die-one', type: 'Foundation' },
    { name: 'die-six', type: 'Foundation' },
    { name: 'die-three', type: 'Foundation' },
    { name: 'die-two', type: 'Foundation' },
    { name: 'dislike', type: 'Foundation' },
    { name: 'dollar-bill', type: 'Foundation' },
    { name: 'dollar', type: 'Foundation' },
    { name: 'download', type: 'Foundation' },
    { name: 'eject', type: 'Foundation' },
    { name: 'elevator', type: 'Foundation' },
    { name: 'euro', type: 'Foundation' },
    { name: 'eye', type: 'Foundation' },
    { name: 'fast-forward', type: 'Foundation' },
    { name: 'female-symbol', type: 'Foundation' },
    { name: 'female', type: 'Foundation' },
    { name: 'filter', type: 'Foundation' },
    { name: 'first-aid', type: 'Foundation' },
    { name: 'flag', type: 'Foundation' },
    { name: 'folder-add', type: 'Foundation' },
    { name: 'folder-lock', type: 'Foundation' },
    { name: 'folder', type: 'Foundation' },
    { name: 'foot', type: 'Foundation' },
    { name: 'foundation', type: 'Foundation' },
    { name: 'graph-bar', type: 'Foundation' },
    { name: 'graph-horizontal', type: 'Foundation' },
    { name: 'graph-pie', type: 'Foundation' },
    { name: 'graph-trend', type: 'Foundation' },
    { name: 'guide-dog', type: 'Foundation' },
    { name: 'hearing-aid', type: 'Foundation' },
    { name: 'heart', type: 'Foundation' },
    { name: 'home', type: 'Foundation' },
    { name: 'html5', type: 'Foundation' },
    { name: 'indent-less', type: 'Foundation' },
    { name: 'indent-more', type: 'Foundation' },
    { name: 'info', type: 'Foundation' },
    { name: 'italic', type: 'Foundation' },
    { name: 'key', type: 'Foundation' },
    { name: 'laptop', type: 'Foundation' },
    { name: 'layout', type: 'Foundation' },
    { name: 'lightbulb', type: 'Foundation' },
    { name: 'like', type: 'Foundation' },
    { name: 'link', type: 'Foundation' },
    { name: 'list-bullet', type: 'Foundation' },
    { name: 'list-number', type: 'Foundation' },
    { name: 'list-thumbnails', type: 'Foundation' },
    { name: 'list', type: 'Foundation' },
    { name: 'lock', type: 'Foundation' },
    { name: 'loop', type: 'Foundation' },
    { name: 'magnifying-glass', type: 'Foundation' },
    { name: 'mail', type: 'Foundation' },
    { name: 'male-female', type: 'Foundation' },
    { name: 'male-symbol', type: 'Foundation' },
    { name: 'male', type: 'Foundation' },
    { name: 'map', type: 'Foundation' },
    { name: 'marker', type: 'Foundation' },
    { name: 'megaphone', type: 'Foundation' },
    { name: 'microphone', type: 'Foundation' },
    { name: 'minus-circle', type: 'Foundation' },
    { name: 'minus', type: 'Foundation' },
    { name: 'mobile-signal', type: 'Foundation' },
    { name: 'mobile', type: 'Foundation' },
    { name: 'monitor', type: 'Foundation' },
    { name: 'mountains', type: 'Foundation' },
    { name: 'music', type: 'Foundation' },
    { name: 'next', type: 'Foundation' },
    { name: 'no-dogs', type: 'Foundation' },
    { name: 'no-smoking', type: 'Foundation' },
    { name: 'page-add', type: 'Foundation' },
    { name: 'page-copy', type: 'Foundation' },
    { name: 'page-csv', type: 'Foundation' },
    { name: 'page-delete', type: 'Foundation' },
    { name: 'page-doc', type: 'Foundation' },
    { name: 'page-edit', type: 'Foundation' },
    { name: 'page-export-csv', type: 'Foundation' },
    { name: 'page-export-doc', type: 'Foundation' },
    { name: 'page-export-pdf', type: 'Foundation' },
    { name: 'page-export', type: 'Foundation' },
    { name: 'page-filled', type: 'Foundation' },
    { name: 'page-multiple', type: 'Foundation' },
    { name: 'page-pdf', type: 'Foundation' },
    { name: 'page-remove', type: 'Foundation' },
    { name: 'page-search', type: 'Foundation' },
    { name: 'page', type: 'Foundation' },
    { name: 'paint-bucket', type: 'Foundation' },
    { name: 'paperclip', type: 'Foundation' },
    { name: 'pause', type: 'Foundation' },
    { name: 'paw', type: 'Foundation' },
    { name: 'paypal', type: 'Foundation' },
    { name: 'pencil', type: 'Foundation' },
    { name: 'photo', type: 'Foundation' },
    { name: 'play-circle', type: 'Foundation' },
    { name: 'play-video', type: 'Foundation' },
    { name: 'play', type: 'Foundation' },
    { name: 'plus', type: 'Foundation' },
    { name: 'pound', type: 'Foundation' },
    { name: 'power', type: 'Foundation' },
    { name: 'previous', type: 'Foundation' },
    { name: 'price-tag', type: 'Foundation' },
    { name: 'pricetag-multiple', type: 'Foundation' },
    { name: 'print', type: 'Foundation' },
    { name: 'prohibited', type: 'Foundation' },
    { name: 'projection-screen', type: 'Foundation' },
    { name: 'puzzle', type: 'Foundation' },
    { name: 'quote', type: 'Foundation' },
    { name: 'record', type: 'Foundation' },
    { name: 'refresh', type: 'Foundation' },
    { name: 'results-demographics', type: 'Foundation' },
    { name: 'results', type: 'Foundation' },
    { name: 'rewind-ten', type: 'Foundation' },
    { name: 'rewind', type: 'Foundation' },
    { name: 'rss', type: 'Foundation' },
    { name: 'safety-cone', type: 'Foundation' },
    { name: 'save', type: 'Foundation' },
    { name: 'share', type: 'Foundation' },
    { name: 'sheriff-badge', type: 'Foundation' },
    { name: 'shield', type: 'Foundation' },
    { name: 'shopping-bag', type: 'Foundation' },
    { name: 'shopping-cart', type: 'Foundation' },
    { name: 'shuffle', type: 'Foundation' },
    { name: 'skull', type: 'Foundation' },
    { name: 'social-500px', type: 'Foundation' },
    { name: 'social-adobe', type: 'Foundation' },
    { name: 'social-amazon', type: 'Foundation' },
    { name: 'social-android', type: 'Foundation' },
    { name: 'social-apple', type: 'Foundation' },
    { name: 'social-behance', type: 'Foundation' },
    { name: 'social-bing', type: 'Foundation' },
    { name: 'social-blogger', type: 'Foundation' },
    { name: 'social-delicious', type: 'Foundation' },
    { name: 'social-designer-news', type: 'Foundation' },
    { name: 'social-deviant-art', type: 'Foundation' },
    { name: 'social-digg', type: 'Foundation' },
    { name: 'social-dribbble', type: 'Foundation' },
    { name: 'social-drive', type: 'Foundation' },
    { name: 'social-dropbox', type: 'Foundation' },
    { name: 'social-evernote', type: 'Foundation' },
    { name: 'social-facebook', type: 'Foundation' },
    { name: 'social-flickr', type: 'Foundation' },
    { name: 'social-forrst', type: 'Foundation' },
    { name: 'social-foursquare', type: 'Foundation' },
    { name: 'social-game-center', type: 'Foundation' },
    { name: 'social-github', type: 'Foundation' },
    { name: 'social-google-plus', type: 'Foundation' },
    { name: 'social-hacker-news', type: 'Foundation' },
    { name: 'social-hi5', type: 'Foundation' },
    { name: 'social-instagram', type: 'Foundation' },
    { name: 'social-joomla', type: 'Foundation' },
    { name: 'social-lastfm', type: 'Foundation' },
    { name: 'social-linkedin', type: 'Foundation' },
    { name: 'social-medium', type: 'Foundation' },
    { name: 'social-myspace', type: 'Foundation' },
    { name: 'social-orkut', type: 'Foundation' },
    { name: 'social-path', type: 'Foundation' },
    { name: 'social-picasa', type: 'Foundation' },
    { name: 'social-pinterest', type: 'Foundation' },
    { name: 'social-rdio', type: 'Foundation' },
    { name: 'social-reddit', type: 'Foundation' },
    { name: 'social-skillshare', type: 'Foundation' },
    { name: 'social-skype', type: 'Foundation' },
    { name: 'social-smashing-mag', type: 'Foundation' },
    { name: 'social-snapchat', type: 'Foundation' },
    { name: 'social-spotify', type: 'Foundation' },
    { name: 'social-squidoo', type: 'Foundation' },
    { name: 'social-stack-overflow', type: 'Foundation' },
    { name: 'social-steam', type: 'Foundation' },
    { name: 'social-stumbleupon', type: 'Foundation' },
    { name: 'social-treehouse', type: 'Foundation' },
    { name: 'social-tumblr', type: 'Foundation' },
    { name: 'social-twitter', type: 'Foundation' },
    { name: 'social-vimeo', type: 'Foundation' },
    { name: 'social-windows', type: 'Foundation' },
    { name: 'social-xbox', type: 'Foundation' },
    { name: 'social-yahoo', type: 'Foundation' },
    { name: 'social-yelp', type: 'Foundation' },
    { name: 'social-youtube', type: 'Foundation' },
    { name: 'social-zerply', type: 'Foundation' },
    { name: 'social-zurb', type: 'Foundation' },
    { name: 'sound', type: 'Foundation' },
    { name: 'star', type: 'Foundation' },
    { name: 'stop', type: 'Foundation' },
    { name: 'strikethrough', type: 'Foundation' },
    { name: 'subscript', type: 'Foundation' },
    { name: 'superscript', type: 'Foundation' },
    { name: 'tablet-landscape', type: 'Foundation' },
    { name: 'tablet-portrait', type: 'Foundation' },
    { name: 'target-two', type: 'Foundation' },
    { name: 'target', type: 'Foundation' },
    { name: 'telephone-accessible', type: 'Foundation' },
    { name: 'telephone', type: 'Foundation' },
    { name: 'text-color', type: 'Foundation' },
    { name: 'thumbnails', type: 'Foundation' },
    { name: 'ticket', type: 'Foundation' },
    { name: 'torso-business', type: 'Foundation' },
    { name: 'torso-female', type: 'Foundation' },
    { name: 'torso', type: 'Foundation' },
    { name: 'torsos-all-female', type: 'Foundation' },
    { name: 'torsos-all', type: 'Foundation' },
    { name: 'torsos-female-male', type: 'Foundation' },
    { name: 'torsos-male-female', type: 'Foundation' },
    { name: 'torsos', type: 'Foundation' },
    { name: 'trash', type: 'Foundation' },
    { name: 'trees', type: 'Foundation' },
    { name: 'trophy', type: 'Foundation' },
    { name: 'underline', type: 'Foundation' },
    { name: 'universal-access', type: 'Foundation' },
    { name: 'unlink', type: 'Foundation' },
    { name: 'unlock', type: 'Foundation' },
    { name: 'upload-cloud', type: 'Foundation' },
    { name: 'upload', type: 'Foundation' },
    { name: 'usb', type: 'Foundation' },
    { name: 'video', type: 'Foundation' },
    { name: 'volume-none', type: 'Foundation' },
    { name: 'volume-strike', type: 'Foundation' },
    { name: 'volume', type: 'Foundation' },
    { name: 'web', type: 'Foundation' },
    { name: 'wheelchair', type: 'Foundation' },
    { name: 'widget', type: 'Foundation' },
    { name: 'wrench', type: 'Foundation' },
    { name: 'x-circle', type: 'Foundation' },
    { name: 'x', type: 'Foundation' },
    { name: 'yen', type: 'Foundation' },
    { name: 'zoom-in', type: 'Foundation' },
    { name: 'zoom-out', type: 'Foundation' },


    { name: 'ios-add', type: 'Ionicons' },
    { name: 'ios-add-circle', type: 'Ionicons' },
    { name: 'ios-add-circle-outline', type: 'Ionicons' },
    { name: 'ios-airplane', type: 'Ionicons' },
    { name: 'ios-alarm', type: 'Ionicons' },
    { name: 'ios-albums', type: 'Ionicons' },
    { name: 'ios-alert', type: 'Ionicons' },
    { name: 'ios-american-football', type: 'Ionicons' },
    { name: 'ios-analytics', type: 'Ionicons' },
    { name: 'ios-aperture', type: 'Ionicons' },
    { name: 'ios-apps', type: 'Ionicons' },
    { name: 'ios-appstore', type: 'Ionicons' },
    { name: 'ios-archive', type: 'Ionicons' },
    { name: 'ios-arrow-back', type: 'Ionicons' },
    { name: 'ios-arrow-down', type: 'Ionicons' },
    { name: 'ios-arrow-dropdown', type: 'Ionicons' },
    { name: 'ios-arrow-dropdown-circle', type: 'Ionicons' },
    { name: 'ios-arrow-dropleft', type: 'Ionicons' },
    { name: 'ios-arrow-dropleft-circle', type: 'Ionicons' },
    { name: 'ios-arrow-dropright', type: 'Ionicons' },
    { name: 'ios-arrow-dropright-circle', type: 'Ionicons' },
    { name: 'ios-arrow-dropup', type: 'Ionicons' },
    { name: 'ios-arrow-dropup-circle', type: 'Ionicons' },
    { name: 'ios-arrow-forward', type: 'Ionicons' },
    { name: 'ios-arrow-round-back', type: 'Ionicons' },
    { name: 'ios-arrow-round-down', type: 'Ionicons' },
    { name: 'ios-arrow-round-forward', type: 'Ionicons' },
    { name: 'ios-arrow-round-up', type: 'Ionicons' },
    { name: 'ios-arrow-up', type: 'Ionicons' },
    { name: 'ios-at', type: 'Ionicons' },
    { name: 'ios-attach', type: 'Ionicons' },
    { name: 'ios-backspace', type: 'Ionicons' },
    { name: 'ios-barcode', type: 'Ionicons' },
    { name: 'ios-baseball', type: 'Ionicons' },
    { name: 'ios-basket', type: 'Ionicons' },
    { name: 'ios-basketball', type: 'Ionicons' },
    { name: 'ios-battery-charging', type: 'Ionicons' },
    { name: 'ios-battery-dead', type: 'Ionicons' },
    { name: 'ios-battery-full', type: 'Ionicons' },
    { name: 'ios-beaker', type: 'Ionicons' },
    { name: 'ios-bed', type: 'Ionicons' },
    { name: 'ios-beer', type: 'Ionicons' },
    { name: 'ios-bicycle', type: 'Ionicons' },
    { name: 'ios-bluetooth', type: 'Ionicons' },
    { name: 'ios-boat', type: 'Ionicons' },
    { name: 'ios-body', type: 'Ionicons' },
    { name: 'ios-bonfire', type: 'Ionicons' },
    { name: 'ios-book', type: 'Ionicons' },
    { name: 'ios-bookmark', type: 'Ionicons' },
    { name: 'ios-bookmarks', type: 'Ionicons' },
    { name: 'ios-bowtie', type: 'Ionicons' },
    { name: 'ios-briefcase', type: 'Ionicons' },
    { name: 'ios-browsers', type: 'Ionicons' },
    { name: 'ios-brush', type: 'Ionicons' },
    { name: 'ios-bug', type: 'Ionicons' },
    { name: 'ios-build', type: 'Ionicons' },
    { name: 'ios-bulb', type: 'Ionicons' },
    { name: 'ios-bus', type: 'Ionicons' },
    { name: 'ios-business', type: 'Ionicons' },
    { name: 'ios-cafe', type: 'Ionicons' },
    { name: 'ios-calculator', type: 'Ionicons' },
    { name: 'ios-calendar', type: 'Ionicons' },
    { name: 'ios-call', type: 'Ionicons' },
    { name: 'ios-camera', type: 'Ionicons' },
    { name: 'ios-car', type: 'Ionicons' },
    { name: 'ios-card', type: 'Ionicons' },
    { name: 'ios-cart', type: 'Ionicons' },
    { name: 'ios-cash', type: 'Ionicons' },
    { name: 'ios-cellular', type: 'Ionicons' },
    { name: 'ios-chatboxes', type: 'Ionicons' },
    { name: 'ios-chatbubbles', type: 'Ionicons' },
    { name: 'ios-checkbox', type: 'Ionicons' },
    { name: 'ios-checkbox-outline', type: 'Ionicons' },
    { name: 'ios-checkmark', type: 'Ionicons' },
    { name: 'ios-checkmark-circle', type: 'Ionicons' },
    { name: 'ios-checkmark-circle-outline', type: 'Ionicons' },
    { name: 'ios-clipboard', type: 'Ionicons' },
    { name: 'ios-clock', type: 'Ionicons' },
    { name: 'ios-close', type: 'Ionicons' },
    { name: 'ios-close-circle', type: 'Ionicons' },
    { name: 'ios-close-circle-outline', type: 'Ionicons' },
    { name: 'ios-cloud', type: 'Ionicons' },
    { name: 'ios-cloud-circle', type: 'Ionicons' },
    { name: 'ios-cloud-done', type: 'Ionicons' },
    { name: 'ios-cloud-download', type: 'Ionicons' },
    { name: 'ios-cloud-outline', type: 'Ionicons' },
    { name: 'ios-cloud-upload', type: 'Ionicons' },
    { name: 'ios-cloudy', type: 'Ionicons' },
    { name: 'ios-cloudy-night', type: 'Ionicons' },
    { name: 'ios-code', type: 'Ionicons' },
    { name: 'ios-code-download', type: 'Ionicons' },
    { name: 'ios-code-working', type: 'Ionicons' },
    { name: 'ios-cog', type: 'Ionicons' },
    { name: 'ios-color-fill', type: 'Ionicons' },
    { name: 'ios-color-filter', type: 'Ionicons' },
    { name: 'ios-color-palette', type: 'Ionicons' },
    { name: 'ios-color-wand', type: 'Ionicons' },
    { name: 'ios-compass', type: 'Ionicons' },
    { name: 'ios-construct', type: 'Ionicons' },
    { name: 'ios-contact', type: 'Ionicons' },
    { name: 'ios-contacts', type: 'Ionicons' },
    { name: 'ios-contract', type: 'Ionicons' },
    { name: 'ios-contrast', type: 'Ionicons' },
    { name: 'ios-copy', type: 'Ionicons' },
    { name: 'ios-create', type: 'Ionicons' },
    { name: 'ios-crop', type: 'Ionicons' },
    { name: 'ios-cube', type: 'Ionicons' },
    { name: 'ios-cut', type: 'Ionicons' },
    { name: 'ios-desktop', type: 'Ionicons' },
    { name: 'ios-disc', type: 'Ionicons' },
    { name: 'ios-document', type: 'Ionicons' },
    { name: 'ios-done-all', type: 'Ionicons' },
    { name: 'ios-download', type: 'Ionicons' },
    { name: 'ios-easel', type: 'Ionicons' },
    { name: 'ios-egg', type: 'Ionicons' },
    { name: 'ios-exit', type: 'Ionicons' },
    { name: 'ios-expand', type: 'Ionicons' },
    { name: 'ios-eye', type: 'Ionicons' },
    { name: 'ios-eye-off', type: 'Ionicons' },
    { name: 'ios-fastforward', type: 'Ionicons' },
    { name: 'ios-female', type: 'Ionicons' },
    { name: 'ios-filing', type: 'Ionicons' },
    { name: 'ios-film', type: 'Ionicons' },
    { name: 'ios-finger-print', type: 'Ionicons' },
    { name: 'ios-fitness', type: 'Ionicons' },
    { name: 'ios-flag', type: 'Ionicons' },
    { name: 'ios-flame', type: 'Ionicons' },
    { name: 'ios-flash', type: 'Ionicons' },
    { name: 'ios-flash-off', type: 'Ionicons' },
    { name: 'ios-flashlight', type: 'Ionicons' },
    { name: 'ios-flask', type: 'Ionicons' },
    { name: 'ios-flower', type: 'Ionicons' },
    { name: 'ios-folder', type: 'Ionicons' },
    { name: 'ios-folder-open', type: 'Ionicons' },
    { name: 'ios-football', type: 'Ionicons' },
    { name: 'ios-funnel', type: 'Ionicons' },
    { name: 'ios-gift', type: 'Ionicons' },
    { name: 'ios-git-branch', type: 'Ionicons' },
    { name: 'ios-git-commit', type: 'Ionicons' },
    { name: 'ios-git-compare', type: 'Ionicons' },
    { name: 'ios-git-merge', type: 'Ionicons' },
    { name: 'ios-git-network', type: 'Ionicons' },
    { name: 'ios-git-pull-request', type: 'Ionicons' },
    { name: 'ios-glasses', type: 'Ionicons' },
    { name: 'ios-globe', type: 'Ionicons' },
    { name: 'ios-grid', type: 'Ionicons' },
    { name: 'ios-hammer', type: 'Ionicons' },
    { name: 'ios-hand', type: 'Ionicons' },
    { name: 'ios-happy', type: 'Ionicons' },
    { name: 'ios-headset', type: 'Ionicons' },
    { name: 'ios-heart', type: 'Ionicons' },
    { name: 'ios-heart-dislike', type: 'Ionicons' },
    { name: 'ios-heart-empty', type: 'Ionicons' },
    { name: 'ios-heart-half', type: 'Ionicons' },
    { name: 'ios-help', type: 'Ionicons' },
    { name: 'ios-help-buoy', type: 'Ionicons' },
    { name: 'ios-help-circle', type: 'Ionicons' },
    { name: 'ios-help-circle-outline', type: 'Ionicons' },
    { name: 'ios-home', type: 'Ionicons' },
    { name: 'ios-hourglass', type: 'Ionicons' },
    { name: 'ios-ice-cream', type: 'Ionicons' },
    { name: 'ios-image', type: 'Ionicons' },
    { name: 'ios-images', type: 'Ionicons' },
    { name: 'ios-infinite', type: 'Ionicons' },
    { name: 'ios-information', type: 'Ionicons' },
    { name: 'ios-information-circle', type: 'Ionicons' },
    { name: 'ios-information-circle-outline', type: 'Ionicons' },
    { name: 'ios-jet', type: 'Ionicons' },
    { name: 'ios-journal', type: 'Ionicons' },
    { name: 'ios-key', type: 'Ionicons' },
    { name: 'ios-keypad', type: 'Ionicons' },
    { name: 'ios-laptop', type: 'Ionicons' },
    { name: 'ios-leaf', type: 'Ionicons' },
    { name: 'ios-link', type: 'Ionicons' },
    { name: 'ios-list', type: 'Ionicons' },
    { name: 'ios-list-box', type: 'Ionicons' },
    { name: 'ios-locate', type: 'Ionicons' },
    { name: 'ios-lock', type: 'Ionicons' },
    { name: 'ios-log-in', type: 'Ionicons' },
    { name: 'ios-log-out', type: 'Ionicons' },
    { name: 'ios-magnet', type: 'Ionicons' },
    { name: 'ios-mail', type: 'Ionicons' },
    { name: 'ios-mail-open', type: 'Ionicons' },
    { name: 'ios-mail-unread', type: 'Ionicons' },
    { name: 'ios-male', type: 'Ionicons' },
    { name: 'ios-man', type: 'Ionicons' },
    { name: 'ios-map', type: 'Ionicons' },
    { name: 'ios-medal', type: 'Ionicons' },
    { name: 'ios-medical', type: 'Ionicons' },
    { name: 'ios-medkit', type: 'Ionicons' },
    { name: 'ios-megaphone', type: 'Ionicons' },
    { name: 'ios-menu', type: 'Ionicons' },
    { name: 'ios-mic', type: 'Ionicons' },
    { name: 'ios-mic-off', type: 'Ionicons' },
    { name: 'ios-microphone', type: 'Ionicons' },
    { name: 'ios-moon', type: 'Ionicons' },
    { name: 'ios-more', type: 'Ionicons' },
    { name: 'ios-move', type: 'Ionicons' },
    { name: 'ios-musical-note', type: 'Ionicons' },
    { name: 'ios-musical-notes', type: 'Ionicons' },
    { name: 'ios-navigate', type: 'Ionicons' },
    { name: 'ios-notifications', type: 'Ionicons' },
    { name: 'ios-notifications-off', type: 'Ionicons' },
    { name: 'ios-notifications-outline', type: 'Ionicons' },
    { name: 'ios-nuclear', type: 'Ionicons' },
    { name: 'ios-nutrition', type: 'Ionicons' },
    { name: 'ios-open', type: 'Ionicons' },
    { name: 'ios-options', type: 'Ionicons' },
    { name: 'ios-outlet', type: 'Ionicons' },
    { name: 'ios-paper', type: 'Ionicons' },
    { name: 'ios-paper-plane', type: 'Ionicons' },
    { name: 'ios-partly-sunny', type: 'Ionicons' },
    { name: 'ios-pause', type: 'Ionicons' },
    { name: 'ios-paw', type: 'Ionicons' },
    { name: 'ios-people', type: 'Ionicons' },
    { name: 'ios-person', type: 'Ionicons' },
    { name: 'ios-person-add', type: 'Ionicons' },
    { name: 'ios-phone-landscape', type: 'Ionicons' },
    { name: 'ios-phone-portrait', type: 'Ionicons' },
    { name: 'ios-photos', type: 'Ionicons' },
    { name: 'ios-pie', type: 'Ionicons' },
    { name: 'ios-pin', type: 'Ionicons' },
    { name: 'ios-pint', type: 'Ionicons' },
    { name: 'ios-pizza', type: 'Ionicons' },
    { name: 'ios-planet', type: 'Ionicons' },
    { name: 'ios-play', type: 'Ionicons' },
    { name: 'ios-play-circle', type: 'Ionicons' },
    { name: 'ios-podium', type: 'Ionicons' },
    { name: 'ios-power', type: 'Ionicons' },
    { name: 'ios-pricetag', type: 'Ionicons' },
    { name: 'ios-pricetags', type: 'Ionicons' },
    { name: 'ios-print', type: 'Ionicons' },
    { name: 'ios-pulse', type: 'Ionicons' },
    { name: 'ios-qr-scanner', type: 'Ionicons' },
    { name: 'ios-quote', type: 'Ionicons' },
    { name: 'ios-radio', type: 'Ionicons' },
    { name: 'ios-radio-button-off', type: 'Ionicons' },
    { name: 'ios-radio-button-on', type: 'Ionicons' },
    { name: 'ios-rainy', type: 'Ionicons' },
    { name: 'ios-recording', type: 'Ionicons' },
    { name: 'ios-redo', type: 'Ionicons' },
    { name: 'ios-refresh', type: 'Ionicons' },
    { name: 'ios-refresh-circle', type: 'Ionicons' },
    { name: 'ios-remove', type: 'Ionicons' },
    { name: 'ios-remove-circle', type: 'Ionicons' },
    { name: 'ios-remove-circle-outline', type: 'Ionicons' },
    { name: 'ios-reorder', type: 'Ionicons' },
    { name: 'ios-repeat', type: 'Ionicons' },
    { name: 'ios-resize', type: 'Ionicons' },
    { name: 'ios-restaurant', type: 'Ionicons' },
    { name: 'ios-return-left', type: 'Ionicons' },
    { name: 'ios-return-right', type: 'Ionicons' },
    { name: 'ios-reverse-camera', type: 'Ionicons' },
    { name: 'ios-rewind', type: 'Ionicons' },
    { name: 'ios-ribbon', type: 'Ionicons' },
    { name: 'ios-rocket', type: 'Ionicons' },
    { name: 'ios-rose', type: 'Ionicons' },
    { name: 'ios-sad', type: 'Ionicons' },
    { name: 'ios-save', type: 'Ionicons' },
    { name: 'ios-school', type: 'Ionicons' },
    { name: 'ios-search', type: 'Ionicons' },
    { name: 'ios-send', type: 'Ionicons' },
    { name: 'ios-settings', type: 'Ionicons' },
    { name: 'ios-share', type: 'Ionicons' },
    { name: 'ios-share-alt', type: 'Ionicons' },
    { name: 'ios-shirt', type: 'Ionicons' },
    { name: 'ios-shuffle', type: 'Ionicons' },
    { name: 'ios-skip-backward', type: 'Ionicons' },
    { name: 'ios-skip-forward', type: 'Ionicons' },
    { name: 'ios-snow', type: 'Ionicons' },
    { name: 'ios-speedometer', type: 'Ionicons' },
    { name: 'ios-square', type: 'Ionicons' },
    { name: 'ios-square-outline', type: 'Ionicons' },
    { name: 'ios-star', type: 'Ionicons' },
    { name: 'ios-star-half', type: 'Ionicons' },
    { name: 'ios-star-outline', type: 'Ionicons' },
    { name: 'ios-stats', type: 'Ionicons' },
    { name: 'ios-stopwatch', type: 'Ionicons' },
    { name: 'ios-subway', type: 'Ionicons' },
    { name: 'ios-sunny', type: 'Ionicons' },
    { name: 'ios-swap', type: 'Ionicons' },
    { name: 'ios-switch', type: 'Ionicons' },
    { name: 'ios-sync', type: 'Ionicons' },
    { name: 'ios-tablet-landscape', type: 'Ionicons' },
    { name: 'ios-tablet-portrait', type: 'Ionicons' },
    { name: 'ios-tennisball', type: 'Ionicons' },
    { name: 'ios-text', type: 'Ionicons' },
    { name: 'ios-thermometer', type: 'Ionicons' },
    { name: 'ios-thumbs-down', type: 'Ionicons' },
    { name: 'ios-thumbs-up', type: 'Ionicons' },
    { name: 'ios-thunderstorm', type: 'Ionicons' },
    { name: 'ios-time', type: 'Ionicons' },
    { name: 'ios-timer', type: 'Ionicons' },
    { name: 'ios-today', type: 'Ionicons' },
    { name: 'ios-train', type: 'Ionicons' },
    { name: 'ios-transgender', type: 'Ionicons' },
    { name: 'ios-trash', type: 'Ionicons' },
    { name: 'ios-trending-down', type: 'Ionicons' },
    { name: 'ios-trending-up', type: 'Ionicons' },
    { name: 'ios-trophy', type: 'Ionicons' },
    { name: 'ios-tv', type: 'Ionicons' },
    { name: 'ios-umbrella', type: 'Ionicons' },
    { name: 'ios-undo', type: 'Ionicons' },
    { name: 'ios-unlock', type: 'Ionicons' },
    { name: 'ios-videocam', type: 'Ionicons' },
    { name: 'ios-volume-high', type: 'Ionicons' },
    { name: 'ios-volume-low', type: 'Ionicons' },
    { name: 'ios-volume-mute', type: 'Ionicons' },
    { name: 'ios-volume-off', type: 'Ionicons' },
    { name: 'ios-walk', type: 'Ionicons' },
    { name: 'ios-wallet', type: 'Ionicons' },
    { name: 'ios-warning', type: 'Ionicons' },
    { name: 'ios-watch', type: 'Ionicons' },
    { name: 'ios-water', type: 'Ionicons' },
    { name: 'ios-wifi', type: 'Ionicons' },
    { name: 'ios-wine', type: 'Ionicons' },
    { name: 'ios-woman', type: 'Ionicons' },
    { name: 'logo-android', type: 'Ionicons' },
    { name: 'logo-angular', type: 'Ionicons' },
    { name: 'logo-apple', type: 'Ionicons' },
    { name: 'logo-bitbucket', type: 'Ionicons' },
    { name: 'logo-bitcoin', type: 'Ionicons' },
    { name: 'logo-buffer', type: 'Ionicons' },
    { name: 'logo-chrome', type: 'Ionicons' },
    { name: 'logo-closed-captioning', type: 'Ionicons' },
    { name: 'logo-codepen', type: 'Ionicons' },
    { name: 'logo-css3', type: 'Ionicons' },
    { name: 'logo-designernews', type: 'Ionicons' },
    { name: 'logo-dribbble', type: 'Ionicons' },
    { name: 'logo-dropbox', type: 'Ionicons' },
    { name: 'logo-euro', type: 'Ionicons' },
    { name: 'logo-facebook', type: 'Ionicons' },
    { name: 'logo-flickr', type: 'Ionicons' },
    { name: 'logo-foursquare', type: 'Ionicons' },
    { name: 'logo-freebsd-devil', type: 'Ionicons' },
    { name: 'logo-game-controller-a', type: 'Ionicons' },
    { name: 'logo-game-controller-b', type: 'Ionicons' },
    { name: 'logo-github', type: 'Ionicons' },
    { name: 'logo-google', type: 'Ionicons' },
    { name: 'logo-googleplus', type: 'Ionicons' },
    { name: 'logo-hackernews', type: 'Ionicons' },
    { name: 'logo-html5', type: 'Ionicons' },
    { name: 'logo-instagram', type: 'Ionicons' },
    { name: 'logo-ionic', type: 'Ionicons' },
    { name: 'logo-ionitron', type: 'Ionicons' },
    { name: 'logo-javascript', type: 'Ionicons' },
    { name: 'logo-linkedin', type: 'Ionicons' },
    { name: 'logo-markdown', type: 'Ionicons' },
    { name: 'logo-model-s', type: 'Ionicons' },
    { name: 'logo-no-smoking', type: 'Ionicons' },
    { name: 'logo-nodejs', type: 'Ionicons' },
    { name: 'logo-npm', type: 'Ionicons' },
    { name: 'logo-octocat', type: 'Ionicons' },
    { name: 'logo-pinterest', type: 'Ionicons' },
    { name: 'logo-playstation', type: 'Ionicons' },
    { name: 'logo-polymer', type: 'Ionicons' },
    { name: 'logo-python', type: 'Ionicons' },
    { name: 'logo-reddit', type: 'Ionicons' },
    { name: 'logo-rss', type: 'Ionicons' },
    { name: 'logo-sass', type: 'Ionicons' },
    { name: 'logo-skype', type: 'Ionicons' },
    { name: 'logo-slack', type: 'Ionicons' },
    { name: 'logo-snapchat', type: 'Ionicons' },
    { name: 'logo-steam', type: 'Ionicons' },
    { name: 'logo-tumblr', type: 'Ionicons' },
    { name: 'logo-tux', type: 'Ionicons' },
    { name: 'logo-twitch', type: 'Ionicons' },
    { name: 'logo-twitter', type: 'Ionicons' },
    { name: 'logo-usd', type: 'Ionicons' },
    { name: 'logo-vimeo', type: 'Ionicons' },
    { name: 'logo-vk', type: 'Ionicons' },
    { name: 'logo-whatsapp', type: 'Ionicons' },
    { name: 'logo-windows', type: 'Ionicons' },
    { name: 'logo-wordpress', type: 'Ionicons' },
    { name: 'logo-xbox', type: 'Ionicons' },
    { name: 'logo-xing', type: 'Ionicons' },
    { name: 'logo-yahoo', type: 'Ionicons' },
    { name: 'logo-yen', type: 'Ionicons' },
    { name: 'logo-youtube', type: 'Ionicons' },
    { name: 'md-add', type: 'Ionicons' },
    { name: 'md-add-circle', type: 'Ionicons' },
    { name: 'md-add-circle-outline', type: 'Ionicons' },
    { name: 'md-airplane', type: 'Ionicons' },
    { name: 'md-alarm', type: 'Ionicons' },
    { name: 'md-albums', type: 'Ionicons' },
    { name: 'md-alert', type: 'Ionicons' },
    { name: 'md-american-football', type: 'Ionicons' },
    { name: 'md-analytics', type: 'Ionicons' },
    { name: 'md-aperture', type: 'Ionicons' },
    { name: 'md-apps', type: 'Ionicons' },
    { name: 'md-appstore', type: 'Ionicons' },
    { name: 'md-archive', type: 'Ionicons' },
    { name: 'md-arrow-back', type: 'Ionicons' },
    { name: 'md-arrow-down', type: 'Ionicons' },
    { name: 'md-arrow-dropdown', type: 'Ionicons' },
    { name: 'md-arrow-dropdown-circle', type: 'Ionicons' },
    { name: 'md-arrow-dropleft', type: 'Ionicons' },
    { name: 'md-arrow-dropleft-circle', type: 'Ionicons' },
    { name: 'md-arrow-dropright', type: 'Ionicons' },
    { name: 'md-arrow-dropright-circle', type: 'Ionicons' },
    { name: 'md-arrow-dropup', type: 'Ionicons' },
    { name: 'md-arrow-dropup-circle', type: 'Ionicons' },
    { name: 'md-arrow-forward', type: 'Ionicons' },
    { name: 'md-arrow-round-back', type: 'Ionicons' },
    { name: 'md-arrow-round-down', type: 'Ionicons' },
    { name: 'md-arrow-round-forward', type: 'Ionicons' },
    { name: 'md-arrow-round-up', type: 'Ionicons' },
    { name: 'md-arrow-up', type: 'Ionicons' },
    { name: 'md-at', type: 'Ionicons' },
    { name: 'md-attach', type: 'Ionicons' },
    { name: 'md-backspace', type: 'Ionicons' },
    { name: 'md-barcode', type: 'Ionicons' },
    { name: 'md-baseball', type: 'Ionicons' },
    { name: 'md-basket', type: 'Ionicons' },
    { name: 'md-basketball', type: 'Ionicons' },
    { name: 'md-battery-charging', type: 'Ionicons' },
    { name: 'md-battery-dead', type: 'Ionicons' },
    { name: 'md-battery-full', type: 'Ionicons' },
    { name: 'md-beaker', type: 'Ionicons' },
    { name: 'md-bed', type: 'Ionicons' },
    { name: 'md-beer', type: 'Ionicons' },
    { name: 'md-bicycle', type: 'Ionicons' },
    { name: 'md-bluetooth', type: 'Ionicons' },
    { name: 'md-boat', type: 'Ionicons' },
    { name: 'md-body', type: 'Ionicons' },
    { name: 'md-bonfire', type: 'Ionicons' },
    { name: 'md-book', type: 'Ionicons' },
    { name: 'md-bookmark', type: 'Ionicons' },
    { name: 'md-bookmarks', type: 'Ionicons' },
    { name: 'md-bowtie', type: 'Ionicons' },
    { name: 'md-briefcase', type: 'Ionicons' },
    { name: 'md-browsers', type: 'Ionicons' },
    { name: 'md-brush', type: 'Ionicons' },
    { name: 'md-bug', type: 'Ionicons' },
    { name: 'md-build', type: 'Ionicons' },
    { name: 'md-bulb', type: 'Ionicons' },
    { name: 'md-bus', type: 'Ionicons' },
    { name: 'md-business', type: 'Ionicons' },
    { name: 'md-cafe', type: 'Ionicons' },
    { name: 'md-calculator', type: 'Ionicons' },
    { name: 'md-calendar', type: 'Ionicons' },
    { name: 'md-call', type: 'Ionicons' },
    { name: 'md-camera', type: 'Ionicons' },
    { name: 'md-car', type: 'Ionicons' },
    { name: 'md-card', type: 'Ionicons' },
    { name: 'md-cart', type: 'Ionicons' },
    { name: 'md-cash', type: 'Ionicons' },
    { name: 'md-cellular', type: 'Ionicons' },
    { name: 'md-chatboxes', type: 'Ionicons' },
    { name: 'md-chatbubbles', type: 'Ionicons' },
    { name: 'md-checkbox', type: 'Ionicons' },
    { name: 'md-checkbox-outline', type: 'Ionicons' },
    { name: 'md-checkmark', type: 'Ionicons' },
    { name: 'md-checkmark-circle', type: 'Ionicons' },
    { name: 'md-checkmark-circle-outline', type: 'Ionicons' },
    { name: 'md-clipboard', type: 'Ionicons' },
    { name: 'md-clock', type: 'Ionicons' },
    { name: 'md-close', type: 'Ionicons' },
    { name: 'md-close-circle', type: 'Ionicons' },
    { name: 'md-close-circle-outline', type: 'Ionicons' },
    { name: 'md-cloud', type: 'Ionicons' },
    { name: 'md-cloud-circle', type: 'Ionicons' },
    { name: 'md-cloud-done', type: 'Ionicons' },
    { name: 'md-cloud-download', type: 'Ionicons' },
    { name: 'md-cloud-outline', type: 'Ionicons' },
    { name: 'md-cloud-upload', type: 'Ionicons' },
    { name: 'md-cloudy', type: 'Ionicons' },
    { name: 'md-cloudy-night', type: 'Ionicons' },
    { name: 'md-code', type: 'Ionicons' },
    { name: 'md-code-download', type: 'Ionicons' },
    { name: 'md-code-working', type: 'Ionicons' },
    { name: 'md-cog', type: 'Ionicons' },
    { name: 'md-color-fill', type: 'Ionicons' },
    { name: 'md-color-filter', type: 'Ionicons' },
    { name: 'md-color-palette', type: 'Ionicons' },
    { name: 'md-color-wand', type: 'Ionicons' },
    { name: 'md-compass', type: 'Ionicons' },
    { name: 'md-construct', type: 'Ionicons' },
    { name: 'md-contact', type: 'Ionicons' },
    { name: 'md-contacts', type: 'Ionicons' },
    { name: 'md-contract', type: 'Ionicons' },
    { name: 'md-contrast', type: 'Ionicons' },
    { name: 'md-copy', type: 'Ionicons' },
    { name: 'md-create', type: 'Ionicons' },
    { name: 'md-crop', type: 'Ionicons' },
    { name: 'md-cube', type: 'Ionicons' },
    { name: 'md-cut', type: 'Ionicons' },
    { name: 'md-desktop', type: 'Ionicons' },
    { name: 'md-disc', type: 'Ionicons' },
    { name: 'md-document', type: 'Ionicons' },
    { name: 'md-done-all', type: 'Ionicons' },
    { name: 'md-download', type: 'Ionicons' },
    { name: 'md-easel', type: 'Ionicons' },
    { name: 'md-egg', type: 'Ionicons' },
    { name: 'md-exit', type: 'Ionicons' },
    { name: 'md-expand', type: 'Ionicons' },
    { name: 'md-eye', type: 'Ionicons' },
    { name: 'md-eye-off', type: 'Ionicons' },
    { name: 'md-fastforward', type: 'Ionicons' },
    { name: 'md-female', type: 'Ionicons' },
    { name: 'md-filing', type: 'Ionicons' },
    { name: 'md-film', type: 'Ionicons' },
    { name: 'md-finger-print', type: 'Ionicons' },
    { name: 'md-fitness', type: 'Ionicons' },
    { name: 'md-flag', type: 'Ionicons' },
    { name: 'md-flame', type: 'Ionicons' },
    { name: 'md-flash', type: 'Ionicons' },
    { name: 'md-flash-off', type: 'Ionicons' },
    { name: 'md-flashlight', type: 'Ionicons' },
    { name: 'md-flask', type: 'Ionicons' },
    { name: 'md-flower', type: 'Ionicons' },
    { name: 'md-folder', type: 'Ionicons' },
    { name: 'md-folder-open', type: 'Ionicons' },
    { name: 'md-football', type: 'Ionicons' },
    { name: 'md-funnel', type: 'Ionicons' },
    { name: 'md-gift', type: 'Ionicons' },
    { name: 'md-git-branch', type: 'Ionicons' },
    { name: 'md-git-commit', type: 'Ionicons' },
    { name: 'md-git-compare', type: 'Ionicons' },
    { name: 'md-git-merge', type: 'Ionicons' },
    { name: 'md-git-network', type: 'Ionicons' },
    { name: 'md-git-pull-request', type: 'Ionicons' },
    { name: 'md-glasses', type: 'Ionicons' },
    { name: 'md-globe', type: 'Ionicons' },
    { name: 'md-grid', type: 'Ionicons' },
    { name: 'md-hammer', type: 'Ionicons' },
    { name: 'md-hand', type: 'Ionicons' },
    { name: 'md-happy', type: 'Ionicons' },
    { name: 'md-headset', type: 'Ionicons' },
    { name: 'md-heart', type: 'Ionicons' },
    { name: 'md-heart-dislike', type: 'Ionicons' },
    { name: 'md-heart-empty', type: 'Ionicons' },
    { name: 'md-heart-half', type: 'Ionicons' },
    { name: 'md-help', type: 'Ionicons' },
    { name: 'md-help-buoy', type: 'Ionicons' },
    { name: 'md-help-circle', type: 'Ionicons' },
    { name: 'md-help-circle-outline', type: 'Ionicons' },
    { name: 'md-home', type: 'Ionicons' },
    { name: 'md-hourglass', type: 'Ionicons' },
    { name: 'md-ice-cream', type: 'Ionicons' },
    { name: 'md-image', type: 'Ionicons' },
    { name: 'md-images', type: 'Ionicons' },
    { name: 'md-infinite', type: 'Ionicons' },
    { name: 'md-information', type: 'Ionicons' },
    { name: 'md-information-circle', type: 'Ionicons' },
    { name: 'md-information-circle-outline', type: 'Ionicons' },
    { name: 'md-jet', type: 'Ionicons' },
    { name: 'md-journal', type: 'Ionicons' },
    { name: 'md-key', type: 'Ionicons' },
    { name: 'md-keypad', type: 'Ionicons' },
    { name: 'md-laptop', type: 'Ionicons' },
    { name: 'md-leaf', type: 'Ionicons' },
    { name: 'md-link', type: 'Ionicons' },
    { name: 'md-list', type: 'Ionicons' },
    { name: 'md-list-box', type: 'Ionicons' },
    { name: 'md-locate', type: 'Ionicons' },
    { name: 'md-lock', type: 'Ionicons' },
    { name: 'md-log-in', type: 'Ionicons' },
    { name: 'md-log-out', type: 'Ionicons' },
    { name: 'md-magnet', type: 'Ionicons' },
    { name: 'md-mail', type: 'Ionicons' },
    { name: 'md-mail-open', type: 'Ionicons' },
    { name: 'md-mail-unread', type: 'Ionicons' },
    { name: 'md-male', type: 'Ionicons' },
    { name: 'md-man', type: 'Ionicons' },
    { name: 'md-map', type: 'Ionicons' },
    { name: 'md-medal', type: 'Ionicons' },
    { name: 'md-medical', type: 'Ionicons' },
    { name: 'md-medkit', type: 'Ionicons' },
    { name: 'md-megaphone', type: 'Ionicons' },
    { name: 'md-menu', type: 'Ionicons' },
    { name: 'md-mic', type: 'Ionicons' },
    { name: 'md-mic-off', type: 'Ionicons' },
    { name: 'md-microphone', type: 'Ionicons' },
    { name: 'md-moon', type: 'Ionicons' },
    { name: 'md-more', type: 'Ionicons' },
    { name: 'md-move', type: 'Ionicons' },
    { name: 'md-musical-note', type: 'Ionicons' },
    { name: 'md-musical-notes', type: 'Ionicons' },
    { name: 'md-navigate', type: 'Ionicons' },
    { name: 'md-notifications', type: 'Ionicons' },
    { name: 'md-notifications-off', type: 'Ionicons' },
    { name: 'md-notifications-outline', type: 'Ionicons' },
    { name: 'md-nuclear', type: 'Ionicons' },
    { name: 'md-nutrition', type: 'Ionicons' },
    { name: 'md-open', type: 'Ionicons' },
    { name: 'md-options', type: 'Ionicons' },
    { name: 'md-outlet', type: 'Ionicons' },
    { name: 'md-paper', type: 'Ionicons' },
    { name: 'md-paper-plane', type: 'Ionicons' },
    { name: 'md-partly-sunny', type: 'Ionicons' },
    { name: 'md-pause', type: 'Ionicons' },
    { name: 'md-paw', type: 'Ionicons' },
    { name: 'md-people', type: 'Ionicons' },
    { name: 'md-person', type: 'Ionicons' },
    { name: 'md-person-add', type: 'Ionicons' },
    { name: 'md-phone-landscape', type: 'Ionicons' },
    { name: 'md-phone-portrait', type: 'Ionicons' },
    { name: 'md-photos', type: 'Ionicons' },
    { name: 'md-pie', type: 'Ionicons' },
    { name: 'md-pin', type: 'Ionicons' },
    { name: 'md-pint', type: 'Ionicons' },
    { name: 'md-pizza', type: 'Ionicons' },
    { name: 'md-planet', type: 'Ionicons' },
    { name: 'md-play', type: 'Ionicons' },
    { name: 'md-play-circle', type: 'Ionicons' },
    { name: 'md-podium', type: 'Ionicons' },
    { name: 'md-power', type: 'Ionicons' },
    { name: 'md-pricetag', type: 'Ionicons' },
    { name: 'md-pricetags', type: 'Ionicons' },
    { name: 'md-print', type: 'Ionicons' },
    { name: 'md-pulse', type: 'Ionicons' },
    { name: 'md-qr-scanner', type: 'Ionicons' },
    { name: 'md-quote', type: 'Ionicons' },
    { name: 'md-radio', type: 'Ionicons' },
    { name: 'md-radio-button-off', type: 'Ionicons' },
    { name: 'md-radio-button-on', type: 'Ionicons' },
    { name: 'md-rainy', type: 'Ionicons' },
    { name: 'md-recording', type: 'Ionicons' },
    { name: 'md-redo', type: 'Ionicons' },
    { name: 'md-refresh', type: 'Ionicons' },
    { name: 'md-refresh-circle', type: 'Ionicons' },
    { name: 'md-remove', type: 'Ionicons' },
    { name: 'md-remove-circle', type: 'Ionicons' },
    { name: 'md-remove-circle-outline', type: 'Ionicons' },
    { name: 'md-reorder', type: 'Ionicons' },
    { name: 'md-repeat', type: 'Ionicons' },
    { name: 'md-resize', type: 'Ionicons' },
    { name: 'md-restaurant', type: 'Ionicons' },
    { name: 'md-return-left', type: 'Ionicons' },
    { name: 'md-return-right', type: 'Ionicons' },
    { name: 'md-reverse-camera', type: 'Ionicons' },
    { name: 'md-rewind', type: 'Ionicons' },
    { name: 'md-ribbon', type: 'Ionicons' },
    { name: 'md-rocket', type: 'Ionicons' },
    { name: 'md-rose', type: 'Ionicons' },
    { name: 'md-sad', type: 'Ionicons' },
    { name: 'md-save', type: 'Ionicons' },
    { name: 'md-school', type: 'Ionicons' },
    { name: 'md-search', type: 'Ionicons' },
    { name: 'md-send', type: 'Ionicons' },
    { name: 'md-settings', type: 'Ionicons' },
    { name: 'md-share', type: 'Ionicons' },
    { name: 'md-share-alt', type: 'Ionicons' },
    { name: 'md-shirt', type: 'Ionicons' },
    { name: 'md-shuffle', type: 'Ionicons' },
    { name: 'md-skip-backward', type: 'Ionicons' },
    { name: 'md-skip-forward', type: 'Ionicons' },
    { name: 'md-snow', type: 'Ionicons' },
    { name: 'md-speedometer', type: 'Ionicons' },
    { name: 'md-square', type: 'Ionicons' },
    { name: 'md-square-outline', type: 'Ionicons' },
    { name: 'md-star', type: 'Ionicons' },
    { name: 'md-star-half', type: 'Ionicons' },
    { name: 'md-star-outline', type: 'Ionicons' },
    { name: 'md-stats', type: 'Ionicons' },
    { name: 'md-stopwatch', type: 'Ionicons' },
    { name: 'md-subway', type: 'Ionicons' },
    { name: 'md-sunny', type: 'Ionicons' },
    { name: 'md-swap', type: 'Ionicons' },
    { name: 'md-switch', type: 'Ionicons' },
    { name: 'md-sync', type: 'Ionicons' },
    { name: 'md-tablet-landscape', type: 'Ionicons' },
    { name: 'md-tablet-portrait', type: 'Ionicons' },
    { name: 'md-tennisball', type: 'Ionicons' },
    { name: 'md-text', type: 'Ionicons' },
    { name: 'md-thermometer', type: 'Ionicons' },
    { name: 'md-thumbs-down', type: 'Ionicons' },
    { name: 'md-thumbs-up', type: 'Ionicons' },
    { name: 'md-thunderstorm', type: 'Ionicons' },
    { name: 'md-time', type: 'Ionicons' },
    { name: 'md-timer', type: 'Ionicons' },
    { name: 'md-today', type: 'Ionicons' },
    { name: 'md-train', type: 'Ionicons' },
    { name: 'md-transgender', type: 'Ionicons' },
    { name: 'md-trash', type: 'Ionicons' },
    { name: 'md-trending-down', type: 'Ionicons' },
    { name: 'md-trending-up', type: 'Ionicons' },
    { name: 'md-trophy', type: 'Ionicons' },
    { name: 'md-tv', type: 'Ionicons' },
    { name: 'md-umbrella', type: 'Ionicons' },
    { name: 'md-undo', type: 'Ionicons' },
    { name: 'md-unlock', type: 'Ionicons' },
    { name: 'md-videocam', type: 'Ionicons' },
    { name: 'md-volume-high', type: 'Ionicons' },
    { name: 'md-volume-low', type: 'Ionicons' },
    { name: 'md-volume-mute', type: 'Ionicons' },
    { name: 'md-volume-off', type: 'Ionicons' },
    { name: 'md-walk', type: 'Ionicons' },
    { name: 'md-wallet', type: 'Ionicons' },
    { name: 'md-warning', type: 'Ionicons' },
    { name: 'md-watch', type: 'Ionicons' },
    { name: 'md-water', type: 'Ionicons' },
    { name: 'md-wifi', type: 'Ionicons' },
    { name: 'md-wine', type: 'Ionicons' },
    { name: 'md-woman', type: 'Ionicons' },


    { name: 'access-point', type: 'MaterialCommunityIcons' },
	{ name: 'access-point-network', type: 'MaterialCommunityIcons' },
	{ name: 'account', type: 'MaterialCommunityIcons' },
	{ name: 'account-alert', type: 'MaterialCommunityIcons' },
	{ name: 'account-box', type: 'MaterialCommunityIcons' },
	{ name: 'account-box-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'account-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'account-card-details', type: 'MaterialCommunityIcons' },
	{ name: 'account-check', type: 'MaterialCommunityIcons' },
	{ name: 'account-child', type: 'MaterialCommunityIcons' },
	{ name: 'account-child-circle', type: 'MaterialCommunityIcons' },
	{ name: 'account-circle', type: 'MaterialCommunityIcons' },
	{ name: 'account-convert', type: 'MaterialCommunityIcons' },
	{ name: 'account-details', type: 'MaterialCommunityIcons' },
	{ name: 'account-edit', type: 'MaterialCommunityIcons' },
	{ name: 'account-group', type: 'MaterialCommunityIcons' },
	{ name: 'account-heart', type: 'MaterialCommunityIcons' },
	{ name: 'account-key', type: 'MaterialCommunityIcons' },
	{ name: 'account-location', type: 'MaterialCommunityIcons' },
	{ name: 'account-minus', type: 'MaterialCommunityIcons' },
	{ name: 'account-minus-outline', type: 'MaterialCommunityIcons' },
	{ name: 'account-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'account-multiple-check', type: 'MaterialCommunityIcons' },
	{ name: 'account-multiple-minus', type: 'MaterialCommunityIcons' },
	{ name: 'account-multiple-outline', type: 'MaterialCommunityIcons' },
	{ name: 'account-multiple-plus', type: 'MaterialCommunityIcons' },
	{ name: 'account-multiple-plus-outline', type: 'MaterialCommunityIcons' },
	{ name: 'account-network', type: 'MaterialCommunityIcons' },
	{ name: 'account-off', type: 'MaterialCommunityIcons' },
	{ name: 'account-outline', type: 'MaterialCommunityIcons' },
	{ name: 'account-plus', type: 'MaterialCommunityIcons' },
	{ name: 'account-plus-outline', type: 'MaterialCommunityIcons' },
	{ name: 'account-remove', type: 'MaterialCommunityIcons' },
	{ name: 'account-remove-outline', type: 'MaterialCommunityIcons' },
	{ name: 'account-search', type: 'MaterialCommunityIcons' },
	{ name: 'account-search-outline', type: 'MaterialCommunityIcons' },
	{ name: 'account-settings', type: 'MaterialCommunityIcons' },
	{ name: 'account-star', type: 'MaterialCommunityIcons' },
	{ name: 'account-supervisor', type: 'MaterialCommunityIcons' },
	{ name: 'account-supervisor-circle', type: 'MaterialCommunityIcons' },
	{ name: 'account-switch', type: 'MaterialCommunityIcons' },
	{ name: 'accusoft', type: 'MaterialCommunityIcons' },
	{ name: 'adjust', type: 'MaterialCommunityIcons' },
	{ name: 'adobe', type: 'MaterialCommunityIcons' },
	{ name: 'air-conditioner', type: 'MaterialCommunityIcons' },
	{ name: 'airballoon', type: 'MaterialCommunityIcons' },
	{ name: 'airplane', type: 'MaterialCommunityIcons' },
	{ name: 'airplane-landing', type: 'MaterialCommunityIcons' },
	{ name: 'airplane-off', type: 'MaterialCommunityIcons' },
	{ name: 'airplane-takeoff', type: 'MaterialCommunityIcons' },
	{ name: 'airplay', type: 'MaterialCommunityIcons' },
	{ name: 'airport', type: 'MaterialCommunityIcons' },
	{ name: 'alarm', type: 'MaterialCommunityIcons' },
	{ name: 'alarm-bell', type: 'MaterialCommunityIcons' },
	{ name: 'alarm-check', type: 'MaterialCommunityIcons' },
	{ name: 'alarm-light', type: 'MaterialCommunityIcons' },
	{ name: 'alarm-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'alarm-off', type: 'MaterialCommunityIcons' },
	{ name: 'alarm-plus', type: 'MaterialCommunityIcons' },
	{ name: 'alarm-snooze', type: 'MaterialCommunityIcons' },
	{ name: 'album', type: 'MaterialCommunityIcons' },
	{ name: 'alert', type: 'MaterialCommunityIcons' },
	{ name: 'alert-box', type: 'MaterialCommunityIcons' },
	{ name: 'alert-circle', type: 'MaterialCommunityIcons' },
	{ name: 'alert-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'alert-decagram', type: 'MaterialCommunityIcons' },
	{ name: 'alert-octagon', type: 'MaterialCommunityIcons' },
	{ name: 'alert-octagram', type: 'MaterialCommunityIcons' },
	{ name: 'alert-outline', type: 'MaterialCommunityIcons' },
	{ name: 'alien', type: 'MaterialCommunityIcons' },
	{ name: 'all-inclusive', type: 'MaterialCommunityIcons' },
	{ name: 'alpha', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-a', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-a-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-b', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-b-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-c', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-c-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-d', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-d-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-e', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-e-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-f', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-f-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-g', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-g-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-h', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-h-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-i', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-i-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-j', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-j-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-k', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-k-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-l', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-l-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-m', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-m-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-n', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-n-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-o', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-o-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-p', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-p-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-q', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-q-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-r', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-r-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-s', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-s-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-t', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-t-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-u', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-u-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-v', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-v-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-w', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-w-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-x', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-x-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-y', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-y-box', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-z', type: 'MaterialCommunityIcons' },
	{ name: 'alpha-z-box', type: 'MaterialCommunityIcons' },
	{ name: 'alphabetical', type: 'MaterialCommunityIcons' },
	{ name: 'altimeter', type: 'MaterialCommunityIcons' },
	{ name: 'amazon', type: 'MaterialCommunityIcons' },
	{ name: 'amazon-alexa', type: 'MaterialCommunityIcons' },
	{ name: 'amazon-drive', type: 'MaterialCommunityIcons' },
	{ name: 'ambulance', type: 'MaterialCommunityIcons' },
	{ name: 'ampersand', type: 'MaterialCommunityIcons' },
	{ name: 'amplifier', type: 'MaterialCommunityIcons' },
	{ name: 'anchor', type: 'MaterialCommunityIcons' },
	{ name: 'android', type: 'MaterialCommunityIcons' },
	{ name: 'android-auto', type: 'MaterialCommunityIcons' },
	{ name: 'android-debug-bridge', type: 'MaterialCommunityIcons' },
	{ name: 'android-head', type: 'MaterialCommunityIcons' },
	{ name: 'android-studio', type: 'MaterialCommunityIcons' },
	{ name: 'angle-acute', type: 'MaterialCommunityIcons' },
	{ name: 'angle-obtuse', type: 'MaterialCommunityIcons' },
	{ name: 'angle-right', type: 'MaterialCommunityIcons' },
	{ name: 'angular', type: 'MaterialCommunityIcons' },
	{ name: 'angularjs', type: 'MaterialCommunityIcons' },
	{ name: 'animation', type: 'MaterialCommunityIcons' },
	{ name: 'animation-outline', type: 'MaterialCommunityIcons' },
	{ name: 'animation-play', type: 'MaterialCommunityIcons' },
	{ name: 'animation-play-outline', type: 'MaterialCommunityIcons' },
	{ name: 'anvil', type: 'MaterialCommunityIcons' },
	{ name: 'apple', type: 'MaterialCommunityIcons' },
	{ name: 'apple-finder', type: 'MaterialCommunityIcons' },
	{ name: 'apple-icloud', type: 'MaterialCommunityIcons' },
	{ name: 'apple-ios', type: 'MaterialCommunityIcons' },
	{ name: 'apple-keyboard-caps', type: 'MaterialCommunityIcons' },
	{ name: 'apple-keyboard-command', type: 'MaterialCommunityIcons' },
	{ name: 'apple-keyboard-control', type: 'MaterialCommunityIcons' },
	{ name: 'apple-keyboard-option', type: 'MaterialCommunityIcons' },
	{ name: 'apple-keyboard-shift', type: 'MaterialCommunityIcons' },
	{ name: 'apple-safari', type: 'MaterialCommunityIcons' },
	{ name: 'application', type: 'MaterialCommunityIcons' },
	{ name: 'apps', type: 'MaterialCommunityIcons' },
	{ name: 'arch', type: 'MaterialCommunityIcons' },
	{ name: 'archive', type: 'MaterialCommunityIcons' },
	{ name: 'arrange-bring-forward', type: 'MaterialCommunityIcons' },
	{ name: 'arrange-bring-to-front', type: 'MaterialCommunityIcons' },
	{ name: 'arrange-send-backward', type: 'MaterialCommunityIcons' },
	{ name: 'arrange-send-to-back', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-all', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-bottom-left', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-bottom-left-bold-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-bottom-left-thick', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-bottom-right', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-bottom-right-bold-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-bottom-right-thick', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-collapse', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-collapse-all', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-collapse-down', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-collapse-horizontal', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-collapse-left', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-collapse-right', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-collapse-up', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-collapse-vertical', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-decision', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-decision-auto', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-decision-auto-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-decision-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-down', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-down-bold', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-down-bold-box', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-down-bold-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-down-bold-circle', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-down-bold-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-down-bold-hexagon-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-down-bold-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-down-box', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-down-drop-circle', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-down-drop-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-down-thick', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-expand', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-expand-all', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-expand-down', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-expand-horizontal', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-expand-left', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-expand-right', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-expand-up', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-expand-vertical', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-left', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-left-bold', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-left-bold-box', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-left-bold-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-left-bold-circle', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-left-bold-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-left-bold-hexagon-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-left-bold-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-left-box', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-left-drop-circle', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-left-drop-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-left-right-bold-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-left-thick', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-right', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-right-bold', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-right-bold-box', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-right-bold-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-right-bold-circle', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-right-bold-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-right-bold-hexagon-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-right-bold-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-right-box', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-right-drop-circle', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-right-drop-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-right-thick', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-split-horizontal', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-split-vertical', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-top-left', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-top-left-bold-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-top-left-thick', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-top-right', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-top-right-bold-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-top-right-thick', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-up', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-up-bold', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-up-bold-box', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-up-bold-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-up-bold-circle', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-up-bold-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-up-bold-hexagon-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-up-bold-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-up-box', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-up-down-bold-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-up-drop-circle', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-up-drop-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'arrow-up-thick', type: 'MaterialCommunityIcons' },
	{ name: 'artist', type: 'MaterialCommunityIcons' },
	{ name: 'aspect-ratio', type: 'MaterialCommunityIcons' },
	{ name: 'assistant', type: 'MaterialCommunityIcons' },
	{ name: 'asterisk', type: 'MaterialCommunityIcons' },
	{ name: 'at', type: 'MaterialCommunityIcons' },
	{ name: 'atlassian', type: 'MaterialCommunityIcons' },
	{ name: 'atom', type: 'MaterialCommunityIcons' },
	{ name: 'attachment', type: 'MaterialCommunityIcons' },
	{ name: 'audio-video', type: 'MaterialCommunityIcons' },
	{ name: 'audiobook', type: 'MaterialCommunityIcons' },
	{ name: 'augmented-reality', type: 'MaterialCommunityIcons' },
	{ name: 'auto-fix', type: 'MaterialCommunityIcons' },
	{ name: 'auto-upload', type: 'MaterialCommunityIcons' },
	{ name: 'autorenew', type: 'MaterialCommunityIcons' },
	{ name: 'av-timer', type: 'MaterialCommunityIcons' },
	{ name: 'axe', type: 'MaterialCommunityIcons' },
	{ name: 'azure', type: 'MaterialCommunityIcons' },
	{ name: 'babel', type: 'MaterialCommunityIcons' },
	{ name: 'baby', type: 'MaterialCommunityIcons' },
	{ name: 'baby-buggy', type: 'MaterialCommunityIcons' },
	{ name: 'backburger', type: 'MaterialCommunityIcons' },
	{ name: 'backspace', type: 'MaterialCommunityIcons' },
	{ name: 'backup-restore', type: 'MaterialCommunityIcons' },
	{ name: 'badminton', type: 'MaterialCommunityIcons' },
	{ name: 'balloon', type: 'MaterialCommunityIcons' },
	{ name: 'ballot', type: 'MaterialCommunityIcons' },
	{ name: 'ballot-outline', type: 'MaterialCommunityIcons' },
	{ name: 'bandcamp', type: 'MaterialCommunityIcons' },
	{ name: 'bank', type: 'MaterialCommunityIcons' },
	{ name: 'bank-transfer', type: 'MaterialCommunityIcons' },
	{ name: 'bank-transfer-in', type: 'MaterialCommunityIcons' },
	{ name: 'bank-transfer-out', type: 'MaterialCommunityIcons' },
	{ name: 'barcode', type: 'MaterialCommunityIcons' },
	{ name: 'barcode-scan', type: 'MaterialCommunityIcons' },
	{ name: 'barley', type: 'MaterialCommunityIcons' },
	{ name: 'barrel', type: 'MaterialCommunityIcons' },
	{ name: 'baseball', type: 'MaterialCommunityIcons' },
	{ name: 'baseball-bat', type: 'MaterialCommunityIcons' },
	{ name: 'basecamp', type: 'MaterialCommunityIcons' },
	{ name: 'basket', type: 'MaterialCommunityIcons' },
	{ name: 'basket-fill', type: 'MaterialCommunityIcons' },
	{ name: 'basket-unfill', type: 'MaterialCommunityIcons' },
	{ name: 'basketball', type: 'MaterialCommunityIcons' },
	{ name: 'battery', type: 'MaterialCommunityIcons' },
	{ name: 'battery-10', type: 'MaterialCommunityIcons' },
	{ name: 'battery-10-bluetooth', type: 'MaterialCommunityIcons' },
	{ name: 'battery-20', type: 'MaterialCommunityIcons' },
	{ name: 'battery-20-bluetooth', type: 'MaterialCommunityIcons' },
	{ name: 'battery-30', type: 'MaterialCommunityIcons' },
	{ name: 'battery-30-bluetooth', type: 'MaterialCommunityIcons' },
	{ name: 'battery-40', type: 'MaterialCommunityIcons' },
	{ name: 'battery-40-bluetooth', type: 'MaterialCommunityIcons' },
	{ name: 'battery-50', type: 'MaterialCommunityIcons' },
	{ name: 'battery-50-bluetooth', type: 'MaterialCommunityIcons' },
	{ name: 'battery-60', type: 'MaterialCommunityIcons' },
	{ name: 'battery-60-bluetooth', type: 'MaterialCommunityIcons' },
	{ name: 'battery-70', type: 'MaterialCommunityIcons' },
	{ name: 'battery-70-bluetooth', type: 'MaterialCommunityIcons' },
	{ name: 'battery-80', type: 'MaterialCommunityIcons' },
	{ name: 'battery-80-bluetooth', type: 'MaterialCommunityIcons' },
	{ name: 'battery-90', type: 'MaterialCommunityIcons' },
	{ name: 'battery-90-bluetooth', type: 'MaterialCommunityIcons' },
	{ name: 'battery-alert', type: 'MaterialCommunityIcons' },
	{ name: 'battery-alert-bluetooth', type: 'MaterialCommunityIcons' },
	{ name: 'battery-bluetooth', type: 'MaterialCommunityIcons' },
	{ name: 'battery-bluetooth-variant', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-10', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-100', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-20', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-30', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-40', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-50', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-60', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-70', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-80', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-90', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-outline', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-wireless', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-wireless-10', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-wireless-20', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-wireless-30', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-wireless-40', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-wireless-50', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-wireless-60', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-wireless-70', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-wireless-80', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-wireless-90', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-wireless-alert', type: 'MaterialCommunityIcons' },
	{ name: 'battery-charging-wireless-outline', type: 'MaterialCommunityIcons' },
	{ name: 'battery-minus', type: 'MaterialCommunityIcons' },
	{ name: 'battery-negative', type: 'MaterialCommunityIcons' },
	{ name: 'battery-outline', type: 'MaterialCommunityIcons' },
	{ name: 'battery-plus', type: 'MaterialCommunityIcons' },
	{ name: 'battery-positive', type: 'MaterialCommunityIcons' },
	{ name: 'battery-unknown', type: 'MaterialCommunityIcons' },
	{ name: 'battery-unknown-bluetooth', type: 'MaterialCommunityIcons' },
	{ name: 'beach', type: 'MaterialCommunityIcons' },
	{ name: 'beaker', type: 'MaterialCommunityIcons' },
	{ name: 'beats', type: 'MaterialCommunityIcons' },
	{ name: 'bed-empty', type: 'MaterialCommunityIcons' },
	{ name: 'beer', type: 'MaterialCommunityIcons' },
	{ name: 'behance', type: 'MaterialCommunityIcons' },
	{ name: 'bell', type: 'MaterialCommunityIcons' },
	{ name: 'bell-off', type: 'MaterialCommunityIcons' },
	{ name: 'bell-off-outline', type: 'MaterialCommunityIcons' },
	{ name: 'bell-outline', type: 'MaterialCommunityIcons' },
	{ name: 'bell-plus', type: 'MaterialCommunityIcons' },
	{ name: 'bell-plus-outline', type: 'MaterialCommunityIcons' },
	{ name: 'bell-ring', type: 'MaterialCommunityIcons' },
	{ name: 'bell-ring-outline', type: 'MaterialCommunityIcons' },
	{ name: 'bell-sleep', type: 'MaterialCommunityIcons' },
	{ name: 'bell-sleep-outline', type: 'MaterialCommunityIcons' },
	{ name: 'beta', type: 'MaterialCommunityIcons' },
	{ name: 'betamax', type: 'MaterialCommunityIcons' },
	{ name: 'bible', type: 'MaterialCommunityIcons' },
	{ name: 'bike', type: 'MaterialCommunityIcons' },
	{ name: 'bing', type: 'MaterialCommunityIcons' },
	{ name: 'binoculars', type: 'MaterialCommunityIcons' },
	{ name: 'bio', type: 'MaterialCommunityIcons' },
	{ name: 'biohazard', type: 'MaterialCommunityIcons' },
	{ name: 'bitbucket', type: 'MaterialCommunityIcons' },
	{ name: 'bitcoin', type: 'MaterialCommunityIcons' },
	{ name: 'black-mesa', type: 'MaterialCommunityIcons' },
	{ name: 'blackberry', type: 'MaterialCommunityIcons' },
	{ name: 'blender', type: 'MaterialCommunityIcons' },
	{ name: 'blinds', type: 'MaterialCommunityIcons' },
	{ name: 'block-helper', type: 'MaterialCommunityIcons' },
	{ name: 'blogger', type: 'MaterialCommunityIcons' },
	{ name: 'bluetooth', type: 'MaterialCommunityIcons' },
	{ name: 'bluetooth-audio', type: 'MaterialCommunityIcons' },
	{ name: 'bluetooth-connect', type: 'MaterialCommunityIcons' },
	{ name: 'bluetooth-off', type: 'MaterialCommunityIcons' },
	{ name: 'bluetooth-settings', type: 'MaterialCommunityIcons' },
	{ name: 'bluetooth-transfer', type: 'MaterialCommunityIcons' },
	{ name: 'blur', type: 'MaterialCommunityIcons' },
	{ name: 'blur-linear', type: 'MaterialCommunityIcons' },
	{ name: 'blur-off', type: 'MaterialCommunityIcons' },
	{ name: 'blur-radial', type: 'MaterialCommunityIcons' },
	{ name: 'bomb', type: 'MaterialCommunityIcons' },
	{ name: 'bomb-off', type: 'MaterialCommunityIcons' },
	{ name: 'bone', type: 'MaterialCommunityIcons' },
	{ name: 'book', type: 'MaterialCommunityIcons' },
	{ name: 'book-lock', type: 'MaterialCommunityIcons' },
	{ name: 'book-lock-open', type: 'MaterialCommunityIcons' },
	{ name: 'book-minus', type: 'MaterialCommunityIcons' },
	{ name: 'book-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'book-multiple-minus', type: 'MaterialCommunityIcons' },
	{ name: 'book-multiple-plus', type: 'MaterialCommunityIcons' },
	{ name: 'book-multiple-remove', type: 'MaterialCommunityIcons' },
	{ name: 'book-multiple-variant', type: 'MaterialCommunityIcons' },
	{ name: 'book-open', type: 'MaterialCommunityIcons' },
	{ name: 'book-open-page-variant', type: 'MaterialCommunityIcons' },
	{ name: 'book-open-variant', type: 'MaterialCommunityIcons' },
	{ name: 'book-plus', type: 'MaterialCommunityIcons' },
	{ name: 'book-remove', type: 'MaterialCommunityIcons' },
	{ name: 'book-variant', type: 'MaterialCommunityIcons' },
	{ name: 'bookmark', type: 'MaterialCommunityIcons' },
	{ name: 'bookmark-check', type: 'MaterialCommunityIcons' },
	{ name: 'bookmark-minus', type: 'MaterialCommunityIcons' },
	{ name: 'bookmark-minus-outline', type: 'MaterialCommunityIcons' },
	{ name: 'bookmark-music', type: 'MaterialCommunityIcons' },
	{ name: 'bookmark-off', type: 'MaterialCommunityIcons' },
	{ name: 'bookmark-off-outline', type: 'MaterialCommunityIcons' },
	{ name: 'bookmark-outline', type: 'MaterialCommunityIcons' },
	{ name: 'bookmark-plus', type: 'MaterialCommunityIcons' },
	{ name: 'bookmark-plus-outline', type: 'MaterialCommunityIcons' },
	{ name: 'bookmark-remove', type: 'MaterialCommunityIcons' },
	{ name: 'boombox', type: 'MaterialCommunityIcons' },
	{ name: 'bootstrap', type: 'MaterialCommunityIcons' },
	{ name: 'border-all', type: 'MaterialCommunityIcons' },
	{ name: 'border-all-variant', type: 'MaterialCommunityIcons' },
	{ name: 'border-bottom', type: 'MaterialCommunityIcons' },
	{ name: 'border-bottom-variant', type: 'MaterialCommunityIcons' },
	{ name: 'border-color', type: 'MaterialCommunityIcons' },
	{ name: 'border-horizontal', type: 'MaterialCommunityIcons' },
	{ name: 'border-inside', type: 'MaterialCommunityIcons' },
	{ name: 'border-left', type: 'MaterialCommunityIcons' },
	{ name: 'border-left-variant', type: 'MaterialCommunityIcons' },
	{ name: 'border-none', type: 'MaterialCommunityIcons' },
	{ name: 'border-none-variant', type: 'MaterialCommunityIcons' },
	{ name: 'border-outside', type: 'MaterialCommunityIcons' },
	{ name: 'border-right', type: 'MaterialCommunityIcons' },
	{ name: 'border-right-variant', type: 'MaterialCommunityIcons' },
	{ name: 'border-style', type: 'MaterialCommunityIcons' },
	{ name: 'border-top', type: 'MaterialCommunityIcons' },
	{ name: 'border-top-variant', type: 'MaterialCommunityIcons' },
	{ name: 'border-vertical', type: 'MaterialCommunityIcons' },
	{ name: 'bottle-wine', type: 'MaterialCommunityIcons' },
	{ name: 'bow-tie', type: 'MaterialCommunityIcons' },
	{ name: 'bowl', type: 'MaterialCommunityIcons' },
	{ name: 'bowling', type: 'MaterialCommunityIcons' },
	{ name: 'box', type: 'MaterialCommunityIcons' },
	{ name: 'box-cutter', type: 'MaterialCommunityIcons' },
	{ name: 'box-shadow', type: 'MaterialCommunityIcons' },
	{ name: 'braille', type: 'MaterialCommunityIcons' },
	{ name: 'brain', type: 'MaterialCommunityIcons' },
	{ name: 'bridge', type: 'MaterialCommunityIcons' },
	{ name: 'briefcase', type: 'MaterialCommunityIcons' },
	{ name: 'briefcase-check', type: 'MaterialCommunityIcons' },
	{ name: 'briefcase-download', type: 'MaterialCommunityIcons' },
	{ name: 'briefcase-edit', type: 'MaterialCommunityIcons' },
	{ name: 'briefcase-minus', type: 'MaterialCommunityIcons' },
	{ name: 'briefcase-outline', type: 'MaterialCommunityIcons' },
	{ name: 'briefcase-plus', type: 'MaterialCommunityIcons' },
	{ name: 'briefcase-remove', type: 'MaterialCommunityIcons' },
	{ name: 'briefcase-search', type: 'MaterialCommunityIcons' },
	{ name: 'briefcase-upload', type: 'MaterialCommunityIcons' },
	{ name: 'brightness-1', type: 'MaterialCommunityIcons' },
	{ name: 'brightness-2', type: 'MaterialCommunityIcons' },
	{ name: 'brightness-3', type: 'MaterialCommunityIcons' },
	{ name: 'brightness-4', type: 'MaterialCommunityIcons' },
	{ name: 'brightness-5', type: 'MaterialCommunityIcons' },
	{ name: 'brightness-6', type: 'MaterialCommunityIcons' },
	{ name: 'brightness-7', type: 'MaterialCommunityIcons' },
	{ name: 'brightness-auto', type: 'MaterialCommunityIcons' },
	{ name: 'broom', type: 'MaterialCommunityIcons' },
	{ name: 'brush', type: 'MaterialCommunityIcons' },
	{ name: 'buddhism', type: 'MaterialCommunityIcons' },
	{ name: 'buffer', type: 'MaterialCommunityIcons' },
	{ name: 'bug', type: 'MaterialCommunityIcons' },
	{ name: 'bug-check', type: 'MaterialCommunityIcons' },
	{ name: 'bug-check-outline', type: 'MaterialCommunityIcons' },
	{ name: 'bug-outline', type: 'MaterialCommunityIcons' },
	{ name: 'bulldozer', type: 'MaterialCommunityIcons' },
	{ name: 'bulletin-board', type: 'MaterialCommunityIcons' },
	{ name: 'bullhorn', type: 'MaterialCommunityIcons' },
	{ name: 'bullhorn-outline', type: 'MaterialCommunityIcons' },
	{ name: 'bullseye', type: 'MaterialCommunityIcons' },
	{ name: 'bullseye-arrow', type: 'MaterialCommunityIcons' },
	{ name: 'bus', type: 'MaterialCommunityIcons' },
	{ name: 'bus-alert', type: 'MaterialCommunityIcons' },
	{ name: 'bus-articulated-end', type: 'MaterialCommunityIcons' },
	{ name: 'bus-articulated-front', type: 'MaterialCommunityIcons' },
	{ name: 'bus-clock', type: 'MaterialCommunityIcons' },
	{ name: 'bus-double-decker', type: 'MaterialCommunityIcons' },
	{ name: 'bus-school', type: 'MaterialCommunityIcons' },
	{ name: 'bus-side', type: 'MaterialCommunityIcons' },
	{ name: 'cached', type: 'MaterialCommunityIcons' },
	{ name: 'cake', type: 'MaterialCommunityIcons' },
	{ name: 'cake-layered', type: 'MaterialCommunityIcons' },
	{ name: 'cake-variant', type: 'MaterialCommunityIcons' },
	{ name: 'calculator', type: 'MaterialCommunityIcons' },
	{ name: 'calculator-variant', type: 'MaterialCommunityIcons' },
	{ name: 'calendar', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-alert', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-blank', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-check', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-clock', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-edit', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-export', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-heart', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-import', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-multiple-check', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-multiselect', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-plus', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-question', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-range', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-remove', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-search', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-star', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-text', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-today', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-week', type: 'MaterialCommunityIcons' },
	{ name: 'calendar-week-begin', type: 'MaterialCommunityIcons' },
	{ name: 'call-made', type: 'MaterialCommunityIcons' },
	{ name: 'call-merge', type: 'MaterialCommunityIcons' },
	{ name: 'call-missed', type: 'MaterialCommunityIcons' },
	{ name: 'call-received', type: 'MaterialCommunityIcons' },
	{ name: 'call-split', type: 'MaterialCommunityIcons' },
	{ name: 'camcorder', type: 'MaterialCommunityIcons' },
	{ name: 'camcorder-box', type: 'MaterialCommunityIcons' },
	{ name: 'camcorder-box-off', type: 'MaterialCommunityIcons' },
	{ name: 'camcorder-off', type: 'MaterialCommunityIcons' },
	{ name: 'camera', type: 'MaterialCommunityIcons' },
	{ name: 'camera-account', type: 'MaterialCommunityIcons' },
	{ name: 'camera-burst', type: 'MaterialCommunityIcons' },
	{ name: 'camera-enhance', type: 'MaterialCommunityIcons' },
	{ name: 'camera-front', type: 'MaterialCommunityIcons' },
	{ name: 'camera-front-variant', type: 'MaterialCommunityIcons' },
	{ name: 'camera-gopro', type: 'MaterialCommunityIcons' },
	{ name: 'camera-image', type: 'MaterialCommunityIcons' },
	{ name: 'camera-iris', type: 'MaterialCommunityIcons' },
	{ name: 'camera-metering-center', type: 'MaterialCommunityIcons' },
	{ name: 'camera-metering-matrix', type: 'MaterialCommunityIcons' },
	{ name: 'camera-metering-partial', type: 'MaterialCommunityIcons' },
	{ name: 'camera-metering-spot', type: 'MaterialCommunityIcons' },
	{ name: 'camera-off', type: 'MaterialCommunityIcons' },
	{ name: 'camera-party-mode', type: 'MaterialCommunityIcons' },
	{ name: 'camera-rear', type: 'MaterialCommunityIcons' },
	{ name: 'camera-rear-variant', type: 'MaterialCommunityIcons' },
	{ name: 'camera-switch', type: 'MaterialCommunityIcons' },
	{ name: 'camera-timer', type: 'MaterialCommunityIcons' },
	{ name: 'cancel', type: 'MaterialCommunityIcons' },
	{ name: 'candle', type: 'MaterialCommunityIcons' },
	{ name: 'candycane', type: 'MaterialCommunityIcons' },
	{ name: 'cannabis', type: 'MaterialCommunityIcons' },
	{ name: 'caps-lock', type: 'MaterialCommunityIcons' },
	{ name: 'car', type: 'MaterialCommunityIcons' },
	{ name: 'car-battery', type: 'MaterialCommunityIcons' },
	{ name: 'car-connected', type: 'MaterialCommunityIcons' },
	{ name: 'car-convertible', type: 'MaterialCommunityIcons' },
	{ name: 'car-estate', type: 'MaterialCommunityIcons' },
	{ name: 'car-hatchback', type: 'MaterialCommunityIcons' },
	{ name: 'car-limousine', type: 'MaterialCommunityIcons' },
	{ name: 'car-pickup', type: 'MaterialCommunityIcons' },
	{ name: 'car-side', type: 'MaterialCommunityIcons' },
	{ name: 'car-sports', type: 'MaterialCommunityIcons' },
	{ name: 'car-wash', type: 'MaterialCommunityIcons' },
	{ name: 'caravan', type: 'MaterialCommunityIcons' },
	{ name: 'cards', type: 'MaterialCommunityIcons' },
	{ name: 'cards-club', type: 'MaterialCommunityIcons' },
	{ name: 'cards-diamond', type: 'MaterialCommunityIcons' },
	{ name: 'cards-heart', type: 'MaterialCommunityIcons' },
	{ name: 'cards-outline', type: 'MaterialCommunityIcons' },
	{ name: 'cards-playing-outline', type: 'MaterialCommunityIcons' },
	{ name: 'cards-spade', type: 'MaterialCommunityIcons' },
	{ name: 'cards-variant', type: 'MaterialCommunityIcons' },
	{ name: 'carrot', type: 'MaterialCommunityIcons' },
	{ name: 'cart', type: 'MaterialCommunityIcons' },
	{ name: 'cart-off', type: 'MaterialCommunityIcons' },
	{ name: 'cart-outline', type: 'MaterialCommunityIcons' },
	{ name: 'cart-plus', type: 'MaterialCommunityIcons' },
	{ name: 'case-sensitive-alt', type: 'MaterialCommunityIcons' },
	{ name: 'cash', type: 'MaterialCommunityIcons' },
	{ name: 'cash-100', type: 'MaterialCommunityIcons' },
	{ name: 'cash-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'cash-refund', type: 'MaterialCommunityIcons' },
	{ name: 'cash-usd', type: 'MaterialCommunityIcons' },
	{ name: 'cassette', type: 'MaterialCommunityIcons' },
	{ name: 'cast', type: 'MaterialCommunityIcons' },
	{ name: 'cast-connected', type: 'MaterialCommunityIcons' },
	{ name: 'cast-off', type: 'MaterialCommunityIcons' },
	{ name: 'castle', type: 'MaterialCommunityIcons' },
	{ name: 'cat', type: 'MaterialCommunityIcons' },
	{ name: 'cctv', type: 'MaterialCommunityIcons' },
	{ name: 'ceiling-light', type: 'MaterialCommunityIcons' },
	{ name: 'cellphone', type: 'MaterialCommunityIcons' },
	{ name: 'cellphone-android', type: 'MaterialCommunityIcons' },
	{ name: 'cellphone-arrow-down', type: 'MaterialCommunityIcons' },
	{ name: 'cellphone-basic', type: 'MaterialCommunityIcons' },
	{ name: 'cellphone-dock', type: 'MaterialCommunityIcons' },
	{ name: 'cellphone-erase', type: 'MaterialCommunityIcons' },
	{ name: 'cellphone-iphone', type: 'MaterialCommunityIcons' },
	{ name: 'cellphone-key', type: 'MaterialCommunityIcons' },
	{ name: 'cellphone-link', type: 'MaterialCommunityIcons' },
	{ name: 'cellphone-link-off', type: 'MaterialCommunityIcons' },
	{ name: 'cellphone-lock', type: 'MaterialCommunityIcons' },
	{ name: 'cellphone-message', type: 'MaterialCommunityIcons' },
	{ name: 'cellphone-off', type: 'MaterialCommunityIcons' },
	{ name: 'cellphone-screenshot', type: 'MaterialCommunityIcons' },
	{ name: 'cellphone-settings', type: 'MaterialCommunityIcons' },
	{ name: 'cellphone-settings-variant', type: 'MaterialCommunityIcons' },
	{ name: 'cellphone-sound', type: 'MaterialCommunityIcons' },
	{ name: 'cellphone-text', type: 'MaterialCommunityIcons' },
	{ name: 'cellphone-wireless', type: 'MaterialCommunityIcons' },
	{ name: 'certificate', type: 'MaterialCommunityIcons' },
	{ name: 'chair-school', type: 'MaterialCommunityIcons' },
	{ name: 'chart-arc', type: 'MaterialCommunityIcons' },
	{ name: 'chart-areaspline', type: 'MaterialCommunityIcons' },
	{ name: 'chart-bar', type: 'MaterialCommunityIcons' },
	{ name: 'chart-bar-stacked', type: 'MaterialCommunityIcons' },
	{ name: 'chart-bubble', type: 'MaterialCommunityIcons' },
	{ name: 'chart-donut', type: 'MaterialCommunityIcons' },
	{ name: 'chart-donut-variant', type: 'MaterialCommunityIcons' },
	{ name: 'chart-gantt', type: 'MaterialCommunityIcons' },
	{ name: 'chart-histogram', type: 'MaterialCommunityIcons' },
	{ name: 'chart-line', type: 'MaterialCommunityIcons' },
	{ name: 'chart-line-stacked', type: 'MaterialCommunityIcons' },
	{ name: 'chart-line-variant', type: 'MaterialCommunityIcons' },
	{ name: 'chart-multiline', type: 'MaterialCommunityIcons' },
	{ name: 'chart-pie', type: 'MaterialCommunityIcons' },
	{ name: 'chart-scatterplot-hexbin', type: 'MaterialCommunityIcons' },
	{ name: 'chart-timeline', type: 'MaterialCommunityIcons' },
	{ name: 'check', type: 'MaterialCommunityIcons' },
	{ name: 'check-all', type: 'MaterialCommunityIcons' },
	{ name: 'check-circle', type: 'MaterialCommunityIcons' },
	{ name: 'check-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'check-decagram', type: 'MaterialCommunityIcons' },
	{ name: 'check-outline', type: 'MaterialCommunityIcons' },
	{ name: 'checkbook', type: 'MaterialCommunityIcons' },
	{ name: 'checkbox-blank', type: 'MaterialCommunityIcons' },
	{ name: 'checkbox-blank-circle', type: 'MaterialCommunityIcons' },
	{ name: 'checkbox-blank-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'checkbox-blank-outline', type: 'MaterialCommunityIcons' },
	{ name: 'checkbox-intermediate', type: 'MaterialCommunityIcons' },
	{ name: 'checkbox-marked', type: 'MaterialCommunityIcons' },
	{ name: 'checkbox-marked-circle', type: 'MaterialCommunityIcons' },
	{ name: 'checkbox-marked-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'checkbox-marked-outline', type: 'MaterialCommunityIcons' },
	{ name: 'checkbox-multiple-blank', type: 'MaterialCommunityIcons' },
	{ name: 'checkbox-multiple-blank-circle', type: 'MaterialCommunityIcons' },
	{
		name: 'checkbox-multiple-blank-circle-outline',
		type: 'MaterialCommunityIcons',
	},
	{ name: 'checkbox-multiple-blank-outline', type: 'MaterialCommunityIcons' },
	{ name: 'checkbox-multiple-marked', type: 'MaterialCommunityIcons' },
	{ name: 'checkbox-multiple-marked-circle', type: 'MaterialCommunityIcons' },
	{
		name: 'checkbox-multiple-marked-circle-outline',
		type: 'MaterialCommunityIcons',
	},
	{ name: 'checkbox-multiple-marked-outline', type: 'MaterialCommunityIcons' },
	{ name: 'checkerboard', type: 'MaterialCommunityIcons' },
	{ name: 'chemical-weapon', type: 'MaterialCommunityIcons' },
	{ name: 'chess-bishop', type: 'MaterialCommunityIcons' },
	{ name: 'chess-king', type: 'MaterialCommunityIcons' },
	{ name: 'chess-knight', type: 'MaterialCommunityIcons' },
	{ name: 'chess-pawn', type: 'MaterialCommunityIcons' },
	{ name: 'chess-queen', type: 'MaterialCommunityIcons' },
	{ name: 'chess-rook', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-double-down', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-double-left', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-double-right', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-double-up', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-down', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-down-box', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-down-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-down-circle', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-down-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-left', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-left-box', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-left-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-left-circle', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-left-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-right', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-right-box', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-right-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-right-circle', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-right-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-up', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-up-box', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-up-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-up-circle', type: 'MaterialCommunityIcons' },
	{ name: 'chevron-up-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'chili-hot', type: 'MaterialCommunityIcons' },
	{ name: 'chili-medium', type: 'MaterialCommunityIcons' },
	{ name: 'chili-mild', type: 'MaterialCommunityIcons' },
	{ name: 'chip', type: 'MaterialCommunityIcons' },
	{ name: 'christianity', type: 'MaterialCommunityIcons' },
	{ name: 'church', type: 'MaterialCommunityIcons' },
	{ name: 'circle', type: 'MaterialCommunityIcons' },
	{ name: 'circle-edit-outline', type: 'MaterialCommunityIcons' },
	{ name: 'circle-medium', type: 'MaterialCommunityIcons' },
	{ name: 'circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'circle-slice-1', type: 'MaterialCommunityIcons' },
	{ name: 'circle-slice-2', type: 'MaterialCommunityIcons' },
	{ name: 'circle-slice-3', type: 'MaterialCommunityIcons' },
	{ name: 'circle-slice-4', type: 'MaterialCommunityIcons' },
	{ name: 'circle-slice-5', type: 'MaterialCommunityIcons' },
	{ name: 'circle-slice-6', type: 'MaterialCommunityIcons' },
	{ name: 'circle-slice-7', type: 'MaterialCommunityIcons' },
	{ name: 'circle-slice-8', type: 'MaterialCommunityIcons' },
	{ name: 'circle-small', type: 'MaterialCommunityIcons' },
	{ name: 'cisco-webex', type: 'MaterialCommunityIcons' },
	{ name: 'city', type: 'MaterialCommunityIcons' },
	{ name: 'city-variant', type: 'MaterialCommunityIcons' },
	{ name: 'city-variant-outline', type: 'MaterialCommunityIcons' },
	{ name: 'clipboard', type: 'MaterialCommunityIcons' },
	{ name: 'clipboard-account', type: 'MaterialCommunityIcons' },
	{ name: 'clipboard-alert', type: 'MaterialCommunityIcons' },
	{ name: 'clipboard-arrow-down', type: 'MaterialCommunityIcons' },
	{ name: 'clipboard-arrow-left', type: 'MaterialCommunityIcons' },
	{ name: 'clipboard-check', type: 'MaterialCommunityIcons' },
	{ name: 'clipboard-check-outline', type: 'MaterialCommunityIcons' },
	{ name: 'clipboard-flow', type: 'MaterialCommunityIcons' },
	{ name: 'clipboard-outline', type: 'MaterialCommunityIcons' },
	{ name: 'clipboard-plus', type: 'MaterialCommunityIcons' },
	{ name: 'clipboard-pulse', type: 'MaterialCommunityIcons' },
	{ name: 'clipboard-pulse-outline', type: 'MaterialCommunityIcons' },
	{ name: 'clipboard-text', type: 'MaterialCommunityIcons' },
	{ name: 'clipboard-text-outline', type: 'MaterialCommunityIcons' },
	{ name: 'clippy', type: 'MaterialCommunityIcons' },
	{ name: 'clock', type: 'MaterialCommunityIcons' },
	{ name: 'clock-alert', type: 'MaterialCommunityIcons' },
	{ name: 'clock-alert-outline', type: 'MaterialCommunityIcons' },
	{ name: 'clock-end', type: 'MaterialCommunityIcons' },
	{ name: 'clock-fast', type: 'MaterialCommunityIcons' },
	{ name: 'clock-in', type: 'MaterialCommunityIcons' },
	{ name: 'clock-out', type: 'MaterialCommunityIcons' },
	{ name: 'clock-outline', type: 'MaterialCommunityIcons' },
	{ name: 'clock-start', type: 'MaterialCommunityIcons' },
	{ name: 'close', type: 'MaterialCommunityIcons' },
	{ name: 'close-box', type: 'MaterialCommunityIcons' },
	{ name: 'close-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'close-circle', type: 'MaterialCommunityIcons' },
	{ name: 'close-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'close-network', type: 'MaterialCommunityIcons' },
	{ name: 'close-octagon', type: 'MaterialCommunityIcons' },
	{ name: 'close-octagon-outline', type: 'MaterialCommunityIcons' },
	{ name: 'close-outline', type: 'MaterialCommunityIcons' },
	{ name: 'closed-caption', type: 'MaterialCommunityIcons' },
	{ name: 'cloud', type: 'MaterialCommunityIcons' },
	{ name: 'cloud-alert', type: 'MaterialCommunityIcons' },
	{ name: 'cloud-braces', type: 'MaterialCommunityIcons' },
	{ name: 'cloud-check', type: 'MaterialCommunityIcons' },
	{ name: 'cloud-circle', type: 'MaterialCommunityIcons' },
	{ name: 'cloud-download', type: 'MaterialCommunityIcons' },
	{ name: 'cloud-off-outline', type: 'MaterialCommunityIcons' },
	{ name: 'cloud-outline', type: 'MaterialCommunityIcons' },
	{ name: 'cloud-print', type: 'MaterialCommunityIcons' },
	{ name: 'cloud-print-outline', type: 'MaterialCommunityIcons' },
	{ name: 'cloud-question', type: 'MaterialCommunityIcons' },
	{ name: 'cloud-search', type: 'MaterialCommunityIcons' },
	{ name: 'cloud-search-outline', type: 'MaterialCommunityIcons' },
	{ name: 'cloud-sync', type: 'MaterialCommunityIcons' },
	{ name: 'cloud-tags', type: 'MaterialCommunityIcons' },
	{ name: 'cloud-upload', type: 'MaterialCommunityIcons' },
	{ name: 'clover', type: 'MaterialCommunityIcons' },
	{ name: 'code-array', type: 'MaterialCommunityIcons' },
	{ name: 'code-braces', type: 'MaterialCommunityIcons' },
	{ name: 'code-brackets', type: 'MaterialCommunityIcons' },
	{ name: 'code-equal', type: 'MaterialCommunityIcons' },
	{ name: 'code-greater-than', type: 'MaterialCommunityIcons' },
	{ name: 'code-greater-than-or-equal', type: 'MaterialCommunityIcons' },
	{ name: 'code-less-than', type: 'MaterialCommunityIcons' },
	{ name: 'code-less-than-or-equal', type: 'MaterialCommunityIcons' },
	{ name: 'code-not-equal', type: 'MaterialCommunityIcons' },
	{ name: 'code-not-equal-variant', type: 'MaterialCommunityIcons' },
	{ name: 'code-parentheses', type: 'MaterialCommunityIcons' },
	{ name: 'code-string', type: 'MaterialCommunityIcons' },
	{ name: 'code-tags', type: 'MaterialCommunityIcons' },
	{ name: 'code-tags-check', type: 'MaterialCommunityIcons' },
	{ name: 'codepen', type: 'MaterialCommunityIcons' },
	{ name: 'coffee', type: 'MaterialCommunityIcons' },
	{ name: 'coffee-outline', type: 'MaterialCommunityIcons' },
	{ name: 'coffee-to-go', type: 'MaterialCommunityIcons' },
	{ name: 'cogs', type: 'MaterialCommunityIcons' },
	{ name: 'coin', type: 'MaterialCommunityIcons' },
	{ name: 'coins', type: 'MaterialCommunityIcons' },
	{ name: 'collage', type: 'MaterialCommunityIcons' },
	{ name: 'collapse-all', type: 'MaterialCommunityIcons' },
	{ name: 'collapse-all-outline', type: 'MaterialCommunityIcons' },
	{ name: 'color-helper', type: 'MaterialCommunityIcons' },
	{ name: 'comment', type: 'MaterialCommunityIcons' },
	{ name: 'comment-account', type: 'MaterialCommunityIcons' },
	{ name: 'comment-account-outline', type: 'MaterialCommunityIcons' },
	{ name: 'comment-alert', type: 'MaterialCommunityIcons' },
	{ name: 'comment-alert-outline', type: 'MaterialCommunityIcons' },
	{ name: 'comment-arrow-left', type: 'MaterialCommunityIcons' },
	{ name: 'comment-arrow-left-outline', type: 'MaterialCommunityIcons' },
	{ name: 'comment-arrow-right', type: 'MaterialCommunityIcons' },
	{ name: 'comment-arrow-right-outline', type: 'MaterialCommunityIcons' },
	{ name: 'comment-check', type: 'MaterialCommunityIcons' },
	{ name: 'comment-check-outline', type: 'MaterialCommunityIcons' },
	{ name: 'comment-eye', type: 'MaterialCommunityIcons' },
	{ name: 'comment-eye-outline', type: 'MaterialCommunityIcons' },
	{ name: 'comment-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'comment-multiple-outline', type: 'MaterialCommunityIcons' },
	{ name: 'comment-outline', type: 'MaterialCommunityIcons' },
	{ name: 'comment-plus', type: 'MaterialCommunityIcons' },
	{ name: 'comment-plus-outline', type: 'MaterialCommunityIcons' },
	{ name: 'comment-processing', type: 'MaterialCommunityIcons' },
	{ name: 'comment-processing-outline', type: 'MaterialCommunityIcons' },
	{ name: 'comment-question', type: 'MaterialCommunityIcons' },
	{ name: 'comment-question-outline', type: 'MaterialCommunityIcons' },
	{ name: 'comment-remove', type: 'MaterialCommunityIcons' },
	{ name: 'comment-remove-outline', type: 'MaterialCommunityIcons' },
	{ name: 'comment-search', type: 'MaterialCommunityIcons' },
	{ name: 'comment-search-outline', type: 'MaterialCommunityIcons' },
	{ name: 'comment-text', type: 'MaterialCommunityIcons' },
	{ name: 'comment-text-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'comment-text-multiple-outline', type: 'MaterialCommunityIcons' },
	{ name: 'comment-text-outline', type: 'MaterialCommunityIcons' },
	{ name: 'compare', type: 'MaterialCommunityIcons' },
	{ name: 'compass', type: 'MaterialCommunityIcons' },
	{ name: 'compass-outline', type: 'MaterialCommunityIcons' },
	{ name: 'console', type: 'MaterialCommunityIcons' },
	{ name: 'console-line', type: 'MaterialCommunityIcons' },
	{ name: 'console-network', type: 'MaterialCommunityIcons' },
	{ name: 'contact-mail', type: 'MaterialCommunityIcons' },
	{ name: 'contacts', type: 'MaterialCommunityIcons' },
	{ name: 'contain', type: 'MaterialCommunityIcons' },
	{ name: 'contain-end', type: 'MaterialCommunityIcons' },
	{ name: 'contain-start', type: 'MaterialCommunityIcons' },
	{ name: 'content-copy', type: 'MaterialCommunityIcons' },
	{ name: 'content-cut', type: 'MaterialCommunityIcons' },
	{ name: 'content-duplicate', type: 'MaterialCommunityIcons' },
	{ name: 'content-paste', type: 'MaterialCommunityIcons' },
	{ name: 'content-save', type: 'MaterialCommunityIcons' },
	{ name: 'content-save-all', type: 'MaterialCommunityIcons' },
	{ name: 'content-save-outline', type: 'MaterialCommunityIcons' },
	{ name: 'content-save-settings', type: 'MaterialCommunityIcons' },
	{ name: 'content-save-settings-outline', type: 'MaterialCommunityIcons' },
	{ name: 'contrast', type: 'MaterialCommunityIcons' },
	{ name: 'contrast-box', type: 'MaterialCommunityIcons' },
	{ name: 'contrast-circle', type: 'MaterialCommunityIcons' },
	{ name: 'cookie', type: 'MaterialCommunityIcons' },
	{ name: 'copyright', type: 'MaterialCommunityIcons' },
	{ name: 'cordova', type: 'MaterialCommunityIcons' },
	{ name: 'corn', type: 'MaterialCommunityIcons' },
	{ name: 'counter', type: 'MaterialCommunityIcons' },
	{ name: 'cow', type: 'MaterialCommunityIcons' },
	{ name: 'crane', type: 'MaterialCommunityIcons' },
	{ name: 'creation', type: 'MaterialCommunityIcons' },
	{ name: 'credit-card', type: 'MaterialCommunityIcons' },
	{ name: 'credit-card-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'credit-card-off', type: 'MaterialCommunityIcons' },
	{ name: 'credit-card-plus', type: 'MaterialCommunityIcons' },
	{ name: 'credit-card-refund', type: 'MaterialCommunityIcons' },
	{ name: 'credit-card-scan', type: 'MaterialCommunityIcons' },
	{ name: 'credit-card-settings', type: 'MaterialCommunityIcons' },
	{ name: 'crop', type: 'MaterialCommunityIcons' },
	{ name: 'crop-free', type: 'MaterialCommunityIcons' },
	{ name: 'crop-landscape', type: 'MaterialCommunityIcons' },
	{ name: 'crop-portrait', type: 'MaterialCommunityIcons' },
	{ name: 'crop-rotate', type: 'MaterialCommunityIcons' },
	{ name: 'crop-square', type: 'MaterialCommunityIcons' },
	{ name: 'crosshairs', type: 'MaterialCommunityIcons' },
	{ name: 'crosshairs-gps', type: 'MaterialCommunityIcons' },
	{ name: 'crown', type: 'MaterialCommunityIcons' },
	{ name: 'cryengine', type: 'MaterialCommunityIcons' },
	{ name: 'crystal-ball', type: 'MaterialCommunityIcons' },
	{ name: 'cube', type: 'MaterialCommunityIcons' },
	{ name: 'cube-outline', type: 'MaterialCommunityIcons' },
	{ name: 'cube-send', type: 'MaterialCommunityIcons' },
	{ name: 'cube-unfolded', type: 'MaterialCommunityIcons' },
	{ name: 'cup', type: 'MaterialCommunityIcons' },
	{ name: 'cup-off', type: 'MaterialCommunityIcons' },
	{ name: 'cup-water', type: 'MaterialCommunityIcons' },
	{ name: 'cupcake', type: 'MaterialCommunityIcons' },
	{ name: 'curling', type: 'MaterialCommunityIcons' },
	{ name: 'currency-bdt', type: 'MaterialCommunityIcons' },
	{ name: 'currency-btc', type: 'MaterialCommunityIcons' },
	{ name: 'currency-chf', type: 'MaterialCommunityIcons' },
	{ name: 'currency-cny', type: 'MaterialCommunityIcons' },
	{ name: 'currency-eth', type: 'MaterialCommunityIcons' },
	{ name: 'currency-eur', type: 'MaterialCommunityIcons' },
	{ name: 'currency-gbp', type: 'MaterialCommunityIcons' },
	{ name: 'currency-inr', type: 'MaterialCommunityIcons' },
	{ name: 'currency-jpy', type: 'MaterialCommunityIcons' },
	{ name: 'currency-krw', type: 'MaterialCommunityIcons' },
	{ name: 'currency-kzt', type: 'MaterialCommunityIcons' },
	{ name: 'currency-ngn', type: 'MaterialCommunityIcons' },
	{ name: 'currency-php', type: 'MaterialCommunityIcons' },
	{ name: 'currency-rub', type: 'MaterialCommunityIcons' },
	{ name: 'currency-sign', type: 'MaterialCommunityIcons' },
	{ name: 'currency-try', type: 'MaterialCommunityIcons' },
	{ name: 'currency-twd', type: 'MaterialCommunityIcons' },
	{ name: 'currency-usd', type: 'MaterialCommunityIcons' },
	{ name: 'currency-usd-off', type: 'MaterialCommunityIcons' },
	{ name: 'current-ac', type: 'MaterialCommunityIcons' },
	{ name: 'current-dc', type: 'MaterialCommunityIcons' },
	{ name: 'cursor-default', type: 'MaterialCommunityIcons' },
	{ name: 'cursor-default-outline', type: 'MaterialCommunityIcons' },
	{ name: 'cursor-move', type: 'MaterialCommunityIcons' },
	{ name: 'cursor-pointer', type: 'MaterialCommunityIcons' },
	{ name: 'cursor-text', type: 'MaterialCommunityIcons' },
	{ name: 'database', type: 'MaterialCommunityIcons' },
	{ name: 'database-check', type: 'MaterialCommunityIcons' },
	{ name: 'database-export', type: 'MaterialCommunityIcons' },
	{ name: 'database-import', type: 'MaterialCommunityIcons' },
	{ name: 'database-lock', type: 'MaterialCommunityIcons' },
	{ name: 'database-minus', type: 'MaterialCommunityIcons' },
	{ name: 'database-plus', type: 'MaterialCommunityIcons' },
	{ name: 'database-search', type: 'MaterialCommunityIcons' },
	{ name: 'death-star', type: 'MaterialCommunityIcons' },
	{ name: 'death-star-variant', type: 'MaterialCommunityIcons' },
	{ name: 'debian', type: 'MaterialCommunityIcons' },
	{ name: 'debug-step-into', type: 'MaterialCommunityIcons' },
	{ name: 'debug-step-out', type: 'MaterialCommunityIcons' },
	{ name: 'debug-step-over', type: 'MaterialCommunityIcons' },
	{ name: 'decagram', type: 'MaterialCommunityIcons' },
	{ name: 'decagram-outline', type: 'MaterialCommunityIcons' },
	{ name: 'decimal-decrease', type: 'MaterialCommunityIcons' },
	{ name: 'decimal-increase', type: 'MaterialCommunityIcons' },
	{ name: 'delete', type: 'MaterialCommunityIcons' },
	{ name: 'delete-circle', type: 'MaterialCommunityIcons' },
	{ name: 'delete-empty', type: 'MaterialCommunityIcons' },
	{ name: 'delete-forever', type: 'MaterialCommunityIcons' },
	{ name: 'delete-outline', type: 'MaterialCommunityIcons' },
	{ name: 'delete-restore', type: 'MaterialCommunityIcons' },
	{ name: 'delete-sweep', type: 'MaterialCommunityIcons' },
	{ name: 'delete-variant', type: 'MaterialCommunityIcons' },
	{ name: 'delta', type: 'MaterialCommunityIcons' },
	{ name: 'desk-lamp', type: 'MaterialCommunityIcons' },
	{ name: 'deskphone', type: 'MaterialCommunityIcons' },
	{ name: 'desktop-classic', type: 'MaterialCommunityIcons' },
	{ name: 'desktop-mac', type: 'MaterialCommunityIcons' },
	{ name: 'desktop-mac-dashboard', type: 'MaterialCommunityIcons' },
	{ name: 'desktop-tower', type: 'MaterialCommunityIcons' },
	{ name: 'desktop-tower-monitor', type: 'MaterialCommunityIcons' },
	{ name: 'details', type: 'MaterialCommunityIcons' },
	{ name: 'developer-board', type: 'MaterialCommunityIcons' },
	{ name: 'deviantart', type: 'MaterialCommunityIcons' },
	{ name: 'dialpad', type: 'MaterialCommunityIcons' },
	{ name: 'diamond', type: 'MaterialCommunityIcons' },
	{ name: 'dice-1', type: 'MaterialCommunityIcons' },
	{ name: 'dice-2', type: 'MaterialCommunityIcons' },
	{ name: 'dice-3', type: 'MaterialCommunityIcons' },
	{ name: 'dice-4', type: 'MaterialCommunityIcons' },
	{ name: 'dice-5', type: 'MaterialCommunityIcons' },
	{ name: 'dice-6', type: 'MaterialCommunityIcons' },
	{ name: 'dice-d10', type: 'MaterialCommunityIcons' },
	{ name: 'dice-d12', type: 'MaterialCommunityIcons' },
	{ name: 'dice-d20', type: 'MaterialCommunityIcons' },
	{ name: 'dice-d4', type: 'MaterialCommunityIcons' },
	{ name: 'dice-d6', type: 'MaterialCommunityIcons' },
	{ name: 'dice-d8', type: 'MaterialCommunityIcons' },
	{ name: 'dice-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'dictionary', type: 'MaterialCommunityIcons' },
	{ name: 'dip-switch', type: 'MaterialCommunityIcons' },
	{ name: 'directions', type: 'MaterialCommunityIcons' },
	{ name: 'directions-fork', type: 'MaterialCommunityIcons' },
	{ name: 'disc', type: 'MaterialCommunityIcons' },
	{ name: 'disc-alert', type: 'MaterialCommunityIcons' },
	{ name: 'disc-player', type: 'MaterialCommunityIcons' },
	{ name: 'discord', type: 'MaterialCommunityIcons' },
	{ name: 'dishwasher', type: 'MaterialCommunityIcons' },
	{ name: 'disqus', type: 'MaterialCommunityIcons' },
	{ name: 'disqus-outline', type: 'MaterialCommunityIcons' },
	{ name: 'division', type: 'MaterialCommunityIcons' },
	{ name: 'division-box', type: 'MaterialCommunityIcons' },
	{ name: 'dlna', type: 'MaterialCommunityIcons' },
	{ name: 'dna', type: 'MaterialCommunityIcons' },
	{ name: 'dns', type: 'MaterialCommunityIcons' },
	{ name: 'do-not-disturb', type: 'MaterialCommunityIcons' },
	{ name: 'do-not-disturb-off', type: 'MaterialCommunityIcons' },
	{ name: 'docker', type: 'MaterialCommunityIcons' },
	{ name: 'doctor', type: 'MaterialCommunityIcons' },
	{ name: 'dog', type: 'MaterialCommunityIcons' },
	{ name: 'dog-service', type: 'MaterialCommunityIcons' },
	{ name: 'dog-side', type: 'MaterialCommunityIcons' },
	{ name: 'dolby', type: 'MaterialCommunityIcons' },
	{ name: 'domain', type: 'MaterialCommunityIcons' },
	{ name: 'donkey', type: 'MaterialCommunityIcons' },
	{ name: 'door', type: 'MaterialCommunityIcons' },
	{ name: 'door-closed', type: 'MaterialCommunityIcons' },
	{ name: 'door-open', type: 'MaterialCommunityIcons' },
	{ name: 'doorbell-video', type: 'MaterialCommunityIcons' },
	{ name: 'dot-net', type: 'MaterialCommunityIcons' },
	{ name: 'dots-horizontal', type: 'MaterialCommunityIcons' },
	{ name: 'dots-horizontal-circle', type: 'MaterialCommunityIcons' },
	{ name: 'dots-vertical', type: 'MaterialCommunityIcons' },
	{ name: 'dots-vertical-circle', type: 'MaterialCommunityIcons' },
	{ name: 'douban', type: 'MaterialCommunityIcons' },
	{ name: 'download', type: 'MaterialCommunityIcons' },
	{ name: 'download-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'download-network', type: 'MaterialCommunityIcons' },
	{ name: 'drag', type: 'MaterialCommunityIcons' },
	{ name: 'drag-horizontal', type: 'MaterialCommunityIcons' },
	{ name: 'drag-vertical', type: 'MaterialCommunityIcons' },
	{ name: 'drawing', type: 'MaterialCommunityIcons' },
	{ name: 'drawing-box', type: 'MaterialCommunityIcons' },
	{ name: 'dribbble', type: 'MaterialCommunityIcons' },
	{ name: 'dribbble-box', type: 'MaterialCommunityIcons' },
	{ name: 'drone', type: 'MaterialCommunityIcons' },
	{ name: 'dropbox', type: 'MaterialCommunityIcons' },
	{ name: 'drupal', type: 'MaterialCommunityIcons' },
	{ name: 'duck', type: 'MaterialCommunityIcons' },
	{ name: 'dumbbell', type: 'MaterialCommunityIcons' },
	{ name: 'ear-hearing', type: 'MaterialCommunityIcons' },
	{ name: 'ear-hearing-off', type: 'MaterialCommunityIcons' },
	{ name: 'earth', type: 'MaterialCommunityIcons' },
	{ name: 'earth-box', type: 'MaterialCommunityIcons' },
	{ name: 'earth-box-off', type: 'MaterialCommunityIcons' },
	{ name: 'earth-off', type: 'MaterialCommunityIcons' },
	{ name: 'edge', type: 'MaterialCommunityIcons' },
	{ name: 'egg', type: 'MaterialCommunityIcons' },
	{ name: 'egg-easter', type: 'MaterialCommunityIcons' },
	{ name: 'eight-track', type: 'MaterialCommunityIcons' },
	{ name: 'eject', type: 'MaterialCommunityIcons' },
	{ name: 'elephant', type: 'MaterialCommunityIcons' },
	{ name: 'elevation-decline', type: 'MaterialCommunityIcons' },
	{ name: 'elevation-rise', type: 'MaterialCommunityIcons' },
	{ name: 'elevator', type: 'MaterialCommunityIcons' },
	{ name: 'email', type: 'MaterialCommunityIcons' },
	{ name: 'email-alert', type: 'MaterialCommunityIcons' },
	{ name: 'email-check', type: 'MaterialCommunityIcons' },
	{ name: 'email-check-outline', type: 'MaterialCommunityIcons' },
	{ name: 'email-lock', type: 'MaterialCommunityIcons' },
	{ name: 'email-open', type: 'MaterialCommunityIcons' },
	{ name: 'email-open-outline', type: 'MaterialCommunityIcons' },
	{ name: 'email-outline', type: 'MaterialCommunityIcons' },
	{ name: 'email-plus', type: 'MaterialCommunityIcons' },
	{ name: 'email-plus-outline', type: 'MaterialCommunityIcons' },
	{ name: 'email-search', type: 'MaterialCommunityIcons' },
	{ name: 'email-search-outline', type: 'MaterialCommunityIcons' },
	{ name: 'email-variant', type: 'MaterialCommunityIcons' },
	{ name: 'ember', type: 'MaterialCommunityIcons' },
	{ name: 'emby', type: 'MaterialCommunityIcons' },
	{ name: 'emoticon', type: 'MaterialCommunityIcons' },
	{ name: 'emoticon-cool', type: 'MaterialCommunityIcons' },
	{ name: 'emoticon-dead', type: 'MaterialCommunityIcons' },
	{ name: 'emoticon-devil', type: 'MaterialCommunityIcons' },
	{ name: 'emoticon-excited', type: 'MaterialCommunityIcons' },
	{ name: 'emoticon-happy', type: 'MaterialCommunityIcons' },
	{ name: 'emoticon-neutral', type: 'MaterialCommunityIcons' },
	{ name: 'emoticon-poop', type: 'MaterialCommunityIcons' },
	{ name: 'emoticon-sad', type: 'MaterialCommunityIcons' },
	{ name: 'emoticon-tongue', type: 'MaterialCommunityIcons' },
	{ name: 'engine', type: 'MaterialCommunityIcons' },
	{ name: 'engine-off', type: 'MaterialCommunityIcons' },
	{ name: 'engine-off-outline', type: 'MaterialCommunityIcons' },
	{ name: 'engine-outline', type: 'MaterialCommunityIcons' },
	{ name: 'equal', type: 'MaterialCommunityIcons' },
	{ name: 'equal-box', type: 'MaterialCommunityIcons' },
	{ name: 'eraser', type: 'MaterialCommunityIcons' },
	{ name: 'eraser-variant', type: 'MaterialCommunityIcons' },
	{ name: 'escalator', type: 'MaterialCommunityIcons' },
	{ name: 'et', type: 'MaterialCommunityIcons' },
	{ name: 'ethereum', type: 'MaterialCommunityIcons' },
	{ name: 'ethernet', type: 'MaterialCommunityIcons' },
	{ name: 'ethernet-cable', type: 'MaterialCommunityIcons' },
	{ name: 'ethernet-cable-off', type: 'MaterialCommunityIcons' },
	{ name: 'etsy', type: 'MaterialCommunityIcons' },
	{ name: 'ev-station', type: 'MaterialCommunityIcons' },
	{ name: 'eventbrite', type: 'MaterialCommunityIcons' },
	{ name: 'evernote', type: 'MaterialCommunityIcons' },
	{ name: 'exclamation', type: 'MaterialCommunityIcons' },
	{ name: 'exit-run', type: 'MaterialCommunityIcons' },
	{ name: 'exit-to-app', type: 'MaterialCommunityIcons' },
	{ name: 'expand-all', type: 'MaterialCommunityIcons' },
	{ name: 'expand-all-outline', type: 'MaterialCommunityIcons' },
	{ name: 'exponent', type: 'MaterialCommunityIcons' },
	{ name: 'exponent-box', type: 'MaterialCommunityIcons' },
	{ name: 'export', type: 'MaterialCommunityIcons' },
	{ name: 'eye', type: 'MaterialCommunityIcons' },
	{ name: 'eye-off', type: 'MaterialCommunityIcons' },
	{ name: 'eye-off-outline', type: 'MaterialCommunityIcons' },
	{ name: 'eye-outline', type: 'MaterialCommunityIcons' },
	{ name: 'eye-plus', type: 'MaterialCommunityIcons' },
	{ name: 'eye-plus-outline', type: 'MaterialCommunityIcons' },
	{ name: 'eye-settings', type: 'MaterialCommunityIcons' },
	{ name: 'eye-settings-outline', type: 'MaterialCommunityIcons' },
	{ name: 'eyedropper', type: 'MaterialCommunityIcons' },
	{ name: 'eyedropper-variant', type: 'MaterialCommunityIcons' },
	{ name: 'face', type: 'MaterialCommunityIcons' },
	{ name: 'face-profile', type: 'MaterialCommunityIcons' },
	{ name: 'facebook', type: 'MaterialCommunityIcons' },
	{ name: 'facebook-box', type: 'MaterialCommunityIcons' },
	{ name: 'facebook-messenger', type: 'MaterialCommunityIcons' },
	{ name: 'facebook-workplace', type: 'MaterialCommunityIcons' },
	{ name: 'factory', type: 'MaterialCommunityIcons' },
	{ name: 'fan', type: 'MaterialCommunityIcons' },
	{ name: 'fan-off', type: 'MaterialCommunityIcons' },
	{ name: 'fast-forward', type: 'MaterialCommunityIcons' },
	{ name: 'fast-forward-outline', type: 'MaterialCommunityIcons' },
	{ name: 'fax', type: 'MaterialCommunityIcons' },
	{ name: 'feather', type: 'MaterialCommunityIcons' },
	{ name: 'feature-search', type: 'MaterialCommunityIcons' },
	{ name: 'feature-search-outline', type: 'MaterialCommunityIcons' },
	{ name: 'fedora', type: 'MaterialCommunityIcons' },
	{ name: 'ferry', type: 'MaterialCommunityIcons' },
	{ name: 'file', type: 'MaterialCommunityIcons' },
	{ name: 'file-account', type: 'MaterialCommunityIcons' },
	{ name: 'file-alert', type: 'MaterialCommunityIcons' },
	{ name: 'file-alert-outline', type: 'MaterialCommunityIcons' },
	{ name: 'file-cabinet', type: 'MaterialCommunityIcons' },
	{ name: 'file-chart', type: 'MaterialCommunityIcons' },
	{ name: 'file-check', type: 'MaterialCommunityIcons' },
	{ name: 'file-cloud', type: 'MaterialCommunityIcons' },
	{ name: 'file-compare', type: 'MaterialCommunityIcons' },
	{ name: 'file-delimited', type: 'MaterialCommunityIcons' },
	{ name: 'file-document', type: 'MaterialCommunityIcons' },
	{ name: 'file-document-box', type: 'MaterialCommunityIcons' },
	{ name: 'file-document-box-multiple', type: 'MaterialCommunityIcons' },
	{
		name: 'file-document-box-multiple-outline',
		type: 'MaterialCommunityIcons',
	},
	{ name: 'file-document-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'file-document-outline', type: 'MaterialCommunityIcons' },
	{ name: 'file-download', type: 'MaterialCommunityIcons' },
	{ name: 'file-download-outline', type: 'MaterialCommunityIcons' },
	{ name: 'file-excel', type: 'MaterialCommunityIcons' },
	{ name: 'file-excel-box', type: 'MaterialCommunityIcons' },
	{ name: 'file-export', type: 'MaterialCommunityIcons' },
	{ name: 'file-find', type: 'MaterialCommunityIcons' },
	{ name: 'file-hidden', type: 'MaterialCommunityIcons' },
	{ name: 'file-image', type: 'MaterialCommunityIcons' },
	{ name: 'file-import', type: 'MaterialCommunityIcons' },
	{ name: 'file-lock', type: 'MaterialCommunityIcons' },
	{ name: 'file-move', type: 'MaterialCommunityIcons' },
	{ name: 'file-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'file-music', type: 'MaterialCommunityIcons' },
	{ name: 'file-outline', type: 'MaterialCommunityIcons' },
	{ name: 'file-pdf', type: 'MaterialCommunityIcons' },
	{ name: 'file-pdf-box', type: 'MaterialCommunityIcons' },
	{ name: 'file-percent', type: 'MaterialCommunityIcons' },
	{ name: 'file-plus', type: 'MaterialCommunityIcons' },
	{ name: 'file-powerpoint', type: 'MaterialCommunityIcons' },
	{ name: 'file-powerpoint-box', type: 'MaterialCommunityIcons' },
	{ name: 'file-presentation-box', type: 'MaterialCommunityIcons' },
	{ name: 'file-question', type: 'MaterialCommunityIcons' },
	{ name: 'file-replace', type: 'MaterialCommunityIcons' },
	{ name: 'file-replace-outline', type: 'MaterialCommunityIcons' },
	{ name: 'file-restore', type: 'MaterialCommunityIcons' },
	{ name: 'file-send', type: 'MaterialCommunityIcons' },
	{ name: 'file-tree', type: 'MaterialCommunityIcons' },
	{ name: 'file-undo', type: 'MaterialCommunityIcons' },
	{ name: 'file-upload', type: 'MaterialCommunityIcons' },
	{ name: 'file-upload-outline', type: 'MaterialCommunityIcons' },
	{ name: 'file-video', type: 'MaterialCommunityIcons' },
	{ name: 'file-word', type: 'MaterialCommunityIcons' },
	{ name: 'file-word-box', type: 'MaterialCommunityIcons' },
	{ name: 'file-xml', type: 'MaterialCommunityIcons' },
	{ name: 'film', type: 'MaterialCommunityIcons' },
	{ name: 'filmstrip', type: 'MaterialCommunityIcons' },
	{ name: 'filmstrip-off', type: 'MaterialCommunityIcons' },
	{ name: 'filter', type: 'MaterialCommunityIcons' },
	{ name: 'filter-outline', type: 'MaterialCommunityIcons' },
	{ name: 'filter-remove', type: 'MaterialCommunityIcons' },
	{ name: 'filter-remove-outline', type: 'MaterialCommunityIcons' },
	{ name: 'filter-variant', type: 'MaterialCommunityIcons' },
	{ name: 'finance', type: 'MaterialCommunityIcons' },
	{ name: 'find-replace', type: 'MaterialCommunityIcons' },
	{ name: 'fingerprint', type: 'MaterialCommunityIcons' },
	{ name: 'fire', type: 'MaterialCommunityIcons' },
	{ name: 'fire-truck', type: 'MaterialCommunityIcons' },
	{ name: 'firebase', type: 'MaterialCommunityIcons' },
	{ name: 'firefox', type: 'MaterialCommunityIcons' },
	{ name: 'fish', type: 'MaterialCommunityIcons' },
	{ name: 'flag', type: 'MaterialCommunityIcons' },
	{ name: 'flag-checkered', type: 'MaterialCommunityIcons' },
	{ name: 'flag-outline', type: 'MaterialCommunityIcons' },
	{ name: 'flag-triangle', type: 'MaterialCommunityIcons' },
	{ name: 'flag-variant', type: 'MaterialCommunityIcons' },
	{ name: 'flag-variant-outline', type: 'MaterialCommunityIcons' },
	{ name: 'flash', type: 'MaterialCommunityIcons' },
	{ name: 'flash-auto', type: 'MaterialCommunityIcons' },
	{ name: 'flash-circle', type: 'MaterialCommunityIcons' },
	{ name: 'flash-off', type: 'MaterialCommunityIcons' },
	{ name: 'flash-outline', type: 'MaterialCommunityIcons' },
	{ name: 'flash-red-eye', type: 'MaterialCommunityIcons' },
	{ name: 'flashlight', type: 'MaterialCommunityIcons' },
	{ name: 'flashlight-off', type: 'MaterialCommunityIcons' },
	{ name: 'flask', type: 'MaterialCommunityIcons' },
	{ name: 'flask-empty', type: 'MaterialCommunityIcons' },
	{ name: 'flask-empty-outline', type: 'MaterialCommunityIcons' },
	{ name: 'flask-outline', type: 'MaterialCommunityIcons' },
	{ name: 'flattr', type: 'MaterialCommunityIcons' },
	{ name: 'flip-to-back', type: 'MaterialCommunityIcons' },
	{ name: 'flip-to-front', type: 'MaterialCommunityIcons' },
	{ name: 'floor-lamp', type: 'MaterialCommunityIcons' },
	{ name: 'floor-plan', type: 'MaterialCommunityIcons' },
	{ name: 'floppy', type: 'MaterialCommunityIcons' },
	{ name: 'floppy-variant', type: 'MaterialCommunityIcons' },
	{ name: 'flower', type: 'MaterialCommunityIcons' },
	{ name: 'flower-outline', type: 'MaterialCommunityIcons' },
	{ name: 'flower-tulip', type: 'MaterialCommunityIcons' },
	{ name: 'flower-tulip-outline', type: 'MaterialCommunityIcons' },
	{ name: 'folder', type: 'MaterialCommunityIcons' },
	{ name: 'folder-account', type: 'MaterialCommunityIcons' },
	{ name: 'folder-clock', type: 'MaterialCommunityIcons' },
	{ name: 'folder-clock-outline', type: 'MaterialCommunityIcons' },
	{ name: 'folder-download', type: 'MaterialCommunityIcons' },
	{ name: 'folder-edit', type: 'MaterialCommunityIcons' },
	{ name: 'folder-google-drive', type: 'MaterialCommunityIcons' },
	{ name: 'folder-image', type: 'MaterialCommunityIcons' },
	{ name: 'folder-key', type: 'MaterialCommunityIcons' },
	{ name: 'folder-key-network', type: 'MaterialCommunityIcons' },
	{ name: 'folder-lock', type: 'MaterialCommunityIcons' },
	{ name: 'folder-lock-open', type: 'MaterialCommunityIcons' },
	{ name: 'folder-move', type: 'MaterialCommunityIcons' },
	{ name: 'folder-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'folder-multiple-image', type: 'MaterialCommunityIcons' },
	{ name: 'folder-multiple-outline', type: 'MaterialCommunityIcons' },
	{ name: 'folder-network', type: 'MaterialCommunityIcons' },
	{ name: 'folder-open', type: 'MaterialCommunityIcons' },
	{ name: 'folder-outline', type: 'MaterialCommunityIcons' },
	{ name: 'folder-plus', type: 'MaterialCommunityIcons' },
	{ name: 'folder-remove', type: 'MaterialCommunityIcons' },
	{ name: 'folder-search', type: 'MaterialCommunityIcons' },
	{ name: 'folder-search-outline', type: 'MaterialCommunityIcons' },
	{ name: 'folder-star', type: 'MaterialCommunityIcons' },
	{ name: 'folder-upload', type: 'MaterialCommunityIcons' },
	{ name: 'font-awesome', type: 'MaterialCommunityIcons' },
	{ name: 'food', type: 'MaterialCommunityIcons' },
	{ name: 'food-apple', type: 'MaterialCommunityIcons' },
	{ name: 'food-croissant', type: 'MaterialCommunityIcons' },
	{ name: 'food-fork-drink', type: 'MaterialCommunityIcons' },
	{ name: 'food-off', type: 'MaterialCommunityIcons' },
	{ name: 'food-variant', type: 'MaterialCommunityIcons' },
	{ name: 'football', type: 'MaterialCommunityIcons' },
	{ name: 'football-australian', type: 'MaterialCommunityIcons' },
	{ name: 'football-helmet', type: 'MaterialCommunityIcons' },
	{ name: 'forklift', type: 'MaterialCommunityIcons' },
	{ name: 'format-align-bottom', type: 'MaterialCommunityIcons' },
	{ name: 'format-align-center', type: 'MaterialCommunityIcons' },
	{ name: 'format-align-justify', type: 'MaterialCommunityIcons' },
	{ name: 'format-align-left', type: 'MaterialCommunityIcons' },
	{ name: 'format-align-middle', type: 'MaterialCommunityIcons' },
	{ name: 'format-align-right', type: 'MaterialCommunityIcons' },
	{ name: 'format-align-top', type: 'MaterialCommunityIcons' },
	{ name: 'format-annotation-minus', type: 'MaterialCommunityIcons' },
	{ name: 'format-annotation-plus', type: 'MaterialCommunityIcons' },
	{ name: 'format-bold', type: 'MaterialCommunityIcons' },
	{ name: 'format-clear', type: 'MaterialCommunityIcons' },
	{ name: 'format-color-fill', type: 'MaterialCommunityIcons' },
	{ name: 'format-color-text', type: 'MaterialCommunityIcons' },
	{ name: 'format-columns', type: 'MaterialCommunityIcons' },
	{ name: 'format-float-center', type: 'MaterialCommunityIcons' },
	{ name: 'format-float-left', type: 'MaterialCommunityIcons' },
	{ name: 'format-float-none', type: 'MaterialCommunityIcons' },
	{ name: 'format-float-right', type: 'MaterialCommunityIcons' },
	{ name: 'format-font', type: 'MaterialCommunityIcons' },
	{ name: 'format-font-size-decrease', type: 'MaterialCommunityIcons' },
	{ name: 'format-font-size-increase', type: 'MaterialCommunityIcons' },
	{ name: 'format-header-1', type: 'MaterialCommunityIcons' },
	{ name: 'format-header-2', type: 'MaterialCommunityIcons' },
	{ name: 'format-header-3', type: 'MaterialCommunityIcons' },
	{ name: 'format-header-4', type: 'MaterialCommunityIcons' },
	{ name: 'format-header-5', type: 'MaterialCommunityIcons' },
	{ name: 'format-header-6', type: 'MaterialCommunityIcons' },
	{ name: 'format-header-decrease', type: 'MaterialCommunityIcons' },
	{ name: 'format-header-equal', type: 'MaterialCommunityIcons' },
	{ name: 'format-header-increase', type: 'MaterialCommunityIcons' },
	{ name: 'format-header-pound', type: 'MaterialCommunityIcons' },
	{ name: 'format-horizontal-align-center', type: 'MaterialCommunityIcons' },
	{ name: 'format-horizontal-align-left', type: 'MaterialCommunityIcons' },
	{ name: 'format-horizontal-align-right', type: 'MaterialCommunityIcons' },
	{ name: 'format-indent-decrease', type: 'MaterialCommunityIcons' },
	{ name: 'format-indent-increase', type: 'MaterialCommunityIcons' },
	{ name: 'format-italic', type: 'MaterialCommunityIcons' },
	{ name: 'format-letter-case', type: 'MaterialCommunityIcons' },
	{ name: 'format-letter-case-lower', type: 'MaterialCommunityIcons' },
	{ name: 'format-letter-case-upper', type: 'MaterialCommunityIcons' },
	{ name: 'format-line-spacing', type: 'MaterialCommunityIcons' },
	{ name: 'format-line-style', type: 'MaterialCommunityIcons' },
	{ name: 'format-line-weight', type: 'MaterialCommunityIcons' },
	{ name: 'format-list-bulleted', type: 'MaterialCommunityIcons' },
	{ name: 'format-list-bulleted-type', type: 'MaterialCommunityIcons' },
	{ name: 'format-list-checkbox', type: 'MaterialCommunityIcons' },
	{ name: 'format-list-checks', type: 'MaterialCommunityIcons' },
	{ name: 'format-list-numbers', type: 'MaterialCommunityIcons' },
	{ name: 'format-page-break', type: 'MaterialCommunityIcons' },
	{ name: 'format-paint', type: 'MaterialCommunityIcons' },
	{ name: 'format-paragraph', type: 'MaterialCommunityIcons' },
	{ name: 'format-pilcrow', type: 'MaterialCommunityIcons' },
	{ name: 'format-quote-close', type: 'MaterialCommunityIcons' },
	{ name: 'format-quote-open', type: 'MaterialCommunityIcons' },
	{ name: 'format-rotate-90', type: 'MaterialCommunityIcons' },
	{ name: 'format-section', type: 'MaterialCommunityIcons' },
	{ name: 'format-size', type: 'MaterialCommunityIcons' },
	{ name: 'format-strikethrough', type: 'MaterialCommunityIcons' },
	{ name: 'format-strikethrough-variant', type: 'MaterialCommunityIcons' },
	{ name: 'format-subscript', type: 'MaterialCommunityIcons' },
	{ name: 'format-superscript', type: 'MaterialCommunityIcons' },
	{ name: 'format-text', type: 'MaterialCommunityIcons' },
	{ name: 'format-textdirection-l-to-r', type: 'MaterialCommunityIcons' },
	{ name: 'format-textdirection-r-to-l', type: 'MaterialCommunityIcons' },
	{ name: 'format-title', type: 'MaterialCommunityIcons' },
	{ name: 'format-underline', type: 'MaterialCommunityIcons' },
	{ name: 'format-vertical-align-bottom', type: 'MaterialCommunityIcons' },
	{ name: 'format-vertical-align-center', type: 'MaterialCommunityIcons' },
	{ name: 'format-vertical-align-top', type: 'MaterialCommunityIcons' },
	{ name: 'format-wrap-inline', type: 'MaterialCommunityIcons' },
	{ name: 'format-wrap-square', type: 'MaterialCommunityIcons' },
	{ name: 'format-wrap-tight', type: 'MaterialCommunityIcons' },
	{ name: 'format-wrap-top-bottom', type: 'MaterialCommunityIcons' },
	{ name: 'forum', type: 'MaterialCommunityIcons' },
	{ name: 'forum-outline', type: 'MaterialCommunityIcons' },
	{ name: 'forward', type: 'MaterialCommunityIcons' },
	{ name: 'fountain', type: 'MaterialCommunityIcons' },
	{ name: 'foursquare', type: 'MaterialCommunityIcons' },
	{ name: 'freebsd', type: 'MaterialCommunityIcons' },
	{ name: 'fridge', type: 'MaterialCommunityIcons' },
	{ name: 'fridge-filled', type: 'MaterialCommunityIcons' },
	{ name: 'fridge-filled-bottom', type: 'MaterialCommunityIcons' },
	{ name: 'fridge-filled-top', type: 'MaterialCommunityIcons' },
	{ name: 'fuel', type: 'MaterialCommunityIcons' },
	{ name: 'fullscreen', type: 'MaterialCommunityIcons' },
	{ name: 'fullscreen-exit', type: 'MaterialCommunityIcons' },
	{ name: 'function', type: 'MaterialCommunityIcons' },
	{ name: 'function-variant', type: 'MaterialCommunityIcons' },
	{ name: 'gamepad', type: 'MaterialCommunityIcons' },
	{ name: 'gamepad-variant', type: 'MaterialCommunityIcons' },
	{ name: 'garage', type: 'MaterialCommunityIcons' },
	{ name: 'garage-alert', type: 'MaterialCommunityIcons' },
	{ name: 'garage-open', type: 'MaterialCommunityIcons' },
	{ name: 'gas-cylinder', type: 'MaterialCommunityIcons' },
	{ name: 'gas-station', type: 'MaterialCommunityIcons' },
	{ name: 'gate', type: 'MaterialCommunityIcons' },
	{ name: 'gate-and', type: 'MaterialCommunityIcons' },
	{ name: 'gate-nand', type: 'MaterialCommunityIcons' },
	{ name: 'gate-nor', type: 'MaterialCommunityIcons' },
	{ name: 'gate-not', type: 'MaterialCommunityIcons' },
	{ name: 'gate-or', type: 'MaterialCommunityIcons' },
	{ name: 'gate-xnor', type: 'MaterialCommunityIcons' },
	{ name: 'gate-xor', type: 'MaterialCommunityIcons' },
	{ name: 'gauge', type: 'MaterialCommunityIcons' },
	{ name: 'gauge-empty', type: 'MaterialCommunityIcons' },
	{ name: 'gauge-full', type: 'MaterialCommunityIcons' },
	{ name: 'gauge-low', type: 'MaterialCommunityIcons' },
	{ name: 'gavel', type: 'MaterialCommunityIcons' },
	{ name: 'gender-female', type: 'MaterialCommunityIcons' },
	{ name: 'gender-male', type: 'MaterialCommunityIcons' },
	{ name: 'gender-male-female', type: 'MaterialCommunityIcons' },
	{ name: 'gender-transgender', type: 'MaterialCommunityIcons' },
	{ name: 'gentoo', type: 'MaterialCommunityIcons' },
	{ name: 'gesture', type: 'MaterialCommunityIcons' },
	{ name: 'gesture-double-tap', type: 'MaterialCommunityIcons' },
	{ name: 'gesture-pinch', type: 'MaterialCommunityIcons' },
	{ name: 'gesture-spread', type: 'MaterialCommunityIcons' },
	{ name: 'gesture-swipe-down', type: 'MaterialCommunityIcons' },
	{ name: 'gesture-swipe-horizontal', type: 'MaterialCommunityIcons' },
	{ name: 'gesture-swipe-left', type: 'MaterialCommunityIcons' },
	{ name: 'gesture-swipe-right', type: 'MaterialCommunityIcons' },
	{ name: 'gesture-swipe-up', type: 'MaterialCommunityIcons' },
	{ name: 'gesture-swipe-vertical', type: 'MaterialCommunityIcons' },
	{ name: 'gesture-tap', type: 'MaterialCommunityIcons' },
	{ name: 'gesture-two-double-tap', type: 'MaterialCommunityIcons' },
	{ name: 'gesture-two-tap', type: 'MaterialCommunityIcons' },
	{ name: 'ghost', type: 'MaterialCommunityIcons' },
	{ name: 'ghost-off', type: 'MaterialCommunityIcons' },
	{ name: 'gift', type: 'MaterialCommunityIcons' },
	{ name: 'git', type: 'MaterialCommunityIcons' },
	{ name: 'github-box', type: 'MaterialCommunityIcons' },
	{ name: 'github-circle', type: 'MaterialCommunityIcons' },
	{ name: 'github-face', type: 'MaterialCommunityIcons' },
	{ name: 'glass-cocktail', type: 'MaterialCommunityIcons' },
	{ name: 'glass-flute', type: 'MaterialCommunityIcons' },
	{ name: 'glass-mug', type: 'MaterialCommunityIcons' },
	{ name: 'glass-stange', type: 'MaterialCommunityIcons' },
	{ name: 'glass-tulip', type: 'MaterialCommunityIcons' },
	{ name: 'glass-wine', type: 'MaterialCommunityIcons' },
	{ name: 'glassdoor', type: 'MaterialCommunityIcons' },
	{ name: 'glasses', type: 'MaterialCommunityIcons' },
	{ name: 'globe-model', type: 'MaterialCommunityIcons' },
	{ name: 'gmail', type: 'MaterialCommunityIcons' },
	{ name: 'gnome', type: 'MaterialCommunityIcons' },
	{ name: 'golf', type: 'MaterialCommunityIcons' },
	{ name: 'gondola', type: 'MaterialCommunityIcons' },
	{ name: 'google', type: 'MaterialCommunityIcons' },
	{ name: 'google-allo', type: 'MaterialCommunityIcons' },
	{ name: 'google-analytics', type: 'MaterialCommunityIcons' },
	{ name: 'google-assistant', type: 'MaterialCommunityIcons' },
	{ name: 'google-cardboard', type: 'MaterialCommunityIcons' },
	{ name: 'google-chrome', type: 'MaterialCommunityIcons' },
	{ name: 'google-circles', type: 'MaterialCommunityIcons' },
	{ name: 'google-circles-communities', type: 'MaterialCommunityIcons' },
	{ name: 'google-circles-extended', type: 'MaterialCommunityIcons' },
	{ name: 'google-circles-group', type: 'MaterialCommunityIcons' },
	{ name: 'google-controller', type: 'MaterialCommunityIcons' },
	{ name: 'google-controller-off', type: 'MaterialCommunityIcons' },
	{ name: 'google-drive', type: 'MaterialCommunityIcons' },
	{ name: 'google-earth', type: 'MaterialCommunityIcons' },
	{ name: 'google-fit', type: 'MaterialCommunityIcons' },
	{ name: 'google-glass', type: 'MaterialCommunityIcons' },
	{ name: 'google-hangouts', type: 'MaterialCommunityIcons' },
	{ name: 'google-home', type: 'MaterialCommunityIcons' },
	{ name: 'google-keep', type: 'MaterialCommunityIcons' },
	{ name: 'google-lens', type: 'MaterialCommunityIcons' },
	{ name: 'google-maps', type: 'MaterialCommunityIcons' },
	{ name: 'google-nearby', type: 'MaterialCommunityIcons' },
	{ name: 'google-pages', type: 'MaterialCommunityIcons' },
	{ name: 'google-photos', type: 'MaterialCommunityIcons' },
	{ name: 'google-physical-web', type: 'MaterialCommunityIcons' },
	{ name: 'google-play', type: 'MaterialCommunityIcons' },
	{ name: 'google-plus', type: 'MaterialCommunityIcons' },
	{ name: 'google-plus-box', type: 'MaterialCommunityIcons' },
	{ name: 'google-spreadsheet', type: 'MaterialCommunityIcons' },
	{ name: 'google-translate', type: 'MaterialCommunityIcons' },
	{ name: 'google-wallet', type: 'MaterialCommunityIcons' },
	{ name: 'gpu', type: 'MaterialCommunityIcons' },
	{ name: 'gradient', type: 'MaterialCommunityIcons' },
	{ name: 'graphql', type: 'MaterialCommunityIcons' },
	{ name: 'grease-pencil', type: 'MaterialCommunityIcons' },
	{ name: 'greater-than', type: 'MaterialCommunityIcons' },
	{ name: 'greater-than-or-equal', type: 'MaterialCommunityIcons' },
	{ name: 'grid', type: 'MaterialCommunityIcons' },
	{ name: 'grid-large', type: 'MaterialCommunityIcons' },
	{ name: 'grid-off', type: 'MaterialCommunityIcons' },
	{ name: 'group', type: 'MaterialCommunityIcons' },
	{ name: 'guitar-acoustic', type: 'MaterialCommunityIcons' },
	{ name: 'guitar-electric', type: 'MaterialCommunityIcons' },
	{ name: 'guitar-pick', type: 'MaterialCommunityIcons' },
	{ name: 'guitar-pick-outline', type: 'MaterialCommunityIcons' },
	{ name: 'guy-fawkes-mask', type: 'MaterialCommunityIcons' },
	{ name: 'hackernews', type: 'MaterialCommunityIcons' },
	{ name: 'hail', type: 'MaterialCommunityIcons' },
	{ name: 'hamburger', type: 'MaterialCommunityIcons' },
	{ name: 'hammer', type: 'MaterialCommunityIcons' },
	{ name: 'hand', type: 'MaterialCommunityIcons' },
	{ name: 'hand-okay', type: 'MaterialCommunityIcons' },
	{ name: 'hand-peace', type: 'MaterialCommunityIcons' },
	{ name: 'hand-peace-variant', type: 'MaterialCommunityIcons' },
	{ name: 'hand-pointing-down', type: 'MaterialCommunityIcons' },
	{ name: 'hand-pointing-left', type: 'MaterialCommunityIcons' },
	{ name: 'hand-pointing-right', type: 'MaterialCommunityIcons' },
	{ name: 'hand-pointing-up', type: 'MaterialCommunityIcons' },
	{ name: 'hanger', type: 'MaterialCommunityIcons' },
	{ name: 'hard-hat', type: 'MaterialCommunityIcons' },
	{ name: 'harddisk', type: 'MaterialCommunityIcons' },
	{ name: 'headphones', type: 'MaterialCommunityIcons' },
	{ name: 'headphones-bluetooth', type: 'MaterialCommunityIcons' },
	{ name: 'headphones-box', type: 'MaterialCommunityIcons' },
	{ name: 'headphones-off', type: 'MaterialCommunityIcons' },
	{ name: 'headphones-settings', type: 'MaterialCommunityIcons' },
	{ name: 'headset', type: 'MaterialCommunityIcons' },
	{ name: 'headset-dock', type: 'MaterialCommunityIcons' },
	{ name: 'headset-off', type: 'MaterialCommunityIcons' },
	{ name: 'heart', type: 'MaterialCommunityIcons' },
	{ name: 'heart-box', type: 'MaterialCommunityIcons' },
	{ name: 'heart-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'heart-broken', type: 'MaterialCommunityIcons' },
	{ name: 'heart-circle', type: 'MaterialCommunityIcons' },
	{ name: 'heart-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'heart-half', type: 'MaterialCommunityIcons' },
	{ name: 'heart-half-full', type: 'MaterialCommunityIcons' },
	{ name: 'heart-half-outline', type: 'MaterialCommunityIcons' },
	{ name: 'heart-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'heart-multiple-outline', type: 'MaterialCommunityIcons' },
	{ name: 'heart-off', type: 'MaterialCommunityIcons' },
	{ name: 'heart-outline', type: 'MaterialCommunityIcons' },
	{ name: 'heart-pulse', type: 'MaterialCommunityIcons' },
	{ name: 'helicopter', type: 'MaterialCommunityIcons' },
	{ name: 'help', type: 'MaterialCommunityIcons' },
	{ name: 'help-box', type: 'MaterialCommunityIcons' },
	{ name: 'help-circle', type: 'MaterialCommunityIcons' },
	{ name: 'help-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'help-network', type: 'MaterialCommunityIcons' },
	{ name: 'hexagon', type: 'MaterialCommunityIcons' },
	{ name: 'hexagon-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'hexagon-outline', type: 'MaterialCommunityIcons' },
	{ name: 'hexagon-slice-1', type: 'MaterialCommunityIcons' },
	{ name: 'hexagon-slice-2', type: 'MaterialCommunityIcons' },
	{ name: 'hexagon-slice-3', type: 'MaterialCommunityIcons' },
	{ name: 'hexagon-slice-4', type: 'MaterialCommunityIcons' },
	{ name: 'hexagon-slice-5', type: 'MaterialCommunityIcons' },
	{ name: 'hexagon-slice-6', type: 'MaterialCommunityIcons' },
	{ name: 'hexagram', type: 'MaterialCommunityIcons' },
	{ name: 'hexagram-outline', type: 'MaterialCommunityIcons' },
	{ name: 'high-definition', type: 'MaterialCommunityIcons' },
	{ name: 'high-definition-box', type: 'MaterialCommunityIcons' },
	{ name: 'highway', type: 'MaterialCommunityIcons' },
	{ name: 'hinduism', type: 'MaterialCommunityIcons' },
	{ name: 'history', type: 'MaterialCommunityIcons' },
	{ name: 'hockey-puck', type: 'MaterialCommunityIcons' },
	{ name: 'hockey-sticks', type: 'MaterialCommunityIcons' },
	{ name: 'hololens', type: 'MaterialCommunityIcons' },
	{ name: 'home', type: 'MaterialCommunityIcons' },
	{ name: 'home-account', type: 'MaterialCommunityIcons' },
	{ name: 'home-alert', type: 'MaterialCommunityIcons' },
	{ name: 'home-assistant', type: 'MaterialCommunityIcons' },
	{ name: 'home-automation', type: 'MaterialCommunityIcons' },
	{ name: 'home-circle', type: 'MaterialCommunityIcons' },
	{ name: 'home-currency-usd', type: 'MaterialCommunityIcons' },
	{ name: 'home-heart', type: 'MaterialCommunityIcons' },
	{ name: 'home-lock', type: 'MaterialCommunityIcons' },
	{ name: 'home-lock-open', type: 'MaterialCommunityIcons' },
	{ name: 'home-map-marker', type: 'MaterialCommunityIcons' },
	{ name: 'home-minus', type: 'MaterialCommunityIcons' },
	{ name: 'home-modern', type: 'MaterialCommunityIcons' },
	{ name: 'home-outline', type: 'MaterialCommunityIcons' },
	{ name: 'home-plus', type: 'MaterialCommunityIcons' },
	{ name: 'home-variant', type: 'MaterialCommunityIcons' },
	{ name: 'hook', type: 'MaterialCommunityIcons' },
	{ name: 'hook-off', type: 'MaterialCommunityIcons' },
	{ name: 'hops', type: 'MaterialCommunityIcons' },
	{ name: 'horseshoe', type: 'MaterialCommunityIcons' },
	{ name: 'hospital', type: 'MaterialCommunityIcons' },
	{ name: 'hospital-building', type: 'MaterialCommunityIcons' },
	{ name: 'hospital-marker', type: 'MaterialCommunityIcons' },
	{ name: 'hot-tub', type: 'MaterialCommunityIcons' },
	{ name: 'hotel', type: 'MaterialCommunityIcons' },
	{ name: 'houzz', type: 'MaterialCommunityIcons' },
	{ name: 'houzz-box', type: 'MaterialCommunityIcons' },
	{ name: 'hulu', type: 'MaterialCommunityIcons' },
	{ name: 'human', type: 'MaterialCommunityIcons' },
	{ name: 'human-child', type: 'MaterialCommunityIcons' },
	{ name: 'human-female', type: 'MaterialCommunityIcons' },
	{ name: 'human-female-boy', type: 'MaterialCommunityIcons' },
	{ name: 'human-female-female', type: 'MaterialCommunityIcons' },
	{ name: 'human-female-girl', type: 'MaterialCommunityIcons' },
	{ name: 'human-greeting', type: 'MaterialCommunityIcons' },
	{ name: 'human-handsdown', type: 'MaterialCommunityIcons' },
	{ name: 'human-handsup', type: 'MaterialCommunityIcons' },
	{ name: 'human-male', type: 'MaterialCommunityIcons' },
	{ name: 'human-male-boy', type: 'MaterialCommunityIcons' },
	{ name: 'human-male-female', type: 'MaterialCommunityIcons' },
	{ name: 'human-male-girl', type: 'MaterialCommunityIcons' },
	{ name: 'human-male-male', type: 'MaterialCommunityIcons' },
	{ name: 'human-pregnant', type: 'MaterialCommunityIcons' },
	{ name: 'humble-bundle', type: 'MaterialCommunityIcons' },
	{ name: 'ice-cream', type: 'MaterialCommunityIcons' },
	{ name: 'image', type: 'MaterialCommunityIcons' },
	{ name: 'image-album', type: 'MaterialCommunityIcons' },
	{ name: 'image-area', type: 'MaterialCommunityIcons' },
	{ name: 'image-area-close', type: 'MaterialCommunityIcons' },
	{ name: 'image-broken', type: 'MaterialCommunityIcons' },
	{ name: 'image-broken-variant', type: 'MaterialCommunityIcons' },
	{ name: 'image-filter', type: 'MaterialCommunityIcons' },
	{ name: 'image-filter-black-white', type: 'MaterialCommunityIcons' },
	{ name: 'image-filter-center-focus', type: 'MaterialCommunityIcons' },
	{ name: 'image-filter-center-focus-weak', type: 'MaterialCommunityIcons' },
	{ name: 'image-filter-drama', type: 'MaterialCommunityIcons' },
	{ name: 'image-filter-frames', type: 'MaterialCommunityIcons' },
	{ name: 'image-filter-hdr', type: 'MaterialCommunityIcons' },
	{ name: 'image-filter-none', type: 'MaterialCommunityIcons' },
	{ name: 'image-filter-tilt-shift', type: 'MaterialCommunityIcons' },
	{ name: 'image-filter-vintage', type: 'MaterialCommunityIcons' },
	{ name: 'image-move', type: 'MaterialCommunityIcons' },
	{ name: 'image-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'image-off', type: 'MaterialCommunityIcons' },
	{ name: 'image-outline', type: 'MaterialCommunityIcons' },
	{ name: 'image-plus', type: 'MaterialCommunityIcons' },
	{ name: 'image-search', type: 'MaterialCommunityIcons' },
	{ name: 'image-search-outline', type: 'MaterialCommunityIcons' },
	{ name: 'import', type: 'MaterialCommunityIcons' },
	{ name: 'inbox', type: 'MaterialCommunityIcons' },
	{ name: 'inbox-arrow-down', type: 'MaterialCommunityIcons' },
	{ name: 'inbox-arrow-up', type: 'MaterialCommunityIcons' },
	{ name: 'inbox-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'incognito', type: 'MaterialCommunityIcons' },
	{ name: 'infinity', type: 'MaterialCommunityIcons' },
	{ name: 'information', type: 'MaterialCommunityIcons' },
	{ name: 'information-outline', type: 'MaterialCommunityIcons' },
	{ name: 'information-variant', type: 'MaterialCommunityIcons' },
	{ name: 'instagram', type: 'MaterialCommunityIcons' },
	{ name: 'instapaper', type: 'MaterialCommunityIcons' },
	{ name: 'internet-explorer', type: 'MaterialCommunityIcons' },
	{ name: 'invert-colors', type: 'MaterialCommunityIcons' },
	{ name: 'ip', type: 'MaterialCommunityIcons' },
	{ name: 'ip-network', type: 'MaterialCommunityIcons' },
	{ name: 'islam', type: 'MaterialCommunityIcons' },
	{ name: 'itunes', type: 'MaterialCommunityIcons' },
	{ name: 'jeepney', type: 'MaterialCommunityIcons' },
	{ name: 'jira', type: 'MaterialCommunityIcons' },
	{ name: 'jquery', type: 'MaterialCommunityIcons' },
	{ name: 'jsfiddle', type: 'MaterialCommunityIcons' },
	{ name: 'json', type: 'MaterialCommunityIcons' },
	{ name: 'judaism', type: 'MaterialCommunityIcons' },
	{ name: 'karate', type: 'MaterialCommunityIcons' },
	{ name: 'keg', type: 'MaterialCommunityIcons' },
	{ name: 'kettle', type: 'MaterialCommunityIcons' },
	{ name: 'key', type: 'MaterialCommunityIcons' },
	{ name: 'key-change', type: 'MaterialCommunityIcons' },
	{ name: 'key-minus', type: 'MaterialCommunityIcons' },
	{ name: 'key-plus', type: 'MaterialCommunityIcons' },
	{ name: 'key-remove', type: 'MaterialCommunityIcons' },
	{ name: 'key-variant', type: 'MaterialCommunityIcons' },
	{ name: 'keyboard', type: 'MaterialCommunityIcons' },
	{ name: 'keyboard-backspace', type: 'MaterialCommunityIcons' },
	{ name: 'keyboard-caps', type: 'MaterialCommunityIcons' },
	{ name: 'keyboard-close', type: 'MaterialCommunityIcons' },
	{ name: 'keyboard-off', type: 'MaterialCommunityIcons' },
	{ name: 'keyboard-outline', type: 'MaterialCommunityIcons' },
	{ name: 'keyboard-return', type: 'MaterialCommunityIcons' },
	{ name: 'keyboard-settings', type: 'MaterialCommunityIcons' },
	{ name: 'keyboard-settings-outline', type: 'MaterialCommunityIcons' },
	{ name: 'keyboard-tab', type: 'MaterialCommunityIcons' },
	{ name: 'keyboard-variant', type: 'MaterialCommunityIcons' },
	{ name: 'kickstarter', type: 'MaterialCommunityIcons' },
	{ name: 'knife', type: 'MaterialCommunityIcons' },
	{ name: 'knife-military', type: 'MaterialCommunityIcons' },
	{ name: 'kodi', type: 'MaterialCommunityIcons' },
	{ name: 'label', type: 'MaterialCommunityIcons' },
	{ name: 'label-off', type: 'MaterialCommunityIcons' },
	{ name: 'label-off-outline', type: 'MaterialCommunityIcons' },
	{ name: 'label-outline', type: 'MaterialCommunityIcons' },
	{ name: 'label-variant', type: 'MaterialCommunityIcons' },
	{ name: 'label-variant-outline', type: 'MaterialCommunityIcons' },
	{ name: 'ladybug', type: 'MaterialCommunityIcons' },
	{ name: 'lambda', type: 'MaterialCommunityIcons' },
	{ name: 'lamp', type: 'MaterialCommunityIcons' },
	{ name: 'lan', type: 'MaterialCommunityIcons' },
	{ name: 'lan-connect', type: 'MaterialCommunityIcons' },
	{ name: 'lan-disconnect', type: 'MaterialCommunityIcons' },
	{ name: 'lan-pending', type: 'MaterialCommunityIcons' },
	{ name: 'language-c', type: 'MaterialCommunityIcons' },
	{ name: 'language-cpp', type: 'MaterialCommunityIcons' },
	{ name: 'language-csharp', type: 'MaterialCommunityIcons' },
	{ name: 'language-css3', type: 'MaterialCommunityIcons' },
	{ name: 'language-go', type: 'MaterialCommunityIcons' },
	{ name: 'language-html5', type: 'MaterialCommunityIcons' },
	{ name: 'language-java', type: 'MaterialCommunityIcons' },
	{ name: 'language-javascript', type: 'MaterialCommunityIcons' },
	{ name: 'language-lua', type: 'MaterialCommunityIcons' },
	{ name: 'language-php', type: 'MaterialCommunityIcons' },
	{ name: 'language-python', type: 'MaterialCommunityIcons' },
	{ name: 'language-python-text', type: 'MaterialCommunityIcons' },
	{ name: 'language-r', type: 'MaterialCommunityIcons' },
	{ name: 'language-ruby-on-rails', type: 'MaterialCommunityIcons' },
	{ name: 'language-swift', type: 'MaterialCommunityIcons' },
	{ name: 'language-typescript', type: 'MaterialCommunityIcons' },
	{ name: 'laptop', type: 'MaterialCommunityIcons' },
	{ name: 'laptop-chromebook', type: 'MaterialCommunityIcons' },
	{ name: 'laptop-mac', type: 'MaterialCommunityIcons' },
	{ name: 'laptop-off', type: 'MaterialCommunityIcons' },
	{ name: 'laptop-windows', type: 'MaterialCommunityIcons' },
	{ name: 'laravel', type: 'MaterialCommunityIcons' },
	{ name: 'lastfm', type: 'MaterialCommunityIcons' },
	{ name: 'lastpass', type: 'MaterialCommunityIcons' },
	{ name: 'launch', type: 'MaterialCommunityIcons' },
	{ name: 'lava-lamp', type: 'MaterialCommunityIcons' },
	{ name: 'layers', type: 'MaterialCommunityIcons' },
	{ name: 'layers-off', type: 'MaterialCommunityIcons' },
	{ name: 'layers-off-outline', type: 'MaterialCommunityIcons' },
	{ name: 'layers-outline', type: 'MaterialCommunityIcons' },
	{ name: 'lead-pencil', type: 'MaterialCommunityIcons' },
	{ name: 'leaf', type: 'MaterialCommunityIcons' },
	{ name: 'led-off', type: 'MaterialCommunityIcons' },
	{ name: 'led-on', type: 'MaterialCommunityIcons' },
	{ name: 'led-outline', type: 'MaterialCommunityIcons' },
	{ name: 'led-strip', type: 'MaterialCommunityIcons' },
	{ name: 'led-variant-off', type: 'MaterialCommunityIcons' },
	{ name: 'led-variant-on', type: 'MaterialCommunityIcons' },
	{ name: 'led-variant-outline', type: 'MaterialCommunityIcons' },
	{ name: 'less-than', type: 'MaterialCommunityIcons' },
	{ name: 'less-than-or-equal', type: 'MaterialCommunityIcons' },
	{ name: 'library', type: 'MaterialCommunityIcons' },
	{ name: 'library-books', type: 'MaterialCommunityIcons' },
	{ name: 'library-music', type: 'MaterialCommunityIcons' },
	{ name: 'library-plus', type: 'MaterialCommunityIcons' },
	{ name: 'lifebuoy', type: 'MaterialCommunityIcons' },
	{ name: 'light-switch', type: 'MaterialCommunityIcons' },
	{ name: 'lightbulb', type: 'MaterialCommunityIcons' },
	{ name: 'lightbulb-on', type: 'MaterialCommunityIcons' },
	{ name: 'lightbulb-on-outline', type: 'MaterialCommunityIcons' },
	{ name: 'lightbulb-outline', type: 'MaterialCommunityIcons' },
	{ name: 'lighthouse', type: 'MaterialCommunityIcons' },
	{ name: 'lighthouse-on', type: 'MaterialCommunityIcons' },
	{ name: 'link', type: 'MaterialCommunityIcons' },
	{ name: 'link-off', type: 'MaterialCommunityIcons' },
	{ name: 'link-variant', type: 'MaterialCommunityIcons' },
	{ name: 'link-variant-off', type: 'MaterialCommunityIcons' },
	{ name: 'linkedin', type: 'MaterialCommunityIcons' },
	{ name: 'linkedin-box', type: 'MaterialCommunityIcons' },
	{ name: 'linux', type: 'MaterialCommunityIcons' },
	{ name: 'linux-mint', type: 'MaterialCommunityIcons' },
	{ name: 'litecoin', type: 'MaterialCommunityIcons' },
	{ name: 'loading', type: 'MaterialCommunityIcons' },
	{ name: 'lock', type: 'MaterialCommunityIcons' },
	{ name: 'lock-alert', type: 'MaterialCommunityIcons' },
	{ name: 'lock-clock', type: 'MaterialCommunityIcons' },
	{ name: 'lock-open', type: 'MaterialCommunityIcons' },
	{ name: 'lock-open-outline', type: 'MaterialCommunityIcons' },
	{ name: 'lock-outline', type: 'MaterialCommunityIcons' },
	{ name: 'lock-pattern', type: 'MaterialCommunityIcons' },
	{ name: 'lock-plus', type: 'MaterialCommunityIcons' },
	{ name: 'lock-question', type: 'MaterialCommunityIcons' },
	{ name: 'lock-reset', type: 'MaterialCommunityIcons' },
	{ name: 'lock-smart', type: 'MaterialCommunityIcons' },
	{ name: 'locker', type: 'MaterialCommunityIcons' },
	{ name: 'locker-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'login', type: 'MaterialCommunityIcons' },
	{ name: 'login-variant', type: 'MaterialCommunityIcons' },
	{ name: 'logout', type: 'MaterialCommunityIcons' },
	{ name: 'logout-variant', type: 'MaterialCommunityIcons' },
	{ name: 'looks', type: 'MaterialCommunityIcons' },
	{ name: 'loop', type: 'MaterialCommunityIcons' },
	{ name: 'loupe', type: 'MaterialCommunityIcons' },
	{ name: 'lumx', type: 'MaterialCommunityIcons' },
	{ name: 'lyft', type: 'MaterialCommunityIcons' },
	{ name: 'magnet', type: 'MaterialCommunityIcons' },
	{ name: 'magnet-on', type: 'MaterialCommunityIcons' },
	{ name: 'magnify', type: 'MaterialCommunityIcons' },
	{ name: 'magnify-close', type: 'MaterialCommunityIcons' },
	{ name: 'magnify-minus', type: 'MaterialCommunityIcons' },
	{ name: 'magnify-minus-cursor', type: 'MaterialCommunityIcons' },
	{ name: 'magnify-minus-outline', type: 'MaterialCommunityIcons' },
	{ name: 'magnify-plus', type: 'MaterialCommunityIcons' },
	{ name: 'magnify-plus-cursor', type: 'MaterialCommunityIcons' },
	{ name: 'magnify-plus-outline', type: 'MaterialCommunityIcons' },
	{ name: 'mail-ru', type: 'MaterialCommunityIcons' },
	{ name: 'mailbox', type: 'MaterialCommunityIcons' },
	{ name: 'map', type: 'MaterialCommunityIcons' },
	{ name: 'map-legend', type: 'MaterialCommunityIcons' },
	{ name: 'map-marker', type: 'MaterialCommunityIcons' },
	{ name: 'map-marker-circle', type: 'MaterialCommunityIcons' },
	{ name: 'map-marker-distance', type: 'MaterialCommunityIcons' },
	{ name: 'map-marker-minus', type: 'MaterialCommunityIcons' },
	{ name: 'map-marker-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'map-marker-off', type: 'MaterialCommunityIcons' },
	{ name: 'map-marker-outline', type: 'MaterialCommunityIcons' },
	{ name: 'map-marker-plus', type: 'MaterialCommunityIcons' },
	{ name: 'map-marker-radius', type: 'MaterialCommunityIcons' },
	{ name: 'map-minus', type: 'MaterialCommunityIcons' },
	{ name: 'map-outline', type: 'MaterialCommunityIcons' },
	{ name: 'map-plus', type: 'MaterialCommunityIcons' },
	{ name: 'map-search', type: 'MaterialCommunityIcons' },
	{ name: 'map-search-outline', type: 'MaterialCommunityIcons' },
	{ name: 'margin', type: 'MaterialCommunityIcons' },
	{ name: 'markdown', type: 'MaterialCommunityIcons' },
	{ name: 'marker', type: 'MaterialCommunityIcons' },
	{ name: 'marker-check', type: 'MaterialCommunityIcons' },
	{ name: 'mastodon', type: 'MaterialCommunityIcons' },
	{ name: 'mastodon-variant', type: 'MaterialCommunityIcons' },
	{ name: 'material-design', type: 'MaterialCommunityIcons' },
	{ name: 'material-ui', type: 'MaterialCommunityIcons' },
	{ name: 'math-compass', type: 'MaterialCommunityIcons' },
	{ name: 'matrix', type: 'MaterialCommunityIcons' },
	{ name: 'maxcdn', type: 'MaterialCommunityIcons' },
	{ name: 'medal', type: 'MaterialCommunityIcons' },
	{ name: 'medical-bag', type: 'MaterialCommunityIcons' },
	{ name: 'medium', type: 'MaterialCommunityIcons' },
	{ name: 'meetup', type: 'MaterialCommunityIcons' },
	{ name: 'memory', type: 'MaterialCommunityIcons' },
	{ name: 'menu', type: 'MaterialCommunityIcons' },
	{ name: 'menu-down', type: 'MaterialCommunityIcons' },
	{ name: 'menu-down-outline', type: 'MaterialCommunityIcons' },
	{ name: 'menu-left', type: 'MaterialCommunityIcons' },
	{ name: 'menu-left-outline', type: 'MaterialCommunityIcons' },
	{ name: 'menu-right', type: 'MaterialCommunityIcons' },
	{ name: 'menu-right-outline', type: 'MaterialCommunityIcons' },
	{ name: 'menu-swap', type: 'MaterialCommunityIcons' },
	{ name: 'menu-swap-outline', type: 'MaterialCommunityIcons' },
	{ name: 'menu-up', type: 'MaterialCommunityIcons' },
	{ name: 'menu-up-outline', type: 'MaterialCommunityIcons' },
	{ name: 'message', type: 'MaterialCommunityIcons' },
	{ name: 'message-alert', type: 'MaterialCommunityIcons' },
	{ name: 'message-alert-outline', type: 'MaterialCommunityIcons' },
	{ name: 'message-bulleted', type: 'MaterialCommunityIcons' },
	{ name: 'message-bulleted-off', type: 'MaterialCommunityIcons' },
	{ name: 'message-draw', type: 'MaterialCommunityIcons' },
	{ name: 'message-image', type: 'MaterialCommunityIcons' },
	{ name: 'message-outline', type: 'MaterialCommunityIcons' },
	{ name: 'message-plus', type: 'MaterialCommunityIcons' },
	{ name: 'message-processing', type: 'MaterialCommunityIcons' },
	{ name: 'message-reply', type: 'MaterialCommunityIcons' },
	{ name: 'message-reply-text', type: 'MaterialCommunityIcons' },
	{ name: 'message-settings', type: 'MaterialCommunityIcons' },
	{ name: 'message-settings-variant', type: 'MaterialCommunityIcons' },
	{ name: 'message-text', type: 'MaterialCommunityIcons' },
	{ name: 'message-text-outline', type: 'MaterialCommunityIcons' },
	{ name: 'message-video', type: 'MaterialCommunityIcons' },
	{ name: 'meteor', type: 'MaterialCommunityIcons' },
	{ name: 'metronome', type: 'MaterialCommunityIcons' },
	{ name: 'metronome-tick', type: 'MaterialCommunityIcons' },
	{ name: 'micro-sd', type: 'MaterialCommunityIcons' },
	{ name: 'microphone', type: 'MaterialCommunityIcons' },
	{ name: 'microphone-minus', type: 'MaterialCommunityIcons' },
	{ name: 'microphone-off', type: 'MaterialCommunityIcons' },
	{ name: 'microphone-outline', type: 'MaterialCommunityIcons' },
	{ name: 'microphone-plus', type: 'MaterialCommunityIcons' },
	{ name: 'microphone-settings', type: 'MaterialCommunityIcons' },
	{ name: 'microphone-variant', type: 'MaterialCommunityIcons' },
	{ name: 'microphone-variant-off', type: 'MaterialCommunityIcons' },
	{ name: 'microscope', type: 'MaterialCommunityIcons' },
	{ name: 'microsoft', type: 'MaterialCommunityIcons' },
	{ name: 'microsoft-dynamics', type: 'MaterialCommunityIcons' },
	{ name: 'midi', type: 'MaterialCommunityIcons' },
	{ name: 'midi-port', type: 'MaterialCommunityIcons' },
	{ name: 'minecraft', type: 'MaterialCommunityIcons' },
	{ name: 'mini-sd', type: 'MaterialCommunityIcons' },
	{ name: 'minidisc', type: 'MaterialCommunityIcons' },
	{ name: 'minus', type: 'MaterialCommunityIcons' },
	{ name: 'minus-box', type: 'MaterialCommunityIcons' },
	{ name: 'minus-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'minus-circle', type: 'MaterialCommunityIcons' },
	{ name: 'minus-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'minus-network', type: 'MaterialCommunityIcons' },
	{ name: 'mixcloud', type: 'MaterialCommunityIcons' },
	{ name: 'mixed-reality', type: 'MaterialCommunityIcons' },
	{ name: 'mixer', type: 'MaterialCommunityIcons' },
	{ name: 'monitor', type: 'MaterialCommunityIcons' },
	{ name: 'monitor-cellphone', type: 'MaterialCommunityIcons' },
	{ name: 'monitor-cellphone-star', type: 'MaterialCommunityIcons' },
	{ name: 'monitor-dashboard', type: 'MaterialCommunityIcons' },
	{ name: 'monitor-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'more', type: 'MaterialCommunityIcons' },
	{ name: 'motorbike', type: 'MaterialCommunityIcons' },
	{ name: 'mouse', type: 'MaterialCommunityIcons' },
	{ name: 'mouse-bluetooth', type: 'MaterialCommunityIcons' },
	{ name: 'mouse-off', type: 'MaterialCommunityIcons' },
	{ name: 'mouse-variant', type: 'MaterialCommunityIcons' },
	{ name: 'mouse-variant-off', type: 'MaterialCommunityIcons' },
	{ name: 'move-resize', type: 'MaterialCommunityIcons' },
	{ name: 'move-resize-variant', type: 'MaterialCommunityIcons' },
	{ name: 'movie', type: 'MaterialCommunityIcons' },
	{ name: 'movie-roll', type: 'MaterialCommunityIcons' },
	{ name: 'muffin', type: 'MaterialCommunityIcons' },
	{ name: 'multiplication', type: 'MaterialCommunityIcons' },
	{ name: 'multiplication-box', type: 'MaterialCommunityIcons' },
	{ name: 'mushroom', type: 'MaterialCommunityIcons' },
	{ name: 'mushroom-outline', type: 'MaterialCommunityIcons' },
	{ name: 'music', type: 'MaterialCommunityIcons' },
	{ name: 'music-box', type: 'MaterialCommunityIcons' },
	{ name: 'music-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'music-circle', type: 'MaterialCommunityIcons' },
	{ name: 'music-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'music-note', type: 'MaterialCommunityIcons' },
	{ name: 'music-note-bluetooth', type: 'MaterialCommunityIcons' },
	{ name: 'music-note-bluetooth-off', type: 'MaterialCommunityIcons' },
	{ name: 'music-note-eighth', type: 'MaterialCommunityIcons' },
	{ name: 'music-note-half', type: 'MaterialCommunityIcons' },
	{ name: 'music-note-off', type: 'MaterialCommunityIcons' },
	{ name: 'music-note-quarter', type: 'MaterialCommunityIcons' },
	{ name: 'music-note-sixteenth', type: 'MaterialCommunityIcons' },
	{ name: 'music-note-whole', type: 'MaterialCommunityIcons' },
	{ name: 'music-off', type: 'MaterialCommunityIcons' },
	{ name: 'nas', type: 'MaterialCommunityIcons' },
	{ name: 'nativescript', type: 'MaterialCommunityIcons' },
	{ name: 'nature', type: 'MaterialCommunityIcons' },
	{ name: 'nature-people', type: 'MaterialCommunityIcons' },
	{ name: 'navigation', type: 'MaterialCommunityIcons' },
	{ name: 'near-me', type: 'MaterialCommunityIcons' },
	{ name: 'needle', type: 'MaterialCommunityIcons' },
	{ name: 'netflix', type: 'MaterialCommunityIcons' },
	{ name: 'network', type: 'MaterialCommunityIcons' },
	{ name: 'network-strength-1', type: 'MaterialCommunityIcons' },
	{ name: 'network-strength-1-alert', type: 'MaterialCommunityIcons' },
	{ name: 'network-strength-2', type: 'MaterialCommunityIcons' },
	{ name: 'network-strength-2-alert', type: 'MaterialCommunityIcons' },
	{ name: 'network-strength-3', type: 'MaterialCommunityIcons' },
	{ name: 'network-strength-3-alert', type: 'MaterialCommunityIcons' },
	{ name: 'network-strength-4', type: 'MaterialCommunityIcons' },
	{ name: 'network-strength-4-alert', type: 'MaterialCommunityIcons' },
	{ name: 'network-strength-off', type: 'MaterialCommunityIcons' },
	{ name: 'network-strength-off-outline', type: 'MaterialCommunityIcons' },
	{ name: 'network-strength-outline', type: 'MaterialCommunityIcons' },
	{ name: 'new-box', type: 'MaterialCommunityIcons' },
	{ name: 'newspaper', type: 'MaterialCommunityIcons' },
	{ name: 'nfc', type: 'MaterialCommunityIcons' },
	{ name: 'nfc-tap', type: 'MaterialCommunityIcons' },
	{ name: 'nfc-variant', type: 'MaterialCommunityIcons' },
	{ name: 'ninja', type: 'MaterialCommunityIcons' },
	{ name: 'nintendo-switch', type: 'MaterialCommunityIcons' },
	{ name: 'nodejs', type: 'MaterialCommunityIcons' },
	{ name: 'not-equal', type: 'MaterialCommunityIcons' },
	{ name: 'not-equal-variant', type: 'MaterialCommunityIcons' },
	{ name: 'note', type: 'MaterialCommunityIcons' },
	{ name: 'note-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'note-multiple-outline', type: 'MaterialCommunityIcons' },
	{ name: 'note-outline', type: 'MaterialCommunityIcons' },
	{ name: 'note-plus', type: 'MaterialCommunityIcons' },
	{ name: 'note-plus-outline', type: 'MaterialCommunityIcons' },
	{ name: 'note-text', type: 'MaterialCommunityIcons' },
	{ name: 'notebook', type: 'MaterialCommunityIcons' },
	{ name: 'notification-clear-all', type: 'MaterialCommunityIcons' },
	{ name: 'npm', type: 'MaterialCommunityIcons' },
	{ name: 'npm-variant', type: 'MaterialCommunityIcons' },
	{ name: 'npm-variant-outline', type: 'MaterialCommunityIcons' },
	{ name: 'nuke', type: 'MaterialCommunityIcons' },
	{ name: 'null', type: 'MaterialCommunityIcons' },
	{ name: 'numeric', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-0', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-0-box', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-0-box-multiple-outline', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-0-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-1', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-1-box', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-1-box-multiple-outline', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-1-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-2', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-2-box', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-2-box-multiple-outline', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-2-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-3', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-3-box', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-3-box-multiple-outline', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-3-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-4', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-4-box', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-4-box-multiple-outline', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-4-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-5', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-5-box', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-5-box-multiple-outline', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-5-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-6', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-6-box', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-6-box-multiple-outline', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-6-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-7', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-7-box', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-7-box-multiple-outline', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-7-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-8', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-8-box', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-8-box-multiple-outline', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-8-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-9', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-9-box', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-9-box-multiple-outline', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-9-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'numeric-9-plus-box', type: 'MaterialCommunityIcons' },
	{
		name: 'numeric-9-plus-box-multiple-outline',
		type: 'MaterialCommunityIcons',
	},
	{ name: 'numeric-9-plus-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'nut', type: 'MaterialCommunityIcons' },
	{ name: 'nutrition', type: 'MaterialCommunityIcons' },
	{ name: 'oar', type: 'MaterialCommunityIcons' },
	{ name: 'octagon', type: 'MaterialCommunityIcons' },
	{ name: 'octagon-outline', type: 'MaterialCommunityIcons' },
	{ name: 'octagram', type: 'MaterialCommunityIcons' },
	{ name: 'octagram-outline', type: 'MaterialCommunityIcons' },
	{ name: 'odnoklassniki', type: 'MaterialCommunityIcons' },
	{ name: 'office', type: 'MaterialCommunityIcons' },
	{ name: 'office-building', type: 'MaterialCommunityIcons' },
	{ name: 'oil', type: 'MaterialCommunityIcons' },
	{ name: 'oil-temperature', type: 'MaterialCommunityIcons' },
	{ name: 'omega', type: 'MaterialCommunityIcons' },
	{ name: 'onedrive', type: 'MaterialCommunityIcons' },
	{ name: 'onenote', type: 'MaterialCommunityIcons' },
	{ name: 'onepassword', type: 'MaterialCommunityIcons' },
	{ name: 'opacity', type: 'MaterialCommunityIcons' },
	{ name: 'open-in-app', type: 'MaterialCommunityIcons' },
	{ name: 'open-in-new', type: 'MaterialCommunityIcons' },
	{ name: 'openid', type: 'MaterialCommunityIcons' },
	{ name: 'opera', type: 'MaterialCommunityIcons' },
	{ name: 'orbit', type: 'MaterialCommunityIcons' },
	{ name: 'origin', type: 'MaterialCommunityIcons' },
	{ name: 'ornament', type: 'MaterialCommunityIcons' },
	{ name: 'ornament-variant', type: 'MaterialCommunityIcons' },
	{ name: 'owl', type: 'MaterialCommunityIcons' },
	{ name: 'package', type: 'MaterialCommunityIcons' },
	{ name: 'package-down', type: 'MaterialCommunityIcons' },
	{ name: 'package-up', type: 'MaterialCommunityIcons' },
	{ name: 'package-variant', type: 'MaterialCommunityIcons' },
	{ name: 'package-variant-closed', type: 'MaterialCommunityIcons' },
	{ name: 'page-first', type: 'MaterialCommunityIcons' },
	{ name: 'page-last', type: 'MaterialCommunityIcons' },
	{ name: 'page-layout-body', type: 'MaterialCommunityIcons' },
	{ name: 'page-layout-footer', type: 'MaterialCommunityIcons' },
	{ name: 'page-layout-header', type: 'MaterialCommunityIcons' },
	{ name: 'page-layout-sidebar-left', type: 'MaterialCommunityIcons' },
	{ name: 'page-layout-sidebar-right', type: 'MaterialCommunityIcons' },
	{ name: 'palette', type: 'MaterialCommunityIcons' },
	{ name: 'palette-advanced', type: 'MaterialCommunityIcons' },
	{ name: 'palette-swatch', type: 'MaterialCommunityIcons' },
	{ name: 'panda', type: 'MaterialCommunityIcons' },
	{ name: 'pandora', type: 'MaterialCommunityIcons' },
	{ name: 'panorama', type: 'MaterialCommunityIcons' },
	{ name: 'panorama-fisheye', type: 'MaterialCommunityIcons' },
	{ name: 'panorama-horizontal', type: 'MaterialCommunityIcons' },
	{ name: 'panorama-vertical', type: 'MaterialCommunityIcons' },
	{ name: 'panorama-wide-angle', type: 'MaterialCommunityIcons' },
	{ name: 'paper-cut-vertical', type: 'MaterialCommunityIcons' },
	{ name: 'paperclip', type: 'MaterialCommunityIcons' },
	{ name: 'parking', type: 'MaterialCommunityIcons' },
	{ name: 'passport', type: 'MaterialCommunityIcons' },
	{ name: 'patreon', type: 'MaterialCommunityIcons' },
	{ name: 'pause', type: 'MaterialCommunityIcons' },
	{ name: 'pause-circle', type: 'MaterialCommunityIcons' },
	{ name: 'pause-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'pause-octagon', type: 'MaterialCommunityIcons' },
	{ name: 'pause-octagon-outline', type: 'MaterialCommunityIcons' },
	{ name: 'paw', type: 'MaterialCommunityIcons' },
	{ name: 'paw-off', type: 'MaterialCommunityIcons' },
	{ name: 'paypal', type: 'MaterialCommunityIcons' },
	{ name: 'peace', type: 'MaterialCommunityIcons' },
	{ name: 'pen', type: 'MaterialCommunityIcons' },
	{ name: 'pencil', type: 'MaterialCommunityIcons' },
	{ name: 'pencil-box', type: 'MaterialCommunityIcons' },
	{ name: 'pencil-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'pencil-circle', type: 'MaterialCommunityIcons' },
	{ name: 'pencil-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'pencil-lock', type: 'MaterialCommunityIcons' },
	{ name: 'pencil-off', type: 'MaterialCommunityIcons' },
	{ name: 'pentagon', type: 'MaterialCommunityIcons' },
	{ name: 'pentagon-outline', type: 'MaterialCommunityIcons' },
	{ name: 'percent', type: 'MaterialCommunityIcons' },
	{ name: 'periodic-table', type: 'MaterialCommunityIcons' },
	{ name: 'periodic-table-co2', type: 'MaterialCommunityIcons' },
	{ name: 'periscope', type: 'MaterialCommunityIcons' },
	{ name: 'pharmacy', type: 'MaterialCommunityIcons' },
	{ name: 'phone', type: 'MaterialCommunityIcons' },
	{ name: 'phone-bluetooth', type: 'MaterialCommunityIcons' },
	{ name: 'phone-classic', type: 'MaterialCommunityIcons' },
	{ name: 'phone-forward', type: 'MaterialCommunityIcons' },
	{ name: 'phone-hangup', type: 'MaterialCommunityIcons' },
	{ name: 'phone-in-talk', type: 'MaterialCommunityIcons' },
	{ name: 'phone-incoming', type: 'MaterialCommunityIcons' },
	{ name: 'phone-lock', type: 'MaterialCommunityIcons' },
	{ name: 'phone-log', type: 'MaterialCommunityIcons' },
	{ name: 'phone-minus', type: 'MaterialCommunityIcons' },
	{ name: 'phone-missed', type: 'MaterialCommunityIcons' },
	{ name: 'phone-outgoing', type: 'MaterialCommunityIcons' },
	{ name: 'phone-paused', type: 'MaterialCommunityIcons' },
	{ name: 'phone-plus', type: 'MaterialCommunityIcons' },
	{ name: 'phone-return', type: 'MaterialCommunityIcons' },
	{ name: 'phone-rotate-landscape', type: 'MaterialCommunityIcons' },
	{ name: 'phone-rotate-portrait', type: 'MaterialCommunityIcons' },
	{ name: 'phone-settings', type: 'MaterialCommunityIcons' },
	{ name: 'phone-voip', type: 'MaterialCommunityIcons' },
	{ name: 'pi', type: 'MaterialCommunityIcons' },
	{ name: 'pi-box', type: 'MaterialCommunityIcons' },
	{ name: 'piano', type: 'MaterialCommunityIcons' },
	{ name: 'pickaxe', type: 'MaterialCommunityIcons' },
	{ name: 'pier', type: 'MaterialCommunityIcons' },
	{ name: 'pier-crane', type: 'MaterialCommunityIcons' },
	{ name: 'pig', type: 'MaterialCommunityIcons' },
	{ name: 'pill', type: 'MaterialCommunityIcons' },
	{ name: 'pillar', type: 'MaterialCommunityIcons' },
	{ name: 'pin', type: 'MaterialCommunityIcons' },
	{ name: 'pin-off', type: 'MaterialCommunityIcons' },
	{ name: 'pin-off-outline', type: 'MaterialCommunityIcons' },
	{ name: 'pin-outline', type: 'MaterialCommunityIcons' },
	{ name: 'pine-tree', type: 'MaterialCommunityIcons' },
	{ name: 'pine-tree-box', type: 'MaterialCommunityIcons' },
	{ name: 'pinterest', type: 'MaterialCommunityIcons' },
	{ name: 'pinterest-box', type: 'MaterialCommunityIcons' },
	{ name: 'pinwheel', type: 'MaterialCommunityIcons' },
	{ name: 'pinwheel-outline', type: 'MaterialCommunityIcons' },
	{ name: 'pipe', type: 'MaterialCommunityIcons' },
	{ name: 'pipe-disconnected', type: 'MaterialCommunityIcons' },
	{ name: 'pipe-leak', type: 'MaterialCommunityIcons' },
	{ name: 'pirate', type: 'MaterialCommunityIcons' },
	{ name: 'pistol', type: 'MaterialCommunityIcons' },
	{ name: 'piston', type: 'MaterialCommunityIcons' },
	{ name: 'pizza', type: 'MaterialCommunityIcons' },
	{ name: 'plane-shield', type: 'MaterialCommunityIcons' },
	{ name: 'play', type: 'MaterialCommunityIcons' },
	{ name: 'play-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'play-circle', type: 'MaterialCommunityIcons' },
	{ name: 'play-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'play-network', type: 'MaterialCommunityIcons' },
	{ name: 'play-pause', type: 'MaterialCommunityIcons' },
	{ name: 'play-protected-content', type: 'MaterialCommunityIcons' },
	{ name: 'play-speed', type: 'MaterialCommunityIcons' },
	{ name: 'playlist-check', type: 'MaterialCommunityIcons' },
	{ name: 'playlist-edit', type: 'MaterialCommunityIcons' },
	{ name: 'playlist-minus', type: 'MaterialCommunityIcons' },
	{ name: 'playlist-play', type: 'MaterialCommunityIcons' },
	{ name: 'playlist-plus', type: 'MaterialCommunityIcons' },
	{ name: 'playlist-remove', type: 'MaterialCommunityIcons' },
	{ name: 'playstation', type: 'MaterialCommunityIcons' },
	{ name: 'plex', type: 'MaterialCommunityIcons' },
	{ name: 'plus', type: 'MaterialCommunityIcons' },
	{ name: 'plus-box', type: 'MaterialCommunityIcons' },
	{ name: 'plus-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'plus-circle', type: 'MaterialCommunityIcons' },
	{ name: 'plus-circle-multiple-outline', type: 'MaterialCommunityIcons' },
	{ name: 'plus-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'plus-minus', type: 'MaterialCommunityIcons' },
	{ name: 'plus-minus-box', type: 'MaterialCommunityIcons' },
	{ name: 'plus-network', type: 'MaterialCommunityIcons' },
	{ name: 'plus-one', type: 'MaterialCommunityIcons' },
	{ name: 'plus-outline', type: 'MaterialCommunityIcons' },
	{ name: 'pocket', type: 'MaterialCommunityIcons' },
	{ name: 'podcast', type: 'MaterialCommunityIcons' },
	{ name: 'pokeball', type: 'MaterialCommunityIcons' },
	{ name: 'pokemon-go', type: 'MaterialCommunityIcons' },
	{ name: 'poker-chip', type: 'MaterialCommunityIcons' },
	{ name: 'polaroid', type: 'MaterialCommunityIcons' },
	{ name: 'poll', type: 'MaterialCommunityIcons' },
	{ name: 'poll-box', type: 'MaterialCommunityIcons' },
	{ name: 'polymer', type: 'MaterialCommunityIcons' },
	{ name: 'pool', type: 'MaterialCommunityIcons' },
	{ name: 'popcorn', type: 'MaterialCommunityIcons' },
	{ name: 'pot', type: 'MaterialCommunityIcons' },
	{ name: 'pot-mix', type: 'MaterialCommunityIcons' },
	{ name: 'pound', type: 'MaterialCommunityIcons' },
	{ name: 'pound-box', type: 'MaterialCommunityIcons' },
	{ name: 'power', type: 'MaterialCommunityIcons' },
	{ name: 'power-cycle', type: 'MaterialCommunityIcons' },
	{ name: 'power-off', type: 'MaterialCommunityIcons' },
	{ name: 'power-on', type: 'MaterialCommunityIcons' },
	{ name: 'power-plug', type: 'MaterialCommunityIcons' },
	{ name: 'power-plug-off', type: 'MaterialCommunityIcons' },
	{ name: 'power-settings', type: 'MaterialCommunityIcons' },
	{ name: 'power-sleep', type: 'MaterialCommunityIcons' },
	{ name: 'power-socket', type: 'MaterialCommunityIcons' },
	{ name: 'power-socket-au', type: 'MaterialCommunityIcons' },
	{ name: 'power-socket-eu', type: 'MaterialCommunityIcons' },
	{ name: 'power-socket-uk', type: 'MaterialCommunityIcons' },
	{ name: 'power-socket-us', type: 'MaterialCommunityIcons' },
	{ name: 'power-standby', type: 'MaterialCommunityIcons' },
	{ name: 'powershell', type: 'MaterialCommunityIcons' },
	{ name: 'prescription', type: 'MaterialCommunityIcons' },
	{ name: 'presentation', type: 'MaterialCommunityIcons' },
	{ name: 'presentation-play', type: 'MaterialCommunityIcons' },
	{ name: 'printer', type: 'MaterialCommunityIcons' },
	{ name: 'printer-3d', type: 'MaterialCommunityIcons' },
	{ name: 'printer-alert', type: 'MaterialCommunityIcons' },
	{ name: 'printer-settings', type: 'MaterialCommunityIcons' },
	{ name: 'printer-wireless', type: 'MaterialCommunityIcons' },
	{ name: 'priority-high', type: 'MaterialCommunityIcons' },
	{ name: 'priority-low', type: 'MaterialCommunityIcons' },
	{ name: 'professional-hexagon', type: 'MaterialCommunityIcons' },
	{ name: 'progress-check', type: 'MaterialCommunityIcons' },
	{ name: 'progress-clock', type: 'MaterialCommunityIcons' },
	{ name: 'progress-download', type: 'MaterialCommunityIcons' },
	{ name: 'progress-upload', type: 'MaterialCommunityIcons' },
	{ name: 'projector', type: 'MaterialCommunityIcons' },
	{ name: 'projector-screen', type: 'MaterialCommunityIcons' },
	{ name: 'publish', type: 'MaterialCommunityIcons' },
	{ name: 'pulse', type: 'MaterialCommunityIcons' },
	{ name: 'puzzle', type: 'MaterialCommunityIcons' },
	{ name: 'puzzle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'qi', type: 'MaterialCommunityIcons' },
	{ name: 'qqchat', type: 'MaterialCommunityIcons' },
	{ name: 'qrcode', type: 'MaterialCommunityIcons' },
	{ name: 'qrcode-edit', type: 'MaterialCommunityIcons' },
	{ name: 'qrcode-scan', type: 'MaterialCommunityIcons' },
	{ name: 'quadcopter', type: 'MaterialCommunityIcons' },
	{ name: 'quality-high', type: 'MaterialCommunityIcons' },
	{ name: 'quality-low', type: 'MaterialCommunityIcons' },
	{ name: 'quality-medium', type: 'MaterialCommunityIcons' },
	{ name: 'quicktime', type: 'MaterialCommunityIcons' },
	{ name: 'rabbit', type: 'MaterialCommunityIcons' },
	{ name: 'radar', type: 'MaterialCommunityIcons' },
	{ name: 'radiator', type: 'MaterialCommunityIcons' },
	{ name: 'radiator-disabled', type: 'MaterialCommunityIcons' },
	{ name: 'radiator-off', type: 'MaterialCommunityIcons' },
	{ name: 'radio', type: 'MaterialCommunityIcons' },
	{ name: 'radio-handheld', type: 'MaterialCommunityIcons' },
	{ name: 'radio-tower', type: 'MaterialCommunityIcons' },
	{ name: 'radioactive', type: 'MaterialCommunityIcons' },
	{ name: 'radiobox-blank', type: 'MaterialCommunityIcons' },
	{ name: 'radiobox-marked', type: 'MaterialCommunityIcons' },
	{ name: 'raspberrypi', type: 'MaterialCommunityIcons' },
	{ name: 'ray-end', type: 'MaterialCommunityIcons' },
	{ name: 'ray-end-arrow', type: 'MaterialCommunityIcons' },
	{ name: 'ray-start', type: 'MaterialCommunityIcons' },
	{ name: 'ray-start-arrow', type: 'MaterialCommunityIcons' },
	{ name: 'ray-start-end', type: 'MaterialCommunityIcons' },
	{ name: 'ray-vertex', type: 'MaterialCommunityIcons' },
	{ name: 'react', type: 'MaterialCommunityIcons' },
	{ name: 'read', type: 'MaterialCommunityIcons' },
	{ name: 'receipt', type: 'MaterialCommunityIcons' },
	{ name: 'record', type: 'MaterialCommunityIcons' },
	{ name: 'record-player', type: 'MaterialCommunityIcons' },
	{ name: 'record-rec', type: 'MaterialCommunityIcons' },
	{ name: 'recycle', type: 'MaterialCommunityIcons' },
	{ name: 'reddit', type: 'MaterialCommunityIcons' },
	{ name: 'redo', type: 'MaterialCommunityIcons' },
	{ name: 'redo-variant', type: 'MaterialCommunityIcons' },
	{ name: 'reflect-horizontal', type: 'MaterialCommunityIcons' },
	{ name: 'reflect-vertical', type: 'MaterialCommunityIcons' },
	{ name: 'refresh', type: 'MaterialCommunityIcons' },
	{ name: 'regex', type: 'MaterialCommunityIcons' },
	{ name: 'registered-trademark', type: 'MaterialCommunityIcons' },
	{ name: 'relative-scale', type: 'MaterialCommunityIcons' },
	{ name: 'reload', type: 'MaterialCommunityIcons' },
	{ name: 'reminder', type: 'MaterialCommunityIcons' },
	{ name: 'remote', type: 'MaterialCommunityIcons' },
	{ name: 'remote-desktop', type: 'MaterialCommunityIcons' },
	{ name: 'rename-box', type: 'MaterialCommunityIcons' },
	{ name: 'reorder-horizontal', type: 'MaterialCommunityIcons' },
	{ name: 'reorder-vertical', type: 'MaterialCommunityIcons' },
	{ name: 'repeat', type: 'MaterialCommunityIcons' },
	{ name: 'repeat-off', type: 'MaterialCommunityIcons' },
	{ name: 'repeat-once', type: 'MaterialCommunityIcons' },
	{ name: 'replay', type: 'MaterialCommunityIcons' },
	{ name: 'reply', type: 'MaterialCommunityIcons' },
	{ name: 'reply-all', type: 'MaterialCommunityIcons' },
	{ name: 'reproduction', type: 'MaterialCommunityIcons' },
	{ name: 'resistor', type: 'MaterialCommunityIcons' },
	{ name: 'resistor-nodes', type: 'MaterialCommunityIcons' },
	{ name: 'resize', type: 'MaterialCommunityIcons' },
	{ name: 'resize-bottom-right', type: 'MaterialCommunityIcons' },
	{ name: 'responsive', type: 'MaterialCommunityIcons' },
	{ name: 'restart', type: 'MaterialCommunityIcons' },
	{ name: 'restore', type: 'MaterialCommunityIcons' },
	{ name: 'restore-clock', type: 'MaterialCommunityIcons' },
	{ name: 'rewind', type: 'MaterialCommunityIcons' },
	{ name: 'rewind-outline', type: 'MaterialCommunityIcons' },
	{ name: 'rhombus', type: 'MaterialCommunityIcons' },
	{ name: 'rhombus-medium', type: 'MaterialCommunityIcons' },
	{ name: 'rhombus-outline', type: 'MaterialCommunityIcons' },
	{ name: 'rhombus-split', type: 'MaterialCommunityIcons' },
	{ name: 'ribbon', type: 'MaterialCommunityIcons' },
	{ name: 'rice', type: 'MaterialCommunityIcons' },
	{ name: 'ring', type: 'MaterialCommunityIcons' },
	{ name: 'road', type: 'MaterialCommunityIcons' },
	{ name: 'road-variant', type: 'MaterialCommunityIcons' },
	{ name: 'robot', type: 'MaterialCommunityIcons' },
	{ name: 'robot-industrial', type: 'MaterialCommunityIcons' },
	{ name: 'robot-vacuum', type: 'MaterialCommunityIcons' },
	{ name: 'robot-vacuum-variant', type: 'MaterialCommunityIcons' },
	{ name: 'rocket', type: 'MaterialCommunityIcons' },
	{ name: 'room-service', type: 'MaterialCommunityIcons' },
	{ name: 'rotate-3d', type: 'MaterialCommunityIcons' },
	{ name: 'rotate-left', type: 'MaterialCommunityIcons' },
	{ name: 'rotate-left-variant', type: 'MaterialCommunityIcons' },
	{ name: 'rotate-right', type: 'MaterialCommunityIcons' },
	{ name: 'rotate-right-variant', type: 'MaterialCommunityIcons' },
	{ name: 'rounded-corner', type: 'MaterialCommunityIcons' },
	{ name: 'router-wireless', type: 'MaterialCommunityIcons' },
	{ name: 'router-wireless-settings', type: 'MaterialCommunityIcons' },
	{ name: 'routes', type: 'MaterialCommunityIcons' },
	{ name: 'rowing', type: 'MaterialCommunityIcons' },
	{ name: 'rss', type: 'MaterialCommunityIcons' },
	{ name: 'rss-box', type: 'MaterialCommunityIcons' },
	{ name: 'ruler', type: 'MaterialCommunityIcons' },
	{ name: 'run', type: 'MaterialCommunityIcons' },
	{ name: 'run-fast', type: 'MaterialCommunityIcons' },
	{ name: 'safe', type: 'MaterialCommunityIcons' },
	{ name: 'sale', type: 'MaterialCommunityIcons' },
	{ name: 'salesforce', type: 'MaterialCommunityIcons' },
	{ name: 'sass', type: 'MaterialCommunityIcons' },
	{ name: 'satellite', type: 'MaterialCommunityIcons' },
	{ name: 'satellite-uplink', type: 'MaterialCommunityIcons' },
	{ name: 'satellite-variant', type: 'MaterialCommunityIcons' },
	{ name: 'sausage', type: 'MaterialCommunityIcons' },
	{ name: 'saxophone', type: 'MaterialCommunityIcons' },
	{ name: 'scale', type: 'MaterialCommunityIcons' },
	{ name: 'scale-balance', type: 'MaterialCommunityIcons' },
	{ name: 'scale-bathroom', type: 'MaterialCommunityIcons' },
	{ name: 'scanner', type: 'MaterialCommunityIcons' },
	{ name: 'scanner-off', type: 'MaterialCommunityIcons' },
	{ name: 'school', type: 'MaterialCommunityIcons' },
	{ name: 'scissors-cutting', type: 'MaterialCommunityIcons' },
	{ name: 'screen-rotation', type: 'MaterialCommunityIcons' },
	{ name: 'screen-rotation-lock', type: 'MaterialCommunityIcons' },
	{ name: 'screwdriver', type: 'MaterialCommunityIcons' },
	{ name: 'script', type: 'MaterialCommunityIcons' },
	{ name: 'sd', type: 'MaterialCommunityIcons' },
	{ name: 'seal', type: 'MaterialCommunityIcons' },
	{ name: 'search-web', type: 'MaterialCommunityIcons' },
	{ name: 'seat-flat', type: 'MaterialCommunityIcons' },
	{ name: 'seat-flat-angled', type: 'MaterialCommunityIcons' },
	{ name: 'seat-individual-suite', type: 'MaterialCommunityIcons' },
	{ name: 'seat-legroom-extra', type: 'MaterialCommunityIcons' },
	{ name: 'seat-legroom-normal', type: 'MaterialCommunityIcons' },
	{ name: 'seat-legroom-reduced', type: 'MaterialCommunityIcons' },
	{ name: 'seat-recline-extra', type: 'MaterialCommunityIcons' },
	{ name: 'seat-recline-normal', type: 'MaterialCommunityIcons' },
	{ name: 'security', type: 'MaterialCommunityIcons' },
	{ name: 'security-account', type: 'MaterialCommunityIcons' },
	{ name: 'security-account-outline', type: 'MaterialCommunityIcons' },
	{ name: 'security-close', type: 'MaterialCommunityIcons' },
	{ name: 'security-home', type: 'MaterialCommunityIcons' },
	{ name: 'security-lock', type: 'MaterialCommunityIcons' },
	{ name: 'security-network', type: 'MaterialCommunityIcons' },
	{ name: 'security-off', type: 'MaterialCommunityIcons' },
	{ name: 'select', type: 'MaterialCommunityIcons' },
	{ name: 'select-all', type: 'MaterialCommunityIcons' },
	{ name: 'select-compare', type: 'MaterialCommunityIcons' },
	{ name: 'select-drag', type: 'MaterialCommunityIcons' },
	{ name: 'select-inverse', type: 'MaterialCommunityIcons' },
	{ name: 'select-off', type: 'MaterialCommunityIcons' },
	{ name: 'selection', type: 'MaterialCommunityIcons' },
	{ name: 'selection-drag', type: 'MaterialCommunityIcons' },
	{ name: 'selection-off', type: 'MaterialCommunityIcons' },
	{ name: 'send', type: 'MaterialCommunityIcons' },
	{ name: 'send-lock', type: 'MaterialCommunityIcons' },
	{ name: 'serial-port', type: 'MaterialCommunityIcons' },
	{ name: 'server', type: 'MaterialCommunityIcons' },
	{ name: 'server-minus', type: 'MaterialCommunityIcons' },
	{ name: 'server-network', type: 'MaterialCommunityIcons' },
	{ name: 'server-network-off', type: 'MaterialCommunityIcons' },
	{ name: 'server-off', type: 'MaterialCommunityIcons' },
	{ name: 'server-plus', type: 'MaterialCommunityIcons' },
	{ name: 'server-remove', type: 'MaterialCommunityIcons' },
	{ name: 'server-security', type: 'MaterialCommunityIcons' },
	{ name: 'set-all', type: 'MaterialCommunityIcons' },
	{ name: 'set-center', type: 'MaterialCommunityIcons' },
	{ name: 'set-center-right', type: 'MaterialCommunityIcons' },
	{ name: 'set-left', type: 'MaterialCommunityIcons' },
	{ name: 'set-left-center', type: 'MaterialCommunityIcons' },
	{ name: 'set-left-right', type: 'MaterialCommunityIcons' },
	{ name: 'set-none', type: 'MaterialCommunityIcons' },
	{ name: 'set-right', type: 'MaterialCommunityIcons' },
	{ name: 'set-top-box', type: 'MaterialCommunityIcons' },
	{ name: 'settings', type: 'MaterialCommunityIcons' },
	{ name: 'settings-box', type: 'MaterialCommunityIcons' },
	{ name: 'settings-helper', type: 'MaterialCommunityIcons' },
	{ name: 'settings-outline', type: 'MaterialCommunityIcons' },
	{ name: 'shape', type: 'MaterialCommunityIcons' },
	{ name: 'shape-circle-plus', type: 'MaterialCommunityIcons' },
	{ name: 'shape-outline', type: 'MaterialCommunityIcons' },
	{ name: 'shape-plus', type: 'MaterialCommunityIcons' },
	{ name: 'shape-polygon-plus', type: 'MaterialCommunityIcons' },
	{ name: 'shape-rectangle-plus', type: 'MaterialCommunityIcons' },
	{ name: 'shape-square-plus', type: 'MaterialCommunityIcons' },
	{ name: 'share', type: 'MaterialCommunityIcons' },
	{ name: 'share-outline', type: 'MaterialCommunityIcons' },
	{ name: 'share-variant', type: 'MaterialCommunityIcons' },
	{ name: 'shield', type: 'MaterialCommunityIcons' },
	{ name: 'shield-half-full', type: 'MaterialCommunityIcons' },
	{ name: 'shield-outline', type: 'MaterialCommunityIcons' },
	{ name: 'shield-plus', type: 'MaterialCommunityIcons' },
	{ name: 'shield-plus-outline', type: 'MaterialCommunityIcons' },
	{ name: 'shield-remove', type: 'MaterialCommunityIcons' },
	{ name: 'shield-remove-outline', type: 'MaterialCommunityIcons' },
	{ name: 'ship-wheel', type: 'MaterialCommunityIcons' },
	{ name: 'shoe-formal', type: 'MaterialCommunityIcons' },
	{ name: 'shoe-heel', type: 'MaterialCommunityIcons' },
	{ name: 'shopify', type: 'MaterialCommunityIcons' },
	{ name: 'shopping', type: 'MaterialCommunityIcons' },
	{ name: 'shopping-music', type: 'MaterialCommunityIcons' },
	{ name: 'shovel', type: 'MaterialCommunityIcons' },
	{ name: 'shovel-off', type: 'MaterialCommunityIcons' },
	{ name: 'shower', type: 'MaterialCommunityIcons' },
	{ name: 'shower-head', type: 'MaterialCommunityIcons' },
	{ name: 'shredder', type: 'MaterialCommunityIcons' },
	{ name: 'shuffle', type: 'MaterialCommunityIcons' },
	{ name: 'shuffle-disabled', type: 'MaterialCommunityIcons' },
	{ name: 'shuffle-variant', type: 'MaterialCommunityIcons' },
	{ name: 'sigma', type: 'MaterialCommunityIcons' },
	{ name: 'sigma-lower', type: 'MaterialCommunityIcons' },
	{ name: 'sign-caution', type: 'MaterialCommunityIcons' },
	{ name: 'sign-direction', type: 'MaterialCommunityIcons' },
	{ name: 'sign-text', type: 'MaterialCommunityIcons' },
	{ name: 'signal', type: 'MaterialCommunityIcons' },
	{ name: 'signal-2g', type: 'MaterialCommunityIcons' },
	{ name: 'signal-3g', type: 'MaterialCommunityIcons' },
	{ name: 'signal-4g', type: 'MaterialCommunityIcons' },
	{ name: 'signal-5g', type: 'MaterialCommunityIcons' },
	{ name: 'signal-cellular-1', type: 'MaterialCommunityIcons' },
	{ name: 'signal-cellular-2', type: 'MaterialCommunityIcons' },
	{ name: 'signal-cellular-3', type: 'MaterialCommunityIcons' },
	{ name: 'signal-cellular-outline', type: 'MaterialCommunityIcons' },
	{ name: 'signal-hspa', type: 'MaterialCommunityIcons' },
	{ name: 'signal-hspa-plus', type: 'MaterialCommunityIcons' },
	{ name: 'signal-off', type: 'MaterialCommunityIcons' },
	{ name: 'signal-variant', type: 'MaterialCommunityIcons' },
	{ name: 'silo', type: 'MaterialCommunityIcons' },
	{ name: 'silverware', type: 'MaterialCommunityIcons' },
	{ name: 'silverware-fork', type: 'MaterialCommunityIcons' },
	{ name: 'silverware-fork-knife', type: 'MaterialCommunityIcons' },
	{ name: 'silverware-spoon', type: 'MaterialCommunityIcons' },
	{ name: 'silverware-variant', type: 'MaterialCommunityIcons' },
	{ name: 'sim', type: 'MaterialCommunityIcons' },
	{ name: 'sim-alert', type: 'MaterialCommunityIcons' },
	{ name: 'sim-off', type: 'MaterialCommunityIcons' },
	{ name: 'sina-weibo', type: 'MaterialCommunityIcons' },
	{ name: 'sitemap', type: 'MaterialCommunityIcons' },
	{ name: 'skip-backward', type: 'MaterialCommunityIcons' },
	{ name: 'skip-forward', type: 'MaterialCommunityIcons' },
	{ name: 'skip-next', type: 'MaterialCommunityIcons' },
	{ name: 'skip-next-circle', type: 'MaterialCommunityIcons' },
	{ name: 'skip-next-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'skip-previous', type: 'MaterialCommunityIcons' },
	{ name: 'skip-previous-circle', type: 'MaterialCommunityIcons' },
	{ name: 'skip-previous-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'skull', type: 'MaterialCommunityIcons' },
	{ name: 'skype', type: 'MaterialCommunityIcons' },
	{ name: 'skype-business', type: 'MaterialCommunityIcons' },
	{ name: 'slack', type: 'MaterialCommunityIcons' },
	{ name: 'slackware', type: 'MaterialCommunityIcons' },
	{ name: 'sleep', type: 'MaterialCommunityIcons' },
	{ name: 'sleep-off', type: 'MaterialCommunityIcons' },
	{ name: 'smog', type: 'MaterialCommunityIcons' },
	{ name: 'smoke-detector', type: 'MaterialCommunityIcons' },
	{ name: 'smoking', type: 'MaterialCommunityIcons' },
	{ name: 'smoking-off', type: 'MaterialCommunityIcons' },
	{ name: 'snapchat', type: 'MaterialCommunityIcons' },
	{ name: 'snowflake', type: 'MaterialCommunityIcons' },
	{ name: 'snowman', type: 'MaterialCommunityIcons' },
	{ name: 'soccer', type: 'MaterialCommunityIcons' },
	{ name: 'soccer-field', type: 'MaterialCommunityIcons' },
	{ name: 'sofa', type: 'MaterialCommunityIcons' },
	{ name: 'solar-power', type: 'MaterialCommunityIcons' },
	{ name: 'solid', type: 'MaterialCommunityIcons' },
	{ name: 'sort', type: 'MaterialCommunityIcons' },
	{ name: 'sort-alphabetical', type: 'MaterialCommunityIcons' },
	{ name: 'sort-ascending', type: 'MaterialCommunityIcons' },
	{ name: 'sort-descending', type: 'MaterialCommunityIcons' },
	{ name: 'sort-numeric', type: 'MaterialCommunityIcons' },
	{ name: 'sort-variant', type: 'MaterialCommunityIcons' },
	{ name: 'soundcloud', type: 'MaterialCommunityIcons' },
	{ name: 'source-branch', type: 'MaterialCommunityIcons' },
	{ name: 'source-commit', type: 'MaterialCommunityIcons' },
	{ name: 'source-commit-end', type: 'MaterialCommunityIcons' },
	{ name: 'source-commit-end-local', type: 'MaterialCommunityIcons' },
	{ name: 'source-commit-local', type: 'MaterialCommunityIcons' },
	{ name: 'source-commit-next-local', type: 'MaterialCommunityIcons' },
	{ name: 'source-commit-start', type: 'MaterialCommunityIcons' },
	{ name: 'source-commit-start-next-local', type: 'MaterialCommunityIcons' },
	{ name: 'source-fork', type: 'MaterialCommunityIcons' },
	{ name: 'source-merge', type: 'MaterialCommunityIcons' },
	{ name: 'source-pull', type: 'MaterialCommunityIcons' },
	{ name: 'soy-sauce', type: 'MaterialCommunityIcons' },
	{ name: 'speaker', type: 'MaterialCommunityIcons' },
	{ name: 'speaker-bluetooth', type: 'MaterialCommunityIcons' },
	{ name: 'speaker-off', type: 'MaterialCommunityIcons' },
	{ name: 'speaker-wireless', type: 'MaterialCommunityIcons' },
	{ name: 'speedometer', type: 'MaterialCommunityIcons' },
	{ name: 'spellcheck', type: 'MaterialCommunityIcons' },
	{ name: 'spotify', type: 'MaterialCommunityIcons' },
	{ name: 'spotlight', type: 'MaterialCommunityIcons' },
	{ name: 'spotlight-beam', type: 'MaterialCommunityIcons' },
	{ name: 'spray', type: 'MaterialCommunityIcons' },
	{ name: 'spray-bottle', type: 'MaterialCommunityIcons' },
	{ name: 'square', type: 'MaterialCommunityIcons' },
	{ name: 'square-edit-outline', type: 'MaterialCommunityIcons' },
	{ name: 'square-inc', type: 'MaterialCommunityIcons' },
	{ name: 'square-inc-cash', type: 'MaterialCommunityIcons' },
	{ name: 'square-medium', type: 'MaterialCommunityIcons' },
	{ name: 'square-medium-outline', type: 'MaterialCommunityIcons' },
	{ name: 'square-outline', type: 'MaterialCommunityIcons' },
	{ name: 'square-root', type: 'MaterialCommunityIcons' },
	{ name: 'square-root-box', type: 'MaterialCommunityIcons' },
	{ name: 'square-small', type: 'MaterialCommunityIcons' },
	{ name: 'squeegee', type: 'MaterialCommunityIcons' },
	{ name: 'ssh', type: 'MaterialCommunityIcons' },
	{ name: 'stack-exchange', type: 'MaterialCommunityIcons' },
	{ name: 'stack-overflow', type: 'MaterialCommunityIcons' },
	{ name: 'stadium', type: 'MaterialCommunityIcons' },
	{ name: 'stairs', type: 'MaterialCommunityIcons' },
	{ name: 'standard-definition', type: 'MaterialCommunityIcons' },
	{ name: 'star', type: 'MaterialCommunityIcons' },
	{ name: 'star-box', type: 'MaterialCommunityIcons' },
	{ name: 'star-box-outline', type: 'MaterialCommunityIcons' },
	{ name: 'star-circle', type: 'MaterialCommunityIcons' },
	{ name: 'star-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'star-face', type: 'MaterialCommunityIcons' },
	{ name: 'star-four-points', type: 'MaterialCommunityIcons' },
	{ name: 'star-four-points-outline', type: 'MaterialCommunityIcons' },
	{ name: 'star-half', type: 'MaterialCommunityIcons' },
	{ name: 'star-off', type: 'MaterialCommunityIcons' },
	{ name: 'star-outline', type: 'MaterialCommunityIcons' },
	{ name: 'star-three-points', type: 'MaterialCommunityIcons' },
	{ name: 'star-three-points-outline', type: 'MaterialCommunityIcons' },
	{ name: 'steam', type: 'MaterialCommunityIcons' },
	{ name: 'steam-box', type: 'MaterialCommunityIcons' },
	{ name: 'steering', type: 'MaterialCommunityIcons' },
	{ name: 'steering-off', type: 'MaterialCommunityIcons' },
	{ name: 'step-backward', type: 'MaterialCommunityIcons' },
	{ name: 'step-backward-2', type: 'MaterialCommunityIcons' },
	{ name: 'step-forward', type: 'MaterialCommunityIcons' },
	{ name: 'step-forward-2', type: 'MaterialCommunityIcons' },
	{ name: 'stethoscope', type: 'MaterialCommunityIcons' },
	{ name: 'sticker', type: 'MaterialCommunityIcons' },
	{ name: 'sticker-emoji', type: 'MaterialCommunityIcons' },
	{ name: 'stocking', type: 'MaterialCommunityIcons' },
	{ name: 'stop', type: 'MaterialCommunityIcons' },
	{ name: 'stop-circle', type: 'MaterialCommunityIcons' },
	{ name: 'stop-circle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'store', type: 'MaterialCommunityIcons' },
	{ name: 'store-24-hour', type: 'MaterialCommunityIcons' },
	{ name: 'stove', type: 'MaterialCommunityIcons' },
	{ name: 'strava', type: 'MaterialCommunityIcons' },
	{ name: 'subdirectory-arrow-left', type: 'MaterialCommunityIcons' },
	{ name: 'subdirectory-arrow-right', type: 'MaterialCommunityIcons' },
	{ name: 'subtitles', type: 'MaterialCommunityIcons' },
	{ name: 'subtitles-outline', type: 'MaterialCommunityIcons' },
	{ name: 'subway', type: 'MaterialCommunityIcons' },
	{ name: 'subway-variant', type: 'MaterialCommunityIcons' },
	{ name: 'summit', type: 'MaterialCommunityIcons' },
	{ name: 'sunglasses', type: 'MaterialCommunityIcons' },
	{ name: 'surround-sound', type: 'MaterialCommunityIcons' },
	{ name: 'surround-sound-2-0', type: 'MaterialCommunityIcons' },
	{ name: 'surround-sound-3-1', type: 'MaterialCommunityIcons' },
	{ name: 'surround-sound-5-1', type: 'MaterialCommunityIcons' },
	{ name: 'surround-sound-7-1', type: 'MaterialCommunityIcons' },
	{ name: 'svg', type: 'MaterialCommunityIcons' },
	{ name: 'swap-horizontal', type: 'MaterialCommunityIcons' },
	{ name: 'swap-horizontal-variant', type: 'MaterialCommunityIcons' },
	{ name: 'swap-vertical', type: 'MaterialCommunityIcons' },
	{ name: 'swap-vertical-variant', type: 'MaterialCommunityIcons' },
	{ name: 'swim', type: 'MaterialCommunityIcons' },
	{ name: 'switch', type: 'MaterialCommunityIcons' },
	{ name: 'sword', type: 'MaterialCommunityIcons' },
	{ name: 'sword-cross', type: 'MaterialCommunityIcons' },
	{ name: 'symfony', type: 'MaterialCommunityIcons' },
	{ name: 'sync', type: 'MaterialCommunityIcons' },
	{ name: 'sync-alert', type: 'MaterialCommunityIcons' },
	{ name: 'sync-off', type: 'MaterialCommunityIcons' },
	{ name: 'tab', type: 'MaterialCommunityIcons' },
	{ name: 'tab-minus', type: 'MaterialCommunityIcons' },
	{ name: 'tab-plus', type: 'MaterialCommunityIcons' },
	{ name: 'tab-remove', type: 'MaterialCommunityIcons' },
	{ name: 'tab-unselected', type: 'MaterialCommunityIcons' },
	{ name: 'table', type: 'MaterialCommunityIcons' },
	{ name: 'table-border', type: 'MaterialCommunityIcons' },
	{ name: 'table-column', type: 'MaterialCommunityIcons' },
	{ name: 'table-column-plus-after', type: 'MaterialCommunityIcons' },
	{ name: 'table-column-plus-before', type: 'MaterialCommunityIcons' },
	{ name: 'table-column-remove', type: 'MaterialCommunityIcons' },
	{ name: 'table-column-width', type: 'MaterialCommunityIcons' },
	{ name: 'table-edit', type: 'MaterialCommunityIcons' },
	{ name: 'table-large', type: 'MaterialCommunityIcons' },
	{ name: 'table-merge-cells', type: 'MaterialCommunityIcons' },
	{ name: 'table-of-contents', type: 'MaterialCommunityIcons' },
	{ name: 'table-plus', type: 'MaterialCommunityIcons' },
	{ name: 'table-remove', type: 'MaterialCommunityIcons' },
	{ name: 'table-row', type: 'MaterialCommunityIcons' },
	{ name: 'table-row-height', type: 'MaterialCommunityIcons' },
	{ name: 'table-row-plus-after', type: 'MaterialCommunityIcons' },
	{ name: 'table-row-plus-before', type: 'MaterialCommunityIcons' },
	{ name: 'table-row-remove', type: 'MaterialCommunityIcons' },
	{ name: 'table-search', type: 'MaterialCommunityIcons' },
	{ name: 'table-settings', type: 'MaterialCommunityIcons' },
	{ name: 'tablet', type: 'MaterialCommunityIcons' },
	{ name: 'tablet-android', type: 'MaterialCommunityIcons' },
	{ name: 'tablet-cellphone', type: 'MaterialCommunityIcons' },
	{ name: 'tablet-ipad', type: 'MaterialCommunityIcons' },
	{ name: 'taco', type: 'MaterialCommunityIcons' },
	{ name: 'tag', type: 'MaterialCommunityIcons' },
	{ name: 'tag-faces', type: 'MaterialCommunityIcons' },
	{ name: 'tag-heart', type: 'MaterialCommunityIcons' },
	{ name: 'tag-minus', type: 'MaterialCommunityIcons' },
	{ name: 'tag-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'tag-outline', type: 'MaterialCommunityIcons' },
	{ name: 'tag-plus', type: 'MaterialCommunityIcons' },
	{ name: 'tag-remove', type: 'MaterialCommunityIcons' },
	{ name: 'tag-text-outline', type: 'MaterialCommunityIcons' },
	{ name: 'tape-measure', type: 'MaterialCommunityIcons' },
	{ name: 'target', type: 'MaterialCommunityIcons' },
	{ name: 'target-variant', type: 'MaterialCommunityIcons' },
	{ name: 'taxi', type: 'MaterialCommunityIcons' },
	{ name: 'teach', type: 'MaterialCommunityIcons' },
	{ name: 'teamviewer', type: 'MaterialCommunityIcons' },
	{ name: 'telegram', type: 'MaterialCommunityIcons' },
	{ name: 'telescope', type: 'MaterialCommunityIcons' },
	{ name: 'television', type: 'MaterialCommunityIcons' },
	{ name: 'television-box', type: 'MaterialCommunityIcons' },
	{ name: 'television-classic', type: 'MaterialCommunityIcons' },
	{ name: 'television-classic-off', type: 'MaterialCommunityIcons' },
	{ name: 'television-guide', type: 'MaterialCommunityIcons' },
	{ name: 'television-off', type: 'MaterialCommunityIcons' },
	{ name: 'temperature-celsius', type: 'MaterialCommunityIcons' },
	{ name: 'temperature-fahrenheit', type: 'MaterialCommunityIcons' },
	{ name: 'temperature-kelvin', type: 'MaterialCommunityIcons' },
	{ name: 'tennis', type: 'MaterialCommunityIcons' },
	{ name: 'tent', type: 'MaterialCommunityIcons' },
	{ name: 'terrain', type: 'MaterialCommunityIcons' },
	{ name: 'test-tube', type: 'MaterialCommunityIcons' },
	{ name: 'test-tube-empty', type: 'MaterialCommunityIcons' },
	{ name: 'test-tube-off', type: 'MaterialCommunityIcons' },
	{ name: 'text', type: 'MaterialCommunityIcons' },
	{ name: 'text-shadow', type: 'MaterialCommunityIcons' },
	{ name: 'text-short', type: 'MaterialCommunityIcons' },
	{ name: 'text-subject', type: 'MaterialCommunityIcons' },
	{ name: 'text-to-speech', type: 'MaterialCommunityIcons' },
	{ name: 'text-to-speech-off', type: 'MaterialCommunityIcons' },
	{ name: 'textbox', type: 'MaterialCommunityIcons' },
	{ name: 'textbox-password', type: 'MaterialCommunityIcons' },
	{ name: 'texture', type: 'MaterialCommunityIcons' },
	{ name: 'theater', type: 'MaterialCommunityIcons' },
	{ name: 'theme-light-dark', type: 'MaterialCommunityIcons' },
	{ name: 'thermometer', type: 'MaterialCommunityIcons' },
	{ name: 'thermometer-lines', type: 'MaterialCommunityIcons' },
	{ name: 'thermostat', type: 'MaterialCommunityIcons' },
	{ name: 'thermostat-box', type: 'MaterialCommunityIcons' },
	{ name: 'thought-bubble', type: 'MaterialCommunityIcons' },
	{ name: 'thought-bubble-outline', type: 'MaterialCommunityIcons' },
	{ name: 'thumb-down', type: 'MaterialCommunityIcons' },
	{ name: 'thumb-down-outline', type: 'MaterialCommunityIcons' },
	{ name: 'thumb-up', type: 'MaterialCommunityIcons' },
	{ name: 'thumb-up-outline', type: 'MaterialCommunityIcons' },
	{ name: 'thumbs-up-down', type: 'MaterialCommunityIcons' },
	{ name: 'ticket', type: 'MaterialCommunityIcons' },
	{ name: 'ticket-account', type: 'MaterialCommunityIcons' },
	{ name: 'ticket-confirmation', type: 'MaterialCommunityIcons' },
	{ name: 'ticket-outline', type: 'MaterialCommunityIcons' },
	{ name: 'ticket-percent', type: 'MaterialCommunityIcons' },
	{ name: 'tie', type: 'MaterialCommunityIcons' },
	{ name: 'tilde', type: 'MaterialCommunityIcons' },
	{ name: 'timelapse', type: 'MaterialCommunityIcons' },
	{ name: 'timer', type: 'MaterialCommunityIcons' },
	{ name: 'timer-10', type: 'MaterialCommunityIcons' },
	{ name: 'timer-3', type: 'MaterialCommunityIcons' },
	{ name: 'timer-off', type: 'MaterialCommunityIcons' },
	{ name: 'timer-sand', type: 'MaterialCommunityIcons' },
	{ name: 'timer-sand-empty', type: 'MaterialCommunityIcons' },
	{ name: 'timer-sand-full', type: 'MaterialCommunityIcons' },
	{ name: 'timetable', type: 'MaterialCommunityIcons' },
	{ name: 'toggle-switch', type: 'MaterialCommunityIcons' },
	{ name: 'toggle-switch-off', type: 'MaterialCommunityIcons' },
	{ name: 'toggle-switch-off-outline', type: 'MaterialCommunityIcons' },
	{ name: 'toggle-switch-outline', type: 'MaterialCommunityIcons' },
	{ name: 'toilet', type: 'MaterialCommunityIcons' },
	{ name: 'toolbox', type: 'MaterialCommunityIcons' },
	{ name: 'toolbox-outline', type: 'MaterialCommunityIcons' },
	{ name: 'tooltip', type: 'MaterialCommunityIcons' },
	{ name: 'tooltip-edit', type: 'MaterialCommunityIcons' },
	{ name: 'tooltip-image', type: 'MaterialCommunityIcons' },
	{ name: 'tooltip-outline', type: 'MaterialCommunityIcons' },
	{ name: 'tooltip-outline-plus', type: 'MaterialCommunityIcons' },
	{ name: 'tooltip-text', type: 'MaterialCommunityIcons' },
	{ name: 'tooth', type: 'MaterialCommunityIcons' },
	{ name: 'tooth-outline', type: 'MaterialCommunityIcons' },
	{ name: 'tor', type: 'MaterialCommunityIcons' },
	{ name: 'tournament', type: 'MaterialCommunityIcons' },
	{ name: 'tower-beach', type: 'MaterialCommunityIcons' },
	{ name: 'tower-fire', type: 'MaterialCommunityIcons' },
	{ name: 'towing', type: 'MaterialCommunityIcons' },
	{ name: 'track-light', type: 'MaterialCommunityIcons' },
	{ name: 'trackpad', type: 'MaterialCommunityIcons' },
	{ name: 'trackpad-lock', type: 'MaterialCommunityIcons' },
	{ name: 'tractor', type: 'MaterialCommunityIcons' },
	{ name: 'trademark', type: 'MaterialCommunityIcons' },
	{ name: 'traffic-light', type: 'MaterialCommunityIcons' },
	{ name: 'train', type: 'MaterialCommunityIcons' },
	{ name: 'train-variant', type: 'MaterialCommunityIcons' },
	{ name: 'tram', type: 'MaterialCommunityIcons' },
	{ name: 'transcribe', type: 'MaterialCommunityIcons' },
	{ name: 'transcribe-close', type: 'MaterialCommunityIcons' },
	{ name: 'transfer', type: 'MaterialCommunityIcons' },
	{ name: 'transit-transfer', type: 'MaterialCommunityIcons' },
	{ name: 'transition', type: 'MaterialCommunityIcons' },
	{ name: 'transition-masked', type: 'MaterialCommunityIcons' },
	{ name: 'translate', type: 'MaterialCommunityIcons' },
	{ name: 'trash-can', type: 'MaterialCommunityIcons' },
	{ name: 'trash-can-outline', type: 'MaterialCommunityIcons' },
	{ name: 'treasure-chest', type: 'MaterialCommunityIcons' },
	{ name: 'tree', type: 'MaterialCommunityIcons' },
	{ name: 'trello', type: 'MaterialCommunityIcons' },
	{ name: 'trending-down', type: 'MaterialCommunityIcons' },
	{ name: 'trending-neutral', type: 'MaterialCommunityIcons' },
	{ name: 'trending-up', type: 'MaterialCommunityIcons' },
	{ name: 'triangle', type: 'MaterialCommunityIcons' },
	{ name: 'triangle-outline', type: 'MaterialCommunityIcons' },
	{ name: 'trophy', type: 'MaterialCommunityIcons' },
	{ name: 'trophy-award', type: 'MaterialCommunityIcons' },
	{ name: 'trophy-outline', type: 'MaterialCommunityIcons' },
	{ name: 'trophy-variant', type: 'MaterialCommunityIcons' },
	{ name: 'trophy-variant-outline', type: 'MaterialCommunityIcons' },
	{ name: 'truck', type: 'MaterialCommunityIcons' },
	{ name: 'truck-delivery', type: 'MaterialCommunityIcons' },
	{ name: 'truck-fast', type: 'MaterialCommunityIcons' },
	{ name: 'truck-trailer', type: 'MaterialCommunityIcons' },
	{ name: 'tshirt-crew', type: 'MaterialCommunityIcons' },
	{ name: 'tshirt-crew-outline', type: 'MaterialCommunityIcons' },
	{ name: 'tshirt-v', type: 'MaterialCommunityIcons' },
	{ name: 'tshirt-v-outline', type: 'MaterialCommunityIcons' },
	{ name: 'tumble-dryer', type: 'MaterialCommunityIcons' },
	{ name: 'tumblr', type: 'MaterialCommunityIcons' },
	{ name: 'tumblr-box', type: 'MaterialCommunityIcons' },
	{ name: 'tumblr-reblog', type: 'MaterialCommunityIcons' },
	{ name: 'tune', type: 'MaterialCommunityIcons' },
	{ name: 'tune-vertical', type: 'MaterialCommunityIcons' },
	{ name: 'twitch', type: 'MaterialCommunityIcons' },
	{ name: 'twitter', type: 'MaterialCommunityIcons' },
	{ name: 'twitter-box', type: 'MaterialCommunityIcons' },
	{ name: 'twitter-circle', type: 'MaterialCommunityIcons' },
	{ name: 'twitter-retweet', type: 'MaterialCommunityIcons' },
	{ name: 'two-factor-authentication', type: 'MaterialCommunityIcons' },
	{ name: 'uber', type: 'MaterialCommunityIcons' },
	{ name: 'ubuntu', type: 'MaterialCommunityIcons' },
	{ name: 'ultra-high-definition', type: 'MaterialCommunityIcons' },
	{ name: 'umbraco', type: 'MaterialCommunityIcons' },
	{ name: 'umbrella', type: 'MaterialCommunityIcons' },
	{ name: 'umbrella-closed', type: 'MaterialCommunityIcons' },
	{ name: 'umbrella-outline', type: 'MaterialCommunityIcons' },
	{ name: 'undo', type: 'MaterialCommunityIcons' },
	{ name: 'undo-variant', type: 'MaterialCommunityIcons' },
	{ name: 'unfold-less-horizontal', type: 'MaterialCommunityIcons' },
	{ name: 'unfold-less-vertical', type: 'MaterialCommunityIcons' },
	{ name: 'unfold-more-horizontal', type: 'MaterialCommunityIcons' },
	{ name: 'unfold-more-vertical', type: 'MaterialCommunityIcons' },
	{ name: 'ungroup', type: 'MaterialCommunityIcons' },
	{ name: 'unity', type: 'MaterialCommunityIcons' },
	{ name: 'unreal', type: 'MaterialCommunityIcons' },
	{ name: 'untappd', type: 'MaterialCommunityIcons' },
	{ name: 'update', type: 'MaterialCommunityIcons' },
	{ name: 'upload', type: 'MaterialCommunityIcons' },
	{ name: 'upload-multiple', type: 'MaterialCommunityIcons' },
	{ name: 'upload-network', type: 'MaterialCommunityIcons' },
	{ name: 'usb', type: 'MaterialCommunityIcons' },
	{ name: 'van-passenger', type: 'MaterialCommunityIcons' },
	{ name: 'van-utility', type: 'MaterialCommunityIcons' },
	{ name: 'vanish', type: 'MaterialCommunityIcons' },
	{ name: 'variable', type: 'MaterialCommunityIcons' },
	{ name: 'vector-arrange-above', type: 'MaterialCommunityIcons' },
	{ name: 'vector-arrange-below', type: 'MaterialCommunityIcons' },
	{ name: 'vector-bezier', type: 'MaterialCommunityIcons' },
	{ name: 'vector-circle', type: 'MaterialCommunityIcons' },
	{ name: 'vector-circle-variant', type: 'MaterialCommunityIcons' },
	{ name: 'vector-combine', type: 'MaterialCommunityIcons' },
	{ name: 'vector-curve', type: 'MaterialCommunityIcons' },
	{ name: 'vector-difference', type: 'MaterialCommunityIcons' },
	{ name: 'vector-difference-ab', type: 'MaterialCommunityIcons' },
	{ name: 'vector-difference-ba', type: 'MaterialCommunityIcons' },
	{ name: 'vector-ellipse', type: 'MaterialCommunityIcons' },
	{ name: 'vector-intersection', type: 'MaterialCommunityIcons' },
	{ name: 'vector-line', type: 'MaterialCommunityIcons' },
	{ name: 'vector-point', type: 'MaterialCommunityIcons' },
	{ name: 'vector-polygon', type: 'MaterialCommunityIcons' },
	{ name: 'vector-polyline', type: 'MaterialCommunityIcons' },
	{ name: 'vector-radius', type: 'MaterialCommunityIcons' },
	{ name: 'vector-rectangle', type: 'MaterialCommunityIcons' },
	{ name: 'vector-selection', type: 'MaterialCommunityIcons' },
	{ name: 'vector-square', type: 'MaterialCommunityIcons' },
	{ name: 'vector-triangle', type: 'MaterialCommunityIcons' },
	{ name: 'vector-union', type: 'MaterialCommunityIcons' },
	{ name: 'venmo', type: 'MaterialCommunityIcons' },
	{ name: 'verified', type: 'MaterialCommunityIcons' },
	{ name: 'vhs', type: 'MaterialCommunityIcons' },
	{ name: 'vibrate', type: 'MaterialCommunityIcons' },
	{ name: 'video', type: 'MaterialCommunityIcons' },
	{ name: 'video-3d', type: 'MaterialCommunityIcons' },
	{ name: 'video-4k-box', type: 'MaterialCommunityIcons' },
	{ name: 'video-account', type: 'MaterialCommunityIcons' },
	{ name: 'video-image', type: 'MaterialCommunityIcons' },
	{ name: 'video-input-antenna', type: 'MaterialCommunityIcons' },
	{ name: 'video-input-component', type: 'MaterialCommunityIcons' },
	{ name: 'video-input-hdmi', type: 'MaterialCommunityIcons' },
	{ name: 'video-input-svideo', type: 'MaterialCommunityIcons' },
	{ name: 'video-minus', type: 'MaterialCommunityIcons' },
	{ name: 'video-off', type: 'MaterialCommunityIcons' },
	{ name: 'video-plus', type: 'MaterialCommunityIcons' },
	{ name: 'video-stabilization', type: 'MaterialCommunityIcons' },
	{ name: 'video-switch', type: 'MaterialCommunityIcons' },
	{ name: 'video-vintage', type: 'MaterialCommunityIcons' },
	{ name: 'view-agenda', type: 'MaterialCommunityIcons' },
	{ name: 'view-array', type: 'MaterialCommunityIcons' },
	{ name: 'view-carousel', type: 'MaterialCommunityIcons' },
	{ name: 'view-column', type: 'MaterialCommunityIcons' },
	{ name: 'view-dashboard', type: 'MaterialCommunityIcons' },
	{ name: 'view-dashboard-outline', type: 'MaterialCommunityIcons' },
	{ name: 'view-dashboard-variant', type: 'MaterialCommunityIcons' },
	{ name: 'view-day', type: 'MaterialCommunityIcons' },
	{ name: 'view-grid', type: 'MaterialCommunityIcons' },
	{ name: 'view-headline', type: 'MaterialCommunityIcons' },
	{ name: 'view-list', type: 'MaterialCommunityIcons' },
	{ name: 'view-module', type: 'MaterialCommunityIcons' },
	{ name: 'view-parallel', type: 'MaterialCommunityIcons' },
	{ name: 'view-quilt', type: 'MaterialCommunityIcons' },
	{ name: 'view-sequential', type: 'MaterialCommunityIcons' },
	{ name: 'view-stream', type: 'MaterialCommunityIcons' },
	{ name: 'view-week', type: 'MaterialCommunityIcons' },
	{ name: 'vimeo', type: 'MaterialCommunityIcons' },
	{ name: 'violin', type: 'MaterialCommunityIcons' },
	{ name: 'virtual-reality', type: 'MaterialCommunityIcons' },
	{ name: 'visual-studio', type: 'MaterialCommunityIcons' },
	{ name: 'visual-studio-code', type: 'MaterialCommunityIcons' },
	{ name: 'vk', type: 'MaterialCommunityIcons' },
	{ name: 'vk-box', type: 'MaterialCommunityIcons' },
	{ name: 'vk-circle', type: 'MaterialCommunityIcons' },
	{ name: 'vlc', type: 'MaterialCommunityIcons' },
	{ name: 'voice', type: 'MaterialCommunityIcons' },
	{ name: 'voicemail', type: 'MaterialCommunityIcons' },
	{ name: 'volleyball', type: 'MaterialCommunityIcons' },
	{ name: 'volume-high', type: 'MaterialCommunityIcons' },
	{ name: 'volume-low', type: 'MaterialCommunityIcons' },
	{ name: 'volume-medium', type: 'MaterialCommunityIcons' },
	{ name: 'volume-minus', type: 'MaterialCommunityIcons' },
	{ name: 'volume-mute', type: 'MaterialCommunityIcons' },
	{ name: 'volume-off', type: 'MaterialCommunityIcons' },
	{ name: 'volume-plus', type: 'MaterialCommunityIcons' },
	{ name: 'vote', type: 'MaterialCommunityIcons' },
	{ name: 'vote-outline', type: 'MaterialCommunityIcons' },
	{ name: 'vpn', type: 'MaterialCommunityIcons' },
	{ name: 'vuejs', type: 'MaterialCommunityIcons' },
	{ name: 'walk', type: 'MaterialCommunityIcons' },
	{ name: 'wall', type: 'MaterialCommunityIcons' },
	{ name: 'wall-sconce', type: 'MaterialCommunityIcons' },
	{ name: 'wall-sconce-flat', type: 'MaterialCommunityIcons' },
	{ name: 'wall-sconce-variant', type: 'MaterialCommunityIcons' },
	{ name: 'wallet', type: 'MaterialCommunityIcons' },
	{ name: 'wallet-giftcard', type: 'MaterialCommunityIcons' },
	{ name: 'wallet-membership', type: 'MaterialCommunityIcons' },
	{ name: 'wallet-travel', type: 'MaterialCommunityIcons' },
	{ name: 'wan', type: 'MaterialCommunityIcons' },
	{ name: 'washing-machine', type: 'MaterialCommunityIcons' },
	{ name: 'watch', type: 'MaterialCommunityIcons' },
	{ name: 'watch-export', type: 'MaterialCommunityIcons' },
	{ name: 'watch-export-variant', type: 'MaterialCommunityIcons' },
	{ name: 'watch-import', type: 'MaterialCommunityIcons' },
	{ name: 'watch-import-variant', type: 'MaterialCommunityIcons' },
	{ name: 'watch-variant', type: 'MaterialCommunityIcons' },
	{ name: 'watch-vibrate', type: 'MaterialCommunityIcons' },
	{ name: 'water', type: 'MaterialCommunityIcons' },
	{ name: 'water-off', type: 'MaterialCommunityIcons' },
	{ name: 'water-percent', type: 'MaterialCommunityIcons' },
	{ name: 'water-pump', type: 'MaterialCommunityIcons' },
	{ name: 'watermark', type: 'MaterialCommunityIcons' },
	{ name: 'waves', type: 'MaterialCommunityIcons' },
	{ name: 'weather-cloudy', type: 'MaterialCommunityIcons' },
	{ name: 'weather-fog', type: 'MaterialCommunityIcons' },
	{ name: 'weather-hail', type: 'MaterialCommunityIcons' },
	{ name: 'weather-hurricane', type: 'MaterialCommunityIcons' },
	{ name: 'weather-lightning', type: 'MaterialCommunityIcons' },
	{ name: 'weather-lightning-rainy', type: 'MaterialCommunityIcons' },
	{ name: 'weather-night', type: 'MaterialCommunityIcons' },
	{ name: 'weather-partlycloudy', type: 'MaterialCommunityIcons' },
	{ name: 'weather-pouring', type: 'MaterialCommunityIcons' },
	{ name: 'weather-rainy', type: 'MaterialCommunityIcons' },
	{ name: 'weather-snowy', type: 'MaterialCommunityIcons' },
	{ name: 'weather-snowy-rainy', type: 'MaterialCommunityIcons' },
	{ name: 'weather-sunny', type: 'MaterialCommunityIcons' },
	{ name: 'weather-sunset', type: 'MaterialCommunityIcons' },
	{ name: 'weather-sunset-down', type: 'MaterialCommunityIcons' },
	{ name: 'weather-sunset-up', type: 'MaterialCommunityIcons' },
	{ name: 'weather-windy', type: 'MaterialCommunityIcons' },
	{ name: 'weather-windy-variant', type: 'MaterialCommunityIcons' },
	{ name: 'web', type: 'MaterialCommunityIcons' },
	{ name: 'webcam', type: 'MaterialCommunityIcons' },
	{ name: 'webhook', type: 'MaterialCommunityIcons' },
	{ name: 'webpack', type: 'MaterialCommunityIcons' },
	{ name: 'wechat', type: 'MaterialCommunityIcons' },
	{ name: 'weight', type: 'MaterialCommunityIcons' },
	{ name: 'weight-kilogram', type: 'MaterialCommunityIcons' },
	{ name: 'weight-pound', type: 'MaterialCommunityIcons' },
	{ name: 'whatsapp', type: 'MaterialCommunityIcons' },
	{ name: 'wheelchair-accessibility', type: 'MaterialCommunityIcons' },
	{ name: 'whistle', type: 'MaterialCommunityIcons' },
	{ name: 'white-balance-auto', type: 'MaterialCommunityIcons' },
	{ name: 'white-balance-incandescent', type: 'MaterialCommunityIcons' },
	{ name: 'white-balance-iridescent', type: 'MaterialCommunityIcons' },
	{ name: 'white-balance-sunny', type: 'MaterialCommunityIcons' },
	{ name: 'widgets', type: 'MaterialCommunityIcons' },
	{ name: 'wifi', type: 'MaterialCommunityIcons' },
	{ name: 'wifi-off', type: 'MaterialCommunityIcons' },
	{ name: 'wifi-strength-1', type: 'MaterialCommunityIcons' },
	{ name: 'wifi-strength-1-alert', type: 'MaterialCommunityIcons' },
	{ name: 'wifi-strength-1-lock', type: 'MaterialCommunityIcons' },
	{ name: 'wifi-strength-2', type: 'MaterialCommunityIcons' },
	{ name: 'wifi-strength-2-alert', type: 'MaterialCommunityIcons' },
	{ name: 'wifi-strength-2-lock', type: 'MaterialCommunityIcons' },
	{ name: 'wifi-strength-3', type: 'MaterialCommunityIcons' },
	{ name: 'wifi-strength-3-alert', type: 'MaterialCommunityIcons' },
	{ name: 'wifi-strength-3-lock', type: 'MaterialCommunityIcons' },
	{ name: 'wifi-strength-4', type: 'MaterialCommunityIcons' },
	{ name: 'wifi-strength-4-alert', type: 'MaterialCommunityIcons' },
	{ name: 'wifi-strength-4-lock', type: 'MaterialCommunityIcons' },
	{ name: 'wifi-strength-alert-outline', type: 'MaterialCommunityIcons' },
	{ name: 'wifi-strength-lock-outline', type: 'MaterialCommunityIcons' },
	{ name: 'wifi-strength-off', type: 'MaterialCommunityIcons' },
	{ name: 'wifi-strength-off-outline', type: 'MaterialCommunityIcons' },
	{ name: 'wifi-strength-outline', type: 'MaterialCommunityIcons' },
	{ name: 'wii', type: 'MaterialCommunityIcons' },
	{ name: 'wiiu', type: 'MaterialCommunityIcons' },
	{ name: 'wikipedia', type: 'MaterialCommunityIcons' },
	{ name: 'window-close', type: 'MaterialCommunityIcons' },
	{ name: 'window-closed', type: 'MaterialCommunityIcons' },
	{ name: 'window-maximize', type: 'MaterialCommunityIcons' },
	{ name: 'window-minimize', type: 'MaterialCommunityIcons' },
	{ name: 'window-open', type: 'MaterialCommunityIcons' },
	{ name: 'window-restore', type: 'MaterialCommunityIcons' },
	{ name: 'windows', type: 'MaterialCommunityIcons' },
	{ name: 'windows-classic', type: 'MaterialCommunityIcons' },
	{ name: 'wiper', type: 'MaterialCommunityIcons' },
	{ name: 'wordpress', type: 'MaterialCommunityIcons' },
	{ name: 'worker', type: 'MaterialCommunityIcons' },
	{ name: 'wrap', type: 'MaterialCommunityIcons' },
	{ name: 'wrench', type: 'MaterialCommunityIcons' },
	{ name: 'wunderlist', type: 'MaterialCommunityIcons' },
	{ name: 'xamarin', type: 'MaterialCommunityIcons' },
	{ name: 'xamarin-outline', type: 'MaterialCommunityIcons' },
	{ name: 'xaml', type: 'MaterialCommunityIcons' },
	{ name: 'xbox', type: 'MaterialCommunityIcons' },
	{ name: 'xbox-controller', type: 'MaterialCommunityIcons' },
	{ name: 'xbox-controller-battery-alert', type: 'MaterialCommunityIcons' },
	{ name: 'xbox-controller-battery-charging', type: 'MaterialCommunityIcons' },
	{ name: 'xbox-controller-battery-empty', type: 'MaterialCommunityIcons' },
	{ name: 'xbox-controller-battery-full', type: 'MaterialCommunityIcons' },
	{ name: 'xbox-controller-battery-low', type: 'MaterialCommunityIcons' },
	{ name: 'xbox-controller-battery-medium', type: 'MaterialCommunityIcons' },
	{ name: 'xbox-controller-battery-unknown', type: 'MaterialCommunityIcons' },
	{ name: 'xbox-controller-off', type: 'MaterialCommunityIcons' },
	{ name: 'xda', type: 'MaterialCommunityIcons' },
	{ name: 'xing', type: 'MaterialCommunityIcons' },
	{ name: 'xing-box', type: 'MaterialCommunityIcons' },
	{ name: 'xing-circle', type: 'MaterialCommunityIcons' },
	{ name: 'xml', type: 'MaterialCommunityIcons' },
	{ name: 'xmpp', type: 'MaterialCommunityIcons' },
	{ name: 'yahoo', type: 'MaterialCommunityIcons' },
	{ name: 'yammer', type: 'MaterialCommunityIcons' },
	{ name: 'yeast', type: 'MaterialCommunityIcons' },
	{ name: 'yelp', type: 'MaterialCommunityIcons' },
	{ name: 'yin-yang', type: 'MaterialCommunityIcons' },
	{ name: 'youtube', type: 'MaterialCommunityIcons' },
	{ name: 'youtube-creator-studio', type: 'MaterialCommunityIcons' },
	{ name: 'youtube-gaming', type: 'MaterialCommunityIcons' },
	{ name: 'youtube-tv', type: 'MaterialCommunityIcons' },
	{ name: 'z-wave', type: 'MaterialCommunityIcons' },
	{ name: 'zend', type: 'MaterialCommunityIcons' },
	{ name: 'zip-box', type: 'MaterialCommunityIcons' },
	{ name: 'zip-disk', type: 'MaterialCommunityIcons' },
	{ name: 'zodiac-aquarius', type: 'MaterialCommunityIcons' },
	{ name: 'zodiac-aries', type: 'MaterialCommunityIcons' },
	{ name: 'zodiac-cancer', type: 'MaterialCommunityIcons' },
	{ name: 'zodiac-capricorn', type: 'MaterialCommunityIcons' },
	{ name: 'zodiac-gemini', type: 'MaterialCommunityIcons' },
	{ name: 'zodiac-leo', type: 'MaterialCommunityIcons' },
	{ name: 'zodiac-libra', type: 'MaterialCommunityIcons' },
	{ name: 'zodiac-pisces', type: 'MaterialCommunityIcons' },
	{ name: 'zodiac-sagittarius', type: 'MaterialCommunityIcons' },
	{ name: 'zodiac-scorpio', type: 'MaterialCommunityIcons' },
	{ name: 'zodiac-taurus', type: 'MaterialCommunityIcons' },
	{ name: 'zodiac-virgo', type: 'MaterialCommunityIcons' },
    { name: 'blank', type: 'MaterialCommunityIcons' },
    
    { name: '3d-rotation', type: 'MaterialIcons' },
    { name: 'ac-unit', type: 'MaterialIcons' },
    { name: 'access-alarm', type: 'MaterialIcons' },
    { name: 'access-alarms', type: 'MaterialIcons' },
    { name: 'access-time', type: 'MaterialIcons' },
    { name: 'accessibility', type: 'MaterialIcons' },
    { name: 'accessible', type: 'MaterialIcons' },
    { name: 'account-balance', type: 'MaterialIcons' },
    { name: 'account-balance-wallet', type: 'MaterialIcons' },
    { name: 'account-box', type: 'MaterialIcons' },
    { name: 'account-circle', type: 'MaterialIcons' },
    { name: 'adb', type: 'MaterialIcons' },
    { name: 'add', type: 'MaterialIcons' },
    { name: 'add-a-photo', type: 'MaterialIcons' },
    { name: 'add-alarm', type: 'MaterialIcons' },
    { name: 'add-alert', type: 'MaterialIcons' },
    { name: 'add-box', type: 'MaterialIcons' },
    { name: 'add-circle', type: 'MaterialIcons' },
    { name: 'add-circle-outline', type: 'MaterialIcons' },
    { name: 'add-location', type: 'MaterialIcons' },
    { name: 'add-shopping-cart', type: 'MaterialIcons' },
    { name: 'add-to-photos', type: 'MaterialIcons' },
    { name: 'add-to-queue', type: 'MaterialIcons' },
    { name: 'adjust', type: 'MaterialIcons' },
    { name: 'airline-seat-flat', type: 'MaterialIcons' },
    { name: 'airline-seat-flat-angled', type: 'MaterialIcons' },
    { name: 'airline-seat-individual-suite', type: 'MaterialIcons' },
    { name: 'airline-seat-legroom-extra', type: 'MaterialIcons' },
    { name: 'airline-seat-legroom-normal', type: 'MaterialIcons' },
    { name: 'airline-seat-legroom-reduced', type: 'MaterialIcons' },
    { name: 'airline-seat-recline-extra', type: 'MaterialIcons' },
    { name: 'airline-seat-recline-normal', type: 'MaterialIcons' },
    { name: 'airplanemode-active', type: 'MaterialIcons' },
    { name: 'airplanemode-inactive', type: 'MaterialIcons' },
    { name: 'airplay', type: 'MaterialIcons' },
    { name: 'airport-shuttle', type: 'MaterialIcons' },
    { name: 'alarm', type: 'MaterialIcons' },
    { name: 'alarm-add', type: 'MaterialIcons' },
    { name: 'alarm-off', type: 'MaterialIcons' },
    { name: 'alarm-on', type: 'MaterialIcons' },
    { name: 'album', type: 'MaterialIcons' },
    { name: 'all-inclusive', type: 'MaterialIcons' },
    { name: 'all-out', type: 'MaterialIcons' },
    { name: 'android', type: 'MaterialIcons' },
    { name: 'announcement', type: 'MaterialIcons' },
    { name: 'apps', type: 'MaterialIcons' },
    { name: 'archive', type: 'MaterialIcons' },
    { name: 'arrow-back', type: 'MaterialIcons' },
    { name: 'arrow-downward', type: 'MaterialIcons' },
    { name: 'arrow-drop-down', type: 'MaterialIcons' },
    { name: 'arrow-drop-down-circle', type: 'MaterialIcons' },
    { name: 'arrow-drop-up', type: 'MaterialIcons' },
    { name: 'arrow-forward', type: 'MaterialIcons' },
    { name: 'arrow-upward', type: 'MaterialIcons' },
    { name: 'art-track', type: 'MaterialIcons' },
    { name: 'aspect-ratio', type: 'MaterialIcons' },
    { name: 'assessment', type: 'MaterialIcons' },
    { name: 'assignment', type: 'MaterialIcons' },
    { name: 'assignment-ind', type: 'MaterialIcons' },
    { name: 'assignment-late', type: 'MaterialIcons' },
    { name: 'assignment-return', type: 'MaterialIcons' },
    { name: 'assignment-returned', type: 'MaterialIcons' },
    { name: 'assignment-turned-in', type: 'MaterialIcons' },
    { name: 'assistant', type: 'MaterialIcons' },
    { name: 'assistant-photo', type: 'MaterialIcons' },
    { name: 'attach-file', type: 'MaterialIcons' },
    { name: 'attach-money', type: 'MaterialIcons' },
    { name: 'attachment', type: 'MaterialIcons' },
    { name: 'audiotrack', type: 'MaterialIcons' },
    { name: 'autorenew', type: 'MaterialIcons' },
    { name: 'av-timer', type: 'MaterialIcons' },
    { name: 'backspace', type: 'MaterialIcons' },
    { name: 'backup', type: 'MaterialIcons' },
    { name: 'battery-alert', type: 'MaterialIcons' },
    { name: 'battery-charging-full', type: 'MaterialIcons' },
    { name: 'battery-full', type: 'MaterialIcons' },
    { name: 'battery-std', type: 'MaterialIcons' },
    { name: 'battery-unknown', type: 'MaterialIcons' },
    { name: 'beach-access', type: 'MaterialIcons' },
    { name: 'beenhere', type: 'MaterialIcons' },
    { name: 'block', type: 'MaterialIcons' },
    { name: 'bluetooth', type: 'MaterialIcons' },
    { name: 'bluetooth-audio', type: 'MaterialIcons' },
    { name: 'bluetooth-connected', type: 'MaterialIcons' },
    { name: 'bluetooth-disabled', type: 'MaterialIcons' },
    { name: 'bluetooth-searching', type: 'MaterialIcons' },
    { name: 'blur-circular', type: 'MaterialIcons' },
    { name: 'blur-linear', type: 'MaterialIcons' },
    { name: 'blur-off', type: 'MaterialIcons' },
    { name: 'blur-on', type: 'MaterialIcons' },
    { name: 'book', type: 'MaterialIcons' },
    { name: 'bookmark', type: 'MaterialIcons' },
    { name: 'bookmark-border', type: 'MaterialIcons' },
    { name: 'border-all', type: 'MaterialIcons' },
    { name: 'border-bottom', type: 'MaterialIcons' },
    { name: 'border-clear', type: 'MaterialIcons' },
    { name: 'border-color', type: 'MaterialIcons' },
    { name: 'border-horizontal', type: 'MaterialIcons' },
    { name: 'border-inner', type: 'MaterialIcons' },
    { name: 'border-left', type: 'MaterialIcons' },
    { name: 'border-outer', type: 'MaterialIcons' },
    { name: 'border-right', type: 'MaterialIcons' },
    { name: 'border-style', type: 'MaterialIcons' },
    { name: 'border-top', type: 'MaterialIcons' },
    { name: 'border-vertical', type: 'MaterialIcons' },
    { name: 'branding-watermark', type: 'MaterialIcons' },
    { name: 'brightness-1', type: 'MaterialIcons' },
    { name: 'brightness-2', type: 'MaterialIcons' },
    { name: 'brightness-3', type: 'MaterialIcons' },
    { name: 'brightness-4', type: 'MaterialIcons' },
    { name: 'brightness-5', type: 'MaterialIcons' },
    { name: 'brightness-6', type: 'MaterialIcons' },
    { name: 'brightness-7', type: 'MaterialIcons' },
    { name: 'brightness-auto', type: 'MaterialIcons' },
    { name: 'brightness-high', type: 'MaterialIcons' },
    { name: 'brightness-low', type: 'MaterialIcons' },
    { name: 'brightness-medium', type: 'MaterialIcons' },
    { name: 'broken-image', type: 'MaterialIcons' },
    { name: 'brush', type: 'MaterialIcons' },
    { name: 'bubble-chart', type: 'MaterialIcons' },
    { name: 'bug-report', type: 'MaterialIcons' },
    { name: 'build', type: 'MaterialIcons' },
    { name: 'burst-mode', type: 'MaterialIcons' },
    { name: 'business', type: 'MaterialIcons' },
    { name: 'business-center', type: 'MaterialIcons' },
    { name: 'cached', type: 'MaterialIcons' },
    { name: 'cake', type: 'MaterialIcons' },
    { name: 'call', type: 'MaterialIcons' },
    { name: 'call-end', type: 'MaterialIcons' },
    { name: 'call-made', type: 'MaterialIcons' },
    { name: 'call-merge', type: 'MaterialIcons' },
    { name: 'call-missed', type: 'MaterialIcons' },
    { name: 'call-missed-outgoing', type: 'MaterialIcons' },
    { name: 'call-received', type: 'MaterialIcons' },
    { name: 'call-split', type: 'MaterialIcons' },
    { name: 'call-to-action', type: 'MaterialIcons' },
    { name: 'camera', type: 'MaterialIcons' },
    { name: 'camera-alt', type: 'MaterialIcons' },
    { name: 'camera-enhance', type: 'MaterialIcons' },
    { name: 'camera-front', type: 'MaterialIcons' },
    { name: 'camera-rear', type: 'MaterialIcons' },
    { name: 'camera-roll', type: 'MaterialIcons' },
    { name: 'cancel', type: 'MaterialIcons' },
    { name: 'card-giftcard', type: 'MaterialIcons' },
    { name: 'card-membership', type: 'MaterialIcons' },
    { name: 'card-travel', type: 'MaterialIcons' },
    { name: 'casino', type: 'MaterialIcons' },
    { name: 'cast', type: 'MaterialIcons' },
    { name: 'cast-connected', type: 'MaterialIcons' },
    { name: 'center-focus-strong', type: 'MaterialIcons' },
    { name: 'center-focus-weak', type: 'MaterialIcons' },
    { name: 'change-history', type: 'MaterialIcons' },
    { name: 'chat', type: 'MaterialIcons' },
    { name: 'chat-bubble', type: 'MaterialIcons' },
    { name: 'chat-bubble-outline', type: 'MaterialIcons' },
    { name: 'check', type: 'MaterialIcons' },
    { name: 'check-box', type: 'MaterialIcons' },
    { name: 'check-box-outline-blank', type: 'MaterialIcons' },
    { name: 'check-circle', type: 'MaterialIcons' },
    { name: 'chevron-left', type: 'MaterialIcons' },
    { name: 'chevron-right', type: 'MaterialIcons' },
    { name: 'child-care', type: 'MaterialIcons' },
    { name: 'child-friendly', type: 'MaterialIcons' },
    { name: 'chrome-reader-mode', type: 'MaterialIcons' },
    { name: 'class', type: 'MaterialIcons' },
    { name: 'clear', type: 'MaterialIcons' },
    { name: 'clear-all', type: 'MaterialIcons' },
    { name: 'close', type: 'MaterialIcons' },
    { name: 'closed-caption', type: 'MaterialIcons' },
    { name: 'cloud', type: 'MaterialIcons' },
    { name: 'cloud-circle', type: 'MaterialIcons' },
    { name: 'cloud-done', type: 'MaterialIcons' },
    { name: 'cloud-download', type: 'MaterialIcons' },
    { name: 'cloud-off', type: 'MaterialIcons' },
    { name: 'cloud-queue', type: 'MaterialIcons' },
    { name: 'cloud-upload', type: 'MaterialIcons' },
    { name: 'code', type: 'MaterialIcons' },
    { name: 'collections', type: 'MaterialIcons' },
    { name: 'collections-bookmark', type: 'MaterialIcons' },
    { name: 'color-lens', type: 'MaterialIcons' },
    { name: 'colorize', type: 'MaterialIcons' },
    { name: 'comment', type: 'MaterialIcons' },
    { name: 'compare', type: 'MaterialIcons' },
    { name: 'compare-arrows', type: 'MaterialIcons' },
    { name: 'computer', type: 'MaterialIcons' },
    { name: 'confirmation-number', type: 'MaterialIcons' },
    { name: 'contact-mail', type: 'MaterialIcons' },
    { name: 'contact-phone', type: 'MaterialIcons' },
    { name: 'contacts', type: 'MaterialIcons' },
    { name: 'content-copy', type: 'MaterialIcons' },
    { name: 'content-cut', type: 'MaterialIcons' },
    { name: 'content-paste', type: 'MaterialIcons' },
    { name: 'control-point', type: 'MaterialIcons' },
    { name: 'control-point-duplicate', type: 'MaterialIcons' },
    { name: 'copyright', type: 'MaterialIcons' },
    { name: 'create', type: 'MaterialIcons' },
    { name: 'create-new-folder', type: 'MaterialIcons' },
    { name: 'credit-card', type: 'MaterialIcons' },
    { name: 'crop', type: 'MaterialIcons' },
    { name: 'crop-16-9', type: 'MaterialIcons' },
    { name: 'crop-3-2', type: 'MaterialIcons' },
    { name: 'crop-5-4', type: 'MaterialIcons' },
    { name: 'crop-7-5', type: 'MaterialIcons' },
    { name: 'crop-din', type: 'MaterialIcons' },
    { name: 'crop-free', type: 'MaterialIcons' },
    { name: 'crop-landscape', type: 'MaterialIcons' },
    { name: 'crop-original', type: 'MaterialIcons' },
    { name: 'crop-portrait', type: 'MaterialIcons' },
    { name: 'crop-rotate', type: 'MaterialIcons' },
    { name: 'crop-square', type: 'MaterialIcons' },
    { name: 'dashboard', type: 'MaterialIcons' },
    { name: 'data-usage', type: 'MaterialIcons' },
    { name: 'date-range', type: 'MaterialIcons' },
    { name: 'dehaze', type: 'MaterialIcons' },
    { name: 'delete', type: 'MaterialIcons' },
    { name: 'delete-forever', type: 'MaterialIcons' },
    { name: 'delete-sweep', type: 'MaterialIcons' },
    { name: 'description', type: 'MaterialIcons' },
    { name: 'desktop-mac', type: 'MaterialIcons' },
    { name: 'desktop-windows', type: 'MaterialIcons' },
    { name: 'details', type: 'MaterialIcons' },
    { name: 'developer-board', type: 'MaterialIcons' },
    { name: 'developer-mode', type: 'MaterialIcons' },
    { name: 'device-hub', type: 'MaterialIcons' },
    { name: 'devices', type: 'MaterialIcons' },
    { name: 'devices-other', type: 'MaterialIcons' },
    { name: 'dialer-sip', type: 'MaterialIcons' },
    { name: 'dialpad', type: 'MaterialIcons' },
    { name: 'directions', type: 'MaterialIcons' },
    { name: 'directions-bike', type: 'MaterialIcons' },
    { name: 'directions-boat', type: 'MaterialIcons' },
    { name: 'directions-bus', type: 'MaterialIcons' },
    { name: 'directions-car', type: 'MaterialIcons' },
    { name: 'directions-railway', type: 'MaterialIcons' },
    { name: 'directions-run', type: 'MaterialIcons' },
    { name: 'directions-subway', type: 'MaterialIcons' },
    { name: 'directions-transit', type: 'MaterialIcons' },
    { name: 'directions-walk', type: 'MaterialIcons' },
    { name: 'disc-full', type: 'MaterialIcons' },
    { name: 'dns', type: 'MaterialIcons' },
    { name: 'do-not-disturb', type: 'MaterialIcons' },
    { name: 'do-not-disturb-alt', type: 'MaterialIcons' },
    { name: 'do-not-disturb-off', type: 'MaterialIcons' },
    { name: 'do-not-disturb-on', type: 'MaterialIcons' },
    { name: 'dock', type: 'MaterialIcons' },
    { name: 'domain', type: 'MaterialIcons' },
    { name: 'done', type: 'MaterialIcons' },
    { name: 'done-all', type: 'MaterialIcons' },
    { name: 'donut-large', type: 'MaterialIcons' },
    { name: 'donut-small', type: 'MaterialIcons' },
    { name: 'drafts', type: 'MaterialIcons' },
    { name: 'drag-handle', type: 'MaterialIcons' },
    { name: 'drive-eta', type: 'MaterialIcons' },
    { name: 'dvr', type: 'MaterialIcons' },
    { name: 'edit', type: 'MaterialIcons' },
    { name: 'edit-location', type: 'MaterialIcons' },
    { name: 'eject', type: 'MaterialIcons' },
    { name: 'email', type: 'MaterialIcons' },
    { name: 'enhanced-encryption', type: 'MaterialIcons' },
    { name: 'equalizer', type: 'MaterialIcons' },
    { name: 'error', type: 'MaterialIcons' },
    { name: 'error-outline', type: 'MaterialIcons' },
    { name: 'euro-symbol', type: 'MaterialIcons' },
    { name: 'ev-station', type: 'MaterialIcons' },
    { name: 'event', type: 'MaterialIcons' },
    { name: 'event-available', type: 'MaterialIcons' },
    { name: 'event-busy', type: 'MaterialIcons' },
    { name: 'event-note', type: 'MaterialIcons' },
    { name: 'event-seat', type: 'MaterialIcons' },
    { name: 'exit-to-app', type: 'MaterialIcons' },
    { name: 'expand-less', type: 'MaterialIcons' },
    { name: 'expand-more', type: 'MaterialIcons' },
    { name: 'explicit', type: 'MaterialIcons' },
    { name: 'explore', type: 'MaterialIcons' },
    { name: 'exposure', type: 'MaterialIcons' },
    { name: 'exposure-neg-1', type: 'MaterialIcons' },
    { name: 'exposure-neg-2', type: 'MaterialIcons' },
    { name: 'exposure-plus-1', type: 'MaterialIcons' },
    { name: 'exposure-plus-2', type: 'MaterialIcons' },
    { name: 'exposure-zero', type: 'MaterialIcons' },
    { name: 'extension', type: 'MaterialIcons' },
    { name: 'face', type: 'MaterialIcons' },
    { name: 'fast-forward', type: 'MaterialIcons' },
    { name: 'fast-rewind', type: 'MaterialIcons' },
    { name: 'favorite', type: 'MaterialIcons' },
    { name: 'favorite-border', type: 'MaterialIcons' },
    { name: 'featured-play-list', type: 'MaterialIcons' },
    { name: 'featured-video', type: 'MaterialIcons' },
    { name: 'feedback', type: 'MaterialIcons' },
    { name: 'fiber-dvr', type: 'MaterialIcons' },
    { name: 'fiber-manual-record', type: 'MaterialIcons' },
    { name: 'fiber-new', type: 'MaterialIcons' },
    { name: 'fiber-pin', type: 'MaterialIcons' },
    { name: 'fiber-smart-record', type: 'MaterialIcons' },
    { name: 'file-download', type: 'MaterialIcons' },
    { name: 'file-upload', type: 'MaterialIcons' },
    { name: 'filter', type: 'MaterialIcons' },
    { name: 'filter-1', type: 'MaterialIcons' },
    { name: 'filter-2', type: 'MaterialIcons' },
    { name: 'filter-3', type: 'MaterialIcons' },
    { name: 'filter-4', type: 'MaterialIcons' },
    { name: 'filter-5', type: 'MaterialIcons' },
    { name: 'filter-6', type: 'MaterialIcons' },
    { name: 'filter-7', type: 'MaterialIcons' },
    { name: 'filter-8', type: 'MaterialIcons' },
    { name: 'filter-9', type: 'MaterialIcons' },
    { name: 'filter-9-plus', type: 'MaterialIcons' },
    { name: 'filter-b-and-w', type: 'MaterialIcons' },
    { name: 'filter-center-focus', type: 'MaterialIcons' },
    { name: 'filter-drama', type: 'MaterialIcons' },
    { name: 'filter-frames', type: 'MaterialIcons' },
    { name: 'filter-hdr', type: 'MaterialIcons' },
    { name: 'filter-list', type: 'MaterialIcons' },
    { name: 'filter-none', type: 'MaterialIcons' },
    { name: 'filter-tilt-shift', type: 'MaterialIcons' },
    { name: 'filter-vintage', type: 'MaterialIcons' },
    { name: 'find-in-page', type: 'MaterialIcons' },
    { name: 'find-replace', type: 'MaterialIcons' },
    { name: 'fingerprint', type: 'MaterialIcons' },
    { name: 'first-page', type: 'MaterialIcons' },
    { name: 'fitness-center', type: 'MaterialIcons' },
    { name: 'flag', type: 'MaterialIcons' },
    { name: 'flare', type: 'MaterialIcons' },
    { name: 'flash-auto', type: 'MaterialIcons' },
    { name: 'flash-off', type: 'MaterialIcons' },
    { name: 'flash-on', type: 'MaterialIcons' },
    { name: 'flight', type: 'MaterialIcons' },
    { name: 'flight-land', type: 'MaterialIcons' },
    { name: 'flight-takeoff', type: 'MaterialIcons' },
    { name: 'flip', type: 'MaterialIcons' },
    { name: 'flip-to-back', type: 'MaterialIcons' },
    { name: 'flip-to-front', type: 'MaterialIcons' },
    { name: 'folder', type: 'MaterialIcons' },
    { name: 'folder-open', type: 'MaterialIcons' },
    { name: 'folder-shared', type: 'MaterialIcons' },
    { name: 'folder-special', type: 'MaterialIcons' },
    { name: 'font-download', type: 'MaterialIcons' },
    { name: 'format-align-center', type: 'MaterialIcons' },
    { name: 'format-align-justify', type: 'MaterialIcons' },
    { name: 'format-align-left', type: 'MaterialIcons' },
    { name: 'format-align-right', type: 'MaterialIcons' },
    { name: 'format-bold', type: 'MaterialIcons' },
    { name: 'format-clear', type: 'MaterialIcons' },
    { name: 'format-color-fill', type: 'MaterialIcons' },
    { name: 'format-color-reset', type: 'MaterialIcons' },
    { name: 'format-color-text', type: 'MaterialIcons' },
    { name: 'format-indent-decrease', type: 'MaterialIcons' },
    { name: 'format-indent-increase', type: 'MaterialIcons' },
    { name: 'format-italic', type: 'MaterialIcons' },
    { name: 'format-line-spacing', type: 'MaterialIcons' },
    { name: 'format-list-bulleted', type: 'MaterialIcons' },
    { name: 'format-list-numbered', type: 'MaterialIcons' },
    { name: 'format-paint', type: 'MaterialIcons' },
    { name: 'format-quote', type: 'MaterialIcons' },
    { name: 'format-shapes', type: 'MaterialIcons' },
    { name: 'format-size', type: 'MaterialIcons' },
    { name: 'format-strikethrough', type: 'MaterialIcons' },
    { name: 'format-textdirection-l-to-r', type: 'MaterialIcons' },
    { name: 'format-textdirection-r-to-l', type: 'MaterialIcons' },
    { name: 'format-underlined', type: 'MaterialIcons' },
    { name: 'forum', type: 'MaterialIcons' },
    { name: 'forward', type: 'MaterialIcons' },
    { name: 'forward-10', type: 'MaterialIcons' },
    { name: 'forward-30', type: 'MaterialIcons' },
    { name: 'forward-5', type: 'MaterialIcons' },
    { name: 'free-breakfast', type: 'MaterialIcons' },
    { name: 'fullscreen', type: 'MaterialIcons' },
    { name: 'fullscreen-exit', type: 'MaterialIcons' },
    { name: 'functions', type: 'MaterialIcons' },
    { name: 'g-translate', type: 'MaterialIcons' },
    { name: 'gamepad', type: 'MaterialIcons' },
    { name: 'games', type: 'MaterialIcons' },
    { name: 'gavel', type: 'MaterialIcons' },
    { name: 'gesture', type: 'MaterialIcons' },
    { name: 'get-app', type: 'MaterialIcons' },
    { name: 'gif', type: 'MaterialIcons' },
    { name: 'golf-course', type: 'MaterialIcons' },
    { name: 'gps-fixed', type: 'MaterialIcons' },
    { name: 'gps-not-fixed', type: 'MaterialIcons' },
    { name: 'gps-off', type: 'MaterialIcons' },
    { name: 'grade', type: 'MaterialIcons' },
    { name: 'gradient', type: 'MaterialIcons' },
    { name: 'grain', type: 'MaterialIcons' },
    { name: 'graphic-eq', type: 'MaterialIcons' },
    { name: 'grid-off', type: 'MaterialIcons' },
    { name: 'grid-on', type: 'MaterialIcons' },
    { name: 'group', type: 'MaterialIcons' },
    { name: 'group-add', type: 'MaterialIcons' },
    { name: 'group-work', type: 'MaterialIcons' },
    { name: 'hd', type: 'MaterialIcons' },
    { name: 'hdr-off', type: 'MaterialIcons' },
    { name: 'hdr-on', type: 'MaterialIcons' },
    { name: 'hdr-strong', type: 'MaterialIcons' },
    { name: 'hdr-weak', type: 'MaterialIcons' },
    { name: 'headset', type: 'MaterialIcons' },
    { name: 'headset-mic', type: 'MaterialIcons' },
    { name: 'healing', type: 'MaterialIcons' },
    { name: 'hearing', type: 'MaterialIcons' },
    { name: 'help', type: 'MaterialIcons' },
    { name: 'help-outline', type: 'MaterialIcons' },
    { name: 'high-quality', type: 'MaterialIcons' },
    { name: 'highlight', type: 'MaterialIcons' },
    { name: 'highlight-off', type: 'MaterialIcons' },
    { name: 'history', type: 'MaterialIcons' },
    { name: 'home', type: 'MaterialIcons' },
    { name: 'hot-tub', type: 'MaterialIcons' },
    { name: 'hotel', type: 'MaterialIcons' },
    { name: 'hourglass-empty', type: 'MaterialIcons' },
    { name: 'hourglass-full', type: 'MaterialIcons' },
    { name: 'http', type: 'MaterialIcons' },
    { name: 'https', type: 'MaterialIcons' },
    { name: 'image', type: 'MaterialIcons' },
    { name: 'image-aspect-ratio', type: 'MaterialIcons' },
    { name: 'import-contacts', type: 'MaterialIcons' },
    { name: 'import-export', type: 'MaterialIcons' },
    { name: 'important-devices', type: 'MaterialIcons' },
    { name: 'inbox', type: 'MaterialIcons' },
    { name: 'indeterminate-check-box', type: 'MaterialIcons' },
    { name: 'info', type: 'MaterialIcons' },
    { name: 'info-outline', type: 'MaterialIcons' },
    { name: 'input', type: 'MaterialIcons' },
    { name: 'insert-chart', type: 'MaterialIcons' },
    { name: 'insert-comment', type: 'MaterialIcons' },
    { name: 'insert-drive-file', type: 'MaterialIcons' },
    { name: 'insert-emoticon', type: 'MaterialIcons' },
    { name: 'insert-invitation', type: 'MaterialIcons' },
    { name: 'insert-link', type: 'MaterialIcons' },
    { name: 'insert-photo', type: 'MaterialIcons' },
    { name: 'invert-colors', type: 'MaterialIcons' },
    { name: 'invert-colors-off', type: 'MaterialIcons' },
    { name: 'iso', type: 'MaterialIcons' },
    { name: 'keyboard', type: 'MaterialIcons' },
    { name: 'keyboard-arrow-down', type: 'MaterialIcons' },
    { name: 'keyboard-arrow-left', type: 'MaterialIcons' },
    { name: 'keyboard-arrow-right', type: 'MaterialIcons' },
    { name: 'keyboard-arrow-up', type: 'MaterialIcons' },
    { name: 'keyboard-backspace', type: 'MaterialIcons' },
    { name: 'keyboard-capslock', type: 'MaterialIcons' },
    { name: 'keyboard-hide', type: 'MaterialIcons' },
    { name: 'keyboard-return', type: 'MaterialIcons' },
    { name: 'keyboard-tab', type: 'MaterialIcons' },
    { name: 'keyboard-voice', type: 'MaterialIcons' },
    { name: 'kitchen', type: 'MaterialIcons' },
    { name: 'label', type: 'MaterialIcons' },
    { name: 'label-outline', type: 'MaterialIcons' },
    { name: 'landscape', type: 'MaterialIcons' },
    { name: 'language', type: 'MaterialIcons' },
    { name: 'laptop', type: 'MaterialIcons' },
    { name: 'laptop-chromebook', type: 'MaterialIcons' },
    { name: 'laptop-mac', type: 'MaterialIcons' },
    { name: 'laptop-windows', type: 'MaterialIcons' },
    { name: 'last-page', type: 'MaterialIcons' },
    { name: 'launch', type: 'MaterialIcons' },
    { name: 'layers', type: 'MaterialIcons' },
    { name: 'layers-clear', type: 'MaterialIcons' },
    { name: 'leak-add', type: 'MaterialIcons' },
    { name: 'leak-remove', type: 'MaterialIcons' },
    { name: 'lens', type: 'MaterialIcons' },
    { name: 'library-add', type: 'MaterialIcons' },
    { name: 'library-books', type: 'MaterialIcons' },
    { name: 'library-music', type: 'MaterialIcons' },
    { name: 'lightbulb-outline', type: 'MaterialIcons' },
    { name: 'line-style', type: 'MaterialIcons' },
    { name: 'line-weight', type: 'MaterialIcons' },
    { name: 'linear-scale', type: 'MaterialIcons' },
    { name: 'link', type: 'MaterialIcons' },
    { name: 'linked-camera', type: 'MaterialIcons' },
    { name: 'list', type: 'MaterialIcons' },
    { name: 'live-help', type: 'MaterialIcons' },
    { name: 'live-tv', type: 'MaterialIcons' },
    { name: 'local-activity', type: 'MaterialIcons' },
    { name: 'local-airport', type: 'MaterialIcons' },
    { name: 'local-atm', type: 'MaterialIcons' },
    { name: 'local-bar', type: 'MaterialIcons' },
    { name: 'local-cafe', type: 'MaterialIcons' },
    { name: 'local-car-wash', type: 'MaterialIcons' },
    { name: 'local-convenience-store', type: 'MaterialIcons' },
    { name: 'local-dining', type: 'MaterialIcons' },
    { name: 'local-drink', type: 'MaterialIcons' },
    { name: 'local-florist', type: 'MaterialIcons' },
    { name: 'local-gas-station', type: 'MaterialIcons' },
    { name: 'local-grocery-store', type: 'MaterialIcons' },
    { name: 'local-hospital', type: 'MaterialIcons' },
    { name: 'local-hotel', type: 'MaterialIcons' },
    { name: 'local-laundry-service', type: 'MaterialIcons' },
    { name: 'local-library', type: 'MaterialIcons' },
    { name: 'local-mall', type: 'MaterialIcons' },
    { name: 'local-movies', type: 'MaterialIcons' },
    { name: 'local-offer', type: 'MaterialIcons' },
    { name: 'local-parking', type: 'MaterialIcons' },
    { name: 'local-pharmacy', type: 'MaterialIcons' },
    { name: 'local-phone', type: 'MaterialIcons' },
    { name: 'local-pizza', type: 'MaterialIcons' },
    { name: 'local-play', type: 'MaterialIcons' },
    { name: 'local-post-office', type: 'MaterialIcons' },
    { name: 'local-printshop', type: 'MaterialIcons' },
    { name: 'local-see', type: 'MaterialIcons' },
    { name: 'local-shipping', type: 'MaterialIcons' },
    { name: 'local-taxi', type: 'MaterialIcons' },
    { name: 'location-city', type: 'MaterialIcons' },
    { name: 'location-disabled', type: 'MaterialIcons' },
    { name: 'location-off', type: 'MaterialIcons' },
    { name: 'location-on', type: 'MaterialIcons' },
    { name: 'location-searching', type: 'MaterialIcons' },
    { name: 'lock', type: 'MaterialIcons' },
    { name: 'lock-open', type: 'MaterialIcons' },
    { name: 'lock-outline', type: 'MaterialIcons' },
    { name: 'looks', type: 'MaterialIcons' },
    { name: 'looks-3', type: 'MaterialIcons' },
    { name: 'looks-4', type: 'MaterialIcons' },
    { name: 'looks-5', type: 'MaterialIcons' },
    { name: 'looks-6', type: 'MaterialIcons' },
    { name: 'looks-one', type: 'MaterialIcons' },
    { name: 'looks-two', type: 'MaterialIcons' },
    { name: 'loop', type: 'MaterialIcons' },
    { name: 'loupe', type: 'MaterialIcons' },
    { name: 'low-priority', type: 'MaterialIcons' },
    { name: 'loyalty', type: 'MaterialIcons' },
    { name: 'mail', type: 'MaterialIcons' },
    { name: 'mail-outline', type: 'MaterialIcons' },
    { name: 'map', type: 'MaterialIcons' },
    { name: 'markunread', type: 'MaterialIcons' },
    { name: 'markunread-mailbox', type: 'MaterialIcons' },
    { name: 'memory', type: 'MaterialIcons' },
    { name: 'menu', type: 'MaterialIcons' },
    { name: 'merge-type', type: 'MaterialIcons' },
    { name: 'message', type: 'MaterialIcons' },
    { name: 'mic', type: 'MaterialIcons' },
    { name: 'mic-none', type: 'MaterialIcons' },
    { name: 'mic-off', type: 'MaterialIcons' },
    { name: 'mms', type: 'MaterialIcons' },
    { name: 'mode-comment', type: 'MaterialIcons' },
    { name: 'mode-edit', type: 'MaterialIcons' },
    { name: 'monetization-on', type: 'MaterialIcons' },
    { name: 'money-off', type: 'MaterialIcons' },
    { name: 'monochrome-photos', type: 'MaterialIcons' },
    { name: 'mood', type: 'MaterialIcons' },
    { name: 'mood-bad', type: 'MaterialIcons' },
    { name: 'more', type: 'MaterialIcons' },
    { name: 'more-horiz', type: 'MaterialIcons' },
    { name: 'more-vert', type: 'MaterialIcons' },
    { name: 'motorcycle', type: 'MaterialIcons' },
    { name: 'mouse', type: 'MaterialIcons' },
    { name: 'move-to-inbox', type: 'MaterialIcons' },
    { name: 'movie', type: 'MaterialIcons' },
    { name: 'movie-creation', type: 'MaterialIcons' },
    { name: 'movie-filter', type: 'MaterialIcons' },
    { name: 'multiline-chart', type: 'MaterialIcons' },
    { name: 'music-note', type: 'MaterialIcons' },
    { name: 'music-video', type: 'MaterialIcons' },
    { name: 'my-location', type: 'MaterialIcons' },
    { name: 'nature', type: 'MaterialIcons' },
    { name: 'nature-people', type: 'MaterialIcons' },
    { name: 'navigate-before', type: 'MaterialIcons' },
    { name: 'navigate-next', type: 'MaterialIcons' },
    { name: 'navigation', type: 'MaterialIcons' },
    { name: 'near-me', type: 'MaterialIcons' },
    { name: 'network-cell', type: 'MaterialIcons' },
    { name: 'network-check', type: 'MaterialIcons' },
    { name: 'network-locked', type: 'MaterialIcons' },
    { name: 'network-wifi', type: 'MaterialIcons' },
    { name: 'new-releases', type: 'MaterialIcons' },
    { name: 'next-week', type: 'MaterialIcons' },
    { name: 'nfc', type: 'MaterialIcons' },
    { name: 'no-encryption', type: 'MaterialIcons' },
    { name: 'no-sim', type: 'MaterialIcons' },
    { name: 'not-interested', type: 'MaterialIcons' },
    { name: 'note', type: 'MaterialIcons' },
    { name: 'note-add', type: 'MaterialIcons' },
    { name: 'notifications', type: 'MaterialIcons' },
    { name: 'notifications-active', type: 'MaterialIcons' },
    { name: 'notifications-none', type: 'MaterialIcons' },
    { name: 'notifications-off', type: 'MaterialIcons' },
    { name: 'notifications-paused', type: 'MaterialIcons' },
    { name: 'offline-pin', type: 'MaterialIcons' },
    { name: 'ondemand-video', type: 'MaterialIcons' },
    { name: 'opacity', type: 'MaterialIcons' },
    { name: 'open-in-browser', type: 'MaterialIcons' },
    { name: 'open-in-new', type: 'MaterialIcons' },
    { name: 'open-with', type: 'MaterialIcons' },
    { name: 'pages', type: 'MaterialIcons' },
    { name: 'pageview', type: 'MaterialIcons' },
    { name: 'palette', type: 'MaterialIcons' },
    { name: 'pan-tool', type: 'MaterialIcons' },
    { name: 'panorama', type: 'MaterialIcons' },
    { name: 'panorama-fish-eye', type: 'MaterialIcons' },
    { name: 'panorama-horizontal', type: 'MaterialIcons' },
    { name: 'panorama-vertical', type: 'MaterialIcons' },
    { name: 'panorama-wide-angle', type: 'MaterialIcons' },
    { name: 'party-mode', type: 'MaterialIcons' },
    { name: 'pause', type: 'MaterialIcons' },
    { name: 'pause-circle-filled', type: 'MaterialIcons' },
    { name: 'pause-circle-outline', type: 'MaterialIcons' },
    { name: 'payment', type: 'MaterialIcons' },
    { name: 'people', type: 'MaterialIcons' },
    { name: 'people-outline', type: 'MaterialIcons' },
    { name: 'perm-camera-mic', type: 'MaterialIcons' },
    { name: 'perm-contact-calendar', type: 'MaterialIcons' },
    { name: 'perm-data-setting', type: 'MaterialIcons' },
    { name: 'perm-device-information', type: 'MaterialIcons' },
    { name: 'perm-identity', type: 'MaterialIcons' },
    { name: 'perm-media', type: 'MaterialIcons' },
    { name: 'perm-phone-msg', type: 'MaterialIcons' },
    { name: 'perm-scan-wifi', type: 'MaterialIcons' },
    { name: 'person', type: 'MaterialIcons' },
    { name: 'person-add', type: 'MaterialIcons' },
    { name: 'person-outline', type: 'MaterialIcons' },
    { name: 'person-pin', type: 'MaterialIcons' },
    { name: 'person-pin-circle', type: 'MaterialIcons' },
    { name: 'personal-video', type: 'MaterialIcons' },
    { name: 'pets', type: 'MaterialIcons' },
    { name: 'phone', type: 'MaterialIcons' },
    { name: 'phone-android', type: 'MaterialIcons' },
    { name: 'phone-bluetooth-speaker', type: 'MaterialIcons' },
    { name: 'phone-forwarded', type: 'MaterialIcons' },
    { name: 'phone-in-talk', type: 'MaterialIcons' },
    { name: 'phone-iphone', type: 'MaterialIcons' },
    { name: 'phone-locked', type: 'MaterialIcons' },
    { name: 'phone-missed', type: 'MaterialIcons' },
    { name: 'phone-paused', type: 'MaterialIcons' },
    { name: 'phonelink', type: 'MaterialIcons' },
    { name: 'phonelink-erase', type: 'MaterialIcons' },
    { name: 'phonelink-lock', type: 'MaterialIcons' },
    { name: 'phonelink-off', type: 'MaterialIcons' },
    { name: 'phonelink-ring', type: 'MaterialIcons' },
    { name: 'phonelink-setup', type: 'MaterialIcons' },
    { name: 'photo', type: 'MaterialIcons' },
    { name: 'photo-album', type: 'MaterialIcons' },
    { name: 'photo-camera', type: 'MaterialIcons' },
    { name: 'photo-filter', type: 'MaterialIcons' },
    { name: 'photo-library', type: 'MaterialIcons' },
    { name: 'photo-size-select-actual', type: 'MaterialIcons' },
    { name: 'photo-size-select-large', type: 'MaterialIcons' },
    { name: 'photo-size-select-small', type: 'MaterialIcons' },
    { name: 'picture-as-pdf', type: 'MaterialIcons' },
    { name: 'picture-in-picture', type: 'MaterialIcons' },
    { name: 'picture-in-picture-alt', type: 'MaterialIcons' },
    { name: 'pie-chart', type: 'MaterialIcons' },
    { name: 'pie-chart-outlined', type: 'MaterialIcons' },
    { name: 'pin-drop', type: 'MaterialIcons' },
    { name: 'place', type: 'MaterialIcons' },
    { name: 'play-arrow', type: 'MaterialIcons' },
    { name: 'play-circle-filled', type: 'MaterialIcons' },
    { name: 'play-circle-outline', type: 'MaterialIcons' },
    { name: 'play-for-work', type: 'MaterialIcons' },
    { name: 'playlist-add', type: 'MaterialIcons' },
    { name: 'playlist-add-check', type: 'MaterialIcons' },
    { name: 'playlist-play', type: 'MaterialIcons' },
    { name: 'plus-one', type: 'MaterialIcons' },
    { name: 'poll', type: 'MaterialIcons' },
    { name: 'polymer', type: 'MaterialIcons' },
    { name: 'pool', type: 'MaterialIcons' },
    { name: 'portable-wifi-off', type: 'MaterialIcons' },
    { name: 'portrait', type: 'MaterialIcons' },
    { name: 'power', type: 'MaterialIcons' },
    { name: 'power-input', type: 'MaterialIcons' },
    { name: 'power-settings-new', type: 'MaterialIcons' },
    { name: 'pregnant-woman', type: 'MaterialIcons' },
    { name: 'present-to-all', type: 'MaterialIcons' },
    { name: 'print', type: 'MaterialIcons' },
    { name: 'priority-high', type: 'MaterialIcons' },
    { name: 'public', type: 'MaterialIcons' },
    { name: 'publish', type: 'MaterialIcons' },
    { name: 'query-builder', type: 'MaterialIcons' },
    { name: 'question-answer', type: 'MaterialIcons' },
    { name: 'queue', type: 'MaterialIcons' },
    { name: 'queue-music', type: 'MaterialIcons' },
    { name: 'queue-play-next', type: 'MaterialIcons' },
    { name: 'radio', type: 'MaterialIcons' },
    { name: 'radio-button-checked', type: 'MaterialIcons' },
    { name: 'radio-button-unchecked', type: 'MaterialIcons' },
    { name: 'rate-review', type: 'MaterialIcons' },
    { name: 'receipt', type: 'MaterialIcons' },
    { name: 'recent-actors', type: 'MaterialIcons' },
    { name: 'record-voice-over', type: 'MaterialIcons' },
    { name: 'redeem', type: 'MaterialIcons' },
    { name: 'redo', type: 'MaterialIcons' },
    { name: 'refresh', type: 'MaterialIcons' },
    { name: 'remove', type: 'MaterialIcons' },
    { name: 'remove-circle', type: 'MaterialIcons' },
    { name: 'remove-circle-outline', type: 'MaterialIcons' },
    { name: 'remove-from-queue', type: 'MaterialIcons' },
    { name: 'remove-red-eye', type: 'MaterialIcons' },
    { name: 'remove-shopping-cart', type: 'MaterialIcons' },
    { name: 'reorder', type: 'MaterialIcons' },
    { name: 'repeat', type: 'MaterialIcons' },
    { name: 'repeat-one', type: 'MaterialIcons' },
    { name: 'replay', type: 'MaterialIcons' },
    { name: 'replay-10', type: 'MaterialIcons' },
    { name: 'replay-30', type: 'MaterialIcons' },
    { name: 'replay-5', type: 'MaterialIcons' },
    { name: 'reply', type: 'MaterialIcons' },
    { name: 'reply-all', type: 'MaterialIcons' },
    { name: 'report', type: 'MaterialIcons' },
    { name: 'report-problem', type: 'MaterialIcons' },
    { name: 'restaurant', type: 'MaterialIcons' },
    { name: 'restaurant-menu', type: 'MaterialIcons' },
    { name: 'restore', type: 'MaterialIcons' },
    { name: 'restore-page', type: 'MaterialIcons' },
    { name: 'ring-volume', type: 'MaterialIcons' },
    { name: 'room', type: 'MaterialIcons' },
    { name: 'room-service', type: 'MaterialIcons' },
    { name: 'rotate-90-degrees-ccw', type: 'MaterialIcons' },
    { name: 'rotate-left', type: 'MaterialIcons' },
    { name: 'rotate-right', type: 'MaterialIcons' },
    { name: 'rounded-corner', type: 'MaterialIcons' },
    { name: 'router', type: 'MaterialIcons' },
    { name: 'rowing', type: 'MaterialIcons' },
    { name: 'rss-feed', type: 'MaterialIcons' },
    { name: 'rv-hookup', type: 'MaterialIcons' },
    { name: 'satellite', type: 'MaterialIcons' },
    { name: 'save', type: 'MaterialIcons' },
    { name: 'scanner', type: 'MaterialIcons' },
    { name: 'schedule', type: 'MaterialIcons' },
    { name: 'school', type: 'MaterialIcons' },
    { name: 'screen-lock-landscape', type: 'MaterialIcons' },
    { name: 'screen-lock-portrait', type: 'MaterialIcons' },
    { name: 'screen-lock-rotation', type: 'MaterialIcons' },
    { name: 'screen-rotation', type: 'MaterialIcons' },
    { name: 'screen-share', type: 'MaterialIcons' },
    { name: 'sd-card', type: 'MaterialIcons' },
    { name: 'sd-storage', type: 'MaterialIcons' },
    { name: 'search', type: 'MaterialIcons' },
    { name: 'security', type: 'MaterialIcons' },
    { name: 'select-all', type: 'MaterialIcons' },
    { name: 'send', type: 'MaterialIcons' },
    { name: 'sentiment-dissatisfied', type: 'MaterialIcons' },
    { name: 'sentiment-neutral', type: 'MaterialIcons' },
    { name: 'sentiment-satisfied', type: 'MaterialIcons' },
    { name: 'sentiment-very-dissatisfied', type: 'MaterialIcons' },
    { name: 'sentiment-very-satisfied', type: 'MaterialIcons' },
    { name: 'settings', type: 'MaterialIcons' },
    { name: 'settings-applications', type: 'MaterialIcons' },
    { name: 'settings-backup-restore', type: 'MaterialIcons' },
    { name: 'settings-bluetooth', type: 'MaterialIcons' },
    { name: 'settings-brightness', type: 'MaterialIcons' },
    { name: 'settings-cell', type: 'MaterialIcons' },
    { name: 'settings-ethernet', type: 'MaterialIcons' },
    { name: 'settings-input-antenna', type: 'MaterialIcons' },
    { name: 'settings-input-component', type: 'MaterialIcons' },
    { name: 'settings-input-composite', type: 'MaterialIcons' },
    { name: 'settings-input-hdmi', type: 'MaterialIcons' },
    { name: 'settings-input-svideo', type: 'MaterialIcons' },
    { name: 'settings-overscan', type: 'MaterialIcons' },
    { name: 'settings-phone', type: 'MaterialIcons' },
    { name: 'settings-power', type: 'MaterialIcons' },
    { name: 'settings-remote', type: 'MaterialIcons' },
    { name: 'settings-system-daydream', type: 'MaterialIcons' },
    { name: 'settings-voice', type: 'MaterialIcons' },
    { name: 'share', type: 'MaterialIcons' },
    { name: 'shop', type: 'MaterialIcons' },
    { name: 'shop-two', type: 'MaterialIcons' },
    { name: 'shopping-basket', type: 'MaterialIcons' },
    { name: 'shopping-cart', type: 'MaterialIcons' },
    { name: 'short-text', type: 'MaterialIcons' },
    { name: 'show-chart', type: 'MaterialIcons' },
    { name: 'shuffle', type: 'MaterialIcons' },
    { name: 'signal-cellular-4-bar', type: 'MaterialIcons' },
    {
        name: 'signal-cellular-connected-no-internet-4-bar',
        type: 'MaterialIcons',
    },
    { name: 'signal-cellular-no-sim', type: 'MaterialIcons' },
    { name: 'signal-cellular-null', type: 'MaterialIcons' },
    { name: 'signal-cellular-off', type: 'MaterialIcons' },
    { name: 'signal-wifi-4-bar', type: 'MaterialIcons' },
    { name: 'signal-wifi-4-bar-lock', type: 'MaterialIcons' },
    { name: 'signal-wifi-off', type: 'MaterialIcons' },
    { name: 'sim-card', type: 'MaterialIcons' },
    { name: 'sim-card-alert', type: 'MaterialIcons' },
    { name: 'skip-next', type: 'MaterialIcons' },
    { name: 'skip-previous', type: 'MaterialIcons' },
    { name: 'slideshow', type: 'MaterialIcons' },
    { name: 'slow-motion-video', type: 'MaterialIcons' },
    { name: 'smartphone', type: 'MaterialIcons' },
    { name: 'smoke-free', type: 'MaterialIcons' },
    { name: 'smoking-rooms', type: 'MaterialIcons' },
    { name: 'sms', type: 'MaterialIcons' },
    { name: 'sms-failed', type: 'MaterialIcons' },
    { name: 'snooze', type: 'MaterialIcons' },
    { name: 'sort', type: 'MaterialIcons' },
    { name: 'sort-by-alpha', type: 'MaterialIcons' },
    { name: 'spa', type: 'MaterialIcons' },
    { name: 'space-bar', type: 'MaterialIcons' },
    { name: 'speaker', type: 'MaterialIcons' },
    { name: 'speaker-group', type: 'MaterialIcons' },
    { name: 'speaker-notes', type: 'MaterialIcons' },
    { name: 'speaker-notes-off', type: 'MaterialIcons' },
    { name: 'speaker-phone', type: 'MaterialIcons' },
    { name: 'spellcheck', type: 'MaterialIcons' },
    { name: 'star', type: 'MaterialIcons' },
    { name: 'star-border', type: 'MaterialIcons' },
    { name: 'star-half', type: 'MaterialIcons' },
    { name: 'stars', type: 'MaterialIcons' },
    { name: 'stay-current-landscape', type: 'MaterialIcons' },
    { name: 'stay-current-portrait', type: 'MaterialIcons' },
    { name: 'stay-primary-landscape', type: 'MaterialIcons' },
    { name: 'stay-primary-portrait', type: 'MaterialIcons' },
    { name: 'stop', type: 'MaterialIcons' },
    { name: 'stop-screen-share', type: 'MaterialIcons' },
    { name: 'storage', type: 'MaterialIcons' },
    { name: 'store', type: 'MaterialIcons' },
    { name: 'store-mall-directory', type: 'MaterialIcons' },
    { name: 'straighten', type: 'MaterialIcons' },
    { name: 'streetview', type: 'MaterialIcons' },
    { name: 'strikethrough-s', type: 'MaterialIcons' },
    { name: 'style', type: 'MaterialIcons' },
    { name: 'subdirectory-arrow-left', type: 'MaterialIcons' },
    { name: 'subdirectory-arrow-right', type: 'MaterialIcons' },
    { name: 'subject', type: 'MaterialIcons' },
    { name: 'subscriptions', type: 'MaterialIcons' },
    { name: 'subtitles', type: 'MaterialIcons' },
    { name: 'subway', type: 'MaterialIcons' },
    { name: 'supervisor-account', type: 'MaterialIcons' },
    { name: 'surround-sound', type: 'MaterialIcons' },
    { name: 'swap-calls', type: 'MaterialIcons' },
    { name: 'swap-horiz', type: 'MaterialIcons' },
    { name: 'swap-vert', type: 'MaterialIcons' },
    { name: 'swap-vertical-circle', type: 'MaterialIcons' },
    { name: 'switch-camera', type: 'MaterialIcons' },
    { name: 'switch-video', type: 'MaterialIcons' },
    { name: 'sync', type: 'MaterialIcons' },
    { name: 'sync-disabled', type: 'MaterialIcons' },
    { name: 'sync-problem', type: 'MaterialIcons' },
    { name: 'system-update', type: 'MaterialIcons' },
    { name: 'system-update-alt', type: 'MaterialIcons' },
    { name: 'tab', type: 'MaterialIcons' },
    { name: 'tab-unselected', type: 'MaterialIcons' },
    { name: 'tablet', type: 'MaterialIcons' },
    { name: 'tablet-android', type: 'MaterialIcons' },
    { name: 'tablet-mac', type: 'MaterialIcons' },
    { name: 'tag-faces', type: 'MaterialIcons' },
    { name: 'tap-and-play', type: 'MaterialIcons' },
    { name: 'terrain', type: 'MaterialIcons' },
    { name: 'text-fields', type: 'MaterialIcons' },
    { name: 'text-format', type: 'MaterialIcons' },
    { name: 'textsms', type: 'MaterialIcons' },
    { name: 'texture', type: 'MaterialIcons' },
    { name: 'theaters', type: 'MaterialIcons' },
    { name: 'thumb-down', type: 'MaterialIcons' },
    { name: 'thumb-up', type: 'MaterialIcons' },
    { name: 'thumbs-up-down', type: 'MaterialIcons' },
    { name: 'time-to-leave', type: 'MaterialIcons' },
    { name: 'timelapse', type: 'MaterialIcons' },
    { name: 'timeline', type: 'MaterialIcons' },
    { name: 'timer', type: 'MaterialIcons' },
    { name: 'timer-10', type: 'MaterialIcons' },
    { name: 'timer-3', type: 'MaterialIcons' },
    { name: 'timer-off', type: 'MaterialIcons' },
    { name: 'title', type: 'MaterialIcons' },
    { name: 'toc', type: 'MaterialIcons' },
    { name: 'today', type: 'MaterialIcons' },
    { name: 'toll', type: 'MaterialIcons' },
    { name: 'tonality', type: 'MaterialIcons' },
    { name: 'touch-app', type: 'MaterialIcons' },
    { name: 'toys', type: 'MaterialIcons' },
    { name: 'track-changes', type: 'MaterialIcons' },
    { name: 'traffic', type: 'MaterialIcons' },
    { name: 'train', type: 'MaterialIcons' },
    { name: 'tram', type: 'MaterialIcons' },
    { name: 'transfer-within-a-station', type: 'MaterialIcons' },
    { name: 'transform', type: 'MaterialIcons' },
    { name: 'translate', type: 'MaterialIcons' },
    { name: 'trending-down', type: 'MaterialIcons' },
    { name: 'trending-flat', type: 'MaterialIcons' },
    { name: 'trending-up', type: 'MaterialIcons' },
    { name: 'tune', type: 'MaterialIcons' },
    { name: 'turned-in', type: 'MaterialIcons' },
    { name: 'turned-in-not', type: 'MaterialIcons' },
    { name: 'tv', type: 'MaterialIcons' },
    { name: 'unarchive', type: 'MaterialIcons' },
    { name: 'undo', type: 'MaterialIcons' },
    { name: 'unfold-less', type: 'MaterialIcons' },
    { name: 'unfold-more', type: 'MaterialIcons' },
    { name: 'update', type: 'MaterialIcons' },
    { name: 'usb', type: 'MaterialIcons' },
    { name: 'verified-user', type: 'MaterialIcons' },
    { name: 'vertical-align-bottom', type: 'MaterialIcons' },
    { name: 'vertical-align-center', type: 'MaterialIcons' },
    { name: 'vertical-align-top', type: 'MaterialIcons' },
    { name: 'vibration', type: 'MaterialIcons' },
    { name: 'video-call', type: 'MaterialIcons' },
    { name: 'video-label', type: 'MaterialIcons' },
    { name: 'video-library', type: 'MaterialIcons' },
    { name: 'videocam', type: 'MaterialIcons' },
    { name: 'videocam-off', type: 'MaterialIcons' },
    { name: 'videogame-asset', type: 'MaterialIcons' },
    { name: 'view-agenda', type: 'MaterialIcons' },
    { name: 'view-array', type: 'MaterialIcons' },
    { name: 'view-carousel', type: 'MaterialIcons' },
    { name: 'view-column', type: 'MaterialIcons' },
    { name: 'view-comfy', type: 'MaterialIcons' },
    { name: 'view-compact', type: 'MaterialIcons' },
    { name: 'view-day', type: 'MaterialIcons' },
    { name: 'view-headline', type: 'MaterialIcons' },
    { name: 'view-list', type: 'MaterialIcons' },
    { name: 'view-module', type: 'MaterialIcons' },
    { name: 'view-quilt', type: 'MaterialIcons' },
    { name: 'view-stream', type: 'MaterialIcons' },
    { name: 'view-week', type: 'MaterialIcons' },
    { name: 'vignette', type: 'MaterialIcons' },
    { name: 'visibility', type: 'MaterialIcons' },
    { name: 'visibility-off', type: 'MaterialIcons' },
    { name: 'voice-chat', type: 'MaterialIcons' },
    { name: 'voicemail', type: 'MaterialIcons' },
    { name: 'volume-down', type: 'MaterialIcons' },
    { name: 'volume-mute', type: 'MaterialIcons' },
    { name: 'volume-off', type: 'MaterialIcons' },
    { name: 'volume-up', type: 'MaterialIcons' },
    { name: 'vpn-key', type: 'MaterialIcons' },
    { name: 'vpn-lock', type: 'MaterialIcons' },
    { name: 'wallpaper', type: 'MaterialIcons' },
    { name: 'warning', type: 'MaterialIcons' },
    { name: 'watch', type: 'MaterialIcons' },
    { name: 'watch-later', type: 'MaterialIcons' },
    { name: 'wb-auto', type: 'MaterialIcons' },
    { name: 'wb-cloudy', type: 'MaterialIcons' },
    { name: 'wb-incandescent', type: 'MaterialIcons' },
    { name: 'wb-iridescent', type: 'MaterialIcons' },
    { name: 'wb-sunny', type: 'MaterialIcons' },
    { name: 'wc', type: 'MaterialIcons' },
    { name: 'web', type: 'MaterialIcons' },
    { name: 'web-asset', type: 'MaterialIcons' },
    { name: 'weekend', type: 'MaterialIcons' },
    { name: 'whatshot', type: 'MaterialIcons' },
    { name: 'widgets', type: 'MaterialIcons' },
    { name: 'wifi', type: 'MaterialIcons' },
    { name: 'wifi-lock', type: 'MaterialIcons' },
    { name: 'wifi-tethering', type: 'MaterialIcons' },
    { name: 'work', type: 'MaterialIcons' },
    { name: 'wrap-text', type: 'MaterialIcons' },
    { name: 'youtube-searched-for', type: 'MaterialIcons' },
    { name: 'zoom-in', type: 'MaterialIcons' },
    { name: 'zoom-out', type: 'MaterialIcons' },
    { name: 'zoom-out-map', type: 'MaterialIcons' },


    { name: 'alert', type: 'Octicons' },
    { name: 'archive', type: 'Octicons' },
    { name: 'arrow-down', type: 'Octicons' },
    { name: 'arrow-left', type: 'Octicons' },
    { name: 'arrow-right', type: 'Octicons' },
    { name: 'arrow-small-down', type: 'Octicons' },
    { name: 'arrow-small-left', type: 'Octicons' },
    { name: 'arrow-small-right', type: 'Octicons' },
    { name: 'arrow-small-up', type: 'Octicons' },
    { name: 'arrow-up', type: 'Octicons' },
    { name: 'beaker', type: 'Octicons' },
    { name: 'bell', type: 'Octicons' },
    { name: 'bold', type: 'Octicons' },
    { name: 'book', type: 'Octicons' },
    { name: 'bookmark', type: 'Octicons' },
    { name: 'briefcase', type: 'Octicons' },
    { name: 'broadcast', type: 'Octicons' },
    { name: 'browser', type: 'Octicons' },
    { name: 'bug', type: 'Octicons' },
    { name: 'calendar', type: 'Octicons' },
    { name: 'check', type: 'Octicons' },
    { name: 'checklist', type: 'Octicons' },
    { name: 'chevron-down', type: 'Octicons' },
    { name: 'chevron-left', type: 'Octicons' },
    { name: 'chevron-right', type: 'Octicons' },
    { name: 'chevron-up', type: 'Octicons' },
    { name: 'circle-slash', type: 'Octicons' },
    { name: 'circuit-board', type: 'Octicons' },
    { name: 'clippy', type: 'Octicons' },
    { name: 'clock', type: 'Octicons' },
    { name: 'cloud-download', type: 'Octicons' },
    { name: 'cloud-upload', type: 'Octicons' },
    { name: 'code', type: 'Octicons' },
    { name: 'comment', type: 'Octicons' },
    { name: 'comment-discussion', type: 'Octicons' },
    { name: 'credit-card', type: 'Octicons' },
    { name: 'dash', type: 'Octicons' },
    { name: 'dashboard', type: 'Octicons' },
    { name: 'database', type: 'Octicons' },
    { name: 'desktop-download', type: 'Octicons' },
    { name: 'device-camera', type: 'Octicons' },
    { name: 'device-camera-video', type: 'Octicons' },
    { name: 'device-desktop', type: 'Octicons' },
    { name: 'device-mobile', type: 'Octicons' },
    { name: 'diff', type: 'Octicons' },
    { name: 'diff-added', type: 'Octicons' },
    { name: 'diff-ignored', type: 'Octicons' },
    { name: 'diff-modified', type: 'Octicons' },
    { name: 'diff-removed', type: 'Octicons' },
    { name: 'diff-renamed', type: 'Octicons' },
    { name: 'ellipsis', type: 'Octicons' },
    { name: 'eye', type: 'Octicons' },
    { name: 'file', type: 'Octicons' },
    { name: 'file-binary', type: 'Octicons' },
    { name: 'file-code', type: 'Octicons' },
    { name: 'file-directory', type: 'Octicons' },
    { name: 'file-media', type: 'Octicons' },
    { name: 'file-pdf', type: 'Octicons' },
    { name: 'file-submodule', type: 'Octicons' },
    { name: 'file-symlink-directory', type: 'Octicons' },
    { name: 'file-symlink-file', type: 'Octicons' },
    { name: 'file-zip', type: 'Octicons' },
    { name: 'flame', type: 'Octicons' },
    { name: 'fold', type: 'Octicons' },
    { name: 'gear', type: 'Octicons' },
    { name: 'gift', type: 'Octicons' },
    { name: 'gist', type: 'Octicons' },
    { name: 'gist-secret', type: 'Octicons' },
    { name: 'git-branch', type: 'Octicons' },
    { name: 'git-commit', type: 'Octicons' },
    { name: 'git-compare', type: 'Octicons' },
    { name: 'git-merge', type: 'Octicons' },
    { name: 'git-pull-request', type: 'Octicons' },
    { name: 'globe', type: 'Octicons' },
    { name: 'grabber', type: 'Octicons' },
    { name: 'graph', type: 'Octicons' },
    { name: 'heart', type: 'Octicons' },
    { name: 'history', type: 'Octicons' },
    { name: 'home', type: 'Octicons' },
    { name: 'horizontal-rule', type: 'Octicons' },
    { name: 'hubot', type: 'Octicons' },
    { name: 'inbox', type: 'Octicons' },
    { name: 'info', type: 'Octicons' },
    { name: 'issue-closed', type: 'Octicons' },
    { name: 'issue-opened', type: 'Octicons' },
    { name: 'issue-reopened', type: 'Octicons' },
    { name: 'italic', type: 'Octicons' },
    { name: 'jersey', type: 'Octicons' },
    { name: 'kebab-horizontal', type: 'Octicons' },
    { name: 'kebab-vertical', type: 'Octicons' },
    { name: 'key', type: 'Octicons' },
    { name: 'keyboard', type: 'Octicons' },
    { name: 'law', type: 'Octicons' },
    { name: 'light-bulb', type: 'Octicons' },
    { name: 'link', type: 'Octicons' },
    { name: 'link-external', type: 'Octicons' },
    { name: 'list-ordered', type: 'Octicons' },
    { name: 'list-unordered', type: 'Octicons' },
    { name: 'location', type: 'Octicons' },
    { name: 'lock', type: 'Octicons' },
    { name: 'logo-gist', type: 'Octicons' },
    { name: 'logo-github', type: 'Octicons' },
    { name: 'mail', type: 'Octicons' },
    { name: 'mail-read', type: 'Octicons' },
    { name: 'mark-github', type: 'Octicons' },
    { name: 'markdown', type: 'Octicons' },
    { name: 'megaphone', type: 'Octicons' },
    { name: 'mention', type: 'Octicons' },
    { name: 'milestone', type: 'Octicons' },
    { name: 'mirror', type: 'Octicons' },
    { name: 'mortar-board', type: 'Octicons' },
    { name: 'mute', type: 'Octicons' },
    { name: 'no-newline', type: 'Octicons' },
    { name: 'note', type: 'Octicons' },
    { name: 'octoface', type: 'Octicons' },
    { name: 'organization', type: 'Octicons' },
    { name: 'package', type: 'Octicons' },
    { name: 'paintcan', type: 'Octicons' },
    { name: 'pencil', type: 'Octicons' },
    { name: 'person', type: 'Octicons' },
    { name: 'pin', type: 'Octicons' },
    { name: 'plug', type: 'Octicons' },
    { name: 'plus', type: 'Octicons' },
    { name: 'plus-small', type: 'Octicons' },
    { name: 'primitive-dot', type: 'Octicons' },
    { name: 'primitive-square', type: 'Octicons' },
    { name: 'project', type: 'Octicons' },
    { name: 'pulse', type: 'Octicons' },
    { name: 'question', type: 'Octicons' },
    { name: 'quote', type: 'Octicons' },
    { name: 'radio-tower', type: 'Octicons' },
    { name: 'reply', type: 'Octicons' },
    { name: 'repo', type: 'Octicons' },
    { name: 'repo-clone', type: 'Octicons' },
    { name: 'repo-force-push', type: 'Octicons' },
    { name: 'repo-forked', type: 'Octicons' },
    { name: 'repo-pull', type: 'Octicons' },
    { name: 'repo-push', type: 'Octicons' },
    { name: 'report', type: 'Octicons' },
    { name: 'rocket', type: 'Octicons' },
    { name: 'rss', type: 'Octicons' },
    { name: 'ruby', type: 'Octicons' },
    { name: 'screen-full', type: 'Octicons' },
    { name: 'screen-normal', type: 'Octicons' },
    { name: 'search', type: 'Octicons' },
    { name: 'server', type: 'Octicons' },
    { name: 'settings', type: 'Octicons' },
    { name: 'shield', type: 'Octicons' },
    { name: 'sign-in', type: 'Octicons' },
    { name: 'sign-out', type: 'Octicons' },
    { name: 'smiley', type: 'Octicons' },
    { name: 'squirrel', type: 'Octicons' },
    { name: 'star', type: 'Octicons' },
    { name: 'stop', type: 'Octicons' },
    { name: 'sync', type: 'Octicons' },
    { name: 'tag', type: 'Octicons' },
    { name: 'tasklist', type: 'Octicons' },
    { name: 'telescope', type: 'Octicons' },
    { name: 'terminal', type: 'Octicons' },
    { name: 'text-size', type: 'Octicons' },
    { name: 'three-bars', type: 'Octicons' },
    { name: 'thumbsdown', type: 'Octicons' },
    { name: 'thumbsup', type: 'Octicons' },
    { name: 'tools', type: 'Octicons' },
    { name: 'trashcan', type: 'Octicons' },
    { name: 'triangle-down', type: 'Octicons' },
    { name: 'triangle-left', type: 'Octicons' },
    { name: 'triangle-right', type: 'Octicons' },
    { name: 'triangle-up', type: 'Octicons' },
    { name: 'unfold', type: 'Octicons' },
    { name: 'unmute', type: 'Octicons' },
    { name: 'unverified', type: 'Octicons' },
    { name: 'verified', type: 'Octicons' },
    { name: 'versions', type: 'Octicons' },
    { name: 'watch', type: 'Octicons' },
    { name: 'x', type: 'Octicons' },
    { name: 'zap', type: 'Octicons' },

    { name: 'user', type: 'SimpleLineIcons' },
	{ name: 'people', type: 'SimpleLineIcons' },
	{ name: 'user-female', type: 'SimpleLineIcons' },
	{ name: 'user-follow', type: 'SimpleLineIcons' },
	{ name: 'user-following', type: 'SimpleLineIcons' },
	{ name: 'user-unfollow', type: 'SimpleLineIcons' },
	{ name: 'login', type: 'SimpleLineIcons' },
	{ name: 'logout', type: 'SimpleLineIcons' },
	{ name: 'emotsmile', type: 'SimpleLineIcons' },
	{ name: 'phone', type: 'SimpleLineIcons' },
	{ name: 'call-end', type: 'SimpleLineIcons' },
	{ name: 'call-in', type: 'SimpleLineIcons' },
	{ name: 'call-out', type: 'SimpleLineIcons' },
	{ name: 'map', type: 'SimpleLineIcons' },
	{ name: 'location-pin', type: 'SimpleLineIcons' },
	{ name: 'direction', type: 'SimpleLineIcons' },
	{ name: 'directions', type: 'SimpleLineIcons' },
	{ name: 'compass', type: 'SimpleLineIcons' },
	{ name: 'layers', type: 'SimpleLineIcons' },
	{ name: 'menu', type: 'SimpleLineIcons' },
	{ name: 'list', type: 'SimpleLineIcons' },
	{ name: 'options-vertical', type: 'SimpleLineIcons' },
	{ name: 'options', type: 'SimpleLineIcons' },
	{ name: 'arrow-down', type: 'SimpleLineIcons' },
	{ name: 'arrow-left', type: 'SimpleLineIcons' },
	{ name: 'arrow-right', type: 'SimpleLineIcons' },
	{ name: 'arrow-up', type: 'SimpleLineIcons' },
	{ name: 'arrow-up-circle', type: 'SimpleLineIcons' },
	{ name: 'arrow-left-circle', type: 'SimpleLineIcons' },
	{ name: 'arrow-right-circle', type: 'SimpleLineIcons' },
	{ name: 'arrow-down-circle', type: 'SimpleLineIcons' },
	{ name: 'check', type: 'SimpleLineIcons' },
	{ name: 'clock', type: 'SimpleLineIcons' },
	{ name: 'plus', type: 'SimpleLineIcons' },
	{ name: 'minus', type: 'SimpleLineIcons' },
	{ name: 'close', type: 'SimpleLineIcons' },
	{ name: 'event', type: 'SimpleLineIcons' },
	{ name: 'exclamation', type: 'SimpleLineIcons' },
	{ name: 'organization', type: 'SimpleLineIcons' },
	{ name: 'trophy', type: 'SimpleLineIcons' },
	{ name: 'screen-smartphone', type: 'SimpleLineIcons' },
	{ name: 'screen-desktop', type: 'SimpleLineIcons' },
	{ name: 'plane', type: 'SimpleLineIcons' },
	{ name: 'notebook', type: 'SimpleLineIcons' },
	{ name: 'mustache', type: 'SimpleLineIcons' },
	{ name: 'mouse', type: 'SimpleLineIcons' },
	{ name: 'magnet', type: 'SimpleLineIcons' },
	{ name: 'energy', type: 'SimpleLineIcons' },
	{ name: 'disc', type: 'SimpleLineIcons' },
	{ name: 'cursor', type: 'SimpleLineIcons' },
	{ name: 'cursor-move', type: 'SimpleLineIcons' },
	{ name: 'crop', type: 'SimpleLineIcons' },
	{ name: 'chemistry', type: 'SimpleLineIcons' },
	{ name: 'speedometer', type: 'SimpleLineIcons' },
	{ name: 'shield', type: 'SimpleLineIcons' },
	{ name: 'screen-tablet', type: 'SimpleLineIcons' },
	{ name: 'magic-wand', type: 'SimpleLineIcons' },
	{ name: 'hourglass', type: 'SimpleLineIcons' },
	{ name: 'graduation', type: 'SimpleLineIcons' },
	{ name: 'ghost', type: 'SimpleLineIcons' },
	{ name: 'game-controller', type: 'SimpleLineIcons' },
	{ name: 'fire', type: 'SimpleLineIcons' },
	{ name: 'eyeglass', type: 'SimpleLineIcons' },
	{ name: 'envelope-open', type: 'SimpleLineIcons' },
	{ name: 'envelope-letter', type: 'SimpleLineIcons' },
	{ name: 'bell', type: 'SimpleLineIcons' },
	{ name: 'badge', type: 'SimpleLineIcons' },
	{ name: 'anchor', type: 'SimpleLineIcons' },
	{ name: 'wallet', type: 'SimpleLineIcons' },
	{ name: 'vector', type: 'SimpleLineIcons' },
	{ name: 'speech', type: 'SimpleLineIcons' },
	{ name: 'puzzle', type: 'SimpleLineIcons' },
	{ name: 'printer', type: 'SimpleLineIcons' },
	{ name: 'present', type: 'SimpleLineIcons' },
	{ name: 'playlist', type: 'SimpleLineIcons' },
	{ name: 'pin', type: 'SimpleLineIcons' },
	{ name: 'picture', type: 'SimpleLineIcons' },
	{ name: 'handbag', type: 'SimpleLineIcons' },
	{ name: 'globe-alt', type: 'SimpleLineIcons' },
	{ name: 'globe', type: 'SimpleLineIcons' },
	{ name: 'folder-alt', type: 'SimpleLineIcons' },
	{ name: 'folder', type: 'SimpleLineIcons' },
	{ name: 'film', type: 'SimpleLineIcons' },
	{ name: 'feed', type: 'SimpleLineIcons' },
	{ name: 'drop', type: 'SimpleLineIcons' },
	{ name: 'drawer', type: 'SimpleLineIcons' },
	{ name: 'docs', type: 'SimpleLineIcons' },
	{ name: 'doc', type: 'SimpleLineIcons' },
	{ name: 'diamond', type: 'SimpleLineIcons' },
	{ name: 'cup', type: 'SimpleLineIcons' },
	{ name: 'calculator', type: 'SimpleLineIcons' },
	{ name: 'bubbles', type: 'SimpleLineIcons' },
	{ name: 'briefcase', type: 'SimpleLineIcons' },
	{ name: 'book-open', type: 'SimpleLineIcons' },
	{ name: 'basket-loaded', type: 'SimpleLineIcons' },
	{ name: 'basket', type: 'SimpleLineIcons' },
	{ name: 'bag', type: 'SimpleLineIcons' },
	{ name: 'action-undo', type: 'SimpleLineIcons' },
	{ name: 'action-redo', type: 'SimpleLineIcons' },
	{ name: 'wrench', type: 'SimpleLineIcons' },
	{ name: 'umbrella', type: 'SimpleLineIcons' },
	{ name: 'trash', type: 'SimpleLineIcons' },
	{ name: 'tag', type: 'SimpleLineIcons' },
	{ name: 'support', type: 'SimpleLineIcons' },
	{ name: 'frame', type: 'SimpleLineIcons' },
	{ name: 'size-fullscreen', type: 'SimpleLineIcons' },
	{ name: 'size-actual', type: 'SimpleLineIcons' },
	{ name: 'shuffle', type: 'SimpleLineIcons' },
	{ name: 'share-alt', type: 'SimpleLineIcons' },
	{ name: 'share', type: 'SimpleLineIcons' },
	{ name: 'rocket', type: 'SimpleLineIcons' },
	{ name: 'question', type: 'SimpleLineIcons' },
	{ name: 'pie-chart', type: 'SimpleLineIcons' },
	{ name: 'pencil', type: 'SimpleLineIcons' },
	{ name: 'note', type: 'SimpleLineIcons' },
	{ name: 'loop', type: 'SimpleLineIcons' },
	{ name: 'home', type: 'SimpleLineIcons' },
	{ name: 'grid', type: 'SimpleLineIcons' },
	{ name: 'graph', type: 'SimpleLineIcons' },
	{ name: 'microphone', type: 'SimpleLineIcons' },
	{ name: 'music-tone-alt', type: 'SimpleLineIcons' },
	{ name: 'music-tone', type: 'SimpleLineIcons' },
	{ name: 'earphones-alt', type: 'SimpleLineIcons' },
	{ name: 'earphones', type: 'SimpleLineIcons' },
	{ name: 'equalizer', type: 'SimpleLineIcons' },
	{ name: 'like', type: 'SimpleLineIcons' },
	{ name: 'dislike', type: 'SimpleLineIcons' },
	{ name: 'control-start', type: 'SimpleLineIcons' },
	{ name: 'control-rewind', type: 'SimpleLineIcons' },
	{ name: 'control-play', type: 'SimpleLineIcons' },
	{ name: 'control-pause', type: 'SimpleLineIcons' },
	{ name: 'control-forward', type: 'SimpleLineIcons' },
	{ name: 'control-end', type: 'SimpleLineIcons' },
	{ name: 'volume-1', type: 'SimpleLineIcons' },
	{ name: 'volume-2', type: 'SimpleLineIcons' },
	{ name: 'volume-off', type: 'SimpleLineIcons' },
	{ name: 'calendar', type: 'SimpleLineIcons' },
	{ name: 'bulb', type: 'SimpleLineIcons' },
	{ name: 'chart', type: 'SimpleLineIcons' },
	{ name: 'ban', type: 'SimpleLineIcons' },
	{ name: 'bubble', type: 'SimpleLineIcons' },
	{ name: 'camrecorder', type: 'SimpleLineIcons' },
	{ name: 'camera', type: 'SimpleLineIcons' },
	{ name: 'cloud-download', type: 'SimpleLineIcons' },
	{ name: 'cloud-upload', type: 'SimpleLineIcons' },
	{ name: 'envelope', type: 'SimpleLineIcons' },
	{ name: 'eye', type: 'SimpleLineIcons' },
	{ name: 'flag', type: 'SimpleLineIcons' },
	{ name: 'heart', type: 'SimpleLineIcons' },
	{ name: 'info', type: 'SimpleLineIcons' },
	{ name: 'key', type: 'SimpleLineIcons' },
	{ name: 'link', type: 'SimpleLineIcons' },
	{ name: 'lock', type: 'SimpleLineIcons' },
	{ name: 'lock-open', type: 'SimpleLineIcons' },
	{ name: 'magnifier', type: 'SimpleLineIcons' },
	{ name: 'magnifier-add', type: 'SimpleLineIcons' },
	{ name: 'magnifier-remove', type: 'SimpleLineIcons' },
	{ name: 'paper-clip', type: 'SimpleLineIcons' },
	{ name: 'paper-plane', type: 'SimpleLineIcons' },
	{ name: 'power', type: 'SimpleLineIcons' },
	{ name: 'refresh', type: 'SimpleLineIcons' },
	{ name: 'reload', type: 'SimpleLineIcons' },
	{ name: 'settings', type: 'SimpleLineIcons' },
	{ name: 'star', type: 'SimpleLineIcons' },
	{ name: 'symbol-female', type: 'SimpleLineIcons' },
	{ name: 'symbol-male', type: 'SimpleLineIcons' },
	{ name: 'target', type: 'SimpleLineIcons' },
	{ name: 'credit-card', type: 'SimpleLineIcons' },
	{ name: 'paypal', type: 'SimpleLineIcons' },
	{ name: 'social-tumblr', type: 'SimpleLineIcons' },
	{ name: 'social-twitter', type: 'SimpleLineIcons' },
	{ name: 'social-facebook', type: 'SimpleLineIcons' },
	{ name: 'social-instagram', type: 'SimpleLineIcons' },
	{ name: 'social-linkedin', type: 'SimpleLineIcons' },
	{ name: 'social-pinterest', type: 'SimpleLineIcons' },
	{ name: 'social-github', type: 'SimpleLineIcons' },
	{ name: 'social-google', type: 'SimpleLineIcons' },
	{ name: 'social-reddit', type: 'SimpleLineIcons' },
	{ name: 'social-skype', type: 'SimpleLineIcons' },
	{ name: 'social-dribbble', type: 'SimpleLineIcons' },
	{ name: 'social-behance', type: 'SimpleLineIcons' },
	{ name: 'social-foursqare', type: 'SimpleLineIcons' },
	{ name: 'social-soundcloud', type: 'SimpleLineIcons' },
	{ name: 'social-spotify', type: 'SimpleLineIcons' },
	{ name: 'social-stumbleupon', type: 'SimpleLineIcons' },
	{ name: 'social-youtube', type: 'SimpleLineIcons' },
	{ name: 'social-dropbox', type: 'SimpleLineIcons' },
	{ name: 'social-vkontakte', type: 'SimpleLineIcons' },
	{ name: 'social-steam', type: 'SimpleLineIcons' },
];
