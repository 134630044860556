import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid, Avatar } from '@material-ui/core'; // Generate required css
import { Card, CardActionArea, CardActions, CardContent, Button, Typography } from '@material-ui/core';
import IconItem from './../../common/icons/IconItem';
import Jump from 'react-reveal/Jump';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
const styles = (theme) => ({
	root: {
		color: theme.palette.primary.main,

		textAlign: 'center'
	},
	whiteRoot: {
		color: '#fff'
	},
	card: {
		overflow: 'auto',
		cursor: 'pointer'
	},
	avatar: {
		margin: '10px auto',
		padding: '10px',
		width: 50,
		height: 50,
		background: 'transparent'
	},
	mediaContaier: {
		width: '40%',
		height: 'auto',
		margin: '0 auto'
	},
	image: {
		// ⚠️ object-fit is not supported by IE 11.
		objectFit: 'cover',
		width: '100%'
	},
	deleteBtn: {
		color: theme.palette.error.main,
		background: theme.palette.error.contrastText
	},

	whiteColor: {
		color: '#fff',
		textAlign: 'center'
	},
	blackColor: {
		color: '#333'
	},
	linkClass: {
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'none'
		}
	}
});
class CategoryItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isDelete: false
		};
	}
	componentDidCatch(error, info) {
		// You can also log the error to an error reporting service
	}
	render() {
		const { classes, category, isWhite } = this.props;
		return (
			<div style={{ display: 'inline-block' }}>
				<Jump>
					<Link to={'./../category/' + category.id} className={classes.linkClass}>
						<div className={classes.card}>
							<div className={isWhite === true ? classes.whiteRoot : classes.root}>
								<div>
									<Avatar className={classes.avatar}>
										{isWhite === true ? (
											<IconItem
												name={category.icon_name}
												font={category.icon_font}
												color='rgb(97, 96, 84)'
												size='1.5em'
											/>
										) : (
											<IconItem
												name={category.icon_name}
												font={category.icon_font}
												color={this.props.theme.palette.primary.main}
												size='1.5em'
											/>
										)}
									</Avatar>
								</div>
							</div>
						</div>
					</Link>
				</Jump>
			</div>
		);
	}
}

CategoryItem.propTypes = {
	classes: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(withStyles(styles, { withTheme: true })(CategoryItem));
